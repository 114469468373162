import { useApolloClient, useQuery } from '@apollo/client';
import { Document, Page, pdf, View, Text, StyleSheet } from '@react-pdf/renderer';
import ModalFlex from 'components/ModalFlex';
import { saveAs } from 'file-saver';
import { useWhyDidYouUpdate } from 'hooks';
import _ from 'lodash';
import { gqlGetByIdQuery } from 'pages/tiredeliveries/graphql';
import React from 'react';
import { createModal } from 'react-modal-promise';
import { PDFDocument, StandardFonts } from 'pdf-lib';
import { format, parseISO } from 'date-fns';
import { getFileUrl } from 'common/db-help-func';
import fontkit from '@pdf-lib/fontkit';
import { getPosition } from '../deliveryNote/PrintReport.modal';
//* ----- Report ----------------------------------------------------------------------------------------

enum TireTypesY {
  A = 279,
  B = 261,
  C = 244,
  D = 227,
  E = 210,
  F = 193,
}
export const wrap = (s, w) => {
  const newS = s.replace(new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, 'g'), '$1\n');
  const separateLines = newS.split(/\r?\n|\r|\n/g);
  return separateLines;
};

const addText = async (record, pdfDoc) => {
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  pdfDoc.registerFontkit(fontkit);
  const latoBytes = await fetch(process.env.PUBLIC_URL + '/fonts/RobotoCondensed-Regular.ttf').then(
    (res) => res.arrayBuffer(),
  );
  const latoBoldBytes = await fetch(
    process.env.PUBLIC_URL + '/fonts/RobotoCondensed-Bold.ttf',
  ).then((res) => res.arrayBuffer());

  const lato = await pdfDoc.embedFont(latoBytes);
  const latoBold = await pdfDoc.embedFont(latoBoldBytes);

  // Loading Unloading Number
  !!record.loadUnloadNoteNo &&
    firstPage.drawText(record.loadUnloadNoteNo, {
      x: 165,
      y: 710,
      size: 9,
      font: lato,
    });

  // Unloading Type
  !!record.typeId &&
    firstPage.drawText(record?.type?.name, {
      x: 400,
      y: 710,
      size: 11,
      font: latoBold,
    });

  //Delivery Date
  !!record.plannedDeliveryDate &&
    firstPage.drawText(format(parseISO(record.plannedDeliveryDate), 'dd/MM/yyyy'), {
      x: 163,
      y: 676,
      size: 9,
      font: lato,
    });
  !!record.plannedDeliveryDate &&
    firstPage.drawText(format(parseISO(record.plannedDeliveryDate), 'dd/MM/yyyy'), {
      x: 163,
      y: 616,
      size: 9,
      font: lato,
    });

  //Delivery Note Number
  if (
    !!record.deliveryNoteNo &&
    !record.deliveryNoteNo.substring(0, getPosition(record.deliveryNoteNo, ' ', 2))
  ) {
    !!record.deliveryNoteNo &&
      firstPage.drawText(record.deliveryNoteNo, {
        x: 512,
        y: 616,
        size: 8,
        font: lato,
      });
  } else {
    !!record.deliveryNoteNo &&
      firstPage.drawText(
        record.deliveryNoteNo.substring(0, getPosition(record.deliveryNoteNo, ' ', 2)),
        {
          x: 512,
          y: 616,
          size: 8,
          font: lato,
        },
      );
    !!record.deliveryNoteNo &&
      firstPage.drawText(
        record.deliveryNoteNo.substring(getPosition(record.deliveryNoteNo, ' ', 2) + 1),
        {
          x: 522,
          y: 605,
          size: 8,
          font: lato,
        },
      );
  }

  //Workstation
  if (record.valWorkstation?.length > 37) {
    wrap(record.valWorkstation, 37).map((rec, i) => {
      firstPage.drawText(rec, {
        x: 362,
        y: 668 - i * 8,
        size: 8,
        font: lato,
      });
    });
  } else {
    !!record.valWorkstation &&
      firstPage.drawText(record.valWorkstation, {
        x: 362,
        y: 661,
        size: 8,
        font: lato,
      });
  }

  //Collector Name
  !!record.colName &&
    firstPage.drawText(record.colName, {
      x: 364,
      y: 581,
      size: 8,
      font: lato,
    });

  //Collector Contract No
  !!record.colContract &&
    firstPage.drawText(record.colContract.substring(0, record.colContract.indexOf(',')), {
      x: 438,
      y: 536,
      size: 8,
      font: lato,
    });

  //Collector Additional Contract Info
  !!record.colContract &&
    firstPage.drawText(record.colContract.substring(record.colContract.indexOf(',') + 1), {
      x: 386,
      y: 526,
      size: 8,
      font: lato,
    });

  //Collector Platform
  !!record.colPlatName &&
    firstPage.drawText(record.colPlatName, {
      x: 373,
      y: 502,
      size: 8,
      font: lato,
    });

  //Collector Address
  if (record.colAddress?.length > 42) {
    firstPage.drawText(wrap(record.colAddress, 42)[0], {
      x: 365,
      y: 468,
      size: 8,
      font: lato,
    });
    firstPage.drawText(record.colAddress.substring(wrap(record.colAddress, 42)[0]?.length + 1), {
      x: 365,
      y: 459,
      size: 8,
      font: lato,
    });
  } else {
    !!record.colAddress &&
      firstPage.drawText(record.colAddress, {
        x: 365,
        y: 468,
        size: 8,
        font: lato,
      });
  }

  //Collector City and County
  let colCityCounty = record?.colCity + record?.colCounty;
  if (!!colCityCounty) {
    colCityCounty = record.colCity + ', ' + record.colCounty;
    if (colCityCounty?.length > 42) {
      firstPage.drawText(wrap(colCityCounty, 42)[0], {
        x: 365,
        y: 449,
        size: 8,
        font: lato,
      });
      firstPage.drawText(colCityCounty.substring(wrap(colCityCounty, 42)[0]?.length + 1), {
        x: 365,
        y: 441,
        size: 8,
        font: lato,
      });
    } else {
      !!colCityCounty &&
        firstPage.drawText(colCityCounty, {
          x: 365,
          y: 449,
          size: 8,
          font: lato,
        });
    }
  }

  //Collector Environmental Permit
  if (!!record.colEnvPermit) {
    if (record.colEnvPermit.includes(',')) {
      firstPage.drawText(record.colEnvPermit.substring(0, record.colEnvPermit.indexOf(',')), {
        x: 366,
        y: 422,
        size: 8,
        font: lato,
      });
      firstPage.drawText(record.colEnvPermit.substring(record.colEnvPermit.indexOf(',') + 1), {
        x: 364,
        y: 406,
        size: 8,
        font: lato,
      });
    } else {
      firstPage.drawText(record.colEnvPermit, {
        x: 366,
        y: 422,
        size: 8,
        font: lato,
      });
    }
  }

  //Collector Environmental Permit Expiration Date
  !!record.colEnvPermitExpDate &&
    firstPage.drawText(record.colEnvPermitExpDate, {
      x: 394,
      y: 375,
      size: 8,
      font: lato,
    });

  //Valoriser Name
  !!record.valName &&
    firstPage.drawText(record.valName, {
      x: 364,
      y: 284,
      size: 8,
      font: lato,
    });

  //Valoriser Address
  if (record.valAddress?.length > 42) {
    firstPage.drawText(wrap(record.valAddress, 42)[0], {
      x: 365,
      y: 264,
      size: 8,
      font: lato,
    });
    firstPage.drawText(record.valAddress.substring(wrap(record.valAddress, 42)[0]?.length + 1), {
      x: 365,
      y: 255,
      size: 8,
      font: lato,
    });
  } else {
    !!record.valAddress &&
      firstPage.drawText(record.valAddress, {
        x: 365,
        y: 264,
        size: 8,
        font: lato,
      });
  }

  //Valoriser City and County
  let valCityCounty = record?.valCity + record?.valCounty;
  if (!!valCityCounty) {
    valCityCounty = record.valCity + ', ' + record.valCounty;
    if (valCityCounty?.length > 42) {
      firstPage.drawText(wrap(valCityCounty, 42)[0], {
        x: 365,
        y: 247,
        size: 8,
        font: lato,
      });
      firstPage.drawText(valCityCounty.substring(wrap(valCityCounty, 42)[0]?.length + 1), {
        x: 365,
        y: 239,
        size: 8,
        font: lato,
      });
    } else {
      !!valCityCounty &&
        firstPage.drawText(valCityCounty, {
          x: 365,
          y: 247,
          size: 8,
          font: lato,
        });
    }
  }

  //Valoriser Environmental Permit
  !!record.valEnvPermit &&
    firstPage.drawText(record.valEnvPermit.substring(0, record.valEnvPermit.indexOf(',')), {
      x: 365,
      y: 215,
      size: 8,
      font: lato,
    });

  //Valoriser Additional Environmental Permit Info
  !!record.valEnvPermit &&
    firstPage.drawText(record.valEnvPermit.substring(record.valEnvPermit.indexOf(',') + 1), {
      x: 364,
      y: 198,
      size: 8,
      font: lato,
    });

  //Valoriser Environmental Permit Expiration Date
  !!record.valEnvPermitExpDate &&
    firstPage.drawText(record.valEnvPermitExpDate, {
      x: 394,
      y: 167,
      size: 8,
      font: lato,
    });

  // Transporter
  if (record.transporter?.length > 25) {
    wrap(record.transporter, 25).map((rec, i) => {
      firstPage.drawText(rec, {
        x: 53,
        y: 662 - i * 9,
        size: 8,
        font: lato,
      });
    });
  } else {
    !!record.transporter &&
      firstPage.drawText(record.transporter, {
        x: 53,
        y: 663,
        size: 8,
        font: lato,
      });
  }

  // Driver
  !!record.driver &&
    firstPage.drawText(record.driver, {
      x: 53,
      y: 602,
      size: 8,
      font: lato,
    });

  // Car License Plate
  !!record.vehPlateNo &&
    firstPage.drawText(record.vehPlateNo, {
      x: 53,
      y: 537,
      size: 8,
      font: lato,
    });

  // Trailer License Plate
  !!record.trailerPlateNo &&
    firstPage.drawText(record.trailerPlateNo, {
      x: 53,
      y: 496,
      size: 8,
      font: lato,
    });

  // Transport License
  !!record.licenseTransport &&
    firstPage.drawText(record.licenseTransport, {
      x: 73,
      y: 423,
      size: 8,
      font: lato,
    });

  // Transport License Expiration Date
  !!record.licenseTransportExpDate &&
    firstPage.drawText(format(parseISO(record.licenseTransportExpDate), 'dd/MM/yyyy'), {
      x: 53,
      y: 328,
      size: 8,
      font: lato,
    });

  //Tire Quantities
  record.tiredeliveriesQty.map((tireQty) => {
    !!tireQty.pieces &&
      firstPage.drawText(tireQty.pieces.toString(), {
        x: 327,
        y: TireTypesY[tireQty.tireType.name],
        size: 8,
        font: lato,
      });
  });
};

async function embedImages(signatureUrl, stampUrl, pdfDoc) {
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  if (!!signatureUrl) {
    const signatureImageBytes = await fetch(signatureUrl, { cache: 'no-cache' }).then((res) =>
      res.arrayBuffer(),
    );

    const signatureImage = await pdfDoc.embedPng(signatureImageBytes);

    //const signatureDims = signatureImage.scale(0.4);

    firstPage.drawImage(signatureImage, {
      x: 414,
      y: 330,
      width: 40,
      height: 40,
    });
  }

  if (!!stampUrl) {
    const stampImageBytes = await fetch(stampUrl, { cache: 'no-cache' }).then((res) =>
      res.arrayBuffer(),
    );

    const stampImage = await pdfDoc.embedPng(stampImageBytes);

    //const stampDims = stampImage.scale(0.4);

    firstPage.drawImage(stampImage, {
      x: 454,
      y: 330,
      width: 40,
      height: 40,
    });
  }
}

//* ----- Modal ----------------------------------------------------------------------------------------
//
type Props = {
  tireDeliveryId: number;
  isOpen: boolean; // for modal
  onResolve: () => void; // for modal
};

const PrintReport = ({ isOpen, onResolve, tireDeliveryId }: Props) => {
  const client = useApolloClient();
  const { data, loading } = useQuery(gqlGetByIdQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { id: tireDeliveryId },
    onCompleted: async (data) => {
      const record = data.getTiredeliveryById;
      const url = process.env.REACT_APP_S3BUCKET + '/documents/LoadingUnloadingV4.pdf';
      const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      addText(record, pdfDoc);
      let signatureUrl;
      let stampUrl;
      signatureUrl = await getFileUrl(client, record.collectorsPlatform.signatureFileId).catch(
        (err) => {
          console.log('signatureUrl error, ', err);
        },
      );
      if (!signatureUrl) {
        signatureUrl = await getFileUrl(client, record.collector.signatureFileId).catch((err) => {
          console.log('signatureUrl error, ', err);
        });
      }

      stampUrl = await getFileUrl(client, record.collectorsPlatform.stampFileId).catch((err) => {
        console.log('stampUrl error, ', err);
      });
      if (!stampUrl) {
        stampUrl = await getFileUrl(client, record.collector.stampFileId).catch((err) => {
          console.log('stampUrl error, ', err);
        });
      }

      await embedImages(signatureUrl, stampUrl, pdfDoc);

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes]);
      saveAs(blob, `${record.loadUnloadNoteNo}.pdf`);
      onResolve();
    },
  });

  useWhyDidYouUpdate('[PrintReport]', { data, loading });
  return <ModalFlex isOpen={isOpen} />;
};

export default createModal<Props, void>(PrintReport);
