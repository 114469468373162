import { CellFloatCustom, CellIntCustom } from 'motion-components';
import React from 'react';

export const CellFloatCustomX = ({
  value,
  numberWidth,
  decimalsDigits,
  noTrailingZeros = false,
  suffix = null,
  prefix = null,
}) => {
  let txt = '-';
  if (typeof value === 'string') {
    const i = parseFloat(value);
    if (isNaN(i)) txt = value;
    else {
      //txt = i.toLocaleString();
      txt = i.toLocaleString(undefined, {
        minimumFractionDigits: decimalsDigits,
        maximumFractionDigits: decimalsDigits,
      });
    }
  } else if (typeof value === 'number') {
    txt = value.toLocaleString(undefined, {
      minimumFractionDigits: decimalsDigits,
      maximumFractionDigits: decimalsDigits,
    });
  }
  // pad to 10 chars FIGURE SPACE = &numsp; U+2007
  //console.log(value, numberWidth, txt.length);
  if (numberWidth > txt.length) txt = '\u2007'.repeat(numberWidth - txt.length) + txt;

  // Χωρίς Μηδενικά στο Τέλος
  if (noTrailingZeros) {
    // i.e. 1.20 -> 1.2  & 1.00 -> 1
    if (txt.slice(-3) === '.00')
      txt = txt.substring(0, txt.length - 3) + '\u2008' + '\u2007' + '\u2007';
    if (txt.slice(-1) === '0') txt = txt.substring(0, txt.length - 1) + '\u2007';
  }

  return (
    <div className="text-left mb-2">
      {!!prefix && prefix} {txt} {!!suffix && suffix}
    </div>
  );
};

export const CellIntNegativeRed = (_a) => {
  var value = _a.cell.value;
  if (value < 0) return <div className="text-danger">{CellIntCustom(10)(_a)}</div>;
  else return <div>{CellIntCustom(10)(_a)}</div>;
};

export const CellFloatNegativeRed = (_a) => {
  var value = _a.cell.value;
  if (value < 0) return <div className="text-danger">{CellFloatCustom(12, 2)(_a)}</div>;
  else return <div>{CellFloatCustom(12, 2)(_a)}</div>;
};
