import { useLazyQuery } from '@apollo/client';
import { exportXlsModal } from 'motion-components';
import { gqlGetQuery } from '../graphql';
import { buildWhereFromFilterValues } from './filters';
import { useRootStore } from 'store';

export const exportColumns = (t, appStore) => {
  return [
    // { fieldName: "id", fieldTitle: t('Id", fieldType: "TEXT" },
    { fieldName: 'id', fieldTitle: t('ID'), fieldType: 'TEXT' },
    { fieldName: 'name', fieldTitle: t('Valoriser'), fieldType: 'TEXT' },
    { fieldName: 'vatin', fieldTitle: t('CUI/CNP'), fieldType: 'TEXT' },
    { fieldName: 'isBlackListed', fieldTitle: t('Blacklisted'), fieldType: 'BOOL' },
    ...(!appStore.isEcoanvelope
      ? [
          {
            fieldName: 'collectorsCollectionpoints[0].hasContract',
            fieldTitle: t('Has Contract'),
            fieldType: 'BOOL',
          },
        ]
      : []),
    { fieldName: 'categoryId', fieldTitle: t('Category'), fieldType: 'TEXT' },
    { fieldName: 'typeId', fieldTitle: t('Type'), fieldType: 'TEXT' },
    { fieldName: 'address', fieldTitle: t('Address'), fieldType: 'TEXT' },
    { fieldName: 'city', fieldTitle: t('City'), fieldType: 'TEXT' },
    { fieldName: 'countyId', fieldTitle: t('Region'), fieldType: 'TEXT' },
    { fieldName: 'phone', fieldTitle: t('Phone'), fieldType: 'TEXT' },
    { fieldName: 'mobile', fieldTitle: t('Mobile'), fieldType: 'TEXT' },
    { fieldName: 'fax', fieldTitle: t('Fax'), fieldType: 'TEXT' },
    { fieldName: 'email', fieldTitle: t('E-mail'), fieldType: 'TEXT' },
    { fieldName: 'website', fieldTitle: t('Web Site Address'), fieldType: 'TEXT' },
    { fieldName: 'contactperson', fieldTitle: t('Contact Person'), fieldType: 'TEXT' },
    { fieldName: 'notes', fieldTitle: t('Notes'), fieldType: 'TEXT' },
    { fieldName: 'workDaysFrom', fieldTitle: t('Monday to Friday from'), fieldType: 'DATETIME' },
    { fieldName: 'workDaysTill', fieldTitle: t('Till'), fieldType: 'DATETIME' },
    { fieldName: 'saturdayDaysFrom', fieldTitle: t('Saturday from'), fieldType: 'DATETIME' },
    { fieldName: 'saturdayDaysTill', fieldTitle: t('Till'), fieldType: 'DATETIME' },
    { fieldName: 'sundayDaysFrom', fieldTitle: t('Sunday from'), fieldType: 'DATETIME' },
    { fieldName: 'sundayDaysTill', fieldTitle: t('Till'), fieldType: 'DATETIME' },
    { fieldName: 'isActive', fieldTitle: t('Active'), fieldType: 'BOOL' },
    { fieldName: 'firstDate', fieldTitle: t('First Date'), fieldType: 'DATETIME' },
    { fieldName: 'lastDate', fieldTitle: t('Last Date'), fieldType: 'DATETIME' },
    { fieldName: 'firstUser', fieldTitle: t('First User'), fieldType: 'TEXT' },
    { fieldName: 'lastUser', fieldTitle: t('Last User'), fieldType: 'TEXT' },
  ];
};

export async function handleExport(client, queryState: any, t, appStore): Promise<void> {
  function useGetQuery() {
    return useLazyQuery(gqlGetQuery);
    //return [null, { loading: null, data: null }];
  }
  const filterVariables = {
    where: buildWhereFromFilterValues(queryState?.filterValues),
  };
  await exportXlsModal({
    apolloClient: client,
    exportFileName: 'export Collectionpoints',
    getLazyQuery: useGetQuery,
    columnsDef: exportColumns(t, appStore),
    filterVariables: filterVariables,
  });
}
