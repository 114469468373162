import React from 'react';
import { Row, Tabs, Tab } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { useHandleError } from 'services';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';
import { WeighingNoteButton } from './weighing-note/weighingnote-button';
import { FormFieldsHeader } from './form-fields-header';
import {
  TabDeliveryRequest,
  TabValorisation,
  TabWeighing,
  TabTruckLoading,
  TabLegacyData,
  TabActions,
  TabDocuments,
} from './tabs';
import { TireDeliveriesStatus } from 'shared/constants';
import { DeliveryActionsButtons } from './actions-buttons';

export const FormFields = ({
  isNew,
  updateRecord,
  refetch,
  data,
  handleApprove,
  dataRefetched,
}) => {
  const { t } = useTranslation();
  //
  const frmkCntx = useFormikContext();
  const { values } = frmkCntx;
  const { appStore } = useRootStore();

  const { handleGraphqlError } = useHandleError('TiredeliveriesFormFields');

  function calcUserCanEdit() {
    const canEdit = true;
    const canEditReasoning = '';
    return { canEdit, canEditReasoning };
  }
  // const canEdit =
  //   !(values as any)?.isFinalized && (authStore.userCanDraft(userRoleId) || authStore.userCanFinalize(userRoleId));
  const { canEdit, canEditReasoning } = calcUserCanEdit();

  function isTabEnabled(tabName: string): boolean {
    if (tabName === 'TabDeliveryRequest') {
      return true;
    } else if (tabName === 'TabTruckLoading') {
      if (data.statusId === TireDeliveriesStatus.Rejected) return false;
      else return true;
    } else if (tabName === 'TabWeighing') {
      if (data.statusId === TireDeliveriesStatus.Rejected) return false;
      else return true;
    } else if (tabName === 'TabValorisation') {
      if (data.statusId === TireDeliveriesStatus.Rejected) return false;
      else return true;
    } else if (tabName === 'TabLegacyData') {
      if (data.statusId === TireDeliveriesStatus.Rejected) return false;
      else return true;
    } else if (tabName === 'TabActions') {
      return true;
    } else if (tabName === 'TabDocuments') {
      return true;
    }
  }

  function isTabReadOnly(tabName: string) {
    const statusId = data.statusId;
    if (tabName === 'TabDeliveryRequest') {
      return true;
    } else if (tabName === 'TabTruckLoading') {
      if (
        (appStore.isCollector && [TireDeliveriesStatus.Approved].includes(statusId)) ||
        (appStore.isEcoanvelope &&
          [
            TireDeliveriesStatus.Approved,
            TireDeliveriesStatus.Delivered,
            TireDeliveriesStatus.UnconfirmedVal,
            TireDeliveriesStatus.UnconfirmedCol,
            TireDeliveriesStatus.Unreconciled,
            TireDeliveriesStatus.Valorised,
          ].includes(statusId))
      )
        return false;
      else return true;
    } else if (tabName === 'TabWeighing') {
      if (
        appStore.isEcoanvelope &&
        [
          TireDeliveriesStatus.Delivered,
          TireDeliveriesStatus.Approved,
          TireDeliveriesStatus.UnconfirmedVal,
          TireDeliveriesStatus.UnconfirmedCol,
          TireDeliveriesStatus.Unreconciled,
          TireDeliveriesStatus.Valorised,
        ].includes(statusId)
      )
        return false;
      else return true;
    } else if (tabName === 'TabValorisation') {
      if (
        appStore.isEcoanvelope &&
        [TireDeliveriesStatus.Delivered, TireDeliveriesStatus.Valorised].includes(statusId)
      )
        return false;
      else return true;
    } else if (tabName === 'TabLegacyData') {
      return true;
    } else if (tabName === 'TabActions') {
      return true;
    } else if (tabName === 'TabDocuments') {
      return true;
    }
  }

  return (
    <>
      {/* Header */}
      <FormFieldsHeader data={data} />

      {/* Action Buttons */}
      <DeliveryActionsButtons refetch={refetch} data={data} />

      {/* Tab */}
      <Tabs defaultActiveKey="form">
        <Tab
          eventKey="form"
          title={t('Delivery Request')}
          disabled={!isTabEnabled('TabDeliveryRequest')}
        >
          <TabDeliveryRequest readonly={!isTabReadOnly('TabDeliveryRequest')} />
        </Tab>
        <Tab
          eventKey="weighing1"
          title={t("Collector's Docs")}
          disabled={!isTabEnabled('TabTruckLoading')}
        >
          <TabTruckLoading
            data={data}
            refetch={refetch}
            readonly={isTabReadOnly('TabTruckLoading')}
          />
        </Tab>
        <Tab
          eventKey="weighing"
          title={t('Valoriser Weighing')}
          disabled={!isTabEnabled('TabWeighing')}
        >
          <TabWeighing readonly={isTabReadOnly('TabWeighing')} />
        </Tab>
        <Tab
          eventKey="valorisation"
          title={t('Valorisation')}
          disabled={!isTabEnabled('TabValorisation')}
        >
          <TabValorisation readonly={isTabReadOnly('TabValorisation')} />
        </Tab>
        <Tab eventKey="actions" title={t('Actions History')} disabled={!isTabEnabled('TabActions')}>
          <TabActions
            tiredeliveryId={data.id}
            readonly={isTabReadOnly('TabActions')}
            forceRerender={dataRefetched}
          />
        </Tab>
        <Tab eventKey="documents" title={t('Documents')} disabled={!isTabEnabled('TabDocuments')}>
          <TabDocuments
            refetch={refetch}
            tiredeliveryId={data.id}
            readonly={isTabReadOnly('TabDocuments')}
            forceRerender={dataRefetched}
            tireDelivery={data}
          />
        </Tab>
      </Tabs>

      <hr />
    </>
  );
};
