import { NavLinkItem, toastFailure, toastSuccess } from 'motion-components';
import React, { FC } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { FaQuestionCircle } from 'react-icons/fa';
import { generatePath } from 'react-router-dom';
import { getRouteByName } from './routes';
import { useRootStore } from 'store';
import { t } from 'i18next';
import printReport from '../pages/tirecollections/form/print-notes/PrintReportCol.modal';
import printReportCP from '../pages/tirecollections/form/print-notes/PrintReportCP.modal';
import promiseModalSubmitReport from 'components/modal-submit-report';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { gitnum } from 'gitnum';
import { getBrowserType } from 'utils/helpers';

const MI = {
  FILES: 'Files',
  TIRE_TYPES: 'Tire Types',
  REGIONS: 'Regions',
  COUNTIES: 'Counties',
  USER_ROLES: 'User Roles',
  USERS: 'Users',
  VALORISERS: 'Valorisers',
  VALORISER: 'Valoriser',
  VALORISATION_METHODS: 'Valorisation Methods',
  VALORISATION_GROUPS: 'Valorisation Groups',
  COLLECTOR: 'Collector',
  COLLECTORS: 'Collectors',
  COLLECTOR_PLATFORMS: 'Collector Platforms',
  COLLECTION_POINTS: 'Collection Points',
  COLLECTION_POINT: 'Collection Point',
  COLLECTION_POINT_TYPES: 'Collection Point Types',
  COLLECTION_POINT_CATEGORIES: 'Collection Point Categories',
  TIRE_COLLECTIONS: 'Tire Collections',
  TIRE_COLLECTION_STATES: 'Tire Collection States',

  TIRE_STOCKS: 'Tire Stocks',
  COLLECTORS_TIRE_STOCKS: 'Collectors Tire Stocks',
  COLLECTORS_INVENTORY_COUNT_REQUESTS: 'Collectors Inventory Count Requests',
  COLLECTORS_INVENTORY_COUNT: 'Collectors Inventory Count',
  VALORISERS_TIRE_STOCKS: 'Valorisers Tire Stocks',

  TIRE_DELIVERIES: 'Tire Deliveries',
  TIRE_DELIVERIES_REQUESTS: 'Tire Deliveries Requests',
  TIRE_UNCONFIRMED_DELIVERIES: 'Un-confirmed Tire Deliveries',
  TIRE_UNRECONCILED_DELIVERIES: 'Un-reconciled Tire Deliveries',
  TIRE_RESOLVED_DELIVERIES: 'Resolved Tire Deliveries',
  WEEK_SCHEDULES: 'Week Schedules',
  WEEK_SCHEDULES_EA: 'Week Schedules',
  ADMINISTRATION: 'Administration',
  LOG_BOOK: 'Log Book',
  AUDIT_TRAIL: 'Audit Trail',
  HELP: 'Help',
  COLLECTOR_MANUAL: "Collector's Guide",
  VALORISER_MANUAL: "Valoriser's Guide",
  REPORT_ERROR: 'Send Context Log',
  LOADING_UNLOADING_CP: 'Loading-Unloading Collection Point',

  REPORTS: 'Reports',
  ADDITIONAL: 'Additional',
  VALORISED_WEIGHT: 'Valorised Weight',
  SUBSCRIPTIONS: 'Subscriptions',
};

//import { t as T } from 'i18next';
// const t = function (str) {
//   console.log(`${str.toUpperCase()}: '${str}'`);
//   return T(str);
// };

const gqlAddContextlog = gql`
  mutation addContextlog($data: ContextlogInput) {
    addContextlog(data: $data) {
      id
    }
  }
`;

type Props = {};

export const MainMenu: FC<Props> = () => {
  /*-------------------- Body -----------------------------------------------*/
  const { appStore, authStore } = useRootStore();
  const [addContextLog] = useMutation(gqlAddContextlog);

  const handleSubmitLog = async () => {
    const report = await promiseModalSubmitReport({
      title: t('Send Context Log'),
    });
    if (!!report) {
      const values = {
        logDate: new Date(),
        originatedFrom: getBrowserType(),
        appVersion: gitnum.version,
        context: JSON.stringify(appStore),
        message: report.message,
        user: appStore.user,
        fileId: report.fileId,
      };
      appStore.setBusy();
      await addContextLog({ variables: { data: values } })
        .then(() => {
          toastSuccess(t('Context Log Sent!'));
        })
        .catch((error) => {
          toastFailure(error);
        })
        .finally(() => {
          appStore.clearBusy();
        });
    }
  };

  const mi = {
    _________: <NavDropdown.Divider />,
    TireTypes: (
      <NavLinkItem text={t(MI.TIRE_TYPES)} to={getRouteByName('TireTypes')} enable={true} />
    ),
    Regions: <NavLinkItem text={t(MI.REGIONS)} to={getRouteByName('Regions')} enable={true} />,
    Counties: <NavLinkItem text={t(MI.COUNTIES)} to={getRouteByName('Counties')} enable={true} />,
    UserRoles: (
      <NavLinkItem text={t(MI.USER_ROLES)} to={getRouteByName('UserRoles')} enable={true} />
    ),
    Users: <NavLinkItem text={t(MI.USERS)} to={getRouteByName('Users')} enable={true} />,

    ValorisationMethods: (
      <NavLinkItem
        text={t(MI.VALORISATION_METHODS)}
        to={getRouteByName('ValorisationMethods')}
        enable={true}
      />
    ),
    ValorisationGroups: (
      <NavLinkItem
        text={t(MI.VALORISATION_GROUPS)}
        to={getRouteByName('ValorisersGroups')}
        enable={true}
      />
    ),
    Valorisers: (
      <NavLinkItem text={t(MI.VALORISERS)} to={getRouteByName('Valorisers')} enable={true} />
    ),

    CollectorsPlatforms: (
      <NavLinkItem
        text={t(MI.COLLECTOR_PLATFORMS)}
        to={getRouteByName('CollectorsPlatforms')}
        enable={true}
      />
    ),
    Collectors: (
      <NavLinkItem text={t(MI.COLLECTORS)} to={getRouteByName('Collectors')} enable={true} />
    ),

    CollectionpointsTypes: (
      <NavLinkItem
        text={t(MI.COLLECTION_POINT_TYPES)}
        to={getRouteByName('CollectionpointsTypes')}
        enable={true}
      />
    ),
    CollectionpointCategories: (
      <NavLinkItem
        text={t(MI.COLLECTION_POINT_CATEGORIES)}
        to={getRouteByName('CollectionpointsCategories')}
        enable={true}
      />
    ),
    Collectionpoints: (
      <NavLinkItem
        text={t(MI.COLLECTION_POINTS)}
        to={getRouteByName('Collectionpoints')}
        enable={true}
      />
    ),

    TireCollectionStates: (
      <NavLinkItem
        text={t(MI.TIRE_COLLECTION_STATES)}
        to={getRouteByName('TirecollectionStatuses')}
      />
    ),
    TireCollections: (
      <NavLinkItem text={t(MI.TIRE_COLLECTIONS)} to={getRouteByName('Tirecollections')} />
    ),

    TireStocks: (
      <NavLinkItem text={t(MI.COLLECTORS_TIRE_STOCKS)} to={getRouteByName('CollectorsStocks')} />
    ),
    ValorisersTireStocks: (
      <NavLinkItem text={t(MI.VALORISERS_TIRE_STOCKS)} to={getRouteByName('ValorisersStocks')} />
    ),
    ValorisersTireStocksClean: (
      <NavLinkItem text={t(MI.TIRE_STOCKS)} to={getRouteByName('ValorisersStocks')} />
    ),
    CollectorsInventoryRequests: (
      <NavLinkItem
        text={t(MI.COLLECTORS_INVENTORY_COUNT_REQUESTS)}
        to={getRouteByName('CollectorsInventoryCountsRequests')}
      />
    ),
    CollectorsInventoryCounts: (
      <NavLinkItem
        text={t(MI.COLLECTORS_INVENTORY_COUNT)}
        to={getRouteByName('CollectorsInventoryCounts')}
      />
    ),

    TireDeliveries: (
      <NavLinkItem text={t(MI.TIRE_DELIVERIES)} to={getRouteByName('Tiredeliveries')} />
    ),
    TireDeliveriesRequests: (
      <NavLinkItem
        text={t(MI.TIRE_DELIVERIES_REQUESTS)}
        to={getRouteByName('Tiredeliveries-requests')}
      />
    ),
    TireDeliveriesValUnconfirmed: (
      <NavLinkItem
        text={t(MI.TIRE_UNCONFIRMED_DELIVERIES)}
        to={getRouteByName('Tiredeliveries-val')}
      />
    ),
    TireDeliveriesColUnconfirmed: (
      <NavLinkItem
        text={t(MI.TIRE_UNCONFIRMED_DELIVERIES)}
        to={getRouteByName('Tiredeliveries-col')}
      />
    ),
    UnconfirmedTireDeliveries: (
      <NavLinkItem
        text={t(MI.TIRE_UNCONFIRMED_DELIVERIES)}
        to={getRouteByName('Tiredeliveries-unconfirmed')}
      />
    ),
    UnreconciledTireDeliveries: (
      <NavLinkItem
        text={t(MI.TIRE_UNRECONCILED_DELIVERIES)}
        to={getRouteByName('Tiredeliveries-unreconciled')}
      />
    ),
    ResolvedTireDeliveries: (
      <NavLinkItem
        text={t(MI.TIRE_RESOLVED_DELIVERIES)}
        to={getRouteByName('Tiredeliveries-resolved')}
      />
    ),
    WeekSchedules: <NavLinkItem text={t(MI.WEEK_SCHEDULES)} to={getRouteByName('WeekSchedules')} />,
    WeekSchedulesEa: (
      <NavLinkItem text={t(MI.WEEK_SCHEDULES_EA)} to={getRouteByName('WeekSchedulesEA')} />
    ),

    // Reports: <NavLinkItem text={t(MI.REPORTS)} to={getRouteByName('Reports')} enable={true} />,
    Reports: (
      <NavDropdown title={t(MI.REPORTS)} id="reports-nav-dropdown">
        <NavLinkItem text={t(MI.REPORTS)} to={getRouteByName('Reports')} enable={true} />
        <NavLinkItem
          text={t(MI.SUBSCRIPTIONS)}
          to={getRouteByName('RepSubscriptions')}
          enable={true}
        />
        {appStore.isEcoanvelope && (
          <NavDropdown title={t(MI.ADDITIONAL)} id="reports-nav-dropdown">
            <NavLinkItem
              text={t(MI.VALORISED_WEIGHT)}
              to={getRouteByName('ValorisedWeight')}
              enable={true}
            />
          </NavDropdown>
        )}
      </NavDropdown>
    ),
  };

  /* --------------- EcoAnvelope Menu ------------------- */
  const menuEcoAnvelope = () => {
    return (
      <>
        {/****  FILES ****/}
        <NavDropdown title={t(MI.FILES)} id="files-nav-dropdown">
          {mi.TireTypes}
          {mi.Regions}
          {mi.Counties}
          {mi._________}
          {mi.UserRoles}
          {mi.Users}
        </NavDropdown>

        {/****  Valorisers ****/}
        <NavDropdown title={t(MI.VALORISERS)} id="collections-nav-dropdown">
          {mi.ValorisationMethods}
          {mi.ValorisationGroups}
          {mi._________}
          {mi.Valorisers}
        </NavDropdown>

        {/****  Collectors ****/}
        <NavDropdown title={t(MI.COLLECTORS)} id="collections-nav-dropdown">
          {mi.CollectorsPlatforms}
          {mi.Collectors}
        </NavDropdown>

        {/****  Collection Points ****/}
        <NavDropdown title={t(MI.COLLECTION_POINTS)} id="collections-nav-dropdown">
          {mi.CollectionpointsTypes}
          {mi.CollectionpointCategories}
          {mi._________}
          {mi.Collectionpoints}
        </NavDropdown>

        {/****  Tire Collections ****/}
        <NavDropdown title={t(MI.TIRE_COLLECTIONS)} id="collections-nav-dropdown">
          {mi.TireCollectionStates}
          {mi._________}
          {mi.TireCollections}
        </NavDropdown>

        {/****  Collectors Tire Stocks ****/}
        <NavDropdown title={t(MI.TIRE_STOCKS)} id="collections-nav-dropdown">
          {mi.TireStocks}
          {mi.ValorisersTireStocks}
          {mi._________}
          {mi.CollectorsInventoryRequests}
          {mi.CollectorsInventoryCounts}
        </NavDropdown>

        {/****  Tire Deliveries ****/}
        <NavDropdown title={t(MI.TIRE_DELIVERIES)} id="collections-nav-dropdown">
          {mi.TireDeliveriesRequests}
          {mi.UnconfirmedTireDeliveries}
          {mi.UnreconciledTireDeliveries}
          {mi.ResolvedTireDeliveries}
          {mi.TireDeliveries}
          {mi._________}
          {mi.WeekSchedulesEa}
        </NavDropdown>

        {/****  Reports ****/}
        {mi.Reports}
      </>
    );
  };

  /* --------------- Eco Anvelope Help Menu ------------------- */
  const menuEcoAnvelopeHelp = () => {
    return (
      <>
        <NavDropdown
          title={
            <>
              <FaQuestionCircle />
              &nbsp;
              {t(MI.HELP)}
            </>
          }
          id="help-nav-dropdown"
        >
          <a
            className="pl-3 nav-link"
            href={t(
              'https://myecoanvelope-app-store-dev.s3.eu-west-1.amazonaws.com/documents/Eco+Anvelope+Colector+Proceduri+-+v1_0.pdf',
            )}
            target="_blank"
          >
            {t(MI.COLLECTOR_MANUAL)}
          </a>
          <a
            className="pl-3 nav-link"
            href={t(
              'https://myecoanvelope-app-store-dev.s3.eu-west-1.amazonaws.com/documents/Eco+Anvelope+Valorificator+Proceduri+-+v1_0.pdf',
            )}
            target="_blank"
          >
            {t(MI.VALORISER_MANUAL)}
          </a>
          <p className="pl-3 nav-link" style={{ cursor: 'pointer' }} onClick={handleSubmitLog}>
            {t(MI.REPORT_ERROR)}
          </p>
        </NavDropdown>
      </>
    );
  };

  /* --------------- Valoriser Menu ------------------- */
  const menuValoriser = () => {
    return (
      <>
        {/****  FILES ****/}
        {mi.Users}

        {/****  Valorisers ****/}
        {appStore?.isMultiValoriser ? (
          mi.Valorisers
        ) : (
          <NavLinkItem
            text={t(MI.VALORISER)}
            to={generatePath(getRouteByName('Valoriser'), { id: appStore?.associatedId })}
            enable={true}
          />
        )}

        {mi.ValorisersTireStocksClean}

        {/****  Tire Deliveries ****/}
        <NavDropdown title={t(MI.TIRE_DELIVERIES)} id="collections-nav-dropdown">
          {mi.TireDeliveriesColUnconfirmed}
          {mi.UnreconciledTireDeliveries}
          {mi.ResolvedTireDeliveries}
          {mi.TireDeliveries}
          {mi._________}
          {mi.WeekSchedules}
        </NavDropdown>

        {/****  Reports ****/}
        {mi.Reports}
      </>
    );
  };

  /* --------------- Valorisers Help Menu ------------------- */
  const menuValorisersHelp = () => {
    return (
      <>
        <NavDropdown
          title={
            <>
              <FaQuestionCircle />
              &nbsp;
              {t(MI.HELP)}
            </>
          }
          id="help-nav-dropdown"
        >
          <a
            className="pl-3 nav-link"
            href={t(
              'https://myecoanvelope-app-store-dev.s3.eu-west-1.amazonaws.com/documents/Eco+Anvelope+Valorificator+Proceduri+-+v1_0.pdf',
            )}
            target="_blank"
          >
            {t(MI.VALORISER_MANUAL)}
          </a>
          <p className="pl-3 nav-link" style={{ cursor: 'pointer' }} onClick={handleSubmitLog}>
            {t(MI.REPORT_ERROR)}
          </p>
        </NavDropdown>
      </>
    );
  };

  /* --------------- Collection Points Menu ------------------- */
  const menuCollectionpoints = () => {
    return (
      <>
        <NavLinkItem
          text={t(MI.COLLECTION_POINT)}
          to={generatePath(getRouteByName('Collectionpoint'), { id: appStore.associatedId })}
          enable={true}
        />
        {mi.Collectors}
        {mi.TireCollections}
      </>
    );
  };

  /* --------------- Collection Points Help Menu ------------------- */

  const handleDocumentCP = async () => {
    await printReportCP({ colPointId: appStore.associatedId });
  };

  const menuCollectionpointsHelp = () => {
    return (
      <>
        <NavDropdown
          title={
            <>
              <FaQuestionCircle />
              &nbsp;
              {t(MI.HELP)}
            </>
          }
          id="help-nav-dropdown"
        >
          <a className="pl-3 nav-link" onClick={handleDocumentCP} target="_blank">
            {t(MI.LOADING_UNLOADING_CP)}
          </a>
        </NavDropdown>
      </>
    );
  };

  /* --------------- Collectors Menu ------------------- */
  const menuCollectors = () => {
    return (
      <>
        <NavDropdown title={t(MI.FILES)} id="collections-nav-dropdown">
          {mi.Users}
          {mi.CollectorsPlatforms}
          <NavLinkItem
            text={t(MI.COLLECTOR)}
            to={generatePath(getRouteByName('Collector'), { id: appStore.associatedId ?? 0 })}
            enable={true}
          />
        </NavDropdown>

        {mi.Collectionpoints}
        {!appStore.isSubcontractor && mi.Valorisers}
        {mi.TireCollections}

        {!appStore.isSubcontractor && (
          <>
            {/****  Collectors Tire Stocks ****/}
            <NavDropdown title={t(MI.TIRE_STOCKS)} id="collections-nav-dropdown">
              {mi.TireStocks}
              {mi._________}
              {mi.CollectorsInventoryRequests}
              {mi.CollectorsInventoryCounts}
            </NavDropdown>

            {/****  Tire Deliveries ****/}
            <NavDropdown title={t(MI.TIRE_DELIVERIES)} id="collections-nav-dropdown">
              {mi.TireDeliveriesRequests}
              {mi.TireDeliveriesValUnconfirmed}
              {mi.UnreconciledTireDeliveries}
              {mi.ResolvedTireDeliveries}
              {mi.TireDeliveries}
              {mi._________}
              {mi.WeekSchedules}
            </NavDropdown>

            {/****  Reports ****/}
            {mi.Reports}
          </>
        )}
      </>
    );
  };

  /* --------------- CollectorDrivers Menu ------------------- */
  const menuCollectorDrivers = () => {
    return (
      <>
        <NavDropdown title={t(MI.FILES)} id="collections-nav-dropdown">
          {mi.CollectorsPlatforms}
          <NavLinkItem
            text={t(MI.COLLECTOR)}
            to={generatePath(getRouteByName('Collector'), { id: appStore.associatedId ?? 0 })}
            enable={true}
          />
        </NavDropdown>

        {mi.Collectionpoints}
        {mi.TireCollections}

        {!appStore.isSubcontractor && (
          <>
            {/****  Tire Deliveries ****/}
            <NavDropdown title={t(MI.TIRE_DELIVERIES)} id="collections-nav-dropdown">
              {mi.TireDeliveriesRequests}
              {mi.TireDeliveriesValUnconfirmed}
              {mi.UnreconciledTireDeliveries}
              {mi.ResolvedTireDeliveries}
              {mi.TireDeliveries}
            </NavDropdown>
          </>
        )}
      </>
    );
  };

  /* --------------- Collectors Help Menu ------------------- */

  const handleDocumentCol = async () => {
    await printReport({ collectorId: appStore.associatedId });
  };

  const menuCollectorsHelp = () => {
    return (
      <>
        <NavDropdown
          title={
            <>
              <FaQuestionCircle />
              &nbsp;
              {t(MI.HELP)}
            </>
          }
          id="help-nav-dropdown"
        >
          <a className="pl-3 nav-link" onClick={handleDocumentCol} target="_blank">
            {t(MI.LOADING_UNLOADING_CP)}
          </a>
          <a
            className="pl-3 nav-link"
            href={t(
              'https://myecoanvelope-app-store-dev.s3.eu-west-1.amazonaws.com/documents/Eco+Anvelope+Colector+Proceduri+-+v1_0.pdf',
            )}
            target="_blank"
          >
            {t(MI.COLLECTOR_MANUAL)}
          </a>
          <p className="pl-3 nav-link" style={{ cursor: 'pointer' }} onClick={handleSubmitLog}>
            {t(MI.REPORT_ERROR)}
          </p>
        </NavDropdown>
      </>
    );
  };

  const switchMenu = () => {
    if (appStore.isEcoanvelope) return menuEcoAnvelope();
    else if (appStore.isCollector) return menuCollectors();
    else if (appStore.isCollectorDriver) return menuCollectorDrivers();
    else if (appStore.isValoriser) return menuValoriser();
    else if (appStore.isCollectionPoint) return menuCollectionpoints();
    else return <></>;
  };

  const switchHelpMenu = () => {
    if (appStore.isEcoanvelope) return menuEcoAnvelopeHelp();
    else if (appStore.isCollector) return menuCollectorsHelp();
    else if (appStore.isCollectorDriver) return <></>;
    else if (appStore.isValoriser) return menuValorisersHelp();
    else if (appStore.isCollectionPoint) return menuCollectionpointsHelp();
    else return <></>;
  };
  /*-------------------- Render ---------------------------------------------*/
  return (
    // -- Menu --
    <Nav className="mr-auto">
      {switchMenu()}

      {/* Administration */}
      {appStore.isEcoanvelope && (
        <NavDropdown title={t(MI.ADMINISTRATION)} id="admin-nav-dropdown">
          <NavLinkItem text={t(MI.LOG_BOOK)} to={getRouteByName('ApplogGridPage')} />
          <NavLinkItem text={t(MI.AUDIT_TRAIL)} to={getRouteByName('AuditTrails')} enable={true} />
        </NavDropdown>
      )}

      {/****  Help Menu ****/}
      {switchHelpMenu()}
    </Nav>
  );
};
