//import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import 'styles/index.scss';
import App from './app/app';

import * as serviceWorker from './serviceWorker';

import './i18n';
import { MotionCmpReactVersion } from 'motion-components';

const ReactFromModule = MotionCmpReactVersion;
console.log(
  'Motion-Components React is same? ',
  React?.version === ReactFromModule,
  React?.version,
  ReactFromModule,
);

// if (process.env.NODE_ENV !== 'production') {
//   // eslint-disable-next-line @typescript-eslint/no-var-requires
//   const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js');
//   whyDidYouRender(React);
// }

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
