import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { CellDateTime, TableSubSimple } from 'motion-components';

const getFiles = gql`
  query getTiredeliveriesActions($where: String, $offset: Int, $limit: Int, $order: String) {
    getTiredeliveriesActions(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        id
        tiredeliveryId
        actionDate
        notes
        oldStatusId
        newStatusId
        actionId
        userId
        collectorDataKept
        action {
          id
          name
        }
        user {
          id
          fullName
          userType {
            id
            name
          }
        }
        oldStatus {
          id
          name
        }
        newStatus {
          id
          name
        }
      }
      recordCount
    }
  }
`;

export const TabActions = ({ tiredeliveryId, readonly, forceRerender = false }) => {
  const { t } = useTranslation();
  const {
    loading,
    data: rawData,
    refetch,
  } = useQuery(getFiles, {
    variables: { where: JSON.stringify({ tiredeliveryId }), order: 'id ASC' },
    fetchPolicy: 'no-cache',
  });

  const data =
    rawData && rawData.getTiredeliveriesActions ? rawData.getTiredeliveriesActions.data : undefined;

  useEffect(() => {
    if (forceRerender) refetch();
  }, [forceRerender]);

  const columns = useMemo(
    () => [
      {
        Header: t('Date'),
        accessor: 'actionDate',
        Cell: CellDateTime as any,
        width: '50px',
      },
      {
        Header: t('Action'),
        accessor: 'action.name',
        Cell: ({ row }) => <div className="text-left  mt-2">{t(row.original?.action?.name)}</div>,
        width: '200',
      },
      {
        Header: t('User'),
        accessor: 'user',
        Cell: ({ row }) => {
          return (
            <div className="text-left  mt-2">
              {row.original?.user?.fullName}
              <br />
              {row.original?.user?.userType?.name}
            </div>
          );
        },
        width: '200',
      },
      {
        Header: t('Initial Status'),
        accessor: 'oldStatusId',
        Cell: ({ row }) => (
          <div className="text-left  mt-2">{t(row.original?.oldStatus?.name)}</div>
        ),
        width: '100',
      },
      {
        Header: t('New Status'),
        accessor: 'newStatusId',
        Cell: ({ row }) => (
          <div className="text-left  mt-2">{t(row.original?.newStatus?.name)}</div>
        ),
        width: '100',
      },
      {
        Header: t('Notes'),
        accessor: 'notes',
        Cell: ({ row }) => <div className="text-left  mt-2">{t(row.original?.notes)}</div>,
        width: '100',
      },
    ],
    [t],
  );

  // -----------------------------------------------------------------
  return (
    <div>
      <h5 className="form-group-title mb-0">{t('Actions History Log')}</h5>
      {data && <TableSubSimple title={t('Files')} columns={columns} data={data} />}
    </div>
  );
};
