import React, { useEffect, useMemo, useCallback } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { gqlGetUser, gqlAddUser, gqlUpdateUser, gqlDeleteUser, gqlClearPassword } from './graphql';
import { useParams, useHistory, useLocation, generatePath } from 'react-router-dom';
import {
  YupStringReq,
  FormCard,
  YupBoolean,
  YupString,
  YupNumeric,
  ButtonAction,
  UserTooltip,
  YupNumericReq,
  YupDate,
  toastWarning,
} from 'motion-components';
import * as Yup from 'yup';
import * as Lo from 'lodash';
import { isNumeric } from 'utils/helpers';
import { toastSuccess } from 'utils/toasts';
import { getRouteByName } from 'app/routes';
import { promiseModalDialog } from 'motion-components';
import { useRootStore } from 'store';
import setPasswordModal from 'app/set-password-modal';
import { useHandleError } from 'services';

import UserForm from './user-form';
import { useDbHelpFunc } from 'common/db-help-func';
import { UserrolesTable } from './userroles-table';
import { useTranslation } from 'react-i18next';
import { UserTypeValues } from 'shared/constants';

/*
    User Page
*/
export const UserPage = () => {
  const { t } = useTranslation();
  const { handleGraphqlError } = useHandleError('user');
  const { appStore, authStore } = useRootStore();

  const history = useHistory();
  const location = useLocation<any>();
  const { id } = useParams<{ id: string }>();
  const idNum = isNumeric(id) ? parseInt(id) : -1;
  const isNew = idNum < 0;

  const formShape = {
    id: YupNumeric,
    userName: isNew ? Yup.string().email().required() : YupStringReq,
    usertypeId: YupNumericReq,
    userPassword: null,
    lastName: YupStringReq,
    firstName: YupStringReq,
    email: YupString,
    tel: YupString,
    mobile1: YupString,
    position: YupString,
    notes: YupString,
    isActive: YupBoolean,
    isMultipleVal: YupBoolean,
    canSeeCol: YupBoolean,
    canSeeDel: YupBoolean,
    signatureFileId: YupNumeric,
    collectorId: YupNumeric,
    valoriserId: YupNumeric,
    collectionpointId: YupNumeric,
    drvLicenseNumber: YupString,
    drvLicenseExpDate: YupDate,
    drvLicenseTransport: YupString,
    drvLicenseTransportExpDate: YupString,
    drvBINumber: YupString,
    drvIssuedBy: YupString,
    defVehiclePlate: YupString,
    defTrailerPlate: YupString,
    drvTransporter: YupString,
  };

  const validationSchema = Yup.object().shape(formShape);
  const pickProperties = (values) => Lo.pick(values, Lo.keys(formShape));
  type FormValuesType = Yup.InferType<typeof validationSchema>;
  const [getCol, { data: colData }] = useLazyQuery(gqlGetUser);

  useEffect(() => {
    if (isNew) {
    } else {
      getCol({ variables: { id: appStore.userLogin.userId } });
    }
  }, [appStore.isAdmin, appStore.userLogin.userId, getCol]);

  const newRecord = useMemo<FormValuesType>(
    () =>
      ({
        id: -1,
        userName: null,
        userPassword: null,
        lastName: null,
        firstName: null,
        email: null,
        tel: null,
        mobile1: null,
        position: null,
        notes: null,
        isActive: true,
        isMultipleVal: false,
        canSeeCol: true,
        canSeeDel: true,
        usertypeId: appStore.isValoriser ? UserTypeValues.Valoriser : null,
        valoriserId: appStore.isValoriser ? appStore.associatedId : null,
        collectorId: appStore.isCollector ? appStore.associatedId : null,
      } as FormValuesType),
    [appStore],
  );

  const [addRecord] = useMutation(gqlAddUser);
  const [updateRecord] = useMutation(gqlUpdateUser);
  const [deleteRecord] = useMutation(gqlDeleteUser);
  const [clearPassword] = useMutation(gqlClearPassword);
  const [get, { data: rawData, error, refetch }] = useLazyQuery(gqlGetUser);
  const data = isNew ? newRecord : rawData ? rawData.getUserById : undefined;

  /* fetch data */
  useEffect(() => {
    if (!isNew) {
      get({ variables: { id: idNum } });
    }
  }, [get, id, idNum, isNew]);

  /*
   * Form Validation
   */
  const dbHelpFunc = useDbHelpFunc();

  const formValidation = useCallback(
    async (values) => {
      const error: any = {};

      // UserName === Unique
      if (!(await dbHelpFunc.isUserNameUnique(values.userName, values.id))) {
        error.userName = t('User name already exists. Specify another name.');
      }

      return error;
    },
    [dbHelpFunc, t],
  );

  /**
   * * ΑΠΟΘΗΚΕΥΣΗ - UPDATE/CREATE
   */
  const handleSubmit = (values: any, actions) => {
    actions.setSubmitting(false);
    if (values.usertypeId === UserTypeValues.CollectorDriver && values.userPassword === null) {
      toastWarning(t('You need to input a user password.'));
    }
    // Αν είναι νέα εγγραφή τοτε γράφω όλα τα πεδία
    else if (isNew) {
      console.log('[User] New:', values);
      addRecord({ variables: { data: { ...values } } })
        .then((d) => {
          toastSuccess(
            `${t('Record Created Successfully')} [${d.data[Object.keys(d.data)[0]]?.id}]`,
          );
          // re-enter page
          history.push(generatePath(getRouteByName('User'), { id: d.data.addUser.id }), {
            ...location.state,
          });
        })
        .catch((error) => {
          handleGraphqlError('mutation-add', error);
        });
    } else {
      // Αν είναι update εγγραφής τοτε γράφω μονο τα αλλαγμένα πεδία
      console.log('[User] Update:', values);
      updateRecord({ variables: { data: { ...values, id: idNum } } })
        .then((d) => {
          actions.setFieldValue('userPassword', undefined);
          toastSuccess(
            `${t('Record Updated Successfully')} [${d.data[Object.keys(d.data)[0]].id}]`,
          );
          refetch();
        })
        .catch((error) => {
          handleGraphqlError('mutation-update', error);
        });
    }
  };

  /**
   * *  DELETE
   */
  const handleDelete = useCallback(async () => {
    const res = await promiseModalDialog({
      title: `${t('Delete Record')}`,
      text: `${t('The record will be permanently deleted. Do you want to continue?')}`,
    });
    if (res) {
      deleteRecord({ variables: { id: idNum } })
        .then(() => handleReturn())
        .catch((error) => {
          handleGraphqlError('mutation-delete', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRecord, idNum]);

  /**
   * * SET NEW PASSWORD Handler
   */
  const handleClearPassword = useCallback(
    async (user) => {
      const res = await promiseModalDialog({
        title: t('Clear password'),
        text: `${t('Password for user')} ${user} ${t('will be cleared')}...`,
      });
      if (res) {
        const variables = { userName: user };
        clearPassword({ variables })
          .then(() => {
            toastSuccess(t(`Password was cleared successfully`));
          })
          .catch((error) => {
            handleGraphqlError('clear-password', error, t('Password was NOT cleared'));
          });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [clearPassword, handleGraphqlError, t],
  );

  // Handler να πάει στην πίσω σελίδα
  const handleReturn = useCallback(() => {
    history.push(generatePath(getRouteByName('Users')), { ...location.state });
  }, [history, location.state]);

  const isSameUser = () => {
    return authStore.userId === data?.id;
  };

  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <FormCard
        isNew={isNew}
        title={isNew ? t('User: Create New') : `${t('User')}: ${data?.userName}`}
        onSubmit={handleSubmit}
        onReturn={null}
        onDelete={handleDelete}
        permitDelete={false}
        initialValues={pickProperties(data)}
        validationSchema={validationSchema}
        validate={formValidation}
        enableReinitialize
        idx={idNum}
      >
        <UserForm data={data} isNew={isNew}>
          <div className="d-flex align-items-center">
            {(isSameUser() || data?.usertypeId === UserTypeValues.CollectorDriver) && (
              <ButtonAction
                className="ml-2"
                text={t('Change User Password')}
                onClick={() => {
                  setPasswordModal({
                    userName: data.userName,
                  });
                }}
              ></ButtonAction>
            )}
            {authStore.isUsersAdmin &&
              !isNew &&
              !isSameUser() &&
              data?.usertypeId !== UserTypeValues.CollectorDriver && (
                <ButtonAction
                  className="ml-2"
                  text={t('Clear User Password')}
                  onClick={() => handleClearPassword(data?.userName)}
                ></ButtonAction>
              )}
          </div>
        </UserForm>
        <div className="float-right">
          {data && !isNew && <UserTooltip data={data} /* auditTable={'users'} */ />}
        </div>
        {/* Ρόλοι */}
        {data &&
          data?.userUserroles &&
          data?.usertypeId !== UserTypeValues.CollectorDriver &&
          data?.usertypeId !== UserTypeValues.CollectionPoint && (
            <UserrolesTable data={data.userUserroles} refetch={refetch} />
          )}
      </FormCard>
    );
  }
};
