import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { toastSuccess } from 'utils/toasts';
import promiseModalResolveTireDelivery from './resolveDelivery-modal';
import { FaPen, FaPenSquare } from 'react-icons/fa';

const gqlResolveTireDelivery = gql`
  mutation resolveTireDelivery($tireDeliveryId: Int!, $chosenUserTypeId: Int!) {
    resolveTireDelivery(tireDeliveryId: $tireDeliveryId, chosenUserTypeId: $chosenUserTypeId)
  }
`;

const UseResolveTireDelivery = () => {
  const [updateRecord] = useMutation(gqlResolveTireDelivery);
  const resolveTireDelivery = async (tireDeliveryId, chosenUserTypeId) => {
    const ret = await updateRecord({
      variables: { tireDeliveryId: tireDeliveryId, chosenUserTypeId: chosenUserTypeId },
    });
    console.log('$$$', ret);
    return ret;
  };
  return { resolveTireDelivery };
};

export const ResolveTireDeliveryButton = ({ onClick, data, disabled = false }) => {
  const { t } = useTranslation();
  const { resolveTireDelivery } = UseResolveTireDelivery();

  const handleSubmit = async () => {
    const res = await promiseModalResolveTireDelivery({
      title: t('Resolve Weights'),
      data: data,
    });
    if (res) {
      await resolveTireDelivery(data.id, res.chosenUserTypeId);
      toastSuccess(t('Successfully resolved weights'));
      onClick();
    }
  };
  return (
    <>
      <Button disabled={disabled} variant="info" onClick={handleSubmit} className="ml-3 mr-4">
        <FaPen className="mb-1 mr-3" />
        <span>{t('Resolve Weights')}</span>
      </Button>
    </>
  );
};
