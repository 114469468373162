import gql from 'graphql-tag';

export const gqlGetQueryCol = gql`
  query getWeekSchedulesCol($limit: Int, $offset: Int, $order: String, $where: String) {
    getWeekSchedulesCol(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        id
        collectorId
        collector {
          id
          name
        }
        valoriserId
        valoriser {
          id
          name
        }
        weekId
        week {
          id
          weekNo
          startDate
          endDate
          isCompleted
        }
        isApproved
        monSm
        monLg
        tueSm
        tueLg
        wedSm
        wedLg
        thuSm
        thuLg
        friSm
        friLg
      }
      recordCount
    }
  }
`;

export const gqlGetQueryVal = gql`
  query getWeekSchedulesVal($limit: Int, $offset: Int, $order: String, $where: String) {
    getWeekSchedulesVal(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        id
        collectorId
        collector {
          id
          name
        }
        valoriserId
        valoriser {
          id
          name
        }
        weekId
        week {
          id
          weekNo
          startDate
          endDate
          isCompleted
        }
        isApproved
        monSm
        monLg
        tueSm
        tueLg
        wedSm
        wedLg
        thuSm
        thuLg
        friSm
        friLg
      }
      recordCount
    }
  }
`;

export class WeekScheduleInput {
  id: string;
  monSm: number;
    monLg: number;
    tueSm: number;
    tueLg: number;
    wedSm: number;
    wedLg: number;
    thuSm: number;
    thuLg: number;
    friSm: number;
    friLg: number;
}

export const gqlUpdate = gql`
  mutation updateWeekSchedule($data: WeekScheduleInput) {
    updateWeekSchedule(data: $data) {
      id
    }
  }
`;

export const gqlCompleteWeek = gql`
  mutation completeWeek($weekNo: Int) {
    completeWeek(weekNo: $weekNo)
  }
`
