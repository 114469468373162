import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  FieldInput,
  FormCard,
  FormCol,
  FormRow,
  YupString,
  YupDate,
  YupNumeric,
  TableInMem,
  FieldDatepicker,
} from 'motion-components';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useHandleError } from 'services';
import { isNumeric } from 'utils/helpers';
import { gqlGetByIdQuery } from './graphql';
import * as Yup from 'yup';
import * as Lo from 'lodash';
import { auditTypeColor, useFieldValue2StringExtra } from './common';
import { getAuditTableFieldDisplayName } from './audit-trail-dictionary';
import { useTranslation } from 'react-i18next';

const formShape = {
  id: YupNumeric,
  auditDate: YupDate,
  auditType: YupString,
  tableName: YupString,
  tableRowId: YupNumeric,
  previousValues: YupString,
  currentValues: YupString,
  user: YupString,
};

const validationSchema = Yup.object().shape(formShape);
const pickProperties = (values) => Lo.pick(values, Lo.keys(formShape));
type FormValuesType = Yup.InferType<typeof validationSchema>;

export const AuditTrailFormPage = () => {
  //
  const { t } = useTranslation();
  const { handleGraphqlError } = useHandleError('audit-trail');
  const { id } = useParams<{ id: string }>();
  const idNum = isNumeric(id) ? parseInt(id) : -1;
  const [get, { data: rawdata, error }] = useLazyQuery(gqlGetByIdQuery);
  const data = rawdata?.getAuditTrailById;

  /* fetch data */
  useEffect(() => {
    get({ variables: { id: idNum } });
  }, [get, idNum]);
  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <>
        <FormCard
          isNew={false}
          title={'Audit trail'}
          onSubmit={null}
          onReturn={null}
          onDelete={null}
          permitDelete={false}
          initialValues={pickProperties(data)}
          validationSchema={validationSchema}
          enableReinitialize
          idx={idNum}
        >
          <Card style={{ marginBottom: '1rem', padding: '1rem' }}>
            <FormRow>
              <FieldInput
                as={FormCol}
                disabled={true}
                label={t('Element')}
                name="tableName"
                placeholder=""
              />
              <FieldInput
                as={FormCol}
                disabled={true}
                label={t('Record Id')}
                name="tableRowId"
                placeholder=""
              />
            </FormRow>
            <FormRow>
              <FieldInput
                as={FormCol}
                disabled={true}
                label={t('Action')}
                name="auditType"
                placeholder=""
                inputStyle={{ color: auditTypeColor(data?.auditType) }}
              />
              <FieldInput
                as={FormCol}
                disabled={true}
                label={t('User')}
                name="user"
                placeholder=""
              />
              <FieldDatepicker
                as={FormCol}
                disabled={true}
                label={t('Date')}
                name="auditDate"
                placeholder=""
                dateFormat={'dd/MM/yyyy HH:mm'}
              />
            </FormRow>
          </Card>
          <FieldValuesTable data={data} />
        </FormCard>
      </>
    );
  }
};

interface Data {
  fieldName: string;
  prvValue: string;
  curValue: string;
}
const FieldValuesTable = ({ data: rawData }) => {
  //
  const { t } = useTranslation();

  //   const [data, setData] = useState<Array<Data>>([]);
  const [data, setData] = useState<Array<any>>([]);
  // Transform rawData to Table Data
  useEffect(() => {
    const previousValues = rawData ? JSON.parse(rawData.previousValues) : {};
    const currentValues = rawData ? JSON.parse(rawData.currentValues) : {};

    const dataV = Object.keys(currentValues).map((key, index) => {
      const prvValue = previousValues[key];
      const curValue = currentValues[key];
      return {
        fieldName: key,
        prvValue: prvValue,
        curValue: curValue,
        changed: prvValue !== curValue,
      };
    });
    setData(dataV);
  }, [rawData]);

  const columns = useMemo(() => {
    const columns4Update = [
      {
        Header: t('Field Name'),
        accessor: 'fieldName',
        Cell: ({ row }) => {
          return (
            <FieldNameElement
              tableName={rawData.tableName}
              fieldName={row.original.fieldName}
              bold={row.original.changed}
            />
          );
        },
      },
      {
        Header: t('Initial Value'),
        accessor: 'prvValue',
        Cell: ({ row }) => (
          <FieldValueElement
            tableName={rawData.tableName}
            fieldName={row.original.fieldName}
            fieldValue={row.original.prvValue}
            bold={row.original.changed}
          />
        ),
      },
      {
        Header: t('New Value'),
        accessor: 'curValue',
        Cell: ({ row }) => (
          <FieldValueElement
            tableName={rawData.tableName}
            fieldName={row.original.fieldName}
            fieldValue={row.original.curValue}
            bold={row.original.changed}
          />
        ),
      },
    ];
    const columns4InsDel = [
      {
        Header: t('Field Name'),
        accessor: 'fieldName',
        Cell: ({ row }) => {
          return (
            <FieldNameElement tableName={rawData.tableName} fieldName={row.original.fieldName} />
          );
        },
      },
      {
        Header: t('Field Value'),
        accessor: 'curValue',
        Cell: ({ row }) => {
          return (
            <FieldValueElement
              tableName={rawData.tableName}
              fieldName={row.original.fieldName}
              fieldValue={row.original.curValue}
            />
          );
        },
      },
    ];
    return rawData?.auditType === 'update' ? columns4Update : columns4InsDel;
  }, [rawData, t]);

  return (
    <div>
      <TableInMem
        title={t('Field Values of Record')}
        propPageSize={100}
        onCreate={undefined}
        columns={columns}
        data={data}
        hasSearch={false}
        hasFooter={false}
        // rowCountLabel={'Πλήθος Πεδίων'}
      />
    </div>
  );
};

const FieldNameElement = ({ tableName, fieldName, bold = false }) => {
  const displayKey = getAuditTableFieldDisplayName(tableName, fieldName);

  const displayKeyEl = bold ? <strong>{displayKey}</strong> : <>{displayKey}</>;
  const extraEl =
    displayKey !== fieldName ? (
      <span style={{ fontFamily: 'monospace', color: 'gray' }}>({fieldName})</span>
    ) : null;

  return (
    <span>
      {displayKeyEl} {extraEl}
    </span>
  );
};

const FieldValueElement = ({ tableName, fieldName, fieldValue, bold = false }) => {
  const { fieldValue2StringExtra } = useFieldValue2StringExtra();
  const [curValue, setCurValue] = useState<string>();
  useEffect(() => {
    async function get() {
      let curVal = await fieldValue2StringExtra(tableName, fieldName, fieldValue);

      // remove start & end quotes
      if (typeof curVal === 'string') curVal = curVal.replace(/^"(.*)"$/, '$1');
      setCurValue(curVal);
    }
    get();
  }, [curValue, fieldName, fieldValue, fieldValue2StringExtra, tableName]);

  const valueEl = <span>{curValue}</span>;
  if (bold) return <strong>{valueEl}</strong>;
  else return valueEl;
};
