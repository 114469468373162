import React from 'react';
import { FieldSelectDB } from 'motion-components';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { FsProps } from 'motion-components';
import { useFormikContext } from 'formik';

const gqlGetValues = gql`
  query getValues($where: String, $offset: Int, $limit: Int, $order: String) {
    getCollectorsPlatforms(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        id
        description: name
      }
      recordCount
    }
  }
`;

function useGetValues() {
  return useLazyQuery(gqlGetValues, { fetchPolicy: 'network-only' });
}

type Props = {
  collectorId?: number;
  useWhereValues?: boolean;
} & FsProps;

export const FieldSelectPlatform = ({
  label = 'Platform',
  name = 'platformId',
  collectorId = null,
  useWhereValues = false,
  defaultValues = {},
  ...rest
}: Props) => {
  const { values } = useFormikContext<any>();
  return (
    <FieldSelectDB
      {...rest}
      label={label}
      name={name}
      where={
        collectorId
          ? JSON.stringify({ collectorId: collectorId, isActive: 1 })
          : values.collectorId && useWhereValues
          ? JSON.stringify({ collectorId: values.collectorId, isActive: 1 })
          : JSON.stringify({ isActive: 1 })
      }
      // @ts-ignore
      sbLabel={defaultValues?.platform?.name ?? undefined}
      hook={useGetValues}
    />
  );
};
