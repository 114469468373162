import { useLazyQuery } from '@apollo/client';
import { useState } from 'react';
import { gqlGetUsers } from './graphql';
import { useTranslation } from 'react-i18next';

export const exportColumns = (t) => {
  return [
  { fieldName: 'id', fieldTitle: t('ID'), fieldType: 'INT' },
  { fieldName: 'userName', fieldTitle: t('User'), fieldType: 'TEXT' },
  { fieldName: 'firstName', fieldTitle: t('Name'), fieldType: 'TEXT' },
  { fieldName: 'lastName', fieldTitle: t('Surname'), fieldType: 'TEXT' },
  { fieldName: 'email', fieldTitle: t('Email'), fieldType: 'TEXT' },
  { fieldName: 'tel', fieldTitle: t('Telephone'), fieldType: 'TEXT' },
  { fieldName: 'isActive', fieldTitle: t('Active'), fieldType: 'BOOL' },
  { fieldName: 'userRoles', fieldTitle: t('User Roles'), fieldType: 'TEXT' },
];
};

export function useExportUsers(): any {
  const [get, { loading }] = useLazyQuery(gqlGetUsers, { onCompleted: transformData });
  const { t } = useTranslation();
  const [result, setResult] = useState<any>([get, { loading, data: undefined }]);

  function transformData(data) {
    const recs = data.getUsers.data.map((values) => {
      const userRoles = values.userUserroles
        .filter((values) => values.isActive)
        .map((values) => t(values.userRole.name))
        .join(', ');
      return { ...values, userRoles: userRoles };
    });

    const trsData = { getUsers: { data: recs, recordCount: data?.getUsers?.recordCount } };
    setResult([get, { loading, data: trsData }]);
  }

  return result;
}
