import gql from "graphql-tag";

export class FormInput {
 id: number
 collectorId: number
 platformId: number
 stockDate: Date
 requestDate: Date
 requestedById: number
 approvedDate: Date
 approvedById: number
 approvedNotes: string
 approved: boolean
 isActive: boolean
 collectorsStocktakingLns: any;
}

export const gqlGetQuery = gql`
    query getCollectorsStocktakingHdr($limit: Int, $offset: Int, $order: String, $where: String) {
        getCollectorsStocktakingHdr(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        id
        collectorId
        collector {
          id
          name
        }
        platformId
        collectorsPlatform {
          id
          name
        }
        collectorsStocktakingLns {
          id
          tiretypeId
          tireType {
            id
            name
          }
          pieces
        }
        stockDate
        requestDate
        requestedById
        approvedDate
        approvedById
        approvedByUser {
          id
          userName
        }
        approvedNotes
        approved
        isActive
        firstDate
        lastDate
        firstUser
        lastUser
      }
      recordCount
    }
  }
`;

export const gqlGetByIdQuery = gql`
query getCollectorsStocktakingHdrById($id: Int) {
        getCollectorsStocktakingHdrById(id: $id){
          id
        collectorId
        collector {
          id
          name
        }
        platformId
        collectorsPlatform {
          id
          name
        }
        collectorsStocktakingLns {
          id
          tiretypeId
          pieces
          tireType {
            id
            name
          }
        }
        stockDate
        requestDate
        requestedById
        requestedByUser {
          id
          userName
        }
        approvedDate
        approvedById
        approvedByUser {
          id
          userName
        }
        approvedNotes
        approved
        isActive
        firstDate
        lastDate
        firstUser
        lastUser
      }
    }
`;

export const gqlAdd = gql`
  mutation addCollectorsStocktakingHdrRequest($data: CollectorsStocktakingHdrInput) {
    addCollectorsStocktakingHdrRequest(data: $data) {
      id
    }
  }
`;

export const gqlAcceptCollectorInventoryCount = gql`
  mutation acceptCollectorInventoryCount($inventoryCountId: Int!) {
    acceptCollectorInventoryCount(inventoryCountId: $inventoryCountId)
  }
`;

export const gqlRejectCollectorInventoryCount = gql`
  mutation rejectCollectorInventoryCount($inventoryCountId: Int!, $rejectReason: String!) {
    rejectCollectorInventoryCount(inventoryCountId: $inventoryCountId, rejectReason: $rejectReason)
  }
`;

export const gqlDelete = gql`mutation deleteCollectorsStocktakingHdr($id: Int){
  deleteCollectorsStocktakingHdr(id: $id)
}`;
