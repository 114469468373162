import React, { useState, useCallback, useRef } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { useField, useFormikContext } from 'formik';
import { Form, ProgressBar, InputGroup, Button } from 'react-bootstrap';
import { FormCol, FieldLabel, promiseModalDialog } from 'motion-components';
import { FaFileUpload, FaTrashAlt } from 'react-icons/fa';
import gql from 'graphql-tag';
import { uploadFile2S3withSignedUrl } from 'utils/s3helpfunc';
import { toastSuccess, toastFailure } from 'utils/toasts';
import { useTranslation } from 'react-i18next';

// import { useHandleError } from 'services';

const gqlAddFilesRepository = gql`
  mutation addFilesrepository($fileName: String!, $mimeMediaType: String!) {
    addFilesrepository(fileName: $fileName, mimeMediaType: $mimeMediaType) {
      id
      url
    }
  }
`;

const gqlGetFilesRepositoryFileUrlById = gql`
  query getFilesrepositoryFileUrlById($id: Int) {
    getFilesrepositoryFileUrlById(id: $id)
  }
`;

const gqlDeleteFilesRepository = gql`
  mutation deleteFilesrepository($id: Int!) {
    deleteFilesrepository(id: $id)
  }
`;

type Props = {
  as?: React.ElementType<any>;
  label: string | React.ReactNode;
  fileIdFieldName: string;
  fileRec: any;
  acceptFiles?: string | null;
  submit?: boolean;
};

// fileIdFieldName => fileId fieldName i.e. signFileId
export const FileRepoField = ({
  label,
  fileIdFieldName,
  fileRec,
  as = FormCol,
  acceptFiles = null,
  submit = true,
}: Props) => {
  const { t } = useTranslation();
  const fileInput = useRef(null);
  // const { handleGraphqlError } = useHandleError('FileRepoField');
  const [{ value }] = useField<string>(fileIdFieldName);
  const [name, setName] = useState('');
  const { setFieldValue, setFieldTouched, submitForm, values } = useFormikContext<any>();
  const [uploadStatus, setUploadStatus] = useState(-1);
  // const [uploadId, setUploadId] = useState(-1);
  // const [type, setType] = useState('');

  const client = useApolloClient();
  const [addFile] = useMutation(gqlAddFilesRepository);
  const [deleteFile] = useMutation(gqlDeleteFilesRepository);
  // const [getFileUrl, { data: dataFileUrl }] = useLazyQuery(gqlGetFilesRepositoryFileUrlById);

  /**
  // * ----- HANDLER: Upload File
  */
  const handleFileSelect = async (fileObj: File) => {
    console.log(fileObj);
    // Εχει επιλεγεί αρχείο...
    const res = await addFile({
      // Mutation
      variables: { fileName: fileObj.name, mimeMediaType: fileObj.type },
    });
    const { id, url } = res?.data?.addFilesrepository;
    console.log(id, url);
    if (id && url) {
      setUploadStatus(0);
      uploadFile2S3withSignedUrl(
        fileObj,
        url,
        () => {
          toastSuccess(t('File Uploaded Succesfully')); //'To Aρχείο Μεταφέρθηκε στον server');
          setFieldValue(fileIdFieldName, id);
          setFieldTouched(fileIdFieldName, true);
          if (!submit) setName(fileObj.name);
          setUploadStatus(-1);
          if (!!submit) submitForm();
        }, //onFileUploaded
        () => {
          toastFailure(t('Failed to upload file')); //('Παρουσιάστηκε Κάποιο Πρόβλημα στο Ανέβασμα του Αρχείου');
          setUploadStatus(-1);
        }, //onUploadError
        (percent) => {
          setUploadStatus(percent);
        }, //onUploadProgress
      );
    }
  };

  /*
      Delete File
  */
  const handleFileDelete = useCallback(async () => {
    const res = await promiseModalDialog({
      title: t('Delete File Record'), //'Διαγραφή Εγγραφής Αρχείου',
      text: t('Do you really want to delete the file;'), //'Είστε σίγουροι ότι θέλετε να διαγράψετε το Αρχείο;',
    });
    if (!res) return;
    setFieldValue(fileIdFieldName, null);
    setFieldTouched(fileIdFieldName, true);
    setName('');
    if (!!submit) await submitForm();

    // await deleteFile({ variables: { id: value } }).catch(error => {
    //   handleGraphqlError('mutation-delete', error);
    // });
    setTimeout(async () => {
      await deleteFile({ variables: { id: value } }).catch((error) => {});
      //toastSuccess('Το Αρχείο Διαγράφηκε');
    }, 1000);
  }, [deleteFile, fileIdFieldName, setFieldTouched, setFieldValue, submitForm, t, value]);

  /**
  // * ----- HANDLER: Open File (κανω το query με promise)
  */
  const handleFileOpen = async () => {
    console.log('***OPEN FILE***');
    client
      .query({
        query: gqlGetFilesRepositoryFileUrlById,
        variables: { id: value },
      })
      .then((res) => {
        const url = res?.data?.getFilesrepositoryFileUrlById;
        if (url) {
          window.open(url);
        } else {
          toastFailure(t('Error during file download')); //'Πρόβλημα Στο Κατέβασμα του Αρχείου'
        }
      })
      .catch((err) => toastFailure(t('Error during file download ') + err)); //`Πρόβλημα στο κατέβασμα του αρχείου err:${err}`));
  };

  return (
    <Form.Group as={as}>
      <FieldLabel text={label} />

      {uploadStatus !== -1 ? (
        <ProgressBar className="my-2" now={uploadStatus} />
      ) : (
        <InputGroup>
          <Form.Control
            disabled={true}
            value={fileRec?.fileName || name}
            name={fileRec?.fileName}
            type={'text'}
          />

          {value === null && (
            <InputGroup.Append>
              {/* <FilePicker
                onChange={fileObject => handleFileSelect(fileObject)}
                onError={errMsg => console.log(errMsg)}
                //extensions={fileExtentions}
                extensions={['jpg', 'jpeg', 'png']}
              >
                <Button className={'file-input'} variant="success" onClick={() => console.log('Hi')}>
                  <FaFileUpload />
                </Button>
              </FilePicker> */}
              <Button
                className={'file-input'}
                variant="success"
                onClick={() => {
                  fileInput.current.click();
                }}
              >
                <FaFileUpload />
                <input
                  hidden
                  type="file"
                  onChange={(e) => handleFileSelect(e?.target?.files[0])}
                  accept={acceptFiles}
                  ref={fileInput}
                />
              </Button>
            </InputGroup.Append>
          )}
          {/* {JSON.stringify(values)} */}

          {value && value !== null && (
            <InputGroup.Append>
              <Button className={'file-input'} onClick={() => handleFileOpen()}>
                {t('Open File')}
              </Button>
              <Button className={'file-input'} variant="danger" onClick={() => handleFileDelete()}>
                <FaTrashAlt />
              </Button>
            </InputGroup.Append>
          )}
        </InputGroup>
      )}
    </Form.Group>
  );
};
