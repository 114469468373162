import React, { useMemo, useCallback } from 'react';
import {
  TablePaginatedUC,
  CellLeft,
  CellCenter,
  CellYesNo,
  CellYesNoNull,
  CellDateTime,
  CellInt,
  CellFloatCustom,
  CellIntCustom,
  EyeColumn,
  CellDate,
} from 'motion-components';
import { useLocation } from 'react-router-dom';
import { generatePath, useHistory } from 'react-router';

import LoadingOverlay from 'react-loading-overlay';
import { useRootStore } from 'store';
import { getRouteByName } from 'app/routes';
import { useTranslation } from 'react-i18next';
import { ReportFrequencies, ReportTypes } from 'shared/constants';

/* ----------- Grid ------------------ */

export const DataGrid = ({
  data,
  dataCount,
  initPageIndex,
  onPageChange,
  loading,
  handleExport,
}) => {
  const location = useLocation<any>();
  const history = useHistory();
  const { appStore } = useRootStore();
  const { t } = useTranslation();
  const rowClicked = useCallback(
    (row) => {
      history.push({
        pathname: generatePath(getRouteByName('RepSubscription'), { id: row.id }),
        state: {
          pathname: location.pathname,
          ...location.state,
        },
      });
    },
    [history, location.pathname, location.state],
  );

  const columns = useMemo(
    () => [
      // { Header: "id", accessor: "id", Cell: CellCenter, width: "100", isVisible: false, },
      EyeColumn(rowClicked),
      {
        Header: t('Report'),
        accessor: 'reportId',
        Cell: CellReport,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Frequency'),
        accessor: 'frequencyId',
        Cell: CellFrequency,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Last Execution'),
        accessor: 'lastExecDate',
        Cell: CellDate,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Next Execution'),
        accessor: 'nextExecDate',
        Cell: CellDate,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Active'),
        accessor: 'isActive',
        Cell: CellYesNoNull,
        width: '100',
        isVisible: false,
      },
    ],
    [t],
  );

  return (
    <LoadingOverlay active={loading} spinner text={t('Please wait...')}>
      {data ? (
        <TablePaginatedUC
          id="excelReportsSubs"
          appStore={appStore}
          key="excelReportsSubs"
          title={'Grid'}
          columns={columns}
          data={data}
          dataCount={dataCount}
          // initPageIndex={initPage}
          // onSortByChange={handleSortByChange}
          onExport={handleExport}
          onPageChange={onPageChange}
          initPageIndex={initPageIndex}
          resizable={true}
        />
      ) : (
        <div style={{ height: '200px' }}></div>
      )}
    </LoadingOverlay>
  );
};

export const CellReport = ({ cell: { value } }) => {
  const { t } = useTranslation();
  const options = [
    { value: ReportTypes.deliveriesAnalytical, label: t('Deliveries Analytical') },
    // { value: 'report2', label: t('Report 2') },
    //{ value: 'report3', label: t('Report 3') },
  ];
  const label = options.filter((option) => option.value === value)[0].label;
  return <div className="text-left">{label}</div>;
};

export const CellFrequency = ({ cell: { value } }) => {
  const { t } = useTranslation();
  const options = [
    { value: ReportFrequencies.daily, label: t('Daily') },
    { value: ReportFrequencies.weekly, label: t('Weekly') },
    { value: ReportFrequencies.monthly, label: t('Monthly') },
  ];
  const label = options.filter((option) => option.value === value)[0].label;
  return <div className="text-left">{label}</div>;
};
