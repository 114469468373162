import { TirecollectionStatus, TireDeliveriesStatus } from 'shared/constants';

export function getTirecollectionStatusBadge(stateId: number): string {
  return stateId === TirecollectionStatus.PENDING
    ? 'badge-warning'
    : stateId === TirecollectionStatus.CANCELLED
    ? 'badge-danger'
    : stateId === TirecollectionStatus.COMPLETED
    ? 'badge-success'
    : 'badge-dark';
}

export function getTiredeliveryStatusBadge(stateId: number): string {
  return stateId === TireDeliveriesStatus.Request
    ? '#fbb534'
    : stateId === TireDeliveriesStatus.Rejected || stateId === TireDeliveriesStatus.Cancelled
    ? '#e56b75'
    : stateId === TireDeliveriesStatus.Delivered
    ? '#4d9c4d'
    : stateId === TireDeliveriesStatus.Approved
    ? '#4987b7'
    : stateId === TireDeliveriesStatus.Valorised
    ? '#BA68C8'
    : stateId === TireDeliveriesStatus.Unreconciled
    ? '#17a2b8'
    : '#292b2c';
}

export function getInventoryCountStatusBadge(approved: Boolean | null): string {
  return approved === true
    ? 'badge-success'
    : approved === false
    ? 'badge-danger'
    : approved === null
    ? 'badge-warning'
    : 'badge-dark';
}
