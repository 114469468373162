export function uploadFile2S3withSignedUrl(
  fileObject: any,
  signedUrl: string,
  onFileUploaded: any,
  onUploadError: any,
  onUploadProgress: any,
) {
  // get from url params the content-type
  // s3 changes the content-type name
  const urlParams = getAllUrlParams(signedUrl);
  const signedContentType = urlParams['content-type'];
  // http -> put
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', signedUrl, true);
  xhr.setRequestHeader('Content-Type', signedContentType);
  xhr.onload = () => {
    if (xhr.status === 200) {
      // success!
      console.log('Success');
    } else {
      console.log('Received', xhr.status);
      if (onUploadError) onUploadError();
      return;
    }
    if (onFileUploaded) onFileUploaded();
  };
  xhr.onerror = (ev) => {
    // error...
    console.log('[uploadFile2S3withSignedUrl].onError', xhr.response);
    if (onUploadError) onUploadError();
  };
  xhr.upload.onprogress = (event) => {
    if (event.lengthComputable) {
      const percent = Math.round((event.loaded / event.total) * 100);
      console.log(percent);

      if (onUploadProgress) onUploadProgress(percent);
    }
  };

  try {
    xhr.send(fileObject); // `file` is a File object here
  } catch (error) {
    console.log('[uploadFile2S3withSignedUrl]', error);
  }
}

export function getAllUrlParams(url: string): any {
  url = decodeURIComponent(url);
  // get query string from url (optional) or window
  let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  const obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    const arr = queryString.split('&');

    for (let i = 0; i < arr.length; i++) {
      // separate the keys and the values
      const a = arr[i].split('=');

      // set parameter name and value (use 'true' if empty)
      let paramName = a[0];
      let paramValue = typeof a[1] === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        const key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          const index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}

export function asyncUploadFile2S3withSignedUrl(
  fileObject: any,
  signedUrl: string,
  onUploadProgress: any,
) {
  return new Promise(function (resolve, reject) {
    // get from url params the content-type
    // s3 changes the content-type name
    const urlParams = getAllUrlParams(signedUrl);
    const signedContentType = urlParams['content-type'];
    // http -> put
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedUrl, true);
    xhr.setRequestHeader('Content-Type', signedContentType);
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText,
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: xhr.status,
        statusText: xhr.statusText,
      });
    };
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percent = Math.round((event.loaded / event.total) * 100);
        console.log(percent);

        if (onUploadProgress) onUploadProgress(percent);
      }
    };

    try {
      xhr.send(fileObject); // `file` is a File object here
    } catch (error) {
      console.log('[uploadFile2S3withSignedUrl]', error);
      reject(error);
    }
  });
}
