export const version = '1.0.0';

export enum UserRoles {
  All = 0,
  Admin = 1,
}

export const FILESREPO_S3FOLDER = 'filesrepo';

export enum TirecollectionStatus {
  PENDING = 1, // Εκκρεμεί            πορτοκαλί   warning
  COMPLETED = 2, // Ολοκληρωμένη      πράσινο     success
  CANCELLED = 3, // Ακυρωμε΄νη         κόκκινο     danger
}

export enum UserTypeValues {
  Ecoanvelope = 1,
  Collector = 2,
  CollectorDriver = 3,
  Valoriser = 4,
  CollectionPoint = 5,
}

export const ADMINISTRATOR_ROLE_ID = 1;
export const USERS_ADMIN_ROLE_ID = 2;
export const POINTS_ELEVATED_PERMS_ID = 3;

export enum TireDeliveriesStatus {
  Request = 1, // Request       πορτοκαλί   warning
  Approved = 2, // Accepted     μπλε     primary
  Rejected = 3, // Rejected         κόκκινο     danger
  Delivered = 4, // Delivered     πράσινο     success
  Unreconciled = 5, // Unreconciled    γαλαζιο     info
  Valorised = 6, // Valorificated    μαύρο     dark
  UnconfirmedCol = 7, // Unconfirmed Collector's Delivery
  UnconfirmedVal = 8, // Unconfirmed Valoriser's Delivery
  Cancelled = 9, // Cancelled
}

export enum DocumentTypes {
  LoadingUnloading = 1,
  DeliveryNote = 2,
  WeighingNote = 3,
  Other = 4,
  WeightNoteValoriser = 5,
}

export enum UnloadingTypes {
  Manual = 1,
  Automatic = 2,
}

export enum ReportTypes {
  deliveriesAnalytical = 'deliveriesAnalytical',
  report2 = 'report2',
}

export enum ReportFrequencies {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
}
