import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { generatePath, useHistory } from 'react-router';
import { Card } from 'react-bootstrap';
import './dashboard.scss';
import { getRouteByName } from 'app/routes';
import { useRootStore } from 'store';
import { Dashboard4Collector } from './dashboard4collector';
import { IdxEnum } from './dashboard';
import { useTranslation } from 'react-i18next';

const doc = gql`
  query getCollectionpointDashboardIndexes {
    getCollectionpointDashboardIndexes {
      totalExecutedCollectionOrders
      pendingCollectionOrders
    }
  }
`;

export const Dashboard4Collectionpoint = ({ cardClicked }) => {
  const history = useHistory<any>();
  const { t } = useTranslation();
  const { appStore, authStore } = useRootStore();
  const { data: rawData } = useQuery(doc, { fetchPolicy: 'network-only' });
  const { totalExecutedCollectionOrders, pendingCollectionOrders } =
    rawData?.getCollectionpointDashboardIndexes ?? {};

  return (
    <div className="mt-5">
      {rawData && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          {/* Column 1 */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              overflow: 'hidden',
            }}
          >
            <Card
              text="white"
              className="dash-card dash-card-red dash-anim-3"
              onClick={() => cardClicked(IdxEnum.pendingCollectionOrders)}
            >
              <blockquote className="blockquote mb-0 card-body">
                <h1 className="outline-text">{pendingCollectionOrders}</h1>
                <footer>{t('Pending Tire Collections')}</footer>
              </blockquote>
            </Card>
            <Card
              text="white"
              className="dash-card dash-card-green dash-anim-2"
              onClick={() => cardClicked(IdxEnum.todayExecutedCollectionOrders)}
            >
              <blockquote className="blockquote mb-0 card-body">
                <h1 className="outline-text">{totalExecutedCollectionOrders}</h1>
                <footer>{t('Total Executed Tire Collections')}</footer>
              </blockquote>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};
