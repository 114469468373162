import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { ro } from './utils/romanian-translation.json';
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  ro,
};

i18n
  .use(detector)
  //.use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: process.env.NODE_ENV === 'development' ? 'en' : 'ro',

    keySeparator: false, // we do not use keys in form messages.welcome
    backend: {
      addPath: function (lng, namespace) {
        return 'http://localhost:3000';
      },
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    fallbackLng: false,
    saveMissing: true,
  });

/** www.java2s.c om
 * Replaces %1, %2 and so on in the string to the arguments.
 *
 * @method String.prototype.format
 * @param {Any} ...args The objects to format
 * @return {String} A formatted string
 */
// eslint-disable-next-line no-extend-native
String.prototype.format = function () {
  // eslint-disable-next-line prefer-rest-params
  const args = arguments;
  return this.replace(/%([0-9]+)/g, function (s, n) {
    return args[Number(n) - 1];
  });
};

export default i18n;
