import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery, useApolloClient } from '@apollo/react-hooks';
import { useHistory, useParams } from 'react-router-dom';
import {
  FormCard,
  YupNumeric,
  YupDate,
  YupString,
  YupBoolean,
  promiseModalDialog,
  UserTooltip,
} from 'motion-components';
import * as Yup from 'yup';
import * as Lo from 'lodash';
import { toastSuccess } from 'utils/toasts';
import { useHandleError } from 'services';
import { gqlGetByIdQuery, gqlAdd, gqlUpdate, gqlDelete, FormInput } from '../graphql';
import { isNumeric } from 'utils/helpers';

import { FormFields } from './form-fields';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';

export const ApplogFormPage = () => {
  const { t } = useTranslation();
  const { handleGraphqlError } = useHandleError('batteriescollection');
  const { appStore } = useRootStore();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const idNum = isNumeric(id) ? parseInt(id) : -1;
  const isNew = idNum < 0;

  const formShape = {
    id: YupNumeric,
    logDate: YupDate,
    logType: YupString,
    originatedFrom: YupString,
    grouping: YupString,
    message: YupString,
    user: YupString,
    clientIp: YupString,
  };

  const validationSchema = Yup.object().shape(formShape);
  const pickProperties = (values) => Lo.pick(values, Lo.keys(formShape));
  type FormValuesType = Yup.InferType<typeof validationSchema>;

  const [updateRecord] = useMutation(gqlUpdate);
  const [deleteRecord] = useMutation(gqlDelete);
  const [get, { data: rawData, error, refetch }] = useLazyQuery(gqlGetByIdQuery);
  const data = rawData ? rawData[Object.keys(rawData)[0]] : undefined;

  /* fetch data */
  useEffect(() => {
    get({ variables: { id: idNum } });
  }, [get, id, idNum]);

  /**
   * * ΑΠΟΘΗΚΕΥΣΗ - UPDATE/CREATE
   */
  const handleSubmit = (values: FormValuesType, actions) => {
    actions.setSubmitting(false);
    const mutValues = Lo.pick(values, Lo.keys(new FormInput()));
    // Αν είναι update εγγραφής τοτε γράφω μονο τα αλλαγμένα πεδία
    console.log('[Applog] Update:', mutValues);
    updateRecord({ variables: { data: { ...mutValues, id: idNum } } })
      .then((d) => {
        toastSuccess(`Επιτυχής Διόρθωση Εγγραφής [${d.data[Object.keys(d.data)[0]].id}]`);
        refetch();
      })
      .catch((error) => {
        handleGraphqlError('mutation-update', error);
      });
  };

  /**
   * *  DELETE
   */
  const handleDelete = useCallback(async () => {
    const res = await promiseModalDialog({
      title: t('Delete File Record'), //'Διαγραφή Εγγραφής Αρχείου',
      text: t('Do you really want to delete the file;'), //'Είστε σίγουροι ότι θέλετε να διαγράψετε το Αρχείο;',
    });
    if (res) {
      deleteRecord({ variables: { id: idNum } })
        .then(() => {
          toastSuccess(t('The record has been successfully deleted'));
          history.goBack();
        })
        .catch((error) => {
          handleGraphqlError('mutation-delete', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRecord, idNum]);

  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <FormCard
        title={`${t('Event')}: ${data?.originatedFrom} - ${data?.logType}`}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        onReturn={null}
        permitSubmit={false}
        permitDelete={false}
        initialValues={pickProperties(data)}
        validationSchema={validationSchema}
        enableReinitialize
        idx={idNum}
      >
        <FormFields isNew={isNew} updateRecord={updateRecord} refetch={refetch} data={data} />
        {/* <div className="float-right">{data && <UserTooltip data={data} />}</div> */}
      </FormCard>
    );
  }
};
