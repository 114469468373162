import gql from 'graphql-tag';

export const gqlGetUserRoles = gql`
  query getUserRoles($where: String, $offset: Int, $limit: Int, $order: String) {
    getUserRoles(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        id
        name
        isSystem
        isActive
      }
      recordCount
    }
  }
`;

export const gqlGetUserRole = gql`
  query getUserRoleById($id: Int) {
    getUserRoleById(id: $id) {
      id
      name
      isSystem
      isActive
      firstDate
      lastDate
      firstUser
      lastUser
    }
  }
`;

export const gqlAddUserRole = gql`
  mutation addUserRole($data: UserRoleInput) {
    addUserRole(data: $data) {
      id
    }
  }
`;

export const gqlUpdateUserRole = gql`
  mutation updateUserRole($data: UserRoleInput) {
    updateUserRole(data: $data) {
      id
    }
  }
`;

export const gqlDeleteUserRole = gql`
  mutation deleteUserRole($id: Int) {
    deleteUserRole(id: $id)
  }
`;
