import React from 'react';
import { Row } from 'react-bootstrap';
import {
  buildWhereForDates,
  FormCol,
  FormFilterCard,
  FieldInput,
  FieldDateRanger,
  FieldSelectIsActive,
} from 'motion-components';
import { useTranslation } from 'react-i18next';
import { FieldSelectRegion } from 'components';
import { endOfDay, startOfDay } from 'date-fns';

/* ----------------------- Διαχείριση Φίλτρων ------------------------------ */
export function buildWhereFromFilterValues(values): string {
  // Φτιάξε το where για το graphql
  let where = {};
  if (values) {
    if (values.valoriserId !== undefined) {
      where = { ...where, valoriserId: values.valoriserId };
    }
  }
  return JSON.stringify(where);
}
