import * as Lo from 'lodash';
import React, { useCallback } from 'react';
import { generatePath, useLocation, useHistory } from 'react-router-dom';

import {
  HomePage,
  UsersPage,
  UserPage,
  UserRolesPage,
  UserRolePage,
  ApplogGridPage,
  ApplogFormPage,
  AuditTrailsPage,
  AuditTrailFormPage,
  RegionsGridPage,
  RegionFormPage,
  CountyFormPage,
  CountiesGridPage,
  CollectionpointFormPage,
  CollectionpointsGridPage,
  CollectionpointsCategoriesGridPage,
  CollectionpointsCategoryFormPage,
  CollectionpointsTypeFormPage,
  CollectionpointsTypesGridPage,
  CollectorFormPage,
  CollectorsGridPage,
  CollectorsPlatformFormPage,
  CollectorsPlatformsGridPage,
  CreateNewTirescollection,
  TirecollectionFormPage,
  TirecollectionsGridPage,
  TirecollectionsStatusFormPage,
  TirecollectionsStatusesGridPage,
  TireTypesGridPage,
  TireTypeFormPage,
  ValorisersGroupFormPage,
  ValorisersGroupsGridPage,
  ValorisationMethodFormPage,
  ValorisationMethodsGridPage,
  ValoriserFormPage,
  ValorisersGridPage,
  TiredeliveryFormPage,
  TiredeliveriesGridPage,
  TiredeliveriesRequestsGridPage,
  TiredeliveriesValDeliveriesGridPage,
  TiredeliveriesColDeliveriesGridPage,
  TiredeliveriesUnreconciledGridPage,
  TiredeliveriesAllUnconfirmedGridPage,
  TiredeliveriesResolvedGridPage,
  CollectorsStocksGridPage,
  CollectorsStockTransactionsGridPage,
  ValorisersStocksGridPage,
  ValorisersStockTransactionsGridPage,
  CollectorsStocktakingHdrGridPage,
  CollectorsStocktakingHdrFormPage,
  CollectorsStocktakingRequestsGridPage,
  ExcelReports,
  RepSubscriptionsFormPage,
  RepSubscriptionsGridPage,
  ColWeekSchedules,
  ColWeekSchedulesEA
} from 'pages';
import { UserRoles } from 'shared/constants';
import { ValorisedNetWeight } from 'pages/manual-reports/valorised-netweight';

class RouteType {
  name: string;
  path: string;
  component: React.ComponentType<any>;
  state?: object;
  userRoles?: UserRoles[] = [UserRoles.All]; // Roles με πρόσβαση
}

export const routes: RouteType[] = [
  { name: 'Home', path: '/', component: HomePage },
  { name: 'Users', path: '/users', component: UsersPage, userRoles: [UserRoles.Admin] },
  {
    name: 'ApplogGridPage',
    path: '/applogs',
    component: ApplogGridPage,
    userRoles: [UserRoles.Admin],
  },
  {
    name: 'ApplogFormPage',
    path: '/applog/:id',
    component: ApplogFormPage,
    userRoles: [UserRoles.Admin],
  },
  { name: 'AuditTrails', path: '/audittrails', component: AuditTrailsPage },
  { name: 'AuditTrail', path: '/audittrail/:id', component: AuditTrailFormPage },

  { name: 'User', path: '/user/:id', component: UserPage },
  { name: 'UserRoles', path: '/userroles', component: UserRolesPage, userRoles: [UserRoles.Admin] },
  { name: 'UserRole', path: '/userrole/:id', component: UserRolePage },

  { name: 'Regions', path: '/regions', component: RegionsGridPage },
  { name: 'Region', path: '/region/:id', component: RegionFormPage },
  { name: 'Counties', path: '/counties', component: CountiesGridPage },
  { name: 'County', path: '/county/:id', component: CountyFormPage },
  {
    name: 'Collectionpoints',
    path: '/collectionpoints',
    component: CollectionpointsGridPage,
  },
  {
    name: 'Collectionpoint',
    path: '/collectionpoint/:id',
    component: CollectionpointFormPage,
  },
  {
    name: 'CollectionpointsCategories',
    path: '/collectionpointcategories',
    component: CollectionpointsCategoriesGridPage,
  },
  {
    name: 'CollectionpointsCategory',
    path: '/collectionpointcategory/:id',
    component: CollectionpointsCategoryFormPage,
  },
  {
    name: 'CollectionpointsTypes',
    path: '/collectionpointtypes',
    component: CollectionpointsTypesGridPage,
  },
  {
    name: 'CollectionpointsType',
    path: '/collectionpointtype/:id',
    component: CollectionpointsTypeFormPage,
  },
  { name: 'Collectors', path: '/collectors', component: CollectorsGridPage },
  { name: 'Collector', path: '/collector/:id', component: CollectorFormPage },
  {
    name: 'CollectorsPlatforms',
    path: '/collectorsplatforms',
    component: CollectorsPlatformsGridPage,
  },
  {
    name: 'CollectorsPlatform',
    path: '/collectorsplatform/:id',
    component: CollectorsPlatformFormPage,
  },
  {
    name: 'NewTirecollection',
    path: '/newtirecollection',
    component: CreateNewTirescollection,
  },
  {
    name: 'Tirecollections',
    path: '/tirecollections',
    component: TirecollectionsGridPage,
  },
  {
    name: 'Tirecollection',
    path: '/tirecollection/:id',
    component: TirecollectionFormPage,
  },

  {
    name: 'TirecollectionStatuses',
    path: '/tirecollectionstatuses',
    component: TirecollectionsStatusesGridPage,
  },
  {
    name: 'TirecollectionStatus',
    path: '/tirecollectionstatus/:id',
    component: TirecollectionsStatusFormPage,
  },
  { name: 'TireTypes', path: '/tiretypes', component: TireTypesGridPage },
  { name: 'TireType', path: '/tiretype/:id', component: TireTypeFormPage },

  {
    name: 'ValorisationMethods',
    path: '/valorisationmethods',
    component: ValorisationMethodsGridPage,
  },
  {
    name: 'ValorisationMethod',
    path: '/valorisationmethod/:id',
    component: ValorisationMethodFormPage,
  },
  { name: 'ValorisersGroups', path: '/valorisersgroups', component: ValorisersGroupsGridPage },
  { name: 'ValorisersGroup', path: '/valorizersgroup/:id', component: ValorisersGroupFormPage },
  { name: 'Valorisers', path: '/valorisers', component: ValorisersGridPage },
  { name: 'Valoriser', path: '/valorizer/:id', component: ValoriserFormPage },

  { name: 'CollectionPoints', path: '/', component: HomePage },

  {
    name: 'Tiredeliveries',
    path: '/tiredeliveries',
    component: TiredeliveriesGridPage,
  },
  {
    name: 'Tiredelivery',
    path: '/tiredelivery/:id',
    component: TiredeliveryFormPage,
  },
  {
    name: 'Tiredeliveries-requests',
    path: '/tiredeliveriesrequests',
    component: TiredeliveriesRequestsGridPage,
  },
  {
    name: 'Tiredeliveries-val',
    path: '/tiredeliveriesval',
    component: TiredeliveriesValDeliveriesGridPage,
  },
  {
    name: 'Tiredeliveries-col',
    path: '/tiredeliveriescol',
    component: TiredeliveriesColDeliveriesGridPage,
  },
  {
    name: 'Tiredeliveries-unreconciled',
    path: '/tiredeliveriesunreconciled',
    component: TiredeliveriesUnreconciledGridPage,
  },
  {
    name: 'Tiredeliveries-unconfirmed',
    path: '/tiredeliveriesunconfirmed',
    component: TiredeliveriesAllUnconfirmedGridPage,
  },
  {
    name: 'Tiredeliveries-resolved',
    path: '/tiredeliveriesresolved',
    component: TiredeliveriesResolvedGridPage,
  },
  {
    name: 'CollectorsStocks',
    path: '/collectors-stocks',
    component: CollectorsStocksGridPage,
  },
  {
    name: 'CollectorsStockTransactions',
    path: '/collectors-stock-transactions/:platformId',
    component: CollectorsStockTransactionsGridPage,
  },
  {
    name: 'CollectorsInventoryCount',
    path: '/collectors-inventory-count/:id',
    component: CollectorsStocktakingHdrFormPage,
  },
  {
    name: 'CollectorsInventoryCounts',
    path: '/collectors-inventory-counts',
    component: CollectorsStocktakingHdrGridPage,
  },
  {
    name: 'CollectorsInventoryCountsRequests',
    path: '/collectors-inventory-counts-requests',
    component: CollectorsStocktakingRequestsGridPage,
  },
  {
    name: 'ValorisersStocks',
    path: '/valorisers-stocks',
    component: ValorisersStocksGridPage,
  },
  {
    name: 'ValorisersStockTransactions',
    path: '/valorisers-stock-transactions/:valoriserId',
    component: ValorisersStockTransactionsGridPage,
  },
  {
    name: 'ValorisedWeight',
    path: '/valorisedweight',
    component:  ValorisedNetWeight,
  },
  {
    name: 'Reports',
    path: '/reports',
    component: ExcelReports,
  },
  { name: 'RepSubscriptions', path: '/rep-subscriptions', component: RepSubscriptionsGridPage },
  { name: 'RepSubscription', path: '/rep-subscription/:id', component: RepSubscriptionsFormPage },
  {
    name: 'WeekSchedules',
    path: '/weekschedules',
    component:  ColWeekSchedules,
  },
  {
    name: 'WeekSchedulesEA',
    path: '/weekscheduleseco',
    component:  ColWeekSchedulesEA,
  },
];

export function getRouteByName(name: string): string {
  const idx = Lo.findIndex(routes, ['name', name]);
  if (idx === -1) {
    console.error('getRouteByName not found', name);
    return '/';
  }
  return routes[idx].path;
}

export class Paths {
  static user = (id) => {
    return generatePath(getRouteByName('User'), { id: id });
  };
  static users = () => {
    return generatePath(getRouteByName('Users'));
  };
  static collectionpoint = (id) => {
    return generatePath(getRouteByName('Collectionpoint'), { id: id });
  };
  static collector = (id) => {
    return generatePath(getRouteByName('Collector'), { id: id });
  };
  static collectorsPlatform = (id) => {
    return generatePath(getRouteByName('CollectorsPlatform'), { id: id });
  };
}

export function useNavigateTo() {
  const location = useLocation<any>();
  const history = useHistory();

  const navigateTo = useCallback<(path: string) => void>(
    (path) => {
      history.push({
        pathname: path,
        state: {
          pathname: location.pathname,
          ...location.state,
        },
      });
    },
    [history, location.pathname, location.state],
  );
  return navigateTo;
}
