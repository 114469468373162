import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { promiseModalDialog } from 'motion-components';
import { toastSuccess } from 'utils/toasts';
import { FaCheck } from 'react-icons/fa';
import promiseModalConfirmTireDelivery from './confirmDelivery-modal';

const gqlConfirmTireDelivery = gql`
  mutation confirmTireDelivery($tireDeliveryId: Int!) {
    confirmTireDelivery(tireDeliveryId: $tireDeliveryId)
  }
`;

const UseConfirmTireDelivery = () => {
  const [updateRecord] = useMutation(gqlConfirmTireDelivery);
  const confirmTireDelivery = async (tireDeliveryId) => {
    const ret = await updateRecord({
      variables: { tireDeliveryId: tireDeliveryId },
    });
    console.log('$$$', ret);
    return ret;
  };
  return { confirmTireDelivery };
};

export const ConfirmTireDeliveryButton = ({ deliveryId, onClick, disabled = false, data }) => {
  const { t } = useTranslation();
  const { confirmTireDelivery } = UseConfirmTireDelivery();

  const handleSubmit = async () => {
    const res = await promiseModalConfirmTireDelivery({
      title: t('Confirm data'),
      data: data,
    });
    if (res) {
      await confirmTireDelivery(deliveryId);
      toastSuccess(t('Successfully confirmed data'));
      onClick();
    }
  };
  return (
    <>
      <Button disabled={disabled} variant="success" onClick={handleSubmit} className="ml-3 mr-4">
        <FaCheck className="mb-1 mr-3" />
        <span>{t('Confirm Data')}</span>
      </Button>
    </>
  );
};
