import React from 'react';
import { Row } from 'react-bootstrap';
import {
  buildWhereForDates,
  FormCol,
  FormFilterCard,
  FieldInput,
  FieldDateRanger,
  FieldSelectIsActive,
} from 'motion-components';
import { useTranslation } from 'react-i18next';
import {
  FieldSelectCollector,
  FieldSelectDeliveryStatus,
  FieldSelectPlatform,
  FieldSelectValoriser,
} from 'components';
import { TireDeliveriesStatus } from 'shared/constants';
import { useRootStore } from 'store';
import { useFormikContext } from 'formik';

/* ----------------------- Διαχείριση Φίλτρων ------------------------------ */
export function buildWhereFromFilterValues(values): string {
  // Φτιάξε το where για το graphql
  let where: any = {};
  if (values) {
    if (values.startDate || values.endDate) {
      where = {
        ...where,
        ...buildWhereForDates(values.startDate, values.endDate, 'deliveredDate'),
      };
    }
    if (values.startDateVal || values.endDateVal) {
      where = {
        ...where,
        ...buildWhereForDates(values.startDateVal, values.endDateVal, 'valorisationDate'),
      };
    }
    if (values.startDatePlan || values.endDatePlan) {
      where = {
        ...where,
        ...buildWhereForDates(values.startDatePlan, values.endDatePlan, 'plannedDeliveryDate'),
      };
    }

    if (values.searchText) {
      const searchText = '%' + values.searchText + '%';
      const likeObj = { $like: searchText };
      where = {
        ...where,
        $or: [{ requestNo: likeObj }, { deliveryNoteNo: likeObj }, { weighingNoteNo: likeObj }],
      };
    }
    // Status Multi Select
    if (values.statusId && Array.isArray(values.statusId) && values.statusId.length > 0) {
      const statusIds = values.statusId.map((v) => v.value);
      where = { ...where, statusId: statusIds };
    }
    if (values.collectorId) where = { ...where, collectorId: values.collectorId };
    if (values.platformId) where = { ...where, platformId: values.platformId };
    if (values.valoriserId) where = { ...where, valoriserId: values.valoriserId };
    if (values.isActive !== undefined && values.isActive !== '') {
      where = { ...where, isActive: values.isActive };
    }
  }
  return JSON.stringify(where);
}

/* ----------------------- Filter Form ------------------------------ */
export const Filters = ({ onChange, initialValues }) => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  //
  function handleFiltersSubmit(values, { setSubmitting }) {
    setTimeout(() => {
      setSubmitting(false);
      onChange(values);
    }, 400);
  }
  //console.log("filters-init ", initialValues);

  const filters = {
    startDate: initialValues?.startDate ?? null,
    endDate: initialValues?.endDate ?? null,
    startDateVal: initialValues?.startDateVal ?? null,
    endDateVal: initialValues?.endDateVal ?? null,
    startDatePlan: initialValues?.startDatePlan ?? null,
    endDatePlan: initialValues?.endDatePlan ?? null,

    searchText: initialValues?.searchText ?? '',
    isActive: initialValues?.isActive ?? null,

    statusId: initialValues?.statusId ?? [],
    valoriserId: initialValues?.valoriserId ?? null,
    platformId: initialValues?.platformId ?? null,
    collectorId: initialValues?.collectorId ?? null,
  };

  return (
    <FormFilterCard
      title={t('Filter Criteria')}
      onSubmit={handleFiltersSubmit}
      initialValues={filters}
    >
      <>
        <Row>
          <FieldInput
            as={FormCol}
            name="searchText"
            label={t('Search (Req. Num., Del. Note Num., Weighing Note Num.)')}
            type="search"
          />
          <FieldDateRanger
            as={FormCol}
            label={t('Delivering Date from')}
            label2={t('till')}
            name="startDate"
            name2="endDate"
          />
          {!appStore.isCollector ? (
            <FieldSelectCollector label={t('Collector')} as={FormCol} />
          ) : (
            <FieldSelectPlatform label={t('Platform')} as={FormCol} />
          )}

          <FieldDateRanger
            as={FormCol}
            label={t('Planned Delivering Date from')}
            label2={t('till')}
            name="startDatePlan"
            name2="endDatePlan"
          />
        </Row>
        <Row>
          <FieldSelectDeliveryStatus label={t('Status')} isMulti={true} as={FormCol} />
          <FieldDateRanger
            as={FormCol}
            label={t('Valorisation Date from')}
            label2={t('till')}
            name="startDateVal"
            name2="endDateVal"
          />
          {appStore.isEcoanvelope && (
            <FieldSelectPlatform useWhereValues={true} label={t('Platform')} as={FormCol} />
          )}

          {(!appStore.isValoriser || appStore.isMultiValoriser) && (
            <FieldSelectValoriser label={t('Valoriser')} as={FormCol} />
          )}
          <FieldSelectIsActive label={t('Active Records')} />
        </Row>
      </>
    </FormFilterCard>
  );
};
