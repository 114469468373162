import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useRootStore } from 'store';
import {
  CellDateTime,
  CellLeft,
  GridLinkButton,
  TablePaginatedUC,
  exportXlsModal,
  usePaginatedQuery,
} from 'motion-components';
import { gql, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { useHandleError } from 'services';
import { useTranslation } from 'react-i18next';
import { gqlGetCollectorCollectionpointsQuery } from '../graphql';
import LoadingOverlay from 'react-loading-overlay';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { getRouteByName } from 'app/routes';

export const ColCollectionpointsTable = ({ collectorId, refetch }) => {
  const { t } = useTranslation();
  const { appStore, authStore } = useRootStore();
  const { handleGraphqlError } = useHandleError('ColCollectionpointsTable ');
  const location = useLocation<any>();
  const history = useHistory();

  function buildWhereFromFilterValues(): string {
    // Φτιάξε το where για το graphql
    let where = { collectorId: collectorId };
    return JSON.stringify(where);
  }

  const query = usePaginatedQuery(
    gqlGetCollectorCollectionpointsQuery,
    buildWhereFromFilterValues,
    'id DESC',
    appStore,
    true,
    {
      collectorId: collectorId,
    },
    false,
  );
  const {
    loading,
    data,
    dataCount,
    error,
    initPageIndex,
    filtersChanged,
    gridPageChanged,
    queryState,
  } = query;

  const tablePageSize = appStore.pageSize;
  const client = useApolloClient();
  async function handleExport(client, t): Promise<void> {
    function useGetQuery() {
      return useLazyQuery(gqlGetCollectorCollectionpointsQuery);
      //return [null, { loading: null, data: null }];
    }

    const filterVariables = {
      where: buildWhereFromFilterValues(),
    };

    await exportXlsModal({
      apolloClient: client,
      exportFileName: 'export connected-CollectionPoints',
      getLazyQuery: useGetQuery,
      columnsDef: exportColumns(t),
      filterVariables: filterVariables,
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: t('Collection Point Name'),
        accessor: 'collectionpoint.name',
        Cell: ({ row }) => (
          <>
            <GridLinkButton
              onClick={() => {
                history.push({
                  pathname: generatePath(getRouteByName('Collectionpoint'), {
                    id: row.original.collectionpointId,
                  }),
                });
              }}
            >
              <div className="text-left">{row.original.collectionpoint?.name}</div>
            </GridLinkButton>
          </>
        ),
        width: '100',
        isVisible: false,
      },
      {
        Header: t('County'),
        accessor: 'collectionpoint.county.name',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Address'),
        accessor: 'collectionpoint.address',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        id: 'phone',
        Header: t('Phone'),
        accessor: 'collectionpoint.phone',
        Cell: ({ row }) => (
          <>
            <div className="text-center">{row.original.collectionpoint?.phone}</div>
            <div className="text-center">{row.original.collectionpoint?.mobile}</div>
          </>
        ),
        width: '100',
        isVisible: false,
      },
      {
        Header: t('CUI/CNP'),
        accessor: 'collectionpoint.vatin',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Category'),
        accessor: 'collectionpoint.collectionpointsCategory.name',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Date Connected'),
        accessor: 'firstDate',
        Cell: CellDateTime,
        width: '100',
        isVisible: false,
      },
    ],
    [authStore.isUsersAdmin, t],
  );
  return (
    <LoadingOverlay active={loading} spinner text={t('Please wait...')}>
      {data?.length > 0 ? (
        <>
          <h5 className="form-group-title mb-0 ">{t('Collection Points Connected')}</h5>
          <TablePaginatedUC
            title={t('Collection Points')}
            columns={columns}
            data={data}
            dataCount={dataCount}
            onPageChange={gridPageChanged}
            initPageIndex={initPageIndex}
            id={'UsersTable'}
            appStore={appStore}
            onExport={() => handleExport(client, t)}
          />
          {/* <TableInMem columns={columns} data={data} id="grid" appStore={appStore} /> */}
        </>
      ) : (
        <div style={{ height: '400px' }}></div>
      )}
    </LoadingOverlay>
  );
};

const exportColumns = (t) => {
  return [
    // { fieldName: "id", fieldTitle: "Id", fieldType: "TEXT" },
    {
      fieldName: 'collectionpoint.name',
      fieldTitle: t('Collection Point Name'),
      fieldType: 'TEXT',
    },
    /* { fieldName: 'isHidden', fieldTitle: 'Is Hidden', fieldType: 'TEXT' }, */
    { fieldName: 'collectionpoint.county.name', fieldTitle: t('County'), fieldType: 'TEXT' },
    { fieldName: 'collectionpoint.address', fieldTitle: t('Address'), fieldType: 'TEXT' },
    { fieldName: 'collectionpoint.phone', fieldTitle: t('Phone'), fieldType: 'TEXT' },
    { fieldName: 'collectionpoint.mobile', fieldTitle: t('Mobile'), fieldType: 'TEXT' },
    { fieldName: 'collectionpoint.vatin', fieldTitle: t('CUI/CNP'), fieldType: 'TEXT' },
    {
      fieldName: 'collectionpoint.collectionpointsCategory.name',
      fieldTitle: t('Category'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'firstDate',
      fieldTitle: t('Date Connected'),
      fieldType: 'DATETIME',
    },
  ];
};
