export function charToNumber(str: string) {
  const charList = {
    t: '7',
    h: '4',
    i: '1',
    I: '1'
  };
  return str.replace(/./g, function(c) {
    return c in charList ? charList[c] : c;
  });
}

export function removeSpaces(str: string) {
  return str.replace(/\s+/g, '');
}

export function addComma(str: string) {
  if (str.length === 4 && !str.includes(",")) {
    return str.substring(0, 2) + "," + str.substring(2, 4);
  }
  else return str;
}


export function arrayIncludes(array: [string], substring: string) {
  console.log('$$$$$$$$', array);
  const match = array.find((element) => {
    if (element && element.includes(substring)) {
      return true;
    }
  });
  return match && match.length > 0;
}

export function findLineIncludes(array: [string], substring: string) {
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (element && element.includes(substring)) {
      return element;
    }
  }
  return undefined;
}

export function findLineIdxIncludes(array: string[], substring: string) {
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (element && element.includes(substring)) {
      return index;
    }
  }
  return undefined;
}

//export function findNextLineIncludes(array: [string], substring: string, advanceLines: number) {
export function findNextLineIncludes(array: string[], substring: string, advanceLines: number) {
  const dataLineIdx = findLineIdxIncludes(array, substring);
  if (dataLineIdx && dataLineIdx > 0) {
    if (dataLineIdx + advanceLines <= array.length) return array[dataLineIdx + advanceLines];
  }
  return undefined;
}

export function stringFollowingSubString(line, substring) {
  const myArray = line.split(substring);
  if (myArray?.length >= 1) {
    console.log(`myArray "${myArray[1]}"`);
    return myArray[1];
  } else return null;
}

export function stringUptoSubString(line, substring) {
  const myArray = line.split(substring);
  if (myArray?.length >= 0) {
    console.log(`myArray "${myArray[0]}"`);
    return myArray[0];
  } else return null;
}
