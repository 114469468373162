import React from 'react';
import { Row } from 'react-bootstrap';
import {
  buildWhereForDates,
  FormCol,
  FormFilterCard,
  FieldInput,
  FieldDateRanger,
  FieldSelectIsActive,
  CustomSelectComponent,
} from 'motion-components';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { FieldSelectCollector, FieldSelectPlatform } from 'components';
import { useRootStore } from 'store';

/* ----------------------- Διαχείριση Φίλτρων ------------------------------ */
export function buildWhereFromFilterValues(values): string {
  // Φτιάξε το where για το graphql
  let where = {};
  if (values) {
    if (values.startDate || values.endDate) {
      where = {
        ...where,
        ...buildWhereForDates(values.startDate, values.endDate, 'stockDate'),
      };
    }
    if (values.approved === undefined || values.approved === null || values.approved === '')
      where = { ...where, $not: { approved: null } };
    else where = { ...where, approved: values.approved };

    if (values.collectorId) where = { ...where, collectorId: values.collectorId };
    if (values.platformId) where = { ...where, platformId: values.platformId };
    // if (values.someId) where = { ...where, someId: values.someId };
  }
  return JSON.stringify(where);
}

/* ----------------------- Filter Form ------------------------------ */
export const Filters = ({ onChange, initialValues }) => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  //
  function handleFiltersSubmit(values, { setSubmitting }) {
    setTimeout(() => {
      setSubmitting(false);
      onChange(values);
    }, 400);
  }
  //console.log("filters-init ", initialValues);

  const filters = {
    startDate: initialValues?.startDate ?? null,
    endDate: initialValues?.endDate ?? null,
    //someId: initialValues?.someId ?? null,
    collectorId: initialValues?.collectorId ?? null,
    platformId: initialValues?.platformId ?? null,
    approved: initialValues?.approved ?? null,
  };

  const options = [
    { value: true, label: t('Yes') },
    { value: false, label: t('No') },
  ];

  return (
    <FormFilterCard
      title={t('Filter Criteria')}
      onSubmit={handleFiltersSubmit}
      initialValues={filters}
    >
      <Row>
        <FieldDateRanger
          as={FormCol}
          label={t('Stock Date from')}
          label2={t('Till')}
          name="startDate"
          name2="endDate"
        />
        {!appStore.isCollector && <FieldSelectCollector label={t('Collector')} as={FormCol} />}
        <FieldSelectPlatform label={t('Platform')} as={FormCol} />
        <Field
          as={FormCol}
          title={t('Approved')}
          name={'approved'}
          component={CustomSelectComponent}
          options={options}
        />
      </Row>
    </FormFilterCard>
  );
};
