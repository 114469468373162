import React from 'react';
import { Row } from 'react-bootstrap';
import {
  buildWhereForDates,
  FormCol,
  FormFilterCard,
  FieldInput,
  FieldDateRanger,
  FieldSelectIsActive,
} from 'motion-components';
import { useTranslation } from 'react-i18next';
import { FieldSelectCounty, FieldSelectRegion } from 'components';
import { filter } from 'lodash';
import { useField } from 'formik';
import { useRootStore } from 'store';

/* ----------------------- Διαχείριση Φίλτρων ------------------------------ */
export function buildWhereFromFilterValues(values): string {
  console.log('filters $$$', values);
  // Φτιάξε το where για το graphql
  let where = {};
  if (values) {
    /* if (values.startDate || values.endDate) {
      where = {
        ...where,
        ...buildWhereForDates(values.startDate, values.endDate, "filledDate"),
      };
    } */

    if (values.searchText) {
      const searchText = '%' + values.searchText + '%';
      const likeObj = { $like: searchText };
      where = {
        ...where,
        $or: [{ name: likeObj }],
      };
    }

    if (values.isActive !== undefined && values.isActive !== '') {
      where = { ...where, isActive: values.isActive };
    }
    // County Single Select
    // if (values.countyId) where = { ...where, countyId: values.countyId };

    // filter alias (regionId)
    if (values.regionId) where = { ...where, regionId: values.regionId };

    // County Multi Select
    if (values.countyId && Array.isArray(values.countyId) && values.countyId.length > 0) {
      const countyIds = values.countyId.map((v) => v.value);
      where = { ...where, countyId: countyIds };
    }
  }
  return JSON.stringify(where);
}

/* ----------------------- Filter Form ------------------------------ */
export const Filters = ({ onChange, initialValues }) => {
  const { t } = useTranslation();
  //
  function handleFiltersSubmit(values, { setSubmitting }) {
    setTimeout(() => {
      setSubmitting(false);
      onChange(values);
    }, 400);
  }
  //console.log("filters-init ", initialValues);

  const filters = {
    /* startDate: initialValues?.startDate ?? null,
    endDate: initialValues?.endDate ?? null, */
    searchText: initialValues?.searchText ?? '',
    isActive: initialValues?.isActive ?? undefined,
    regionId: initialValues?.regionId ?? undefined,
    countyId: initialValues?.countyId ?? [],
  };

  return (
    <FormFilterCard
      title={t('Filter Criteria')}
      onSubmit={handleFiltersSubmit}
      initialValues={filters}
    >
      <FilterFields />
    </FormFilterCard>
  );
};

export const FilterFields = () => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const [field, { value }] = useField('regionId');
  return (
    <Row>
      <FieldInput as={FormCol} name="searchText" label={t('Search (Name)')} type="search" />
      <FieldSelectRegion label={t('Region')} as={FormCol} />
      <FieldSelectCounty label={t('County')} as={FormCol} isMulti={true} regionId={value} />
      {!appStore.isCollectionPoint && <FieldSelectIsActive label={t('Active Records')} />}
    </Row>
  );
};
