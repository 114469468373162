import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery, useApolloClient } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import {
  FormCard,
  YupNumeric,
  YupDate,
  YupString,
  YupBoolean,
  promiseModalDialog,
  UserTooltip,
  objParseInt,
  objParseFloat,
} from 'motion-components';
import * as Yup from 'yup';
import * as Lo from 'lodash';
import { toastSuccess } from 'utils/toasts';
import { useHandleError } from 'services';
import {
  gqlGetByIdQuery,
  gqlAdd,
  gqlUpdate,
  gqlDelete,
  FormInput,
  gqlSetCustodyAll,
} from '../graphql';
import { isNumeric } from 'utils/helpers';

import { FormFields } from './form-fields';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { FormFieldsAll } from './form-fields-all';
import { ValMethodsTable } from './valmethods-table';
import '../../../styles/tab-styles.css';
import { ValTireTypesTable } from './valtiretypes-table';
import { FaLock } from 'react-icons/fa';
import {
  BecomeAvailableButton,
  BecomeUnavailableButton,
} from './set-availability/setAvailability-button';
import promiseModalSelectDate from 'components/modal-select-date';

export const ValoriserFormPage = () => {
  const { t } = useTranslation();
  const { handleGraphqlError } = useHandleError('ValorisersFormPage');
  const { appStore } = useRootStore();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const idNum = isNumeric(id) ? parseInt(id) : -1;
  const isNew = idNum < 0;

  const formShape = {
    id: YupNumeric,
    name: YupString,
    vatin: YupString,
    tradeRegister: YupString,
    partnerName: YupString,
    workstation: YupString,
    contractNo: YupString,
    contractExpirationDate: YupDate,
    valoriserGroupId: YupNumeric,
    address: YupString,
    city: YupString,
    countyId: YupNumeric,
    phone: YupString,
    mobile: YupString,
    fax: YupString,
    email: YupString,
    website: YupString,
    contactperson: YupString,
    notes: YupString,
    workDaysFrom: YupDate,
    workDaysTill: YupDate,
    saturdayDaysFrom: YupDate,
    saturdayDaysTill: YupDate,
    sundayDaysFrom: YupDate,
    sundayDaysTill: YupDate,
    environmentalPermit: YupString,
    envPermitExpirationDate: YupDate,
    autoApproveDeliveries: YupBoolean,
    deliveriesYearlyWeightTarget: YupNumeric,
    maxDeliveriesPerDay: YupNumeric,
    isActive: YupBoolean,
    unavailableReason: YupString,
    isUnavailable: YupBoolean,
    unavailableFrom: YupDate,
    unavailableTill: YupDate,
    firstDate: YupDate,
    lastDate: YupDate,
    firstUser: YupString,
    lastUser: YupString,
  };

  const validationSchema = Yup.object().shape(formShape);
  const pickProperties = (values) => Lo.pick(values, Lo.keys(formShape));
  type FormValuesType = Yup.InferType<typeof validationSchema>;

  const newRecord = useMemo<FormValuesType>(
    () =>
      ({
        isActive: true,
      } as FormValuesType),
    [],
  );

  const [addRecord] = useMutation(gqlAdd);
  const [updateRecord] = useMutation(gqlUpdate);
  const [deleteRecord] = useMutation(gqlDelete);
  const [setCustody] = useMutation(gqlSetCustodyAll);
  const [get, { data: rawData, error, refetch }] = useLazyQuery(gqlGetByIdQuery, {
    fetchPolicy: 'network-only',
  });
  const data = isNew ? newRecord : rawData ? rawData[Object.keys(rawData)[0]] : undefined;

  /* fetch data */
  useEffect(() => {
    if (!isNew) get({ variables: { id: idNum } });
  }, [get, id, idNum, isNew]);

  /**
   * * ΑΠΟΘΗΚΕΥΣΗ - UPDATE/CREATE
   */
  const handleSubmit = (values: FormValuesType, actions) => {
    actions.setSubmitting(false);
    let mutValues = Lo.pick(values, Lo.keys(new FormInput()));
    mutValues = objParseInt(['id', 'valoriserGroupId', 'countyId'], mutValues);
    mutValues = objParseFloat([], mutValues);
    // Αν είναι νέα εγγραφή τοτε γράφω όλα τα πεδία
    if (isNew) {
      console.log('[Valorisers] New:', mutValues);
      addRecord({ variables: { data: { ...mutValues } } })
        .then((d) => {
          toastSuccess(
            `${t('Record Created Successfully')} [${d.data[Object.keys(d.data)[0]]?.id}]`,
          );
          history.goBack();
        })
        .catch((error) => {
          handleGraphqlError('mutation-add', error);
        });
    } else {
      // Αν είναι update εγγραφής τοτε γράφω μονο τα αλλαγμένα πεδία
      console.log('[Valorisers] Update:', mutValues);
      updateRecord({ variables: { data: { ...mutValues, id: idNum } } })
        .then((d) => {
          toastSuccess(
            `${t('Record Updated Successfully')} [${d.data[Object.keys(d.data)[0]].id}]`,
          );
          refetch();
        })
        .catch((error) => {
          handleGraphqlError('mutation-update', error);
        });
    }
  };

  /**
   * *  DELETE
   */
  const handleDelete = useCallback(async () => {
    const res = await promiseModalDialog({
      title: `${t('Delete Record')}`,
      text: `${t('The record will be permanently deleted. Do you want to continue?')}`,
    });
    if (res) {
      deleteRecord({ variables: { id: idNum } })
        .then(() => {
          toastSuccess(t('The record has been successfully deleted'));
          history.goBack();
        })
        .catch((error) => {
          handleGraphqlError('mutation-delete', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRecord, idNum]);

  /**
   * *  Set Custody
   */
  const handleSetCustody = useCallback(async () => {
    const res = await promiseModalSelectDate({
      title: `${t('Activate Custody')}`,
      text: `${t(
        "Please select the date you want the custody to finish. All current Tire Deliveries from this valoriser with status 'Delivered' will be put in custody until that date.",
      )}`,
    });
    if (res) {
      setCustody({ variables: { valoriserId: idNum, valRestrictedUntilDate: res.date } })
        .then(() => {
          toastSuccess(t('Custody has been successfully activated'));
        })
        .catch((error) => {
          handleGraphqlError('mutation-setCustody', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRecord, idNum]);

  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <FormCard
        title={isNew ? `${t('New Valoriser')} ` : `${t('Valoriser')}: ${data?.name}`}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        onReturn={null}
        permitDelete={false}
        initialValues={pickProperties(data)}
        validationSchema={validationSchema}
        enableReinitialize
        idx={idNum}
        isNew={isNew}
        injectFooterElements={() =>
          !isNew &&
          appStore.isEcoanvelope && (
            <div>
              {data?.isUnavailable ? (
                <BecomeAvailableButton data={data} onClick={refetch}></BecomeAvailableButton>
              ) : (
                <BecomeUnavailableButton data={data} onClick={refetch}></BecomeUnavailableButton>
              )}

              <Button className="ml-3 mr-4" variant="success" onClick={handleSetCustody}>
                <FaLock className="mb-1 mr-3" />
                <span>{t('Set Custody')}</span>
              </Button>
            </div>
          )
        }
      >
        <FormFields isNew={isNew} updateRecord={updateRecord} refetch={refetch} data={data} />
        <div className="float-right">
          {data && <UserTooltip data={data} /* auditTable={'Valorisers'} */ auditId={data.id} />}
        </div>
        {data && data.id > 0 && (
          <div>
            <Tabs defaultActiveKey="form">
              <Tab eventKey="form" title={t('Information')}>
                <FormFieldsAll
                  isNew={isNew}
                  updateRecord={updateRecord}
                  refetch={refetch}
                  data={data}
                />
              </Tab>
              <Tab eventKey="tireTypes" title={t('Tire Types')}>
                <ValTireTypesTable data={data.valorisersTiretypes} refetch={refetch} />
              </Tab>
              <Tab eventKey="valMethods" title={t('Valorisation Methods')}>
                <ValMethodsTable data={data.valorisersValorisationmethods} refetch={refetch} />
              </Tab>
            </Tabs>
          </div>
        )}
      </FormCard>
    );
  }
};
