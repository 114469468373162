import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  FieldInput,
  UserTooltip,
  ButtonApprove,
  ButtonReject,
  FieldDatepicker,
} from 'motion-components';
import { useFormikContext } from 'formik';
import { useHandleError } from 'services';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';
import {
  FieldSelectCollector,
  FieldSelectPlatform,
  FieldSelectValMethod,
  FieldSelectValoriser,
} from 'components';
import { TiresSection } from './tires-section';
import { FieldSelectTiredeliveriesStatuses } from 'components/fs-tiredelivery-status';

export const FormFieldsApproveRequest = ({ data, handleApprove }) => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();

  //
  const frmkCntx = useFormikContext();
  const { values } = frmkCntx;
  const { authStore } = useRootStore();

  const { handleGraphqlError } = useHandleError('TiredeliveriesFormFields');

  function calcUserCanEdit() {
    const canEdit = appStore.isEcoanvelope;
    const canEditReasoning = '';
    return { canEdit, canEditReasoning };
  }
  // const canEdit =
  //   !(values as any)?.isFinalized && (authStore.userCanDraft(userRoleId) || authStore.userCanFinalize(userRoleId));
  const { canEdit, canEditReasoning } = calcUserCanEdit();

  return (
    <>
      {/* Header */}
      <Row>
        {/* 1st Column */}
        <Col>
          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Delivery Request Number')}
            name="requestNo"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />
          <FieldDatepicker
            disabled={!canEdit}
            as={Col}
            label={t('Planned Delivery Date')}
            name="plannedDeliveryDate"
            showTimeSelect
            dateFormat="d/M/yyyy"
            timeFormat="HH:mm"
          />
          <FieldSelectCollector label={t('Collector')} as={Col} disabled={!canEdit} />
          <FieldSelectPlatform label={t('Platform')} as={Col} disabled={!canEdit} />
          <FieldSelectValoriser label={t('Valoriser')} as={Col} disabled={!canEdit} />
          {appStore.isEcoanvelope && <FieldSelectTiredeliveriesStatuses as={Col} name="statusId" />}
        </Col>

        {/* 2nd Column : Tires*/}
        <Col>
          <div className="mt-4">
            <TiresSection isNew={appStore.isEcoanvelope} data={data} />
          </div>
        </Col>
        {/* 3rd Column : Action Buttons */}

        {appStore.isEcoanvelope && (
          <Col>
            <FieldInput
              disabled={!canEdit}
              as={Col}
              label={t('Distance in km')}
              name="distance"
              type="number"
              placeholder={t('- specify a value - ')}
              required={false}
            />
            <FieldSelectValMethod as={Col} />
            <div className="mt-4 ml-4 mr-4">
              <Row>
                <ButtonApprove
                  onClick={() => handleApprove('accept')}
                  text={t('Approve Delivery Request')}
                  className="col-12"
                  variant="success"
                />
              </Row>
              <Row className="mt-3">
                <ButtonReject
                  onClick={() => handleApprove('reject')}
                  text={t('Reject Delivery Request')}
                  className="col-12"
                />
              </Row>
            </div>
          </Col>
        )}
        {/* 4th Column : User Tooltip */}
        <Col>
          <div className="float-right mt-4 mr-3">
            {data && <UserTooltip data={data} auditTable={'Tiredeliveries'} auditId={data.id} />}
          </div>
        </Col>
      </Row>
    </>
  );
};
