import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import {
  FieldDatepicker,
  FieldInput,
  FormCol,
  FormRow,
  FieldCheckBox,
  FormCol2,
  FormCol4,
  FormCol6,
  FieldTextArea,
  TableSubSimple,
  FieldNumericInputNoLabel,
  FieldIntegerInput,
} from 'motion-components';
import { useFormikContext } from 'formik';
import { useHandleError } from 'services';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';
import { CollectionpointName, FieldSelectCollector, FieldSelectPlatform } from 'components';
import { useLazyQuery } from '@apollo/client';
import { gqlGetQuery } from 'pages/tire_types/graphql';
import LoadingOverlay from 'react-loading-overlay';
import _ from 'lodash';
import { TirecollectionStatus } from 'shared/constants';
import { DeclarePointMissingButton } from './declarePointMissing-button';

export const FormFields = ({ isNew, updateRecord, refetch, data, dataTireTypes }) => {
  const { t } = useTranslation();
  //
  const { appStore } = useRootStore();

  const { handleGraphqlError } = useHandleError('TirecollectionsFormFields');

  const modifiedDataTireTypes = (dataQty, dataTireTypes) => {
    const modifiedArray = [];
    dataTireTypes.map((dataTT) => {
      const index = _.findIndex(dataQty, ['tiretypeId', dataTT.id]);
      if (index !== -1) {
        modifiedArray.push(dataQty[index]);
      } else {
        modifiedArray.push({ expectedPieces: null, pieces: null, tireType: dataTT });
      }
    });

    return modifiedArray;
  };

  function calcUserCanEdit() {
    const canEdit =
      (data.statusId === TirecollectionStatus.PENDING &&
        (appStore.isEcoanvelope || appStore.isCollector)) ||
      (appStore.isEcoanvelope && data.statusId !== TirecollectionStatus.CANCELLED) ||
      isNew;
    const canEditReasoning = '';
    return { canEdit, canEditReasoning };
  }
  // const canEdit =
  //   !(values as any)?.isFinalized && (authStore.userCanDraft(userRoleId) || authStore.userCanFinalize(userRoleId));
  const { canEdit, canEditReasoning } = calcUserCanEdit();

  const columns = useMemo(
    () => [
      // { Header: 'Id', accessor: 'id' },
      {
        Header: t('Type'),
        accessor: 'name',
        Cell: ({ row }) => (
          <div className="text-center mt-2">
            {row.original?.name ?? row.original.tireType?.name ?? ''}
          </div>
        ),
      },
      {
        Header: t(
          appStore.isCollectionPoint
            ? t('Quantity (pieces)')
            : isNew
            ? t('Collected Quantity (pieces)')
            : t('Expected Quantity (pieces)'),
        ),
        accessor: 'pieces',
        Cell: ({ row }) => {
          return (
            <FieldIntegerInput
              hasLabel={false}
              name={`tirecollectionsQty[${row.index}].expectedPieces`}
              required={false}
              disabled={!canEdit}
            />
          );
        },
      },
    ],
    [t],
  );

  const columnsCol = useMemo(
    () => [
      // { Header: 'Id', accessor: 'id' },
      {
        Header: t('Type'),
        accessor: 'name',
        Cell: ({ row }) => (
          <div className="text-center  mt-2">
            {row.original?.name ?? row.original.tireType?.name ?? ''}
          </div>
        ),
      },
      {
        Header: t('Collected Quantity (pieces)'),
        accessor: 'pieces',
        Cell: ({ row }) => {
          return (
            <FieldIntegerInput
              hasLabel={false}
              name={`tirecollectionsQty[${row.index}].pieces`}
              required={false}
              disabled={appStore.isCollectionPoint}
            />
          );
        },
      },
    ],
    [t],
  );
  return !isNew ? (
    <>
      <div className="d-flex flex-row justify-content-start">
        <FormCol4 className="pr-4">
          <FormRow>
            <CollectionpointName labelCaption={t('Collection Point')} data={data} as={Col} />
            <FieldInput
              disabled={true}
              as={Col}
              label={t('Address')}
              name="collectionpoint.address"
              type="text"
              placeholder={t('')}
              required={false}
            />
          </FormRow>
          <FormRow>
            <FieldInput
              disabled={true}
              as={Col}
              label={t('City')}
              name="collectionpoint.city"
              type="text"
              placeholder={t('')}
              required={false}
            />
            <FieldInput
              disabled={true}
              as={Col}
              label={t('County')}
              name="collectionpoint.county.name"
              type="text"
              placeholder={t('')}
              required={false}
            />
          </FormRow>

          <FormRow className="mb-2 mt-2">
            <Col>
              <h5 className="form-group-title">{t('Request')}</h5>
            </Col>
          </FormRow>
          <FormRow>
            <FieldDatepicker
              as={Col}
              label={t('Request Date')}
              name="requestDate"
              showTimeSelect
              dateFormat="d/M/yyyy HH:mm"
              timeFormat="HH:mm"
              timeIntervals={15}
              disabled={!canEdit}
            />
            <FieldSelectCollector label={t('Collector')} disabled as={Col} />
          </FormRow>

          <FormRow>
            <FieldInput
              as={Col}
              label={t('Order Requested By')}
              name="orderedBy"
              type="text"
              placeholder={t('')}
              required={false}
              disabled={!canEdit}
            />
            <FieldSelectPlatform
              required={!appStore.isCollectionPoint}
              label={t('Platform')}
              disabled={!canEdit}
              as={Col}
            />
          </FormRow>
          <FormRow>
            <FieldTextArea
              as={Col}
              label={t('Order Notes')}
              name="orderNotes"
              type="text"
              rows={4}
              placeholder={t('')}
              required={false}
              disabled={!canEdit}
            />
            <FieldInput
              disabled={true}
              as={Col}
              label={t('Order Entered By')}
              name="ordEnteredBy"
              type="text"
              placeholder={t('')}
              required={false}
            />
          </FormRow>
          <Col className="p-0 mt-2">
            {!!data && !!dataTireTypes && (
              <TableSubSimple
                title={t('')}
                columns={columns}
                data={modifiedDataTireTypes(data.tirecollectionsQty, dataTireTypes)}
              />
            )}
          </Col>
          {/* {JSON.stringify(values)} */}
        </FormCol4>

        <FormCol4 className="pl-4">
          {/* <div style={{ height: '67px' }}></div> */}

          {data?.statusId === TirecollectionStatus.PENDING && !appStore.isCollectionPoint ? (
            <div
              style={{ marginTop: '30px', marginBottom: '18px' }}
              className="d-flex flex-column align-items-center"
            >
              <h5 className="ml-3">
                {t('If the Collection Point does not exist, anymore, click below')}:
              </h5>

              <DeclarePointMissingButton data={data} onClick={refetch}></DeclarePointMissingButton>
            </div>
          ) : (
            <div style={{ height: '92px' }}></div>
          )}

          <FormRow className="mb-2  mt-2">
            <Col>
              <h5 className="form-group-title">{t('Collection')}</h5>
            </Col>
          </FormRow>

          <FormRow>
            <FieldDatepicker
              as={Col}
              required={!appStore.isCollectionPoint}
              label={t('Collection Date')}
              name="collectionDate"
              dateFormat="d/M/yyyy"
              colorWeekend={true}
              //disableWeekend={true}
              locale="en-gb"
              disabled={appStore.isCollectionPoint}
            />
            <FieldInput
              as={Col}
              label={t('Vehicle Plate Number')}
              name="vehPlateNo"
              type="text"
              placeholder={t('')}
              required={false}
              disabled={!canEdit}
            />
          </FormRow>

          <FormRow>
            <FieldInput
              as={Col}
              label={t('Document Number')}
              name="colDocNum"
              type="text"
              placeholder={t('')}
              required={false}
              disabled={!canEdit}
            />
            <FieldInput
              disabled={true}
              as={Col}
              label={t('Collection Entered By')}
              name="colEnteredBy"
              type="text"
              placeholder={t('')}
              required={false}
            />
          </FormRow>
          <FormRow>
            <FieldTextArea
              as={Col}
              label={t('Collection Notes')}
              name="collectionNotes"
              type="text"
              rows={4}
              placeholder={t('')}
              required={false}
              disabled={!canEdit}
            />
          </FormRow>
          <Col className="p-0 mt-2">
            {!!data && !!dataTireTypes && (
              <TableSubSimple
                title={t('')}
                columns={columnsCol}
                data={modifiedDataTireTypes(data.tirecollectionsQty, dataTireTypes)}
              />
            )}
          </Col>
          {/* {JSON.stringify(values)} */}
        </FormCol4>
      </div>
    </>
  ) : (
    <LoadingOverlay active={false} spinner text={t('Please wait...')}>
      <Row>
        <Col>
          <FormRow>
            <FieldInput
              disabled={true}
              as={FormCol4}
              label={t('Collection Point')}
              name="collectionpoint.name"
              type="text"
              placeholder={t('')}
              required={false}
            />
          </FormRow>
          <FormRow>
            <FieldInput
              disabled={true}
              as={FormCol4}
              label={t('County')}
              name="collectionpoint.county.name"
              type="text"
              placeholder={t('')}
              required={false}
            />
            <FieldInput
              disabled={true}
              as={FormCol4}
              label={t('Address')}
              name="collectionpoint.address"
              type="text"
              placeholder={t('')}
              required={false}
            />
          </FormRow>

          <FormRow>
            {appStore.isCollector ? (
              <FieldDatepicker
                as={FormCol4}
                required={true}
                label={t('Collection Date')}
                name="collectionDate"
                dateFormat="d/M/yyyy"
                colorWeekend={true}
                //disableWeekend={true}
                locale="en-gb"
                disabled={!canEdit}
              />
            ) : (
              <FieldDatepicker
                as={FormCol4}
                label={t('Request Date')}
                name="requestDate"
                showTimeSelect
                dateFormat="d/M/yyyy HH:mm"
                timeFormat="HH:mm"
                timeIntervals={15}
              />
            )}

            {appStore.isCollector ? (
              <FieldSelectPlatform required={true} label={t('Platform')} as={FormCol4} />
            ) : (
              <FieldSelectCollector required label={t('Collector')} as={FormCol4} />
            )}
          </FormRow>

          {!appStore.isCollector ? (
            <FormRow>
              <FieldTextArea
                as={FormCol4}
                label={t('Notes')}
                name="orderNotes"
                type="text"
                rows={4}
                placeholder={t('')}
                required={false}
              />
              <FieldInput
                as={FormCol4}
                label={t('Order Requested By')}
                name="orderedBy"
                type="text"
                placeholder={t('')}
                required={false}
              />
            </FormRow>
          ) : (
            <>
              <FormRow>
                <FieldInput
                  as={FormCol4}
                  label={t('Vehicle Plate Number')}
                  name="vehPlateNo"
                  type="text"
                  placeholder={t('')}
                  required={false}
                  disabled={!canEdit}
                />
                <FieldInput
                  as={FormCol4}
                  label={t('Document Number')}
                  name="colDocNum"
                  type="text"
                  placeholder={t('')}
                  required={false}
                  disabled={!canEdit}
                />
              </FormRow>
              <FormRow>
                <FieldTextArea
                  as={FormCol4}
                  label={t('Collection Notes')}
                  name="collectionNotes"
                  type="text"
                  rows={4}
                  placeholder={t('')}
                  required={false}
                  disabled={!canEdit}
                />
              </FormRow>
            </>
          )}

          <FormCol4>
            {!!dataTireTypes && dataTireTypes.length > 0 && (
              <TableSubSimple title={t('')} columns={columns} data={dataTireTypes} />
            )}
          </FormCol4>
          {/* {JSON.stringify(values)} */}
        </Col>
      </Row>
    </LoadingOverlay>
  );
};
