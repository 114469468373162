import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery, useApolloClient } from '@apollo/client';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  FormCard,
  YupNumeric,
  YupDate,
  YupString,
  YupBoolean,
  promiseModalDialog,
  UserTooltip,
  objParseInt,
  objParseFloat,
  YupStringReq,
  YupNumericReq,
} from 'motion-components';
import * as Yup from 'yup';
import _, * as Lo from 'lodash';
import { toastSuccess } from 'utils/toasts';
import { useHandleError } from 'services';
import { gqlGetByIdQuery, gqlAdd, gqlUpdate, gqlDelete, FormInput } from '../graphql';
import { isNumeric } from 'utils/helpers';

import { FormFields } from './form-fields';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from 'react-bootstrap';
import { ColPlatformsTable } from './collectorplatforms-table';
import { FormFieldsAll } from './form-fields-all';
import { CollectorDistancesTable } from './collectordistances-table';
import { ColCollectionpointsTable } from './collectorcollectionpoints-table';
import { ColCountiesTable } from './collectorcounties-table';
import { CollectorSeriesTabContext } from './collector-series-tabcontext';
import { ColSubcontractorsTable } from './collectorsubcontractors';

export const CollectorFormPage = () => {
  const { t } = useTranslation();
  const { handleGraphqlError } = useHandleError('CollectorsFormPage');
  const { appStore } = useRootStore();
  const history = useHistory();
  const location = useLocation<any>();
  const { id } = useParams<{ id: string }>();
  const idNum = isNumeric(id) ? parseInt(id) : -1;
  const isNew = idNum < 0;

  const formShape = {
    id: YupNumeric,
    name: YupStringReq,
    vatin: YupString,
    tradeRegister: YupString,
    partnerName: YupString,
    contractNo: YupString,
    contractExpirationDate: YupDate,
    collectorId: YupNumeric,
    isSubcontractor: YupBoolean,
    address: YupStringReq,
    city: YupStringReq,
    countyId: YupNumericReq,
    phone: YupString,
    mobile: YupString,
    fax: YupString,
    email: YupString,
    email2: YupString,
    website: YupString,
    contactperson: YupString,
    notes: YupString,
    environmentalPermit: YupString,
    signatureFileId: YupNumeric,
    stampFileId: YupNumeric,
    autoApproveDeliveries: YupBoolean,
    blockDeliveries: YupBoolean,
    deliveriesYearlyWeightTarget: YupNumeric,
    isBlackListed: YupBoolean,
    crossplatSeries: YupBoolean,
    isActive: YupBoolean,
    firstDate: YupDate,
    lastDate: YupDate,
    firstUser: YupString,
    lastUser: YupString,
    collector: null,
  };

  const validationSchema = Yup.object().shape(formShape);
  const pickProperties = (values) => Lo.pick(values, Lo.keys(formShape));
  type FormValuesType = Yup.InferType<typeof validationSchema>;

  const newRecord = useMemo<FormValuesType>(
    () =>
      ({
        isActive: true,
        collectorId: location?.state?.collectorId,
      } as FormValuesType),
    [],
  );

  const [addRecord] = useMutation(gqlAdd);
  const [updateRecord] = useMutation(gqlUpdate);
  const [deleteRecord] = useMutation(gqlDelete);
  const [get, { data: rawData, error, refetch }] = useLazyQuery(gqlGetByIdQuery, {
    fetchPolicy: 'network-only',
  });
  const data = isNew ? newRecord : rawData ? rawData[Object.keys(rawData)[0]] : undefined;

  /* fetch data */
  useEffect(() => {
    if (!isNew) get({ variables: { id: idNum } });
  }, [get, id, idNum, isNew]);

  /**
   * * ΑΠΟΘΗΚΕΥΣΗ - UPDATE/CREATE
   */
  const handleSubmit = (values: FormValuesType, actions) => {
    actions.setSubmitting(false);
    let mutValues = Lo.pick(values, Lo.keys(new FormInput()));
    mutValues = objParseInt(['id', 'collectorId', 'countyId'], mutValues);
    mutValues = objParseFloat([], mutValues);

    // Αν είναι νέα εγγραφή τοτε γράφω όλα τα πεδία
    if (isNew) {
      console.log('[Collectors] New:', mutValues);
      addRecord({ variables: { data: { ...mutValues } } })
        .then((d) => {
          toastSuccess(
            `${t('Record Created Successfully')} [${d.data[Object.keys(d.data)[0]]?.id}]`,
          );
          history.goBack();
        })
        .catch((error) => {
          handleGraphqlError('mutation-add', error);
        });
    } else {
      // Αν είναι update εγγραφής τοτε γράφω μονο τα αλλαγμένα πεδία
      console.log('[Collectors] Update:', mutValues);
      updateRecord({ variables: { data: { ...mutValues, id: idNum } } })
        .then((d) => {
          toastSuccess(
            `${t('Record Updated Successfully')} [${d.data[Object.keys(d.data)[0]].id}]`,
          );
          refetch();
        })
        .catch((error) => {
          handleGraphqlError('mutation-update', error);
        });
    }
  };

  /**
   * *  DELETE
   */
  const handleDelete = useCallback(async () => {
    const res = await promiseModalDialog({
      title: t('Delete Record'),
      text: t('The record will be permanently deleted. Do you want to continue?'),
    });
    if (res) {
      deleteRecord({ variables: { id: idNum } })
        .then(() => {
          toastSuccess(t('The record has been successfully deleted'));
          history.goBack();
        })
        .catch((error) => {
          handleGraphqlError('mutation-delete', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRecord, idNum]);

  const tabStyle = { minHeight: '360px' };
  const [key, setKey] = useState('form');

  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <FormCard
        title={isNew ? `${t('New Collector')}` : `${t('Collector')}: ${data?.name}`}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        onReturn={null}
        permitDelete={false}
        initialValues={pickProperties(data)}
        validationSchema={validationSchema}
        enableReinitialize
        idx={idNum}
        isNew={isNew}
      >
        <FormFields isNew={isNew} updateRecord={updateRecord} refetch={refetch} data={data} />
        <div className="float-right">
          {data && <UserTooltip data={data} /* auditTable={t('Collectors')} */ auditId={data.id} />}
        </div>
        {data && data.id > 0 && (
          <div>
            <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
              <Tab eventKey="form" title={t('Information')} style={tabStyle}>
                <FormFieldsAll
                  isNew={isNew}
                  updateRecord={updateRecord}
                  refetch={refetch}
                  data={data}
                />
              </Tab>
              {data.collectorId === null && !appStore.isCollectionPoint && (
                <Tab eventKey="colSubcontractors" title={t('Subcontractors')} style={tabStyle}>
                  <ColSubcontractorsTable collectorId={data.id} refetch={refetch} />
                </Tab>
              )}
              {!appStore.isCollectionPoint && (
                <Tab eventKey="colPlatforms" title={t('Platforms')} style={tabStyle}>
                  <ColPlatformsTable
                    data={_.filter(data.collectorsPlatforms, { isActive: true })}
                    collectorId={data.id}
                    refetch={refetch}
                  />
                </Tab>
              )}
              {!appStore.isCollectionPoint && (
                <Tab eventKey="colDistances" title={t('Distances')} style={tabStyle}>
                  <CollectorDistancesTable dataCol={data} refetch={refetch} />
                </Tab>
              )}
              {!appStore.isCollectionPoint && (
                <Tab eventKey="colPoints" title={t('Collection Points')} style={tabStyle}>
                  <ColCollectionpointsTable collectorId={data.id} refetch={refetch} />
                </Tab>
              )}
              <Tab eventKey="colCounties" title={t('Counties')} style={tabStyle}>
                <ColCountiesTable
                  data={data.collectorsCounties}
                  collectorId={data.id}
                  refetch={refetch}
                />
              </Tab>
            </Tabs>
          </div>
        )}
      </FormCard>
    );
  }
};
