import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRootStore } from 'store';
import { gql, useMutation, useQuery } from '@apollo/client';
import LoadingOverlay from 'react-loading-overlay';
import {
  CellCenterV8,
  CellIntCustomV8,
  CellLeftV8,
  EditCellV8,
  FormCol,
  FormCol2,
  FormCol4,
  TableCellV8,
  TablePaginatedV8,
  order2Sorting,
  promiseModalDialog,
  toastFailure,
  toastSuccess,
} from 'motion-components';
import {
  WeekScheduleInput,
  gqlCompleteWeek,
  gqlGetQueryCol,
  gqlGetQueryVal,
  gqlUpdate,
} from './graphql';
import * as Lo from 'lodash';
import {
  FaTruck,
  FaShuttleVan,
  FaChevronLeft,
  FaChevronRight,
  FaPen,
  FaLock,
} from 'react-icons/fa';
import { getWeekDates, getWeekNumber } from 'common/date-utils';
import { format, parseISO } from 'date-fns';
import { UserTypeValues } from 'shared/constants';
import { FieldSelectCollector, FieldSelectValoriser } from 'components';
import { Formik, useFormikContext } from 'formik';
import promiseModalAlert from 'components/modal-alert-promise';

/**
 *
 * @returns Collector WeekSchedules Page for EA
 */

export const ColWeekSchedulesEA = () => {
  const [tabKey, setTabKey] = useState('col');
  const { t } = useTranslation();
  return (
    <Tabs id="controlled-tabs" activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
      <Tab eventKey="col" title={t('Collectors')}>
        <ColWeekSchedules partnerType={UserTypeValues.Collector} />
      </Tab>
      <Tab eventKey="val" title={t('Valorisers')}>
        <ColWeekSchedules partnerType={UserTypeValues.Valoriser} />
      </Tab>
    </Tabs>
  );
};

const ColWeekSchedules = ({ partnerType }) => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const [updateWeekSchedule] = useMutation(gqlUpdate);
  const [completeWeek] = useMutation(gqlCompleteWeek);
  const [weekNo, setWeekNo] = useState(getWeekNumber(new Date()) + 1);
  const [partnerId, setPartnerId] = useState([]);

  function buildWhereFromFilterValues(values): string {
    // Φτιάξε το where για το graphql
    let where = {};
    if (values) {
      if (values.weekNo) {
        where = { ...where, '$week.weekNo$': weekNo };
      }

      if (values.partnerId && Array.isArray(values.partnerId) && values.partnerId.length > 0) {
        const partnerIds = values.partnerId.map((v) => v.value);
        if (partnerType === UserTypeValues.Collector) {
          where = { ...where, collectorId: partnerIds };
        } else {
          where = {
            ...where,
            valoriserId: partnerIds /* isApproved: 1, */ /* total: { $gt: 0 } */,
          };
        }
      }
      where = { ...where, isActive: true };
    }
    return JSON.stringify(where);
  }

  const {
    loading,
    data: rawData,
    refetch,
  } = useQuery(partnerType === UserTypeValues.Collector ? gqlGetQueryCol : gqlGetQueryVal, {
    variables: {
      where: buildWhereFromFilterValues({ partnerId: partnerId, weekNo: weekNo }),
    },
    fetchPolicy: 'no-cache',
  });
  const data =
    partnerType === UserTypeValues.Collector
      ? rawData && rawData.getWeekSchedulesCol
        ? rawData.getWeekSchedulesCol.data
        : undefined
      : rawData && rawData.getWeekSchedulesVal
      ? rawData.getWeekSchedulesVal.data
      : undefined;

  const handleWeekClick = async (direction: 'next' | 'previous') => {
    const newWeekNo = direction === 'next' ? weekNo + 1 : weekNo - 1;
    setWeekNo(newWeekNo);
  };

  const handlePartnerId = async (partnerId) => {
    setPartnerId(partnerId);
  };

  const handleCompleteWeek = async (weekNo) => {
    const res = await promiseModalDialog({
      title: `${t('Complete Week')}`,
      text: `${t(
        'The week will be completed for all Collectors and results sent to Valorisers. Do you want to continue?',
      )}`,
    });
    if (res) {
      appStore.setBusy();
      await completeWeek({ variables: { weekNo: weekNo } })
        .then(async (res) => {
          const result = res.data.completeWeek;
          if (result.length > 0) {
            await promiseModalAlert({
              title: t('Warning!'),
              text:
                t(
                  'The following Collectors have scheduled already more deliveries for next week than their total trucks in the week schedule:',
                ) +
                '\n' +
                result.join('\n'),
            });
          }
          toastSuccess(t('Week successfully finalized!'));
          await refetch();
        })
        .catch((err) => {
          toastFailure(err);
        })
        .finally(() => {
          appStore.clearBusy();
        });
    }
  };

  useEffect(() => {
    refetch({
      where: buildWhereFromFilterValues({ partnerId: partnerId, weekNo: weekNo }),
    });
  }, [weekNo, partnerId]);

  const columns = useMemo(
    () => [
      // { Header: "id", accessor: "id", Cell: CellCenter, width: "100", isVisible: false, },
      ...(partnerType === UserTypeValues.Collector
        ? [
            {
              id: 'valoriser.name',
              header: t('Valoriser'),
              columnName: t('Valoriser'),
              accessorKey: 'valoriser.name',
              orderByField: 'valoriser.name',
              cell: CellLeftV8,
            },
          ]
        : [
            {
              id: 'collector.name',
              header: t('Collector'),
              columnName: t('Collector'),
              accessorKey: 'collector.name',
              orderByField: 'collector.name',
              cell: CellLeftV8,
            },
          ]),

      {
        id: 'monLg',
        header: t('Monday'),
        columnName: t('Monday'),
        accessorKey: 'monLg',
        orderByField: 'monLg',
        cell: TableDoubleCellV8,

        meta: {
          name: 'monLg',
          name2: 'monSm',
          type: 'numeric',
          viewCell: CellIntCustomV8(2),
        },
      },
      {
        id: 'tueLg',
        header: t('Tuesday'),
        columnName: t('Tuesday'),
        accessorKey: 'tueLg',
        orderByField: 'tueLg',
        cell: TableDoubleCellV8,

        meta: {
          name: 'tueLg',
          name2: 'tueSm',
          type: 'numeric',
          viewCell: CellIntCustomV8(2),
        },
      },
      {
        id: 'wedLg',
        header: t('Wednesday'),
        columnName: t('Wednesday'),
        accessorKey: 'wedLg',
        orderByField: 'wedLg',
        cell: TableDoubleCellV8,

        meta: {
          name: 'wedLg',
          name2: 'wedSm',
          type: 'numeric',
          viewCell: CellIntCustomV8(2),
        },
      },
      {
        id: 'thuLg',
        header: t('Thursday'),
        columnName: t('Thursday'),
        accessorKey: 'thuLg',
        orderByField: 'thuLg',
        cell: TableDoubleCellV8,

        meta: {
          name: 'thuLg',
          name2: 'thuSm',
          type: 'numeric',
          viewCell: CellIntCustomV8(2),
        },
      },
      {
        id: 'friLg',
        header: t('Friday'),
        columnName: t('Friday'),
        accessorKey: 'friLg',
        orderByField: 'friLg',
        cell: TableDoubleCellV8,

        meta: {
          name: 'friLg',
          name2: 'friSm',
          type: 'numeric',
          viewCell: CellIntCustomV8(2),
        },
      },
      {
        id: 'edit',
        header: t('Actions'),
        columnName: t('Actions'),
        accessorKey: 'edit',
        orderByField: 'lastUser',
        cell: ({ row, table }) => {
          return !row.original.isApproved && partnerId?.length === 1 ? (
            EditCellV8({ row, table })
          ) : (
            <></>
          );
        },
      },
    ],
    [t, data],
  );

  return (
    <Card className="shadow">
      <Card.Header>
        <Title
          handleWeekClick={handleWeekClick}
          handlePartnerId={handlePartnerId}
          weekNo={weekNo}
          partnerType={partnerType}
          data={data}
        ></Title>
      </Card.Header>
      <LoadingOverlay active={loading} spinner text={t('Please wait...')}>
        <Card
          bg="light"
          style={{ paddingLeft: '1.5rem', paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
        >
          {data && data.length > 0 ? (
            <>
              <TablePaginatedV8
                id="colweeks"
                appStore={appStore}
                key="colweeks"
                columns={columns as any}
                data={data}
                dataCount={data.length}
                rowProps={(row: any) => {
                  let style = undefined;
                  return { style: style };
                }}
                showGridFooter={false}
                enableColumnSelector={false}
                enableManualSorting={false}
                onSaveRow={async (row, changes) => {
                  console.log('$$$ updateRow row= ', row, changes);

                  const values = { id: row.id, ...changes };
                  const mutValues = Lo.pick(values, Lo.keys(new WeekScheduleInput()));
                  await updateWeekSchedule({ variables: { data: mutValues } });
                  return true;
                }}
                //onExport={handleExport}
                //onPageChange={onPageChange}
                //initPageIndex={initPageIndex}
                initSorting={order2Sorting('valoriser.name')}
              />
              <br />

              {!data[0].week.isCompleted && (
                <Button
                  onClick={() => handleCompleteWeek(weekNo)}
                  variant="warning"
                  className="ml-3 mr-4 complete-week"
                >
                  <FaLock className="mb-1 mr-3" />
                  <span>{t('Complete Week')}</span>
                </Button>
              )}

              <span>
                <FaTruck size={25}></FaTruck> : {t('Big car')}
              </span>
              <span>
                <FaShuttleVan size={25} style={{ color: 'darkcyan' }}></FaShuttleVan> :{' '}
                {t('Small car')}{' '}
              </span>
              <span>
                <button className="button-edit" name="edit">
                  <FaPen />
                </button>{' '}
                : {t('Button to change the values')}{' '}
              </span>
            </>
          ) : (
            <div style={{ height: '200px' }}></div>
          )}
        </Card>
      </LoadingOverlay>
    </Card>
  );
};

const Title = ({ handleWeekClick, handlePartnerId, weekNo, partnerType, data }) => {
  const { t } = useTranslation();

  const FilterPartner = ({ partnerType }) => {
    const { values } = useFormikContext();
    return (
      <>
        {partnerType === UserTypeValues.Collector ? (
          <FieldSelectCollector
            onChange={(e) => {
              handlePartnerId(e);
            }}
            where={JSON.stringify({ isActive: true })}
            as={Col}
            name="partnerId"
            //defaultValues={!!data ? data[0] : undefined}
            noLabel={true}
            isMulti={true}
          />
        ) : (
          <FieldSelectValoriser
            onChange={(e) => {
              handlePartnerId(e);
            }}
            where={JSON.stringify({ isActive: true })}
            as={Col}
            name="partnerId"
            //defaultValues={!!data ? data[0] : undefined}
            noLabel={true}
            isMulti={true}
          />
        )}
        {/* {JSON.stringify(values)} */}
      </>
    );
  };
  return (
    <Card.Title className="m-0 d-flex flex-row justify-content-between">
      <Button
        type="button"
        className="btn btn-primary"
        style={{
          marginRight: '3px',
          // backgroundColor: '#aeaeae',
          // borderColor: '#aeaeae',
          height: '2.66rem',
          width: '2.5rem',
          paddingLeft: '4px',
          paddingRight: '4px',
        }}
        onClick={(e) => {
          handleWeekClick('previous');
        }}
      >
        <FaChevronLeft size="1.5rem" style={{ marginBottom: 1 }} />
      </Button>
      <div className="d-flex flex-column align-items-center" style={{ width: 'auto' }}>
        <span>
          {t('Week ')} {weekNo}
        </span>

        <span>
          {format(getWeekDates(weekNo).monday, 'dd/MM/yy')} -{' '}
          {format(getWeekDates(weekNo).friday, 'dd/MM/yy')}
        </span>

        <br />

        <Formik initialValues={{ partnerId: [] }} onSubmit={() => {}}>
          <FilterPartner partnerType={partnerType} />
        </Formik>
      </div>
      <Button
        type="button"
        className="btn btn-primary ml-1"
        style={{
          marginRight: '0px',
          height: '2.66rem',
          width: '2.5rem',
          paddingLeft: '4px',
          paddingRight: '4px',
        }}
        onClick={(e) => {
          handleWeekClick('next');
        }}
      >
        <FaChevronRight size="1.5rem" style={{ marginBottom: 1 }} />
      </Button>
    </Card.Title>
  );
};

const TableDoubleCellV8 = (props) => {
  const { row, column, table } = props;
  const columnMeta = column.columnDef.meta;
  const tableMeta = table.options.meta;
  const initialValue = row.original[columnMeta.name];
  const initialValue2 = row.original[columnMeta.name2];
  const [value, setValue] = useState(initialValue);
  const [value2, setValue2] = useState(initialValue2);
  const [validationMessage, setValidationMessage] = useState('');

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    setValue2(initialValue2);
  }, [initialValue2]);

  const onBlur = (e: ChangeEvent<HTMLInputElement>) => {
    console.log(`$$$ updateData onBlur row=${row.index} ${column.id}=${value} ${typeof value}`);
    displayValidationMessage(e);
    tableMeta.updateData(row.index, columnMeta.name, value, row);
    tableMeta.updateData(row.index, columnMeta.name2, value2, row);
  };

  const onValueChange = (e) => {
    if (columnMeta?.type === 'numeric') {
      setValue(Number(e.target.value));
    } else setValue(e.target.value);
  };

  const onValue2Change = (e) => {
    if (columnMeta?.type === 'numeric') {
      setValue2(Number(e.target.value));
    } else setValue2(e.target.value);
  };

  const displayValidationMessage = <T extends HTMLInputElement | HTMLSelectElement>(
    e: ChangeEvent<T>,
  ) => {
    if (columnMeta?.validate) {
      const isValid = columnMeta.validate(e.target.value);
      if (isValid) {
        e.target.setCustomValidity('');
        setValidationMessage('');
      } else {
        e.target.setCustomValidity(columnMeta.validationMessage);
        setValidationMessage(columnMeta.validationMessage);
      }
    } else if (e.target?.validity?.valid) {
      setValidationMessage('');
    } else {
      setValidationMessage(e.target?.validationMessage ?? '');
    }
  };

  if (tableMeta?.editedRows[row.id]) {
    return (
      <div className="d-flex flex-row justify-content-between">
        <div
          className="d-flex flex-row justify-content-around"
          style={{ border: '2px solid gray', borderRadius: '5px', width: '100%' }}
        >
          <FaTruck style={{ minWidth: '30px' }} size={30}></FaTruck>
          <input
            style={{ width: '100%', textAlign: 'center', fontSize: '1.5vh' }}
            value={value}
            onChange={onValueChange}
            onBlur={onBlur}
            type={columnMeta?.type || 'text'}
            required={columnMeta?.required}
            pattern={columnMeta?.pattern}
            title={validationMessage}
          />
        </div>
        <span className="mx-1"></span>
        <div
          className="d-flex flex-row justify-content-around"
          style={{ border: '2px solid gray', borderRadius: '5px', width: '100%' }}
        >
          <FaShuttleVan style={{ minWidth: '30px', color: 'darkcyan' }} size={30}></FaShuttleVan>
          <input
            style={{ width: '100%', textAlign: 'center', fontSize: '1.5vh' }}
            value={value2}
            onChange={onValue2Change}
            onBlur={onBlur}
            type={columnMeta?.type || 'text'}
            required={columnMeta?.required}
            pattern={columnMeta?.pattern}
            title={validationMessage}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex flex-row justify-content-between">
      <div
        className="d-flex flex-row justify-content-around"
        style={{ border: '2px solid gray', borderRadius: '5px', width: '100%' }}
      >
        <FaTruck size={30}></FaTruck>
        <div className="text-center" style={{ fontSize: '2.8vh' }}>
          {value > 0 ? value : ''}
        </div>
      </div>
      <span className="mx-1"></span>
      <div
        className="d-flex flex-row justify-content-around"
        style={{ border: '2px solid gray', borderRadius: '5px', width: '100%' }}
      >
        <FaShuttleVan size={30} style={{ color: 'darkcyan' }}></FaShuttleVan>
        <div className="text-center" style={{ fontSize: '2.8vh' }}>
          {value2 > 0 ? value2 : ''}
        </div>
      </div>
    </div>
  );
};
