import { t as o } from 'i18next';
import { commonFields } from './common-fields';

function t(s) {
  return s;
}

const fields = [
  { name: 'id', displayName: 'Id' },
  { name: 'deliveryNoteNo', displayName: t('Delivery Note Number') },

  ...commonFields,
];

const childs = [{ tableName: 'tiredeliveries_files', keyName: 'tiredeliveryId' }];

const tiredeliveries = {
  name: 'Tiredeliveries',
  displayName: t('Tire Deliveries'),
  fields: fields,
  childTables: childs,
};

export { tiredeliveries };
