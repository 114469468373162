//import { useWhyDidYouUpdate } from 'hooks';
import { getRouteByName } from 'app/routes';
import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useRootStore } from 'store';
import { Dashboard } from './dashboards/dashboard';
import { useTranslation } from 'react-i18next';

import './home-page.css';
import { MovableText } from 'components/movable-text';
import { gqlGetUnavailableValorisers } from 'pages/valorisers/graphql';
import { useQuery } from '@apollo/client';
import { format, parseISO } from 'date-fns';

const HomePage = () => {
  const { t } = useTranslation();
  const { appStore, authStore } = useRootStore();
  const history = useHistory();
  //-------------------- Body -------------------------------------------
  //
  const {
    loading,
    data: rawData,
    refetch,
  } = useQuery(gqlGetUnavailableValorisers, {
    variables: {
      where: JSON.stringify({ isUnavailable: true }),
    },
    fetchPolicy: 'no-cache',
  });
  const data = rawData && rawData.getValorisers ? rawData.getValorisers.data : undefined;

  //-------------------- Handlers -------------------------------------------
  //
  const handleUserClick = () => {
    console.log('handleUserClick', appStore.userLogin);
    history.push(generatePath(getRouteByName('User'), { id: authStore.userId }));
  };

  //
  //-------------------- Render ----------------------------------------------
  //
  //useWhyDidYouUpdate('[HomePage]', {});

  const getNewsText = (data) => {
    let text = '';
    data.map((valoriser) => {
      const textToAdd = ` ${t('The Valoriser')} ${valoriser.name} ${t(
        'is currently unavailable until',
      )} ${format(parseISO(valoriser.unavailableTill), 'dd/MM/yyyy')}. ${t('Reason')}
      : ${valoriser.unavailableReason}. `;
      text = text + textToAdd;
    });
    return text;
  };

  return (
    <div>
      {!!data && data.length > 0 && (
        <>{/* <MovableText text={getNewsText(data)}></MovableText> */}</>
      )}
      <div className="bg-text">
        <div className="slide-up-container">
          <div
            className="slide-up-context"
            style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}
          >
            <div
              style={{ fontSize: '1.6vh', paddingRight: '15px' }}
              className="d-flex align-items-center justify-content-center"
            >
              <img
                alt=""
                src={process.env.PUBLIC_URL + '/home-logo.jpg'}
                width="260"
                className="mx-auto"
              />
            </div>
            <div>
              <h1>{t('Tires Valorification')}</h1>
              <p>{t('Welcome to the application')}.</p>
              <p>
                {t('You have logged in as user')}
                <strong
                  className="btn-link"
                  // style={{ marginTop: '0', marginBottom: '0.5rem' }}
                  onClick={handleUserClick}
                >
                  {' ' + appStore.userFullName}.
                </strong>
              </p>
            </div>
          </div>
        </div>

        <Dashboard />
      </div>
    </div>
  );
};

export default HomePage;
HomePage.whyDidYouRender = true;
