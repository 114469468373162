import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  FieldDatepicker,
  FieldInput,
  FormCol,
  FormRow,
  FieldCheckBox,
  FieldTextArea,
  FormCol2,
  FieldNumericInput,
} from 'motion-components';
import { useFormikContext } from 'formik';
import { useHandleError } from 'services';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';
import { FieldContainer } from 'components';
import { FileRepoField } from 'components/filerepo-field';
import { FileRepoImage } from 'components/filerepo-image';

export const FormFieldsAll = ({ isNew, updateRecord, refetch, data }) => {
  const { t } = useTranslation();
  //
  const frmkCntx = useFormikContext();
  const { values } = frmkCntx;
  const { appStore } = useRootStore();

  const { handleGraphqlError } = useHandleError('PiBaseFormFields');

  function calcUserCanEdit() {
    const canEdit = appStore.isEcoanvelope;
    const canEditReasoning = '';
    return { canEdit, canEditReasoning };
  }
  // const canEdit =
  //   !(values as any)?.isFinalized && (authStore.userCanDraft(userRoleId) || authStore.userCanFinalize(userRoleId));
  const { canEdit, canEditReasoning } = calcUserCanEdit();

  return (
    <>
      <Row>
        <Col>
          <h5 className="form-group-title">{t('Contact Information')}</h5>
          <div className="d-flex flex-row">
            <FormCol>
              <FieldInput
                disabled={!canEdit}
                label={t('Contact Person')}
                name="contactperson"
                type="text"
                placeholder={t('- specify a value - ')}
                required={false}
              />

              <FieldInput
                disabled={!canEdit}
                label={t('Phone')}
                name="phone"
                type="text"
                placeholder={t('- specify a value - ')}
                required={false}
              />

              <FieldInput
                disabled={!canEdit}
                label={t('Mobile')}
                name="mobile"
                type="text"
                placeholder={t('- specify a value - ')}
                required={false}
              />
            </FormCol>

            <FormCol>
              <FieldInput
                disabled={!canEdit}
                label={t('Fax')}
                name="fax"
                type="text"
                placeholder={t('- specify a value - ')}
                required={false}
              />
              <FieldInput
                disabled={!canEdit}
                label={t('E-mail')}
                name="email"
                type="text"
                placeholder={t('- specify a value - ')}
                required={false}
              />

              <FieldInput
                disabled={!canEdit}
                label={t('Web Site Address')}
                name="website"
                type="text"
                placeholder={t('- specify a value - ')}
                required={false}
              />
            </FormCol>
          </div>

          <h5 className="form-group-title">{t('Additional Information')}</h5>
          <div className="d-flex flex-row">
            <FormCol>
              <FieldTextArea
                disabled={!canEdit}
                label={t('Notes')}
                name="notes"
                type="text"
                rows={5}
                placeholder={t('')}
                required={false}
              />
              <FieldCheckBox
                disabled={!canEdit}
                as={FormCol}
                label={t('Cross Platform Series')}
                name="crossplatSeries"
              />
            </FormCol>
            <FormCol>
              <FieldInput
                disabled={!canEdit}
                as={FieldContainer}
                label={t('Partner Name')}
                name="partnerName"
                placeholder={``}
              />
              <FieldInput
                disabled={!canEdit}
                as={FieldContainer}
                label={t('Contract Number')}
                name="contractNo"
                placeholder={``}
              />
              <FieldDatepicker
                disabled={!canEdit}
                as={FieldContainer}
                label={t('Contract Expiration Date')}
                name="contractExpirationDate"
              />
            </FormCol>
            <FormCol>
              <FieldNumericInput
                disabled={!canEdit}
                as={Col}
                label={t('Yearly Weight Target (t)')}
                name="deliveriesYearlyWeightTarget"
                type="number"
                placeholder={t('- specify a value - ')}
                required={false}
              />

              {!isNew && (
                <>
                  <Row style={{ margin: 0, marginBottom: '-15px' }}>
                    <FileRepoField
                      as={Col}
                      label={t('Collector Signature File')}
                      fileIdFieldName="signatureFileId"
                      fileRec={data?.signatureFileRec}
                    />
                    <FileRepoImage fileId={data?.signatureFileRec?.id} height="64px" />
                  </Row>
                  <Row style={{ margin: 0 }}>
                    <FileRepoField
                      as={Col}
                      label={t('Collector Stamp File')}
                      fileIdFieldName="stampFileId"
                      fileRec={data?.stampFileRec}
                    />
                    <FileRepoImage fileId={data?.stampFileRec?.id} height="64px" />
                  </Row>
                </>
              )}
            </FormCol>
            <FormCol>
              <FieldCheckBox
                disabled={!canEdit}
                as={FormCol}
                label={t('Auto Approve Deliveries')}
                name="autoApproveDeliveries"
              />
              <FieldCheckBox
                disabled={!appStore.isEcoanvelope}
                as={FormCol}
                label={t('Block deliveries after Weight Target')}
                name="blockDeliveries"
                offStyle="primary"
                onStyle="danger"
              />
            </FormCol>
          </div>
        </Col>
      </Row>
    </>
  );
};
