import React from 'react';
import { FieldSelectDB } from 'motion-components';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { FsProps } from 'motion-components';

const gqlGetValues = gql`
  query getCounties($where: String, $offset: Int, $limit: Int, $order: String) {
    getCounties(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        id
        description: name
      }
      recordCount
    }
  }
`;

function useGetValues() {
  return useLazyQuery(gqlGetValues, { fetchPolicy: 'network-only' });
}

export const FieldSelectCounty = ({
  label = 'County',
  name = 'countyId',
  defaultValues = {},
  isMulti = false,
  regionId = undefined,
  collectorId = undefined,
  ...rest
}: FsProps & { isMulti?: boolean; regionId?: number; collectorId?: number }) => {
  const where = regionId
    ? JSON.stringify({ regionId: regionId })
    : collectorId
    ? JSON.stringify({ collectorId: collectorId })
    : undefined;
  return (
    <FieldSelectDB
      {...rest}
      label={label}
      name={name}
      // @ts-ignore
      sbLabel={defaultValues?.county?.name ?? undefined}
      hook={useGetValues}
      isMulti={isMulti}
      where={where}
    />
  );
};
