import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { promiseModalDialog } from 'motion-components';
import { toastSuccess } from 'utils/toasts';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import promiseModalBecomeUnavailable from './becomeUnavailable-modal';

const gqlSetAvailability = gql`
  mutation setAvailability(
    $valoriserId: Int
    $value: Boolean
    $reason: String
    $unavailableFrom: DateTime
    $unavailableTill: DateTime
  ) {
    setAvailability(
      valoriserId: $valoriserId
      value: $value
      reason: $reason
      unavailableFrom: $unavailableFrom
      unavailableTill: $unavailableTill
    )
  }
`;

const UseSetAvailability = () => {
  const [updateRecord] = useMutation(gqlSetAvailability);
  const setAvailability = async (data) => {
    const ret = await updateRecord({
      variables: data,
    });
    console.log('$$$', ret);
    return ret;
  };
  return { setAvailability };
};

export const BecomeUnavailableButton = ({ data, onClick, disabled = false }) => {
  const { t } = useTranslation();
  const { setAvailability } = UseSetAvailability();

  const handleSubmit = async () => {
    const res = await promiseModalBecomeUnavailable({
      title: t('Set as Unavailable'),
    });
    if (res) {
      await setAvailability({
        valoriserId: data.id,
        value: false,
        reason: res?.reason,
        unavailableFrom: res?.unavailableFrom,
        unavailableTill: res?.unavailableTill,
      });
      toastSuccess(t('Successfully became unavailable'));
      onClick();
    }
  };
  return (
    <>
      <Button disabled={disabled} onClick={handleSubmit} className="ml-3 mr-4" variant="warning">
        <FaLock className="mb-1 mr-3" />
        <span>{t(' Set as Unavailable')}</span>
      </Button>
    </>
  );
};

export const BecomeAvailableButton = ({ data, onClick, disabled = false }) => {
  const { t } = useTranslation();
  const { setAvailability } = UseSetAvailability();

  const handleSubmit = async () => {
    const res = await promiseModalDialog({
      title: t('Set as Available'),
      text: t('Are you sure you want to Set as Available again?'),
    });
    if (res) {
      await setAvailability({ valoriserId: data.id, value: true });
      toastSuccess('Successfully became available');
      onClick();
    }
  };
  return (
    <>
      <Button disabled={disabled} onClick={handleSubmit} className="ml-3 mr-4" variant="success">
        <FaLockOpen className="mb-1 mr-3" />
        <span>{t(' Set as Available')}</span>
      </Button>
    </>
  );
};
