import gql from 'graphql-tag';

export class FormInput {
  id: number;
  name: string;
  vatin: string;
  tradeRegister: string;
  partnerName: string;
  workstation: string;
  contractNo: string;
  contractExpirationDate: Date;
  valoriserGroupId: number;
  address: string;
  city: string;
  countyId: number;
  phone: string;
  mobile: string;
  fax: string;
  email: string;
  website: string;
  contactperson: string;
  notes: string;
  workDaysFrom: Date;
  workDaysTill: Date;
  saturdayDaysFrom: Date;
  saturdayDaysTill: Date;
  sundayDaysFrom: Date;
  sundayDaysTill: Date;
  environmentalPermit: string;
  envPermitExpirationDate: Date;
  autoApproveDeliveries: boolean;
  deliveriesYearlyWeightTarget: number;
  maxDeliveriesPerDay: number;
  isActive: boolean;
  firstDate: Date;
  lastDate: Date;
  firstUser: string;
  lastUser: string;
}

export const gqlGetQuery = gql`
  query getValorisers($limit: Int, $offset: Int, $order: String, $where: String) {
    getValorisers(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        id
        address
        city
        contactperson
        county {
          id
          name
        }
        countyId
        email
        fax
        firstDate
        firstUser
        isActive
        isHidden
        lastDate
        lastUser
        mobile
        name
        notes
        phone
        saturdayDaysFrom
        saturdayDaysTill
        sundayDaysFrom
        sundayDaysTill
        valoriserGroupId
        valorisersGroup {
          id
          name
        }
        valorisersTiretypes {
          firstDate
          firstUser
          id
          isActive
          lastDate
          lastUser
          tiretypeId
          tireType {
            id
            name
          }
          valoriserId
        }
        valorisersValorisationmethods {
          id
          valorisationMethodId
          valorisationMethod {
            id
            name
          }
          isActive
        }
        vatin
        tradeRegister
        partnerName
        workstation
        contractNo
        contractExpirationDate
        website
        workDaysFrom
        workDaysTill
        deliveriesYearlyWeightTarget
        maxDeliveriesPerDay
        autoApproveDeliveries
        isUnavailable
        unavailableReason
        unavailableFrom
        unavailableTill
      }
      recordCount
    }
  }
`;

export const gqlGetByIdQuery = gql`
  query getValoriserById($id: Int) {
    getValoriserById(id: $id) {
      id
      address
      city
      contactperson
      county {
        id
        name
      }
      countyId
      email
      fax
      environmentalPermit
      envPermitExpirationDate
      isActive
      firstDate
      firstUser
      lastDate
      lastUser
      isHidden
      mobile
      name
      notes
      phone
      saturdayDaysFrom
      saturdayDaysTill
      sundayDaysFrom
      sundayDaysTill
      valoriserGroupId
      valorisersGroup {
        id
        name
      }
      valorisersTiretypes {
        firstDate
        firstUser
        id
        isActive
        lastDate
        lastUser
        tiretypeId
        tireType {
          id
          name
        }
        valoriserId
      }
      valorisersValorisationmethods {
        firstDate
        firstUser
        id
        isActive
        lastDate
        lastUser
        valorisationMethodId
        valorisationMethod {
          id
          name
        }
        valoriserId
      }
      vatin
      tradeRegister
      partnerName
      workstation
      contractNo
      contractExpirationDate
      website
      workDaysFrom
      workDaysTill
      deliveriesYearlyWeightTarget
      maxDeliveriesPerDay
      autoApproveDeliveries
      isUnavailable
      unavailableReason
      unavailableFrom
      unavailableTill
    }
  }
`;

export const gqlAdd = gql`
  mutation addValoriser($data: ValoriserInput) {
    addValoriser(data: $data) {
      id
    }
  }
`;

export const gqlUpdate = gql`
  mutation updateValoriser($data: ValoriserInput) {
    updateValoriser(data: $data) {
      id
    }
  }
`;

export const gqlDelete = gql`
  mutation deleteValoriser($id: Int) {
    deleteValoriser(id: $id)
  }
`;

export const gqlSetAutoApprove = gql`
  mutation setAutoApprove($valoriserId: Int, $value: Boolean) {
    setAutoApprove(valoriserId: $valoriserId, value: $value)
  }
`;

export const gqlGetUnavailableValorisers = gql`
  query getValorisers($limit: Int, $offset: Int, $order: String, $where: String) {
    getValorisers(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        id
        name
        isUnavailable
        unavailableReason
        unavailableFrom
        unavailableTill
      }
      recordCount
    }
  }
`;

export const gqlSetCustodyAll = gql`
  mutation setCustodyAll($valoriserId: Int, $valRestrictedUntilDate: DateTime) {
    setCustodyAll(valoriserId: $valoriserId, valRestrictedUntilDate: $valRestrictedUntilDate)
  }
`;