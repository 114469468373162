import gql from 'graphql-tag';

export class FormInput {
  id: number;
  statusId: number;
  collectionpointId: number;
  collectorId: number;
  platformId: number;
  requestDate: Date;
  collectionDate: Date;
  isActive: boolean;
  firstDate: Date;
  lastDate: Date;
  firstUser: string;
  lastUser: string;
  colDocNum: string;
  vehPlateNo: string;
  orderNotes: string;
  orderedBy: string;
  ordEnteredBy: string;
  ordEnteredByUsertypeId: number;
  collectionNotes: string;
  colEnteredBy: string;
  colEnteredByUsertypeId: number;
  tirecollectionsQty: any;
  tirecollectionFiles: any;
}

export const gqlGetQuery = gql`
  query getTirecollections($limit: Int, $offset: Int, $order: String, $where: String) {
    getTirecollections(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        id
        orderNo
        collectionDate
        collectionpoint {
          id
          name
          vatin
          address
          fullAddress
          city
          countyId
          county {
            id
            name
            shortName
          }
        }
        collectionpointId
        collector {
          id
          name
        }
        collectorId
        collectorsPlatform {
          id
          name
        }
        colDocNum
        firstDate
        firstUser
        isActive
        lastDate
        lastUser
        platformId
        requestDate
        statusId
        status {
          id
          value
          name
          lcid
        }
        tirecollectionsQty {
          id
          pieces
          expectedPieces
          tireType {
            id
            name
          }
          tiretypeId
          weight
        }

      }
      recordCount
    }
  }
`;

export const gqlGetByIdQuery = gql`
  query getTirecollectionById($id: Int) {
    getTirecollectionById(id: $id) {
      collectionDate
      orderNo
      collectionpointId
      collectionpoint {
        id
        needsConfirmation
        name
        city
        address
        countyId
        county {
          id
          name
        }
      }
      statusId
      status {
        id
        value
        name
        lcid
      }
      collector {
        id
        name
        address
        city
        county {
          id
          name
        }
        countyId
        partnerName
        contractNo
        contractExpirationDate
      }
      collectorId
      collectorsPlatform {
        id
        name
        address
        city
        countyId
        county {
          id
          name
        }
        environmentalPermit
      envPermitExpirationDate
      }
      colEnteredByUsertypeId
      colEnteredBy
      ordEnteredByUsertypeId
      ordEnteredBy
      firstDate
      firstUser
      id
      isActive
      lastDate
      lastUser
      platformId
      requestDate
      collectionDate
      statusId
      orderedBy
      colDocNum
      vehPlateNo
      orderNotes
      collectionNotes
      tirecollectionsQty {
        id
        pieces
        expectedPieces
        tireType {
          id
          name
        }
        tiretypeId
        weight
      }
    }
  }
`;

export const gqlAdd = gql`
  mutation addTirecollection($data: TirecollectionInput) {
    addTirecollection(data: $data) {
      id
    }
  }
`;

export const gqlUpdate = gql`
  mutation updateTirecollection($data: TirecollectionInput) {
    updateTirecollection(data: $data) {
      id
    }
  }
`;

export const gqlDelete = gql`
  mutation deleteTirecollection($id: Int) {
    deleteTirecollection(id: $id)
  }
`;

export const gqlGetCollectionpoints = gql`
  query getCollectionpoints($where: String, $offset: Int, $limit: Int, $order: String) {
    getCollectionpoints(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        id
        name
        vatin
        address
        city
        phone
        mobile
      }
      recordCount
    }
  }
`;

export const gqlGetCollectionpointById = gql`
  query getCollectionpointById($id: Int) {
    getCollectionpointById(id: $id) {
      id
      name
      countyId
      county {
        id
        name
      }
      vatin
      address
      phone
      mobile
    }
  }
`;

export const gqlAddTirecollectionRequest = gql`
  mutation addTirescollectionRequest($collectionPointId: Int) {
    addTirescollectionRequest(collectionPointId: $collectionPointId) {
      collectionPointId
      canAdd
    }
  }
`;
