import gql from 'graphql-tag';

export class FormInput {
  id: number;
  name: string;
  shortName: string;
  regionId: number;
  isActive: boolean;
  firstDate: Date;
  lastDate: Date;
  firstUser: string;
  lastUser: string;
}

export const gqlGetQuery = gql`
  query getCounties($limit: Int, $offset: Int, $order: String, $where: String) {
    getCounties(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        id
        name
        shortName
        regionId
        region {
          id
          isActive
          name
          firstDate
          firstUser
          lastDate
          lastUser
        }
        isActive
        firstDate
        firstUser
        lastDate
        lastUser
      }
      recordCount
    }
  }
`;

export const gqlGetByIdQuery = gql`
  query getCountyById($id: Int) {
    getCountyById(id: $id) {
      id
      name
      shortName
      regionId
      region {
        id
        isActive
        name
        firstDate
        firstUser
        lastDate
        lastUser
      }
      isActive
      firstDate
      firstUser
      lastDate
      lastUser
    }
  }
`;

export const gqlAdd = gql`
  mutation addCounty($data: CountyInput) {
    addCounty(data: $data) {
      id
      name
      shortName
      regionId
      region {
        id
        isActive
        name
        firstDate
        firstUser
        lastDate
        lastUser
      }
      isActive
      firstDate
      firstUser
      lastDate
      lastUser
    }
  }
`;

export const gqlUpdate = gql`
  mutation updateCounty($data: CountyInput) {
    updateCounty(data: $data) {
      id
      name
      shortName
      regionId
      region {
        id
        isActive
        name
        firstDate
        firstUser
        lastDate
        lastUser
      }
      isActive
      firstDate
      firstUser
      lastDate
      lastUser
    }
  }
`;

export const gqlDelete = gql`
  mutation deleteCounty($id: Int) {
    deleteCounty(id: $id)
  }
`;
