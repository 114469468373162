import React from 'react';
import { Modal, Button, ModalProps, Form, Col } from 'react-bootstrap';
import { createModal } from 'react-modal-promise';
import { Formik, Form as FForm } from 'formik';
import { toastWarning } from 'utils/toasts';
import { useTranslation } from 'react-i18next';
import { FieldSelectCounty } from './fs-county';
import { FieldInput, FieldTextArea } from 'motion-components';
import { FileRepoField } from './filerepo-field';

type Props = {
  open: boolean;
  close: (result: any) => void;
  title: string;
  /** body of dialog */
  text: string | React.ReactNode;
  /** accept text */
  acceptText?: string;
  /** deny text */
  denyText?: string;
  type?: string;
} & ModalProps;

const ModalInput = ({
  open,
  close,
  title,
  text,
  acceptText = 'OK',
  denyText = 'CANCEL',
  type = 'text',
}: Props) => {
  const { t } = useTranslation();
  const handleOk = (values) => {
    if (values.message === '') {
      toastWarning(`${t('You need to type a message')}`);
    } else {
      const input = {
        message: values.message,
        fileId: values.fileId,
      };
      close(input);
    }
  };
  return (
    <>
      <Modal show={open} centered backdrop="static">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{ message: '', fileId: null, fileRec: null }}
          onSubmit={(values) => {
            handleOk(values);
          }}
        >
          <Form as={FForm}>
            <Modal.Body>
              <br />
              <FieldTextArea
                as={Col}
                rows={3}
                label={t('Comment')}
                name="message"
                type="text"
                placeholder={t('Specify what prompted the error')}
                required={true}
              ></FieldTextArea>
              <FileRepoField
                as={Col}
                label={t('File')}
                fileIdFieldName="fileId"
                fileRec={'fileRec'}
                submit={false}
              />
            </Modal.Body>
            <Modal.Footer>
              {/*  <Button variant="danger" onClick={() => close(false)}>
            {denyText}
          </Button> */}
              <Button variant="primary" type="submit">
                {t(acceptText)}
              </Button>
              <Button variant="danger" onClick={() => close(false)}>
                {t(denyText)}
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

const promiseModalSubmitReport = createModal(ModalInput);

export default promiseModalSubmitReport;
