import React from 'react';
import { useApolloClient } from '@apollo/client';
import { useRootStore } from 'store';

/*
    hook function
*/
export const useDbBase = () => {
  //
  const { appStore } = useRootStore();
  const client = useApolloClient();

  function setBusy() {
    appStore.setBusy();
  }
  function clearBusy() {
    appStore.clearBusy();
  }

  //query<T = any, TVariables = OperationVariables>(options: QueryOptions<TVariables, T>): Promise<ApolloQueryResult<T>>;
  async function execQuery(query: any, variables: any): Promise<any> {
    setBusy();
    const res = await client
      .query({
        query: query,
        variables: variables,
        fetchPolicy: 'network-only',
      })
      .catch((error) => {
        clearBusy();
        // playSounds.error();
        const str = JSON.stringify(error);
        if (str.includes('token expired')) {
          console.error(str);
          appStore.logOut(); // Logout (clear login data)
          throw new Error('Ο χρόνος της συνεδρίας έληξε.');
        } else if (str.includes('Timeout exceeded')) {
          console.error(str);
          appStore.logOut(); // Logout (clear login data)
          throw new Error('Αδυναμία Επικοινωνίας με τον Σέρβερ.\nΕλέγξτε την σύνδεση του δικτύου.');
        } else throw new Error(error);
      });
    clearBusy();
    return res;
  }

  async function execMutation(mutation: any, variables: any): Promise<any> {
    setBusy();
    const res = await client
      .mutate({
        mutation: mutation,
        variables: variables,
        fetchPolicy: 'network-only',
      })
      .catch((error) => {
        clearBusy();
        // playSounds.error();
        const str = JSON.stringify(error);
        if (str.includes('token expired')) {
          console.error(str);
          appStore.logOut(); // Logout (clear login data)
          throw new Error('Ο χρόνος της συνεδρίας έληξε.');
        } else if (str.includes('Timeout exceeded')) {
          console.error(str);
          appStore.logOut(); // Logout (clear login data)
          throw new Error('Αδυναμία Επικοινωνίας με τον Σέρβερ.\nΕλέγξτε την σύνδεση του δικτύου.');
        } else throw new Error(error);
      });
    clearBusy();
    return res;
  }

  return { client, execQuery, execMutation };
};
