import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { rootStore } from 'store';

// Persist Error to DB
const logErrorGql = gql`
  mutation logError($grouping: String!, $message: String!) {
    logError(originatedFrom: "browserApp", grouping: $grouping, message: $message)
  }
`;

// Persist Info to DB
const logInfoGql = gql`
  mutation logInfo($grouping: String!, $message: String!) {
    logInfo(originatedFrom: "browserApp", grouping: $grouping, message: $message)
  }
`;
export function persistLogError(
  client: any,
  componentName: string,
  location: string,
  message: string,
): void {
  //console.log('PersistErrorLog', componentName, location, message);
  // if (!rootStore.appStore.isLogIn) {
  //   console.log('PersistErrorLog (not logged in)', componentName, location, message);
  //   return;
  // }
  if (message === 'Context creation failed: jwt must be provided') return;
  const grouping = `${componentName}.${location}`;
  client
    .mutate({ mutation: logErrorGql, variables: { grouping, message } })
    .catch((error) => console.log('failed to persist the error'));
}

export function persistLogInfo(
  client: any,
  componentName: string,
  location: string,
  message: string,
): void {
  //console.log('PersistErrorLog', componentName, location, message);
  // if (!rootStore.appStore.isLogIn) {
  //   console.log('PersistInfoLog (not logged in)', componentName, location, message);
  //   return;
  // }
  if (!message) message = '-';
  const grouping = `${componentName}.${location}`;
  client
    .mutate({ mutation: logInfoGql, variables: { grouping, message } })
    .catch((error) => console.log('failed to persist the info'));
}
/**
 * Use as:
 *
 * import { usePersistentLog } from 'services';
 * const { logError, logInfo } = usePersistentLog('userForm');
 *
 */

export function usePersistentLog(componentName: string) {
  const client = useApolloClient();
  function logError(location: string, message: string): void {
    persistLogError(client, componentName, location, message);
  }
  function logInfo(location: string, message: string): void {
    persistLogInfo(client, componentName, location, message);
  }
  return { logError, logInfo };
}
