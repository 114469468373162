import { JwtToken } from './jwt';
import { ADMINISTRATOR_ROLE_ID, POINTS_ELEVATED_PERMS_ID, USERS_ADMIN_ROLE_ID } from './constants';

export function jwtToken(context: any): JwtToken {
  return context?.jwtToken ?? {};
}

export function userId(context: any): number {
  return jwtToken(context).userId;
}

export function userName(context: any): String {
  return jwtToken(context)?.userName ?? 'uknown';
}

export function userIsAdmin(context: any): Boolean {
  const jwt = jwtToken(context);
  console.log('userRolesIds= ', jwt.userRolesIds)
  const hasAdmin = jwt.userRolesIds?.filter((v) => v.userroleId === ADMINISTRATOR_ROLE_ID);
  return hasAdmin && hasAdmin.length > 0;
}

export function userIsUserAdmin(context: any): Boolean {
  const jwt = jwtToken(context);
  const hasAdmin = jwt.userRolesIds?.filter((v) => v.userroleId === USERS_ADMIN_ROLE_ID);
  return hasAdmin && hasAdmin.length > 0;
}

export function userHasCPElevatedPerms(context: any): Boolean {
  const jwt = jwtToken(context);
  const hasAdmin = jwt.userRolesIds?.filter((v) => v.userroleId === POINTS_ELEVATED_PERMS_ID);
  return hasAdmin && hasAdmin.length > 0;
}
