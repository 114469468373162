import React from 'react';
import { FsProps, CustomSelectComponent } from 'motion-components';
import { Field } from 'formik';

export const FieldSelectAuditAction = ({ label = 'Ενέργεια', name, ...rest }: FsProps) => {
  //
  const options = [
    { value: 'create', label: 'Create' },
    { value: 'update', label: 'Update' },
    { value: 'delete', label: 'Delete' },
  ];

  return <Field name={name} title={label} component={CustomSelectComponent} options={options} />;
};
