import React, { useMemo, useCallback } from 'react';
import {
  TablePaginatedUC,
  CellLeft,
  CellCenter,
  EyeColumn,
  CellDate,
  CellRight,
  CellFloatCustom,
} from 'motion-components';
import { useLocation } from 'react-router-dom';
import { generatePath, useHistory } from 'react-router';

import LoadingOverlay from 'react-loading-overlay';
import { useRootStore } from 'store';
import { getRouteByName } from 'app/routes';
import { useTranslation } from 'react-i18next';
import { getTiredeliveryStatusBadge } from 'common/badges-helpfunc';
import { ConfirmTireDeliveryButton } from 'pages/tiredeliveries/form/confirm-delivery/confirmDelivery-button';
import {
  WeighingNoteButton,
  WeighingNoteButtonWOFormik,
} from 'pages/tiredeliveries/form/weighing-note/weighingnote-button';
import { CellFloatCustomX } from 'components/cell-helpers';

/* ----------- Grid ------------------ */

export const DataGrid = ({
  data,
  refetch,
  dataCount,
  initPageIndex,
  onPageChange,
  loading,
  handleExport,
}) => {
  const { t } = useTranslation();
  const location = useLocation<any>();
  const history = useHistory();
  const { appStore } = useRootStore();

  const rowClicked = useCallback(
    (row) => {
      history.push({
        pathname: generatePath(getRouteByName('Tiredelivery'), { id: row.id }),
        state: {
          pathname: location.pathname,
          ...location.state,
        },
      });
    },
    [history, location.pathname, location.state],
  );

  const columns = useMemo(
    () => [
      // { Header: "id", accessor: "id", Cell: CellCenter, width: "100", isVisible: false, },
      EyeColumn(rowClicked),
      {
        Header: t('ID'),
        accessor: 'id',
        Cell: CellCenter,
        width: '2',
        isVisible: false,
      },
      {
        Header: t('Delivery Request Number'),
        accessor: 'requestNo',
        Cell: CellCenter,
        width: '2',
        isVisible: false,
      },
      {
        Header: t('Status'),
        accessor: 'statusId',
        Cell: ({ row }) => {
          const rec = row.original;
          return (
            <div className="text-left">
              <div
                className={'badge pb-2 pt-1 mr-2 '}
                style={{
                  backgroundColor: getTiredeliveryStatusBadge(rec?.status.value),
                  color: 'white',
                }}
              >
                {t(rec?.status.name)}
              </div>
              {rec.isFilesOk === false && (
                <div className={'badge pb-2 pt-1 mr-2 ' + 'badge-danger'}>
                  {t('There are files missing!')}
                </div>
              )}
            </div>
          );
        },
        width: '15',
        isVisible: false,
      },
      {
        Header: t('Collector'),
        accessor: 'collector.name',
        Cell: CellLeft,
        width: '10',
        isVisible: false,
      },
      {
        Header: t("Collector's Platform"),
        accessor: 'collectorsPlatform.name',
        Cell: CellLeft,
        width: '10',
        isVisible: false,
      },
      {
        Header: t('Valoriser'),
        accessor: 'valoriser.name',
        Cell: CellLeft,
        width: '10',
        isVisible: false,
      },
      {
        Header: t('Delivery Note Number'),
        accessor: 'deliveryNoteNo',
        Cell: CellLeft,
        width: '2',
        isVisible: false,
      },
      {
        Header: t('Tires'),
        accessor: 'tiredeliveriesQty',
        Cell: ({ row }) => {
          const qties = row?.original?.tiredeliveriesQty ?? [];
          const qties2 = qties
            .filter((rec) => rec.pieces && rec.pieces > 0)
            .map((rec) => {
              return `${rec?.tireType?.name}: ${rec.pieces}`;
            })
            .join(', ');
          return <div>{qties2}</div>;
        },
        width: '5',
        isVisible: false,
      },
      {
        Header: t('Delivered Date'),
        accessor: 'valoriserDeliveredDate',
        Cell: CellDate,
        width: '2',
        isVisible: false,
      },
      {
        Header: t('Weighing Note Number'),
        accessor: 'valoriserWeighingNoteNo',
        Cell: CellRight,
        width: '2',
        isVisible: false,
      },
      {
        Header: t('Weights'),
        accessor: 'valoriserNetWeight',
        Cell: ({ row }) => (
          <div className="d-flex flex-column">
            <CellFloatCustomX
              value={row.original.valoriserGrossWeight}
              numberWidth={10}
              decimalsDigits={2}
              noTrailingZeros={true}
              prefix={`${t('Gross')}:`}
              suffix="kg"
            />
            <CellFloatCustomX
              value={row.original.valoriserTareWeight}
              numberWidth={10}
              decimalsDigits={2}
              noTrailingZeros={true}
              prefix={`${t('Tare')}:`}
              suffix="kg"
            />
            <CellFloatCustomX
              value={row.original.valoriserDeductWeight}
              numberWidth={10}
              decimalsDigits={2}
              noTrailingZeros={true}
              prefix={`${t('Deduct')}:`}
              suffix="kg"
            />
            <CellFloatCustomX
              value={row.original.valoriserNetWeight}
              numberWidth={10}
              decimalsDigits={2}
              noTrailingZeros={true}
              prefix={`${t('Net')}:`}
              suffix="kg"
            />
          </div>
        ),
        width: '20',
        isVisible: false,
      },
      {
        id: 'actionButtons',
        Header: `${t('Action')}`,
        accessor: 'action',
        width: '20',
        Cell: ({ row }) => (
          <div className="text-center d-flex flex-row">
            <ConfirmTireDeliveryButton
              disabled={row.original.isFilesOk !== true}
              deliveryId={row.original.id}
              onClick={refetch}
              data={row.original}
            ></ConfirmTireDeliveryButton>
            <span className="mx-2"></span>
            <WeighingNoteButtonWOFormik
              disabled={row.original.isFilesOk !== true}
              deliveryId={row.original.id}
              onClick={refetch}
              statusId={row.original.statusId}
              data={row.original}
            ></WeighingNoteButtonWOFormik>
          </div>
        ),
      },
    ],
    [rowClicked, t],
  );

  return (
    <LoadingOverlay active={loading} spinner text={t('Please wait...')}>
      {data ? (
        <TablePaginatedUC
          id="valDeliveries"
          appStore={appStore}
          key="valDeliveries"
          title={'Λίστα'}
          columns={columns}
          data={data}
          dataCount={dataCount}
          // initPageIndex={initPage}
          // onSortByChange={handleSortByChange}
          onExport={handleExport}
          onPageChange={onPageChange}
          initPageIndex={initPageIndex}
          resizable={true}
        />
      ) : (
        <div style={{ height: '200px' }}></div>
      )}
    </LoadingOverlay>
  );
};
