import { faFile } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CellCenter, TableInMem } from 'motion-components';
import React, { useMemo, useState } from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { createModal } from 'react-modal-promise';

type Props = {
  readonly open: boolean;
  readonly close: (result: any) => void;
  readonly title: string;
  /** body of dialog */
  readonly text: string; // | React.ReactNode;
  readonly data: any;
  readonly newData: any;
} & ModalProps;

const ModalSelectColumns = ({ open, close, title, text, data }: Props) => {
  const { t } = useTranslation();
  const [columnsSelected, setColumnsSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const handleGetColumnsSelected = (id, state) => {
    setColumnsSelected((prevStates) => prevStates.filter((row) => row.id !== id));
    setColumnsSelected((prevStates) => [...prevStates, { id: id, state: state }]);
  };

  const handleUnselectAll = () => {
    data.map((rec) => {
      handleGetColumnsSelected(rec.fieldName, false);
    });
    setSelectAll(false);
  };

  const handleSelectAll = () => {
    data.map((rec) => {
      handleGetColumnsSelected(rec.fieldName, true);
    });
    setSelectAll(true);
  };
  const columns = useMemo(
    () => [
      {
        Header: t('Column'),
        accessor: 'fieldTitle',
        Cell: CellCenter,
      },
      {
        id: 'select',
        Header: () => (
          <input
            className="mr-5"
            type="checkbox"
            checked={selectAll}
            onClick={(e) =>
              e.currentTarget.checked === true ? handleSelectAll() : handleUnselectAll()
            }
          ></input>
        ),
        Cell: ({ row }) => (
          <input
            className="mr-5"
            type="checkbox"
            checked={
              columnsSelected.find(
                (col) => col.id === row.original.fieldName && col.state === false,
              ) === undefined
            }
            defaultChecked={true}
            onClick={async (e) =>
              handleGetColumnsSelected(row.original.fieldName, e.currentTarget.checked)
            }
          ></input>
        ),
      },
    ],
    [columnsSelected],
  );
  return (
    <>
      <Modal show={open} size="lg" centered backdrop="static">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableInMem
            //title={text}
            propPageSize={100}
            onCreate={undefined}
            columns={columns}
            data={data}
            hasSearch={false}
            hasFooter={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="p-3"
            style={{ minWidth: '5rem' }}
            variant="warning"
            disabled={columnsSelected.filter((col) => col.state === false).length === data.length}
            onClick={() => close(columnsSelected)}
          >
            &nbsp;{t('Export')}&nbsp;
          </Button>
          <Button
            className="p-3"
            style={{ minWidth: '5rem' }}
            variant="danger"
            onClick={() => close(false)}
          >
            &nbsp;{t('Cancel')}&nbsp;
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const promiseModalSelectColumns = createModal(ModalSelectColumns);

export default promiseModalSelectColumns;
