import React from 'react';
import { Card } from 'react-bootstrap';
import { FlexRow, usePaginatedQuery } from 'motion-components';
import { useRootStore } from 'store';
import { useHandleError } from 'services';
import { gqlGetQuery } from '../graphql';
import { buildWhereFromFilterValues, Filters } from './filters';
import { DataGrid } from './grid';
import { handleExport } from './export-columns';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';

/* ----------------------------------------------------------- */
/* ------------------------ Page ----------------------------- */
export const ApplogGridPage = () => {
  const { t } = useTranslation();

  const { appStore } = useRootStore();
  const { handleGraphqlError } = useHandleError('ApplogGridPage');

  /* ------------------- Query ------------------------------- */
  const query = usePaginatedQuery(
    gqlGetQuery,
    buildWhereFromFilterValues,
    'logDate DESC',
    appStore,
  );
  const {
    loading,
    data,
    dataCount,
    error,
    initPageIndex,
    filtersChanged,
    gridPageChanged,
    queryState,
  } = query;

  const client = useApolloClient();

  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <Card className="shadow">
        <Card.Header>
          <FlexRow>
            <Card.Title className="m-0">{t('System Log Book')}</Card.Title>
          </FlexRow>
        </Card.Header>
        <Card.Body className="nopadding">
          {/* Φίλτρα */}
          <Filters onChange={filtersChanged} initialValues={queryState?.filterValues ?? {}} />
          {/* Grid */}
          <DataGrid
            loading={loading}
            data={data}
            dataCount={dataCount}
            initPageIndex={initPageIndex}
            onPageChange={gridPageChanged}
            handleExport={() => {
              handleExport(client, queryState);
            }}
          />
        </Card.Body>
      </Card>
    );
  }
};
