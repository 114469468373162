import React from 'react';
import { getRouteByName } from 'app/routes';
import { useHistory, generatePath } from 'react-router-dom';
import { FieldInput, FormCol, GridLinkButton } from 'motion-components';
import { Col } from 'react-bootstrap';

export const CollectionpointName = ({ data, labelCaption = 'Collection Point', as = Col }) => {
  const history = useHistory();
  return (
    <FieldInput
      as={as}
      disabled={true}
      label={
        data ? (
          <GridLinkButton
            onClick={() => {
              history.push({
                pathname: generatePath(getRouteByName('Collectionpoint'), {
                  id: data.collectionpointId,
                }),
              });
            }}
          >
            {labelCaption}
          </GridLinkButton>
        ) : (
          labelCaption
        )
      }
      name="collectionpoint.name"
    />
  );
};
