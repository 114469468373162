import React, { useCallback } from 'react';
import { ButtonNewRecord, FlexRow, usePaginatedQuery } from 'motion-components';
import { Card } from 'react-bootstrap';
import { useRootStore } from 'store';
import { useHandleError } from 'services';
import { gqlGetQuery } from '../graphql';
import { buildWhereFromFilterValues, Filters } from './filters';
import { DataGrid } from './grid';
import { handleExport } from './export-columns';
import { useApolloClient } from '@apollo/client';
import { getRouteByName } from 'app/routes';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/* ----------------------------------------------------------- */
/* ------------------------ Page ----------------------------- */
export const TirecollectionsStatusesGridPage = () => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const { handleGraphqlError } = useHandleError('TirecollectionsStatusesGridPage');

  const location = useLocation<any>();
  const history = useHistory();

  /* ------------------- Query ------------------------------- */
  const query = usePaginatedQuery(gqlGetQuery, buildWhereFromFilterValues, 'id', appStore, true, {
    isActive: true,
  });
  const {
    loading,
    data,
    dataCount,
    error,
    initPageIndex,
    filtersChanged,
    gridPageChanged,
    queryState,
  } = query;

  const client = useApolloClient();

  const canCreateNew = appStore.isEcoanvelope;
  const handleCreateNew = useCallback(() => {
    history.push({
      pathname: generatePath(getRouteByName('TirecollectionStatus'), { id: -1 }),
      state: {
        pathname: location.pathname,
        ...location.state,
      },
    });
  }, [history, location.pathname, location.state]);

  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <Card className="shadow">
        <Card.Header>
          <FlexRow>
            <Card.Title className="m-0">{t('Tire Collection States')}</Card.Title>
            {canCreateNew && <ButtonNewRecord onClick={handleCreateNew} size="sm" />}
          </FlexRow>
        </Card.Header>
        <Card.Body className="nopadding">
          {/* Φίλτρα */}
          <Filters onChange={filtersChanged} initialValues={queryState?.filterValues ?? {}} />
          {/* Grid */}
          <DataGrid
            loading={loading}
            data={data}
            dataCount={dataCount}
            initPageIndex={initPageIndex}
            onPageChange={gridPageChanged}
            handleExport={() => {
              handleExport(client, queryState);
            }}
          />
        </Card.Body>
      </Card>
    );
  }
};
