import React from 'react';
import { CustomSelectComponent, FormCol, FsProps } from 'motion-components';
import { Field } from 'formik';
import { ReportTypes } from 'shared/constants';
import { useTranslation } from 'react-i18next';

export const FieldSelectReports = ({
  label = 'Report Type',
  name = 'reportName',
  defaultValues = {},
  ...rest
}: FsProps) => {
  const { t } = useTranslation();
  const options = [
    { value: ReportTypes.deliveriesAnalytical, label: t('Deliveries Analytical') },
    // { value: 'report2', label: t('Report 2') },
    //{ value: 'report3', label: t('Report 3') },
  ];
  return (
    <Field
      as={FormCol}
      title={t('Report Type')}
      name={'reportId'}
      component={CustomSelectComponent}
      options={options}
    />
  );
};
