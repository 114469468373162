import React from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { FieldCheckBox, FsProps } from 'motion-components';
import { useTranslation } from 'react-i18next';
import { FieldContainer } from 'components';

export const FieldIsActive = ({ ...rest }: FsProps) => {
  const { t } = useTranslation();
  return (
    <FieldCheckBox
      as={FieldContainer}
      disabled={rest.disabled}
      label={t('Active')}
      name="isActive"
      onLabel={t('Yes')}
      offLabel={t('No')}
    />
  );
};
