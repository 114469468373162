import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row } from 'react-bootstrap';
import { WeighingNoteButton } from './weighing-note/weighingnote-button';
import { useRootStore } from 'store';
import { TireDeliveriesStatus } from 'shared/constants';
import { ConfirmTireDeliveryButton } from './confirm-delivery/confirmDelivery-button';
import { ResolveTireDeliveryButton } from './resolve-delivery/resolveDelivery-button';
import { FaBackward, FaRecycle } from 'react-icons/fa';
import { TireValorisationButton } from './tire-valorisation/tireValorisation-button';
import { parseISO } from 'date-fns';
import { RemoveCustodyButton } from './remove-custody/removeCustody-button';
import { triggermailservice } from 'services/triggermailservice';
import { CancelTireDeliveryButton } from './cancel-delivery/cancelDelivery-button';

export const DeliveryActionsButtons = ({ data, refetch }) => {
  const { t } = useTranslation();
  //
  const { appStore } = useRootStore();

  const buttons = {
    weighingNote: (
      <WeighingNoteButton
        deliveryId={data?.id}
        onClick={() => refetch()}
        statusId={data?.statusId}
      />
    ),
    verifyWeights: (
      <ConfirmTireDeliveryButton
        disabled={data.isFilesOk !== true}
        deliveryId={data?.id}
        onClick={() => refetch()}
        data={data}
      />
    ),
    reenterWeights: (
      <WeighingNoteButton
        disabled={data.isFilesOk !== true}
        deliveryId={data?.id}
        onClick={() => {
          // Send Mails From Repo (triggermailservice)
          triggermailservice();
          refetch();
        }}
        statusId={data?.statusId}
        data={data}
      />
    ),
    judgeWeightEntries: (
      <ResolveTireDeliveryButton
        data={data}
        onClick={() => {
          // Send Mails From Repo (triggermailservice)
          triggermailservice();
          refetch();
        }}
      />
    ),
    rollbackState: (
      <Button disabled={true} className="ml-4" variant="danger">
        <FaBackward className="mb-1 mr-3" />
        {t('Rollback State')}
      </Button>
    ),
    valorised: (
      <TireValorisationButton
        disabled={parseISO(data.valRestrictedUntilDate) > new Date()}
        data={data}
        onClick={() => refetch()}
      />
    ),
    removeCustody: (
      <RemoveCustodyButton
        disabled={data.valRestrictedUntilDate === null}
        data={data}
        onClick={() => refetch()}
      />
    ),
    cancelDelivery: (
      <CancelTireDeliveryButton
        //disabled={}
        deliveryId={data?.id}
        onClick={() => refetch()}
      />
    ),
  };

  const buttonsDisabled = {
    weighingNote: (
      <WeighingNoteButton deliveryId={data?.id} onClick={() => refetch()} disabled={true} />
    ),
    verifyWeights: (
      <ConfirmTireDeliveryButton
        disabled={true}
        deliveryId={data?.id}
        onClick={() => refetch()}
        data={data}
      ></ConfirmTireDeliveryButton>
    ),
    reenterWeights: (
      <WeighingNoteButton
        disabled={true}
        deliveryId={data?.id}
        onClick={() => refetch()}
        statusId={TireDeliveriesStatus.UnconfirmedCol}
      />
    ),
    judgeWeightEntries: (
      <ResolveTireDeliveryButton disabled={true} data={data} onClick={() => refetch()} />
    ),
    rollbackState: (
      <Button disabled={true} className="ml-4" variant="danger">
        <FaBackward className="mb-1 mr-3" />
        {t('Rollback State')}
      </Button>
    ),
    valorised: (
      <Button disabled={true} className="ml-4">
        <FaRecycle className="mb-1 mr-3" />
        {t('Valorisation of Tires')}
      </Button>
    ),
    cancelDelivery: (
      <CancelTireDeliveryButton disabled={true} deliveryId={data?.id} onClick={() => refetch()} />
    ),
  };

  // ECO-ANVELOPE --------------------
  function ecoAnvelopeButtons(state) {
    let btns = [];

    if (state === TireDeliveriesStatus.Request) {
    } else if (state === TireDeliveriesStatus.Approved) {
      btns = [buttons.cancelDelivery];
      //τα Accept Reject έχουν δικιά τους φόρμα
    } else if (state === TireDeliveriesStatus.Rejected) {
    } else if (state === TireDeliveriesStatus.UnconfirmedCol) {
      btns = [buttons.cancelDelivery];
    } else if (state === TireDeliveriesStatus.UnconfirmedVal) {
      btns = [buttons.cancelDelivery];
    } else if (state === TireDeliveriesStatus.Delivered) {
      btns = [buttons.removeCustody, buttons.cancelDelivery];
    } else if (state === TireDeliveriesStatus.Unreconciled) {
      btns = [buttons.judgeWeightEntries];
    } else if (state === TireDeliveriesStatus.Valorised) {
    }

    return (
      <>
        <span className="text-secondary">{t('Eco Anvelope Actions')}:&nbsp;</span>
        {btns.map((b) => b)}
      </>
    );
  }

  // COLLECTOR ---------------------
  function collectorButtons(state) {
    let btns = [];

    if (state === TireDeliveriesStatus.Request) {
    } else if (state === TireDeliveriesStatus.Approved) {
      btns = [buttons.weighingNote, buttons.cancelDelivery];
    } else if (state === TireDeliveriesStatus.Rejected) {
    } else if (state === TireDeliveriesStatus.UnconfirmedCol) {
      btns = [buttons.weighingNote];
    } else if (state === TireDeliveriesStatus.UnconfirmedVal) {
      btns = [buttons.verifyWeights, buttons.reenterWeights];
    } else if (state === TireDeliveriesStatus.Delivered) {
    } else if (state === TireDeliveriesStatus.Unreconciled) {
    } else if (state === TireDeliveriesStatus.Valorised) {
    }

    return (
      <>
        <span className="text-secondary">{t('Collector Actions')}:&nbsp;</span>
        {btns.map((b) => b)}
      </>
    );
  }

  // VALORISER ---------------------
  function valoriserButtons(state) {
    let btns = [];

    if (state === TireDeliveriesStatus.Request) {
    } else if (state === TireDeliveriesStatus.Approved) {
      btns = [buttons.weighingNote];
    } else if (state === TireDeliveriesStatus.Rejected) {
    } else if (state === TireDeliveriesStatus.UnconfirmedCol) {
      btns = [buttons.verifyWeights, buttons.reenterWeights];
    } else if (state === TireDeliveriesStatus.UnconfirmedVal) {
    } else if (state === TireDeliveriesStatus.Delivered) {
      btns = [buttons.valorised];
    } else if (state === TireDeliveriesStatus.Unreconciled) {
    } else if (state === TireDeliveriesStatus.Valorised) {
    }

    return (
      <>
        <span className="text-secondary">{t('Valoriser Actions')}:&nbsp;</span>
        {btns.map((b) => b)}
      </>
    );
  }

  function SwitchUserButtons() {
    const state = data.statusId;
    if (appStore.isEcoanvelope) return ecoAnvelopeButtons(state);
    else if (appStore.isCollector || appStore.isCollectorDriver) return collectorButtons(state);
    else if (appStore.isValoriser) return valoriserButtons(state);
    else return <>ERROR</>;
  }

  const [showAllButtons, setShowAllButtons] = useState(false);

  return (
    <div>
      {/* <div className="mt-4 d-flex justify-content-end text-secondary">
        <span onClick={() => setShowAllButtons(!showAllButtons)} style={{ cursor: 'pointer' }}>
          {!showAllButtons ? 'Actions Bar +' : 'Actions Bar -'}
        </span>
      </div>

      {// All Buttons }
      {showAllButtons && (
        <Row className="mt-4 d-flex justify-content-center align-items-center">
          All Actions: {buttonsDisabled.weighingNote}
          {buttonsDisabled.verifyWeights}
          {buttonsDisabled.reenterWeights}
          {buttonsDisabled.judgeWeightEntries}
          {buttonsDisabled.valorised}
          {buttonsDisabled.rollbackState}
        </Row>
      )} */}
      <Row className="mt-4 d-flex justify-content-end align-items-center">
        {SwitchUserButtons()}
      </Row>
    </div>
  );
};
