import React, { useMemo, useCallback } from 'react';
import {
  TablePaginatedUC,
  CellLeft,
  CellCenter,
  CellYesNo,
  CellYesNoNull,
  CellDateTime,
  CellInt,
  CellFloatCustom,
  CellIntCustom,
  EyeColumn,
} from 'motion-components';
import { useLocation } from 'react-router-dom';
import { generatePath, useHistory } from 'react-router';

import LoadingOverlay from 'react-loading-overlay';
import { useRootStore } from 'store';
import { getRouteByName } from 'app/routes';
import { useTranslation } from 'react-i18next';

/* ----------- Grid ------------------ */

export const DataGrid = ({
  data,
  dataCount,
  initPageIndex,
  onPageChange,
  loading,
  handleExport,
}) => {
  const location = useLocation<any>();
  const history = useHistory();
  const { appStore } = useRootStore();
  const { t } = useTranslation();

  const rowClicked = useCallback(
    (row) => {
      history.push({
        pathname: generatePath(getRouteByName('TireType'), { id: row.id }),
        state: {
          pathname: location.pathname,
          ...location.state,
        },
      });
    },
    [history, location.pathname, location.state],
  );

  const columns = useMemo(
    () => [
      // { Header: "id", accessor: "id", Cell: CellCenter, width: "100", isVisible: false, },
      EyeColumn(rowClicked),
      {
        Header: t('Tire Type'),
        accessor: 'name',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Typical Weight in Kg'),
        accessor: 'typicalWeight',
        Cell: CellFloatCustom(10, 2, false),
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Notes'),
        accessor: 'notes',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Active'),
        accessor: 'isActive',
        Cell: CellYesNoNull,
        width: '100',
        isVisible: false,
      },
    ],
    [t],
  );

  return (
    <LoadingOverlay active={loading} spinner text={t('Please wait...')}>
      {data ? (
        <TablePaginatedUC
          id="tireTypes"
          appStore={appStore}
          key="tireTypes"
          title={'Λίστα'}
          columns={columns}
          data={data}
          dataCount={dataCount}
          // initPageIndex={initPage}
          // onSortByChange={handleSortByChange}
          onExport={handleExport}
          onPageChange={onPageChange}
          initPageIndex={initPageIndex}
          resizable={true}
        />
      ) : (
        <div style={{ height: '200px' }}></div>
      )}
    </LoadingOverlay>
  );
};
