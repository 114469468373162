import * as jwt from 'jsonwebtoken';

export interface UserRolesIds {
  userroleId: number;
}
export interface JwtToken {
  userId: number;
  userName: string; // login name
  userFullName: string;
  userTypeId: number;
  userRolesIds: UserRolesIds[];
  userValorisers: any;
  associatedId: number; // i.e. CollectorId, ValoriserId, or CollectionpointId
  isBlacklisted: boolean;
  isSubcontractor: boolean;
  JwtExpirationDate: Date;
  JwtIssueDate: Date;
}

const JWT_PRIVATE_KEY = 'MOTION$ANVELOPE';

export function adminDevToken(): string {
  const issueDate = new Date();
  const expireDate = new Date(issueDate.setHours(issueDate.getHours() + 1));

  const jwtObj: JwtToken = {
    userId: 1,
    userName: 'motion',
    userFullName: 'test user',
    userTypeId: 1,
    userRolesIds: [{ userroleId: 1 }],
    userValorisers: null,
    associatedId: 0,
    isBlacklisted: false,
    isSubcontractor: false,
    JwtExpirationDate: issueDate,
    JwtIssueDate: expireDate,
  };

  return encodeJwtToken(jwtObj);
}

export function encodeJwtToken(jwtObj: JwtToken): string {
  return jwt.sign(jwtObj, JWT_PRIVATE_KEY);
}

export function decodeJwtToken(tokenString: string): JwtToken {
  const decoded = jwt.verify(tokenString, JWT_PRIVATE_KEY) as any;
  const jwtToken: JwtToken = decoded;
  jwtToken.JwtExpirationDate = new Date(Date.parse(decoded.JwtExpirationDate));
  jwtToken.JwtIssueDate = new Date(Date.parse(decoded.JwtIssueDate));
  return jwtToken as JwtToken;
}
