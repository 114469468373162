import { FieldDatepicker, FieldInput, FormCol } from 'motion-components';
import React, { useMemo, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRootStore } from 'store';

export const PlatformSeriesTabContext = ({ data, refetch }) => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();

  function edocSeriesName(idx) {
    console.log('$$$', data);
    if (data && data[idx]) return data[idx]?.name;
    else return '-';
  }

  return (
    <div>
      <h5 className="form-group-title mb-0">{t('Numbering Series for Deliveries')}</h5>
      <h5 className="mt-3">
        {t('Delivery Request Series')}: <span className="text-primary">"{edocSeriesName(0)}"</span>
      </h5>
      <Row className="mt-2">
        <FormCol>
          <FieldInput
            label={t('Series Prefix')}
            name="edocSeries[0].docPrefix"
            type="text"
            disabled={!appStore.isEcoanvelope}
            placeholder={t('- specify a value - ')}
            required={false}
          />
        </FormCol>
        <FormCol>
          <FieldInput
            label={t('Last Code')}
            name="edocSeries[0].edocSeriesNumbers[0].lastCode"
            type="text"
            disabled={true}
            required={false}
          />
        </FormCol>
        <FormCol>
          <FieldInput
            label={t('Last Number')}
            name="edocSeries[0].edocSeriesNumbers[0].lastNumber"
            type="text"
            disabled={true}
            placeholder={t('- specify a value - ')}
            required={false}
          />
        </FormCol>
        <FormCol>
          <FieldDatepicker
            disabled={true}
            as={Col}
            label={t('Last Date')}
            name="edocSeries[0].edocSeriesNumbers[0].lastDate"
            showTimeSelect
            dateFormat="d/M/yyyy HH:mm"
            timeFormat="HH:mm"
            timeIntervals={15}
          />
        </FormCol>
      </Row>

      {/* ------------------------------------------------------------------  */}
      <h5 className="mt-3">
        {t('Delivery Note Series')}: <span className="text-primary">"{edocSeriesName(1)}"</span>
      </h5>
      <Row className="mt-2">
        <FormCol>
          <FieldInput
            label={t('Series Prefix')}
            name="edocSeries[1].docPrefix"
            type="text"
            disabled={!appStore.isEcoanvelope}
            placeholder={t('- specify a value - ')}
            required={false}
          />
        </FormCol>
        <FormCol>
          <FieldInput
            label={t('Last Code')}
            name="edocSeries[1].edocSeriesNumbers[0].lastCode"
            type="text"
            disabled={true}
            required={false}
          />
        </FormCol>
        <FormCol>
          <FieldInput
            label={t('Last Number')}
            name="edocSeries[1].edocSeriesNumbers[0].lastNumber"
            type="text"
            disabled={true}
            placeholder={t('- specify a value - ')}
            required={false}
          />
        </FormCol>
        <FormCol>
          <FieldDatepicker
            disabled={true}
            as={Col}
            label={t('Last Date')}
            name="edocSeries[1].edocSeriesNumbers[0].lastDate"
            showTimeSelect
            dateFormat="d/M/yyyy HH:mm"
            timeFormat="HH:mm"
            timeIntervals={15}
          />
        </FormCol>
      </Row>

      {/* ------------------------------------------------------------------  */}
      <h5 className="mt-3">
        {t('Delivery Loading/Unloading Series')}:
        <span className="text-primary">"{edocSeriesName(2)}"</span>
      </h5>
      <Row className="mt-2">
        <FormCol>
          <FieldInput
            label={t('Series Prefix')}
            name="edocSeries[2].docPrefix"
            type="text"
            disabled={!appStore.isEcoanvelope}
            placeholder={t('- specify a value - ')}
            required={false}
          />
        </FormCol>
        <FormCol>
          <FieldInput
            label={t('Last Code')}
            name="edocSeries[2].edocSeriesNumbers[0].lastCode"
            type="text"
            disabled={true}
            required={false}
          />
        </FormCol>
        <FormCol>
          <FieldInput
            label={t('Last Number')}
            name="edocSeries[2].edocSeriesNumbers[0].lastNumber"
            type="text"
            disabled={true}
            placeholder={t('- specify a value - ')}
            required={false}
          />
        </FormCol>
        <FormCol>
          <FieldDatepicker
            disabled={true}
            as={Col}
            label={t('Last Date')}
            name="edocSeries[2].edocSeriesNumbers[0].lastDate"
            showTimeSelect
            dateFormat="d/M/yyyy HH:mm"
            timeFormat="HH:mm"
            timeIntervals={15}
          />
        </FormCol>
      </Row>
    </div>
  );
};
