import React from 'react';
import { Row } from 'react-bootstrap';
import {
  buildWhereForDates,
  FormCol,
  FormFilterCard,
  FieldInput,
  FieldDateRanger,
  FieldSelectIsActive,
} from 'motion-components';
import { useTranslation } from 'react-i18next';
import { FieldSelectCollector } from 'components/fs-collector';
import {
  FieldSelectCounty,
  FieldSelectPlatform,
  FieldSelectRegion,
  FieldSelectTirecollectionsStatuses,
} from 'components';
import { useField } from 'formik';
import { useRootStore } from 'store';

/* ----------------------- Διαχείριση Φίλτρων ------------------------------ */
export function buildWhereFromFilterValues(values): string {
  // Φτιάξε το where για το graphql
  let where = {};
  /* where = { ...where, isActive: true }; */
  if (values) {
    if (values.reqStartDate || values.reqEndDate) {
      where = {
        ...where,
        ...buildWhereForDates(values.reqStartDate, values.reqEndDate, 'requestDate'),
      };
    }

    if (values.startDate || values.endDate) {
      where = {
        ...where,
        ...buildWhereForDates(values.startDate, values.endDate, 'collectionDate'),
      };
    }

    if (values.searchText) {
      const searchText = '%' + values.searchText + '%';
      const likeObj = { $like: searchText };
      where = {
        ...where,
        $or: [{ '$collectionpoint.name$': likeObj }, { orderNo: likeObj }, { colDocNum: likeObj }],
      };
    }
    if (values.collectorId) where = { ...where, collectorId: values.collectorId };
    if (values.platformId) where = { ...where, platformId: values.platformId };
    if (values.statusId) where = { ...where, statusId: values.statusId };
    if (values.collectionpointId) where = { ...where, collectionpointId: values.collectionpointId };

    // filter alias (regionId)
    if (values.regionId) where = { ...where, regionId: values.regionId };

    // County Multi Select
    if (values.countyId && Array.isArray(values.countyId) && values.countyId.length > 0) {
      const countyIds = values.countyId.map((v) => v.value);
      where = { ...where, countyId: countyIds };
    }
    if (values.isActive !== undefined && values.isActive !== '') {
      where = { ...where, isActive: values.isActive };
    }
  }
  return JSON.stringify(where);
}

/* ----------------------- Filter Form ------------------------------ */
export const Filters = ({ onChange, initialValues }) => {
  const { t } = useTranslation();

  function handleFiltersSubmit(values, { setSubmitting }) {
    setTimeout(() => {
      setSubmitting(false);
      onChange(values);
    }, 400);
  }
  //console.log("filters-init ", initialValues);

  const filters = {
    searchText: initialValues?.searchText ?? '',
    collectorId: initialValues?.collectorId ?? null,
    platformId: initialValues?.platformId ?? null,
    statusId: initialValues?.statusId ?? null,
    collectionpointId: initialValues?.collectionpointId ?? null,
    regionId: initialValues?.regionId ?? undefined,
    countyId: initialValues?.countyId ?? [],
    startDate: initialValues?.startDate ?? null,
    endDate: initialValues?.endDate ?? null,
    reqStartDate: initialValues?.reqStartDate ?? null,
    reqEndDate: initialValues?.reqEndDate ?? null,
    isActive: initialValues?.isActive ?? null,
  };

  return (
    <FormFilterCard
      title={t('Filter Criteria')}
      onSubmit={handleFiltersSubmit}
      initialValues={filters}
    >
      <FilterFields />
    </FormFilterCard>
  );
};

export const FilterFields = () => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const [field, { value }] = useField('regionId');
  return (
    <>
      <Row>
        <FieldInput
          as={FormCol}
          name="searchText"
          label={t('Search (Collection Point, Request No, Document No)')}
          type="search"
        />
        {!appStore.isCollector && <FieldSelectCollector label={t('Collector')} as={FormCol} />}
        <FieldSelectRegion label={t('Region')} as={FormCol} />
        <FieldSelectTirecollectionsStatuses label={t('Status')} as={FormCol} />
      </Row>
      {/* Line 2 */}
      <Row>
        <FieldDateRanger
          as={FormCol}
          label={t('Request from')}
          label2={t('till')}
          name="reqStartDate"
          name2="reqEndDate"
        />{' '}
        <FieldDateRanger
          as={FormCol}
          label={t('Collection from')}
          label2={t('till')}
          name="startDate"
          name2="endDate"
        />
        {!appStore.isCollectionPoint && (
          <FieldSelectPlatform label={t('Platform')} useWhereValues={true} as={FormCol} />
        )}
        <FieldSelectCounty label={t('County')} as={FormCol} isMulti={true} regionId={value} />
        <FieldSelectIsActive label={t('Active Records')} />
      </Row>
    </>
  );
};
