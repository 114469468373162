import React from 'react';
import { Col } from 'react-bootstrap';
import { FieldDatepicker, FieldInput } from 'motion-components';
import { useTranslation } from 'react-i18next';
import { TiredeliveriesFilesSection } from '../files-section';
import { useRootStore } from 'store';

export const TabDocuments = ({
  refetch,
  tiredeliveryId,
  readonly,
  forceRerender,
  tireDelivery,
}) => {
  const { t } = useTranslation();
  const { appStore, authStore } = useRootStore();
  return (
    <div className="mt-4">
      <TiredeliveriesFilesSection
        refetchBase={refetch}
        readOnly={readonly}
        tiredeliveryId={tiredeliveryId}
        forceRerender={forceRerender}
        tireDelivery={tireDelivery}
      />
    </div>
  );
};
