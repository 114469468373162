import React, { useMemo, useCallback } from 'react';
import {
  TablePaginatedV8,
  CellLeftV8,
  CellDateTimeV8,
  EyeColumnV8,
  order2Sorting,
  CellDateV8,
} from 'motion-components';
import { useLocation } from 'react-router-dom';
import { generatePath, useHistory } from 'react-router';

import LoadingOverlay from 'react-loading-overlay';
import { useRootStore } from 'store';
import { getRouteByName } from 'app/routes';
import { useTranslation } from 'react-i18next';
import { getTirecollectionStatusBadge } from 'common/badges-helpfunc';

/* ----------- Grid ------------------ */

export const DataGrid = ({
  data,
  dataCount,
  initPageIndex,
  onPageChange,
  loading,
  handleExport,
  initOrderBy,
  onOrderChange,
}) => {
  const location = useLocation<any>();
  const history = useHistory();
  const { appStore } = useRootStore();
  const { t } = useTranslation();
  const rowClicked = useCallback(
    (row) => {
      history.push({
        pathname: generatePath(getRouteByName('Tirecollection'), { id: row.id }),
        state: {
          pathname: location.pathname,
          ...location.state,
        },
      });
    },
    [history, location.pathname, location.state],
  );

  const columns = useMemo(
    () => [
      // { Header: "id", accessor: "id", Cell: CellCenter, width: "100", isVisible: false, },
      EyeColumnV8(rowClicked),
      {
        id: 'orderNo',
        header: t('Collection Request Number'),
        columnName: t('Collection Request Number'),
        accessorKey: 'orderNo',
        orderByField: 'orderNo',
        cell: CellLeftV8,
        size: 3,
      },

      {
        id: 'statusId',
        header: t('Status'),
        columnName: t('Status'),
        accessorKey: 'statusId',
        orderByField: 'statusId',
        cell: ({ row }) => {
          const rec = row.original;
          return (
            <div className="text-left">
              <div
                className={
                  'badge pb-2 pt-1 mr-2 ' + getTirecollectionStatusBadge(rec?.status.value)
                }
              >
                {t(rec?.status.name)}
              </div>
            </div>
          );
        },
        size: 3,
      },
      {
        id: 'collectionpoint.name',
        header: t('Collection Point'),
        columnName: t('Collection Point'),
        accessorKey: 'collectionpoint.name',
        orderByField: 'collectionpoint.name',
        cell: CellLeftV8,
        size: 3,
      },
      {
        id: 'collectionpoint.county.name',
        header: t('County'),
        columnName: t('County'),
        accessorKey: 'collectionpoint.county.name',
        orderByField: 'collectionpoint.county.name',
        cell: CellLeftV8,
        enableSorting: false,
        size: 3,
      },

      {
        id: 'collector.name',
        header: t('Collector'),
        columnName: t('Collector'),
        accessorKey: 'collector.name',
        orderByField: 'collector.name',
        cell: CellLeftV8,
        size: 3,
      },
      {
        id: 'collectorsPlatform.name',
        header: t('Platform'),
        columnName: t('Platform'),
        accessorKey: 'collectorsPlatform.name',
        orderByField: 'collectorsPlatform.name',
        cell: CellLeftV8,
        size: 3,
      },
      {
        id: 'requestDate',
        header: t('Request Date'),
        columnName: t('Request Date'),
        accessorKey: 'requestDate',
        orderByField: 'requestDate',
        cell: CellDateV8,
        size: 3,
      },
      {
        id: 'collectionDate',
        header: t('Collection Date'),
        columnName: t('Collection Date'),
        accessorKey: 'collectionDate',
        orderByField: 'collectionDate',
        cell: CellDateV8,
        size: 3,
      },
      {
        id: 'tirecollectionsQty',
        header: t('Collected Tires'),
        columnName: t('Collected Tires'),
        accessorKey: 'tirecollectionsQty',
        orderByField: 'tirecollectionsQty',
        enableSorting: false,
        // @ts-ignore
        cell: ({ row }) => {
          const qties = row?.original?.tirecollectionsQty ?? [];
          const qties2 = qties
            .filter((rec) => rec.pieces && rec.pieces > 0)
            .map((rec) => {
              return `${rec?.tireType?.name}: ${rec.pieces}`;
            })
            .join(', ');
          return <div>{qties2}</div>;
        },
        size: 3,
      },
      {
        id: 'colDocNum',
        header: t('Collection Document Number'),
        columnName: t('Collection Document Number'),
        accessorKey: 'colDocNum',
        orderByField: 'colDocNum',
        cell: CellLeftV8,
        size: 3,
      },
    ],
    [rowClicked, t],
  );

  return (
    <LoadingOverlay active={loading} spinner text={t('Please wait...')}>
      {data ? (
        <TablePaginatedV8
          id="tirecollections"
          appStore={appStore}
          key="tirecollections"
          columns={columns as any}
          data={data}
          dataCount={dataCount}
          rowProps={(row: any) => {
            let style = undefined;
            return { style: style };
          }}
          onExport={handleExport}
          onPageChange={onPageChange}
          initPageIndex={initPageIndex}
          initSorting={order2Sorting(initOrderBy)}
          onSortByChange={(sort) => {
            console.log('$$$$ grid sortbychange', sort);
            // $$$
            const columnId = sort[0].id;
            const sortOrder = sort[0].desc ? 'DESC' : 'ASC';
            // find column by id
            const cols = columns.filter((r) => r.id === columnId);
            if (cols.length === 1) {
              const col = cols[0] as any;
              let order = col.orderByField ? col.orderByField : 'id';
              order = order + ' ' + sortOrder;
              onOrderChange(order);
            }
          }}
        />
      ) : (
        <div style={{ height: '200px' }}></div>
      )}
    </LoadingOverlay>
  );
};
