import React from 'react';
import { Col } from 'react-bootstrap';
import { FieldDatepicker, FieldInput } from 'motion-components';
import { useTranslation } from 'react-i18next';

export const TabDeliveryRequest = ({ readonly }) => {
  const { t } = useTranslation();
  return (
    <>
      <h5 className="form-group-title-bg">{t('Delivery Request')}</h5>
      <FieldDatepicker
        disabled={true}
        as={Col}
        label={t('Request Date')}
        name="requestDate"
        showTimeSelect
        dateFormat="d/M/yyyy HH:mm"
        timeFormat="HH:mm"
        timeIntervals={15}
      />

      <FieldInput
        disabled={true}
        as={Col}
        label={t('Employee who entered the request')}
        name="requestedBy"
        type="text"
        placeholder={t('- specify a value - ')}
        required={false}
      />

      <h5 className="form-group-title-bg mt-3">{t('Approval / Rejection')}</h5>
      <FieldDatepicker
        disabled={!readonly}
        as={Col}
        label={t('Approved or Rejected Date')}
        name="approvedDate"
        showTimeSelect
        dateFormat="d/M/yyyy HH:mm"
        timeFormat="HH:mm"
        timeIntervals={15}
      />

      <FieldInput
        disabled={true}
        as={Col}
        label={t('Person who approved/rejected the delivery request')}
        name="approvedBy"
        type="text"
        placeholder={t('- specify a value - ')}
        required={false}
      />

      <FieldInput
        disabled={!readonly}
        as={Col}
        label={t('Approval Notes')}
        name="approvalNotes"
        type="text"
        placeholder={t('- specify a value - ')}
        required={false}
      />
    </>
  );
};
