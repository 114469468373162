import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  ButtonApprove,
  ButtonReject,
  FieldDatepicker,
  FieldInput,
  FormCol,
  FormCol2,
  FormCol4,
} from 'motion-components';
import { useTranslation } from 'react-i18next';
import { TiresSection } from './tires-section';
import { useFormikContext } from 'formik';
import { FieldSelectCollector, FieldSelectPlatform } from 'components';
import { useRootStore } from 'store';

export const FormFieldsNewRequest = ({ data }) => {
  const { t } = useTranslation();
  const frmkCntx = useFormikContext();
  const { appStore } = useRootStore();
  const { values } = useFormikContext<any>();
  return (
    <>
      <Col className="col-4 ml-0 pl-0 mr-0 pr-0">
        <FieldDatepicker
          required={true}
          as={Col}
          label={t('Stock Date')}
          name="stockDate"
          dateFormat="d/M/yyyy"
        />
        {appStore.isEcoanvelope && (
          <FieldSelectCollector label={t('Collector')} as={Col} required={true} />
        )}
        <FieldSelectPlatform
          collectorId={values.collectorId}
          label={t('Platform')}
          as={Col}
          required={true}
        />
        <h5 className="form-group-title-bg mt-4 mb-0">{t('Tires')}</h5>
        <div className="ml-3 pl-0 mr-3 pr-0">
          <TiresSection isNew={true} data={data} />
        </div>
      </Col>
    </>
  );
};
