import React, { useMemo, useCallback } from 'react';
import {
  TablePaginatedUC,
  CellLeft,
  CellCenter,
  CellYesNo,
  CellYesNoNull,
  CellDateTime,
  CellInt,
  CellFloatCustom,
  CellIntCustom,
  EyeColumn,
  CellDate,
  CellRight,
} from 'motion-components';
import { useLocation } from 'react-router-dom';
import { generatePath, useHistory } from 'react-router';

import LoadingOverlay from 'react-loading-overlay';
import { useRootStore } from 'store';
import { getRouteByName } from 'app/routes';
import { useTranslation } from 'react-i18next';
import { getTiredeliveryStatusBadge } from 'common/badges-helpfunc';

/* ----------- Grid ------------------ */

export const DataGrid = ({
  data,
  dataCount,
  initPageIndex,
  onPageChange,
  loading,
  handleExport,
}) => {
  const { t } = useTranslation();
  const location = useLocation<any>();
  const history = useHistory();
  const { appStore } = useRootStore();

  const rowClicked = useCallback(
    (row) => {
      history.push({
        pathname: generatePath(getRouteByName('Tiredelivery'), { id: row.id }),
        state: {
          pathname: location.pathname,
          ...location.state,
        },
      });
    },
    [history, location.pathname, location.state],
  );

  const columns = useMemo(
    () => [
      // { Header: "id", accessor: "id", Cell: CellCenter, width: "100", isVisible: false, },
      EyeColumn(rowClicked),
      {
        Header: t('ID'),
        accessor: 'id',
        Cell: CellCenter,
        width: '2',
        isVisible: false,
      },
      {
        Header: t('Delivery Request Number'),
        accessor: 'requestNo',
        Cell: CellRight,
        width: '3',
        isVisible: false,
      },
      {
        Header: t('Status'),
        accessor: 'statusId',
        Cell: ({ row }) => {
          const rec = row.original;
          return (
            <div className="text-left">
              <div
                className={'badge pb-2 pt-1 mr-2 '}
                style={{
                  backgroundColor: getTiredeliveryStatusBadge(rec?.status.value),
                  color: 'white',
                }}
              >
                {t(rec?.status.name)}
              </div>
            </div>
          );
        },
        width: '16',
        isVisible: false,
      },
      {
        Header: t('Collector'),
        accessor: 'collector.name',
        Cell: CellLeft,
        width: '35',
        isVisible: false,
      },
      {
        Header: t("Collector's Platform"),
        accessor: 'collectorsPlatform.name',
        Cell: CellLeft,
        width: '20',
        isVisible: false,
      },
      {
        Header: t('Valoriser'),
        accessor: 'valoriser.name',
        Cell: CellLeft,
        width: '35',
        isVisible: false,
      },
      {
        Header: t('Delivery Note Number'),
        accessor: 'deliveryNoteNo',
        Cell: CellLeft,
        width: '2',
        isVisible: false,
      },
      {
        Header: t('Tires'),
        accessor: 'tiredeliveriesQty',
        Cell: ({ row }) => {
          const qties = row?.original?.tiredeliveriesQty ?? [];
          const qties2 = qties
            .filter((rec) => rec.pieces && rec.pieces > 0)
            .map((rec) => {
              return `${rec?.tireType?.name}: ${rec.pieces}`;
            })
            .join(', ');
          return <div>{qties2}</div>;
        },
        width: '5',
        isVisible: false,
      },
      {
        Header: t('Delivered Date'),
        accessor: 'deliveredDate',
        Cell: CellDate,
        width: '5',
        isVisible: false,
      },
      {
        Header: t('Weighing Note Number'),
        accessor: 'weighingNoteNo',
        Cell: CellRight,
        width: '5',
        isVisible: false,
      },
      {
        Header: t('Net Weight (kg)'),
        accessor: 'netWeight',
        Cell: CellFloatCustom(10, 2, true),
        width: '5',
        isVisible: false,
      },
      {
        Header: t('Valorisation Date'),
        accessor: 'valorisationDate',
        Cell: CellDate,
        width: '7',
        isVisible: false,
      },
      {
        Header: t('Valorisation Method'),
        accessor: 'valorisationMethod.name',
        Cell: CellLeft,
        width: '12',
        isVisible: false,
      },
      {
        Header: t('Distance in km'),
        accessor: 'distance',
        Cell: CellFloatCustom(10, 2, true),
        width: '3',
        isVisible: false,
      },
    ],
    [rowClicked, t],
  );

  return (
    <LoadingOverlay active={loading} spinner text={t('Please wait...')}>
      {data ? (
        <TablePaginatedUC
          id="tiredeliveriesResolved"
          appStore={appStore}
          key="tiredeliveriesResolved"
          title={'Λίστα'}
          columns={columns}
          data={data}
          dataCount={dataCount}
          // initPageIndex={initPage}
          // onSortByChange={handleSortByChange}
          onExport={handleExport}
          onPageChange={onPageChange}
          initPageIndex={initPageIndex}
          resizable={true}
        />
      ) : (
        <div style={{ height: '200px' }}></div>
      )}
    </LoadingOverlay>
  );
};
