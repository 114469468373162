import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalProps, Form, Col as BCol } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { createModal } from 'react-modal-promise';
import { IWeighingNoteReadings } from './parse-weighing-notes';
import { Form as FForm, Formik } from 'formik';
import {
  ButtonSubmit,
  ButtonCancel,
  FieldInput,
  FieldDatepicker,
  YupNumeric,
  FieldFeedback,
  toastWarning,
} from 'motion-components';
import { InferType, date, number, object, ref, string } from 'yup'; // for only what you need
import { toNumber } from 'lodash';
import { parseISO } from 'date-fns';

const Col = (props) => (
  <BCol {...props} className="mt-2 mb-4">
    {props.children}
  </BCol>
);

export interface IWeighingValues {
  weighingNoteNo: string;
  deliveredDate: Date;
  tareWeight: any;
  grossWeight: any;
  deductWeight: any;
  netWeight: any;
}

type Props = {
  readonly open: boolean;
  readonly close: (result: boolean | IWeighingValues) => void;
  readonly title: string;
  /** body of dialog */
  readonly weighingReadings: IWeighingNoteReadings; // strings from OCR
  readonly weighingValues: IWeighingValues; // field Values
} & ModalProps;

const WeighingNoteReadingsForm = ({
  open,
  close,
  title,
  weighingReadings,
  weighingValues,
}: Props) => {
  const { t } = useTranslation();
  title = title ? t(title) : t('Weighing Note Readings');

  /**
   * Setup Yup Validation
   */
  const validationSchema = object().shape({
    deliveredDate: date().required(t('The field cannot be empty and must be a date')),
    weighingNoteNo: string().required(t('The field cannot be empty')),
    grossWeight: number().required(t('The field cannot be empty and must be a number')),
    deductWeight: YupNumeric,
    tareWeight: number().required(t('The field cannot be empty and must be a number')),
    netWeight: number().required(t('The field cannot be empty and must be a number')),
  });
  type FormDataType = InferType<typeof validationSchema>;

  const canEdit = true;
  /*-------------------- Handlers -------------------------------------------*/
  const handleSubmitForm = (values, action) => {
    console.log('Submit', values);

    const sum =
      toNumber(values.grossWeight ?? 0) -
      toNumber(values.tareWeight ?? 0) -
      toNumber(values.netWeight ?? 0);
    if (!(Math.abs(sum) < 0.001)) toastWarning(t('Net weight must be equal to Gross -Tara!'));
    else if (
      values.deliveredDate > new Date() ||
      values.deliveredDate < new Date(new Date().getFullYear() - 1, 11, 31)
    )
      toastWarning(t('Date is incorrect!'));
    else {
      close(values);
    }
    //close(true);
    //close(values);
  };

  /*-------------------- Render ---------------------------------------------*/
  return (
    <Modal show={open} size="lg" centered backdrop="static">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Formik
        onSubmit={handleSubmitForm}
        initialValues={weighingValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {(formikBag) => (
          <Form as={FForm}>
            <Modal.Body>
              <div style={{ display: 'flex' }}>
                {/* Form */}
                <Col>
                  <FieldDatepicker
                    disabled={!canEdit}
                    as={Col}
                    label={`${t('Delivered Date')}`}
                    name="deliveredDate"
                    dateFormat="d/M/yyyy"
                    colorWeekend={true}
                    //disableWeekend={true}
                    locale="en-gb"
                    prefixLabel={`${weighingReadings.date}`}
                  />

                  <FieldInput
                    disabled={!canEdit}
                    as={Col}
                    label={t('Weighing Note Number')}
                    name="weighingNoteNo"
                    type="text"
                    placeholder={t('- specify a value - ')}
                    required={false}
                    prefixLabel={weighingReadings.noteNumber}
                  />

                  <FieldInput
                    disabled={!canEdit}
                    as={Col}
                    label={t('Gross Weight')}
                    name="grossWeight"
                    type="string"
                    placeholder={t('- specify a value - ')}
                    required={false}
                    prefixLabel={weighingReadings.gross}
                    suffixLabel={t('kg')}
                  />
                  <FieldFeedback type="invalid" name={'grossWeight'} />

                  <FieldInput
                    disabled={!canEdit}
                    as={Col}
                    label={t('Tare Weight')}
                    name="tareWeight"
                    type="string"
                    placeholder={t('- specify a value - ')}
                    required={false}
                    prefixLabel={weighingReadings.tare}
                    suffixLabel={t('kg')}
                  />

                  <FieldInput
                    disabled={!canEdit}
                    as={Col}
                    label={t('Weight Deduction')}
                    name="deductWeight"
                    type="string"
                    // placeholder={t('- specify a value - ')}
                    required={false}
                    prefixLabel={' '}
                    suffixLabel={t('kg')}
                  />

                  <FieldInput
                    disabled={!canEdit}
                    as={Col}
                    label={t('Net Weight')}
                    name="netWeight"
                    type="string"
                    placeholder={t('- specify a value - ')}
                    required={false}
                    prefixLabel={weighingReadings.net}
                    suffixLabel={t('kg')}
                  />
                </Col>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ButtonSubmit
                disabled={
                  !!formikBag.errors.netWeight ||
                  !!formikBag.errors.tareWeight ||
                  !!formikBag.errors.grossWeight
                }
              />
              <ButtonCancel onClick={() => close(false)} />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export const showWeighingNoteReadingsForm = createModal(WeighingNoteReadingsForm);
