import { format, parseISO } from 'date-fns';

export function formatDate(value: string): string {
  return format(parseISO(value), 'dd/MM/yyyy');
}

export function formatDateTime(value: string): string {
  if (!value) return '-';
  return format(parseISO(value), 'dd/MM/yyyy HH:mm');
}

export function getFirstDayOfMonth(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getLastDayOfMonth(date: Date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function getWeekNumber(d) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
  // Get first day of year
  var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
  // Return array of year and week number
  return weekNo;
}

export function getWeekDates(weekNumber) {
  const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
  const dayOfWeek = firstDayOfYear.getDay(); // Day of the week for Jan 1 (0 = Sunday, 1 = Monday, ..., 6 = Saturday)

  // Calculate the first Monday of the year
  const firstMondayOfYear = new Date(firstDayOfYear);
  if (dayOfWeek !== 1) { // If Jan 1 is not a Monday
      const daysUntilFirstMonday = (8 - dayOfWeek) % 7;
      firstMondayOfYear.setDate(firstMondayOfYear.getDate() + daysUntilFirstMonday);
  }

  // Calculate the Monday of the specified week
  const mondayOfWeek = new Date(firstMondayOfYear);
  mondayOfWeek.setDate(mondayOfWeek.getDate() + (weekNumber - 1) * 7);

  // Calculate the Friday of the specified week
  const fridayOfWeek = new Date(mondayOfWeek);
  fridayOfWeek.setDate(mondayOfWeek.getDate() + 4);

  return {
      monday: mondayOfWeek,
      friday: fridayOfWeek
  };
}
