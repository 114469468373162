import React from 'react';
import { CustomSelectComponent, FormCol, FsProps } from 'motion-components';
import { Field } from 'formik';
import { ReportFrequencies } from 'shared/constants';
import { useTranslation } from 'react-i18next';

export const FieldSelectReportFrequency = ({
  label = 'Report Type',
  name = 'reportName',
  defaultValues = {},
  ...rest
}: FsProps) => {
  const { t } = useTranslation();
  const options = [
    { value: ReportFrequencies.daily, label: t('Daily') },
    { value: ReportFrequencies.weekly, label: t('Weekly') },
    { value: ReportFrequencies.monthly, label: t('Monthly') },
  ];
  return (
    <Field
      as={FormCol}
      title={t('Report Frequency')}
      name={'frequencyId'}
      component={CustomSelectComponent}
      options={options}
    />
  );
};
