import React, { useState } from 'react';
import { FormFilterCard, FieldDateRanger, FormCol } from 'motion-components';
import { toastSuccess, toastFailure } from 'utils/toasts';
import { Card, Row, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRootStore } from 'store';
import moment from 'moment';
import { format } from 'date-fns';
import { FieldSelectValoriser } from 'components';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import LoadingOverlay from 'react-loading-overlay';
import _ from 'lodash';
import saveAs from 'file-saver';
import { Formik, Form as FForm } from 'formik';
import * as Excel from 'exceljs';

const gqlGetQuery = gql`
  query getValorisers($limit: Int, $offset: Int, $order: String, $where: String) {
    getValorisers(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        id
        name
      }
    }
  }
`;

function formatDate(dt: Date) {
  return format(dt, 'yyyy-MM-dd');
}

const gqlGetMonthlyValorisedWeights = gql`
  query getMonthlyValorisedWeights($valorisers: [ValoriserMonth]) {
    getMonthlyValorisedWeights(valorisers: $valorisers)
  }
`;

/**
 *
 * @returns ExcepReports Page
 */
export const ValorisedNetWeight = () => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const [month, setMonth] = useState(new Date().getMonth());
  const [getValoriserNetWeight, { data }] = useLazyQuery(gqlGetMonthlyValorisedWeights, {
    onCompleted(data) {
      //console.log('$$$data= ', JSON.parse(data.getMonthlyValorisedWeights));
      exportExcel(JSON.parse(data.getMonthlyValorisedWeights));
    },
  });

  const { loading, data: rawData } = useQuery(gqlGetQuery, {
    variables: {
      where: JSON.stringify({ isActive: true }),
    },
    fetchPolicy: 'no-cache',
  });
  const valorisers = rawData && rawData.getValorisers ? rawData.getValorisers.data : undefined;

  const exportExcel = (data) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Livrari Valorificate') as Excel.Worksheet;
    const valos = valorisers.filter(
      (val) =>
        Object.getOwnPropertyNames(data[0]).find((rec) => parseInt(rec) === val.id) !== undefined,
    );
    //console.log('$$$valos= ', valos);
    const columns = _.map(_.sortBy(valos, ['id'], ['asc']), (item) => ({
      name: item.name,
    }));
    // @ts-ignore
    columns.unshift({
      name: `${' '}`,
    });
    columns.push({
      name: 'Total Valorificat',
    });
    //console.log('$$$columns= ', columns);

    // Set Column Widths
    worksheet.getColumn(1).width = 20;
    for (let index = 1; index < columns.length; index++) {
      worksheet.getColumn(index + 1).width = 30;
      worksheet.getColumn(index + 1).numFmt = '#,##0.00';
    }

    // Loop for records ----------------------------------------------
    let rows: Array<any> = [];
    for (let index = 0; index < data.length; index++) {
      const rec = data[index];
      const row = Object.values(rec).filter((val) => val !== 'collectorId');
      //console.log('$$$rowBefore= ', row);
      row.pop();
      row.pop();
      let sum = 0;
      row.map((prop: any) => (sum += prop));
      row.unshift(rec.collectorName);
      row.push(sum);
      //console.log('$$$rowAfter= ', row);
      rows.push(row);
    }
    const lastRow = rows.reduce((a, b) => a.map((v, i) => (i === 0 ? '' : v + b[i])));
    rows.push(lastRow);
    //console.log('$$$Rows= ', rows);
    // Add table ------------------------------------------------------------------
    worksheet.addTable({
      name: 'Deliveries',
      ref: 'A1',
      headerRow: true,
      //totalsRow: true,
      columns,
      rows,
    });
    const row = worksheet.getRow(1);
    row.font = { bold: true };
    row.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ffffffff' },
    };
    const row2 = worksheet.getRow(worksheet.rowCount);
    row2.font = { bold: true };
    workbook.xlsx.writeBuffer().then((data) => {
      console.log('saving');
      const blob = new Blob([data], { type: fileType });

      const fileName = `LIVRARI VALORIFICATE`;
      saveAs(blob, fileName);
    });
  };
  async function handleSubmit(values, { setSubmitting }) {
    appStore.setBusy();
    let errors = [];

    values.valorisers.map((val) => {
      if (!val.startDate || !val.endDate)
        errors.push(t(`Specify a valid time period for valoriser `) + `${val.valoriserName}`);
    });
    if (errors.length > 0) {
      const s = errors.map((v) => {
        return (
          <>
            {v} <br />
          </>
        );
      });
      appStore.clearBusy();
      toastFailure(<div> {s} </div>);
      return;
    }
    // Fetch Report
    setMonth(values.valorisers[0].endDate.getMonth());
    const lastValues = _.map(values.valorisers, (item) => ({
      valoriserId: item.valoriserId,
      startDate: formatDate(item.startDate),
      endDate: formatDate(item.endDate),
      /* startDate: '2016-06-01',
      endDate: '2016-06-30', */
    }));
    await getValoriserNetWeight({
      variables: {
        valorisers: lastValues,
      },
    });
    appStore.clearBusy();
    toastSuccess(t('The report has been generated!'));
    setSubmitting(false);
  }

  const getInitialValues = (data) => {
    const values = _.map(data, (item) => ({
      valoriserId: item.id,
      valoriserName: item.name,
      startDate: moment().startOf('month').toDate(),
      endDate: moment().endOf('month').toDate(),
    }));
    return values;
  };
  return (
    <Card className="shadow">
      <Card.Header>
        <Card.Title className="m-0">{t('Valorised Weight')}</Card.Title>
        <p style={{ padding: '0.3rem 0.0rem' }}>
          {t(
            "Please, select the time period of interest for each valoriser and then press 'Generate Report'",
          )}
          <br />
          {t('The server will generate the report, and an excel file will be downloaded to you.')}
        </p>
      </Card.Header>
      <LoadingOverlay active={loading} spinner text={t('Please wait...')}>
        <Card
          bg="light"
          style={{ paddingLeft: '1.5rem', paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
        >
          <Formik
            onSubmit={handleSubmit}
            enableReinitialize
            initialValues={{ valorisers: getInitialValues(valorisers) }}
          >
            <Form as={FForm}>
              {!!valorisers &&
                valorisers.map((val, index) => {
                  return (
                    <Row>
                      <FieldSelectValoriser
                        name={`valorisers[${index}].valoriserId`}
                        disabled
                        as={FormCol}
                      />
                      <FieldDateRanger
                        as={FormCol}
                        label={t('Time Period from')}
                        label2={t('till')}
                        name={`valorisers[${index}].startDate`}
                        name2={`valorisers[${index}].endDate`}
                      />
                    </Row>
                  );
                })}
              <Row className="mt-4 ml-3">
                <Button type="submit" variant="success" style={{ width: '160px' }}>
                  {t('Generate Report')}
                </Button>
              </Row>
            </Form>
          </Formik>
        </Card>
      </LoadingOverlay>
    </Card>
  );
};

const months = [
  'IANUARIE',
  'FEBRUARIE',
  'MARTIE',
  'APRILIE',
  'MAI',
  'IUNIE',
  'IULIE',
  'AUGUST',
  'SEPTEMBRIE',
  'OCTOMBRIE',
  'NOIEMBRIE',
  'DECEMBRIE',
];
