import { FileRepoImage } from 'components/filerepo-image';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, ModalProps, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { createModal } from 'react-modal-promise';
import { doOcrFile } from 'utils/ocr-textract-func';
import { FaWindowClose, FaGlasses, FaWeight } from 'react-icons/fa';
import { parseWeighingNoteTextractLines } from './parse-weighing-notes';

type Props = {
  readonly open: boolean;
  readonly close: (result: boolean | number) => void;
  readonly title: string;
  /** body of dialog */
  readonly text: string; // | React.ReactNode;
  readonly data: any;
  readonly newData: any;
  readonly fileId: number;
} & ModalProps;

const ModalContentS3Image = ({
  open,
  close,
  title,
  text,
  data,
  newData,
  fileId,
  fileName,
}: Props) => {
  const { t } = useTranslation();
  title = title ? t(title) : t('Image');

  // Close Modal if Escape key pressed
  const escFunction = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        //Do whatever when esc is pressed
        close(false);
      }
    },
    [close],
  );
  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const [ocrText, setOcrText] = useState<string>();
  const [weights, setWeights] = useState<{
    noteType: string;
    noteNumber: string;
    date: string;
    tare: string;
    gross: string;
    net: string;
  }>();

  const [loading, setLoading] = useState(false);

  async function doTextractOCR() {
    setLoading(true);
    const { lines, words } = await doOcrFile(fileName);
    if (lines) {
      setOcrText(lines.join('<br>'));

      parseTextractLines(lines);
    }
    setLoading(false);
  }

  function parseTextractLines(lines: [string]) {
    const readings = parseWeighingNoteTextractLines(lines);
    console.log('$$$$$$$$ setWeights', readings);
    setWeights(readings);
  }

  return (
    <Modal show={open} size="lg" centered backdrop="static">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>

        {/* AWS textract */}
        <Button
          className="p-3"
          style={{ minWidth: '5rem' }}
          variant="success"
          onClick={() => {
            doTextractOCR();
          }}
        >
          {loading ? (
            <Spinner animation="border" size="sm" className="mx-2 mr-3" />
          ) : (
            <FaGlasses className="mb-1 mr-3" />
          )}

          {t('OCR textract')}
        </Button>

        <Button
          className="p-3"
          style={{ minWidth: '5rem' }}
          variant="primary"
          onClick={() => close(false)}
        >
          <FaWindowClose className="mb-1 mr-3" />
          {t('Close')}
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex' }}>
          {/* Image */}
          <FileRepoImage fileId={fileId} maxHeight="70vh" minHeight="70vh" />
          {/* OCR */}
          {ocrText && (
            <div className="ml-4" style={{ overflowY: 'scroll' }}>
              {/* Weights */}
              {weights && (
                <div>
                  <h4>
                    <FaWeight className="mb-1 mr-1" /> {t('Weights')}:
                  </h4>
                  <div>
                    <label className="mr-4">{t('Note Type')}:</label>
                    <strong>{weights?.noteType}</strong>
                  </div>
                  <div>
                    <label className="mr-4">{t('Note Nr.')}:</label>
                    <strong>{weights?.noteNumber}</strong>
                  </div>
                  <div>
                    <label className="mr-4">{t('Date')}:</label>
                    <strong>{weights?.date}</strong>
                  </div>
                  <div>
                    <label className="mr-4">{t('Gross')}:</label>
                    <strong>{weights.gross}</strong>
                  </div>
                  <div>
                    <label className="mr-4">{t('Tare')}:</label>
                    <strong>{weights.tare}</strong>
                  </div>
                  <div>
                    <label className="mr-4">{t('Net')}:</label>
                    <strong>{weights.net}</strong>
                  </div>
                </div>
              )}
              <h4 className="mt-3">{t('OCR result')}:</h4>
              <div dangerouslySetInnerHTML={{ __html: ocrText }} className="mt-2" />
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const promiseModalShowS3Image = createModal(ModalContentS3Image);
