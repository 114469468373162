import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  FieldDatepicker,
  FieldInput,
  FormCol,
  FormRow,
  FieldCheckBox,
  FlexRow,
} from 'motion-components';
import { useFormikContext } from 'formik';
import { useHandleError } from 'services';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';
import { FieldSelectCounty, FieldSelectValGroup } from 'components';

export const FormFields = ({ isNew, updateRecord, refetch, data }) => {
  const { t } = useTranslation();
  //
  const frmkCntx = useFormikContext();
  const { values } = frmkCntx;
  const { appStore } = useRootStore();

  const { handleGraphqlError } = useHandleError('PiBaseFormFields');

  function calcUserCanEdit() {
    const canEdit = appStore.isEcoanvelope;
    const canEditReasoning = '';
    return { canEdit, canEditReasoning };
  }
  // const canEdit =
  //   !(values as any)?.isFinalized && (authStore.userCanDraft(userRoleId) || authStore.userCanFinalize(userRoleId));
  const { canEdit, canEditReasoning } = calcUserCanEdit();

  return (
    <>
      <div className="d-flex flex-row">
        <FormCol>
          <FieldInput
            disabled={!canEdit}
            label={t('Valoriser')}
            name="name"
            type="text"
            placeholder={t('- specify a value - ')}
            required={true}
          />

          <FieldInput
            disabled={!canEdit}
            label={t('CUI/CNP')}
            name="vatin"
            type="text"
            placeholder={t('- specify a value - ')}
            required={true}
          />

          <FieldInput
            disabled={!canEdit}
            label={t('Reg. Com. J')}
            name="tradeRegister"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldSelectValGroup
            label={t('Valorisation Group')}
            required={true}
            disabled={!canEdit}
          />
        </FormCol>

        <FormCol>
          <FieldInput
            disabled={!canEdit}
            label={t('Address')}
            name="address"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldInput
            disabled={!canEdit}
            label={t('City')}
            name="city"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldSelectCounty label={t('County')} required={true} disabled={!canEdit} />
        </FormCol>
        <FormCol>
          <FieldCheckBox disabled={!canEdit} as={FormCol} label={t('Active')} name="isActive" />
          <FieldCheckBox
            disabled={true}
            as={FormCol}
            label={t('Unavailable')}
            name="isUnavailable"
          />
          {data?.isUnavailable === true && (
            <FieldInput
              disabled={true}
              label={t('Unavailability Reason')}
              name="unavailableReason"
              type="text"
              placeholder={t('')}
              required={false}
            />
          )}
          {data?.unavailableFrom !== null && (
            <FieldDatepicker
              disabled={true}
              as={FormCol}
              label={t('Unavailable from')}
              name="unavailableFrom"
              dateFormat="d/M/yyyy HH:mm"
            />
          )}
          {data?.unavailableTill !== null && (
            <FieldDatepicker
              as={FormCol}
              disabled={true}
              label={t('Unavailable till')}
              name="unavailableTill"
              dateFormat="d/M/yyyy HH:mm"
            />
          )}
        </FormCol>
      </div>
    </>
  );
};
