import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { toastSuccess } from 'utils/toasts';
import { FaUnlock } from 'react-icons/fa';
import { promiseModalDialog } from 'motion-components';

const gqlRemoveCustody = gql`
  mutation removeCustody($tireDeliveryId: Int!) {
    removeCustody(tireDeliveryId: $tireDeliveryId)
  }
`;

const UseRemoveCustody = () => {
  const [updateRecord] = useMutation(gqlRemoveCustody);
  const removeCustody = async (tireDeliveryId) => {
    const ret = await updateRecord({
      variables: { tireDeliveryId: tireDeliveryId },
    });
    console.log('$$$', ret);
    return ret;
  };
  return { removeCustody };
};

export const RemoveCustodyButton = ({ onClick, data, disabled = false }) => {
  const { t } = useTranslation();
  const { removeCustody } = UseRemoveCustody();

  const handleSubmit = async () => {
    const res = await promiseModalDialog({
      title: t('Remove Custody'),
      text: t('Are you sure you want to remove this tire delivery from custody?'),
    });
    if (res) {
      await removeCustody(data.id);
      toastSuccess(t('Successfully removed custody'));
      onClick();
    }
  };
  return (
    <>
      <Button disabled={disabled} onClick={handleSubmit} className="ml-3 mr-4">
        <FaUnlock className="mb-1 mr-3" />
        <span>{t('Remove Custody')}</span>
      </Button>
    </>
  );
};
