import gql from 'graphql-tag';
import { ApolloClient } from '@apollo/client';

export const gqlUserMailTempPassword = gql`
  mutation userMailTempPassword($email: String!) {
    userMailTempPassword(email: $email)
  }
`;

const UserLoginDocument = gql`
  query userLogin($userName: String, $userPassword: String) {
    userLogin(userName: $userName, userPassword: $userPassword) {
      jwt
      userMustChangePassword
      expirationDate
    }
  }
`;

export function userLogin(
  client: ApolloClient<object>,
  userName: string,
  userPassword: string,
): Promise<any> {
  return new Promise((resolve, reject) => {
    client
      .query<any, any>({
        query: UserLoginDocument,
        variables: { userName, userPassword },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        if (res.data.userLogin !== null) {
          resolve(res.data.userLogin);
        } else {
          reject(res.errors[0].message);
        }
      })
      .catch((err) => reject(err));
  });
}

const CollectionpointLoginDocument = gql`
  query collectionpointLogin($collectionpointId: Int) {
    collectionpointLogin(collectionpointId: $collectionpointId) {
      jwt
      userMustChangePassword
      expirationDate
    }
  }
`;
export function collectionpointLogin(
  client: ApolloClient<object>,
  collectionpointId: number,
): Promise<any> {
  return new Promise((resolve, reject) => {
    client
      .query<any, any>({
        query: CollectionpointLoginDocument,
        variables: { collectionpointId },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        if (res.data.userLogin !== null) {
          resolve(res.data.collectionpointLogin);
        } else {
          reject(res.errors[0].message);
        }
      })
      .catch((err) => reject(err));
  });
}
