import React from 'react';
import { Col } from 'react-bootstrap';
import { FieldDatepicker, FieldInput } from 'motion-components';
import { useTranslation } from 'react-i18next';
import { TiresSection } from './tires-section';
import { FieldSelectPlatform, FieldSelectPlatformValoriser } from 'components';
import { useFormikContext } from 'formik';
import { FieldSelectTiredeliveryType } from 'components/fs-tiredelivery-type';

export const FormFieldsNewRequest = ({ data }) => {
  const { t } = useTranslation();
  const frmkCntx = useFormikContext();
  const { values } = frmkCntx;
  return (
    <>
      <Col className="col-4 ml-0 pl-0 mr-0 pr-0">
        <FieldDatepicker
          required={true}
          as={Col}
          label={t('Planned Delivery Date')}
          name="plannedDeliveryDate"
          dateFormat="d/M/yyyy"
          colorWeekend={true}
          //disableWeekend={true}
          locale="en-gb"
          //minDate={new Date()}
        />
        <FieldInput
          as={Col}
          label={t('Delivery Request Number')}
          name="requestNo"
          type="text"
          placeholder={t('- specify a value or leave empty for auto numbering - ')}
          required={false}
        />
        <FieldSelectPlatform label={t('Platform')} as={Col} required={true} />
        <FieldSelectPlatformValoriser
          label={t('Valoriser')}
          as={Col}
          required={true}
          platformId={(values as any)?.platformId}
        />
        <FieldSelectTiredeliveryType label={t('Unloading Type')} required={true} as={Col} />

        <h5 className="form-group-title-bg mt-4 mb-0">{t('Tires')}</h5>
        <div className="ml-3 pl-0 mr-3 pr-0">
          <TiresSection isNew={true} data={data} />
        </div>
      </Col>
    </>
  );
};
