import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FieldDatepicker, FieldInput, FormCol, FormRow, FieldCheckBox } from 'motion-components';
import { useFormikContext } from 'formik';
import { useHandleError } from 'services';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';
import { FieldSelectCollector } from 'components/fs-collector';
import { FieldSelectCounty } from 'components';

export const FormFields = ({ isNew, updateRecord, refetch, data }) => {
  const { t } = useTranslation();
  //
  const frmkCntx = useFormikContext();
  const { values } = frmkCntx;
  const { appStore } = useRootStore();

  const { handleGraphqlError } = useHandleError('CollectorsFormFields');

  function calcUserCanEdit() {
    const canEdit = appStore.isEcoanvelope;
    const canEditReasoning = '';
    return { canEdit, canEditReasoning };
  }
  // const canEdit =
  //   !(values as any)?.isFinalized && (authStore.userCanDraft(userRoleId) || authStore.userCanFinalize(userRoleId));
  const { canEdit, canEditReasoning } = calcUserCanEdit();

  return (
    <>
      <div className="d-flex flex-row">
        <FormCol>
          <FieldInput
            disabled={!canEdit}
            label={t('Collector')}
            name="name"
            type="text"
            placeholder={t('- specify a value - ')}
            required={true}
          />
          {appStore.isCollector ? (
            <FieldInput
              disabled={true}
              label={t('Main Collector')}
              name="collector.name"
              type="text"
              placeholder={t('')}
            />
          ) : (
            <FieldSelectCollector
              label={t('Main Collector')}
              placeholder="Without main collector"
              disabled={!isNew && !appStore.isEcoanvelope}
            />
          )}

          <FieldInput
            disabled={!canEdit}
            label={t('CUI/CNP')}
            name="vatin"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldInput
            disabled={!canEdit}
            label={t('Reg. Com. J')}
            name="tradeRegister"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />
        </FormCol>

        <FormCol>
          <FieldInput
            disabled={!canEdit}
            label={t('Address')}
            name="address"
            type="text"
            placeholder={t('- specify a value - ')}
            required={true}
          />

          <FieldInput
            disabled={!canEdit}
            label={t('City')}
            name="city"
            type="text"
            placeholder={t('- specify a value - ')}
            required={true}
          />

          <FieldSelectCounty required={true} label={t('Region')} disabled={!canEdit} />
        </FormCol>
        <FormCol>
          <FieldCheckBox disabled={!canEdit} as={FormCol} label={t('Active')} name="isActive" />
          {!isNew && (
            <FieldCheckBox
              disabled={!appStore.isEcoanvelope}
              as={FormCol}
              label={t('Blacklisted')}
              name="isBlackListed"
              offStyle="primary"
              onStyle="danger"
            />
          )}
        </FormCol>
      </div>
    </>
  );
};
