import React from 'react';
import { Button, Modal, ModalProps, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { createModal } from 'react-modal-promise';
import { Form as FForm, Formik } from 'formik';
import { FieldInput, FieldDatepicker, FormCol } from 'motion-components';
import { UserTypeValues } from 'shared/constants';
import { useRootStore } from 'store';

type Props = {
  open: boolean;
  close: (result: any) => void;
  title: string;
  /** body of dialog */
  text: string | React.ReactNode;
  /** deny text */
  denyText?: string;
  type?: string;
  data?: any;
} & ModalProps;

const ModalInput = ({ open, close, title, data }: Props) => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const associate = appStore.isCollector ? 'valoriser' : 'collector';
  return (
    <>
      <Modal show={open} size="lg" centered backdrop="static">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Formik initialValues={{ ...data }} onSubmit={(values) => {}}>
          <Form as={FForm}>
            <Modal.Body>
              <h5 className="form-group-title mb-2">
                {t("Are you sure this Tire Delivery's data is correct?")}
              </h5>
              <Col>
                <FieldDatepicker
                  disabled={true}
                  as={Col}
                  label={t('Delivered Date')}
                  name={`${associate}DeliveredDate`}
                  dateFormat="d/M/yyyy"
                />

                <FieldInput
                  disabled={true}
                  as={Col}
                  label={t('Weighing Note Number')}
                  name={`${associate}WeighingNoteNo`}
                  type="text"
                  placeholder={t('')}
                  required={false}
                />

                <FieldInput
                  disabled={true}
                  as={Col}
                  label={t('Gross')}
                  name={`${associate}GrossWeight`}
                  type="number"
                  placeholder={t('')}
                  required={false}
                  suffixLabel={t('kg')}
                />

                <FieldInput
                  disabled={true}
                  as={Col}
                  label={t('Tare')}
                  name={`${associate}TareWeight`}
                  type="number"
                  placeholder={t('')}
                  required={false}
                  suffixLabel={t('kg')}
                />

                <FieldInput
                  disabled={true}
                  as={Col}
                  label={t('Deduction')}
                  name={`${associate}DeductWeight`}
                  type="number"
                  placeholder={t('')}
                  required={false}
                  suffixLabel={t('kg')}
                />

                <FieldInput
                  disabled={true}
                  as={Col}
                  label={t('Net')}
                  name={`${associate}NetWeight`}
                  type="number"
                  placeholder={t('')}
                  required={false}
                  suffixLabel={t('kg')}
                />
              </Col>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="p-3"
                style={{ minWidth: '5rem' }}
                variant="primary"
                onClick={() => close(true)}
              >
                &nbsp;{t('Yes')}&nbsp;
              </Button>
              <Button
                className="p-3"
                style={{ minWidth: '5rem' }}
                variant="danger"
                onClick={() => close(false)}
              >
                &nbsp;{t('No')}&nbsp;
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

const promiseModalConfirmTireDelivery = createModal(ModalInput);

export default promiseModalConfirmTireDelivery;
