import React, { useMemo, useCallback } from 'react';
import { useRootStore } from 'store';
import {
  BootstrapSwitchButton,
  ButtonNewRecord,
  CellCheckbox,
  CellLeft,
  CellYesNoNull,
  EyeColumn,
  GridLinkButton,
  TableSubSimple,
  toastSuccess,
} from 'motion-components';
import { gql, useMutation } from '@apollo/client';
import { useHandleError } from 'services';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { getRouteByName, Paths } from 'app/routes';

export const ColPlatformsTable = ({
  data,
  collectorId,
  dataCount = 0,
  initPageIndex = 1,
  onPageChange = () => {},
  handleExport = () => {},
  refetch,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<any>();
  const { appStore, authStore } = useRootStore();
  const { handleGraphqlError } = useHandleError('ValMethodsTable ');

  const handleCreateNew = useCallback(() => {
    history.push({
      pathname: generatePath(getRouteByName('CollectorsPlatform'), { id: -1 }),
      state: {
        pathname: location.pathname,
        collectorId: collectorId,
        ...location.state,
      },
    });
  }, [history, location.pathname, location.state]);

  const rowClicked = useCallback(
    (row) => {
      history.push({
        pathname: generatePath(getRouteByName('CollectorsPlatform'), { id: row.id }),
        state: {
          pathname: location.pathname,
          ...location.state,
        },
      });
    },
    [history, location.pathname, location.state],
  );

  const columns = useMemo(
    () => [
      EyeColumn(rowClicked),
      {
        Header: t('Platform'),
        accessor: 'name',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Address'),
        accessor: 'address',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('City'),
        accessor: 'city',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('County'),
        accessor: 'county.name',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Phone'),
        accessor: 'phone',
        Cell: ({ row }) => (
          <>
            <div className="text-center">{row.original.phone}</div>
            <div className="text-center">{row.original.mobile}</div>
          </>
        ),
        width: '100',
        isVisible: false,
      },
      {
        Header: t('E-mail'),
        accessor: 'email',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Contact Person'),
        accessor: 'contactperson',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Active'),
        accessor: 'isActive',
        Cell: CellYesNoNull,
        width: '100',
        isVisible: false,
      },
    ],
    [authStore.isUsersAdmin, t],
  );
  return (
    <div>
      <h5 className="form-group-title mb-0 d-flex flex-row">
        {t('Collector Platfoms')}
        {appStore.isEcoanvelope && (
          <div style={{ marginLeft: 'auto', marginRight: '20px' }}>
            <ButtonNewRecord onClick={handleCreateNew} size="sm" />
          </div>
        )}
      </h5>
      <TableSubSimple title={t('Collector Platfoms')} columns={columns} data={data} />
    </div>
  );
};
