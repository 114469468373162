import React from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import {
  CellCenter,
  CellFloatCustom,
  CellInt,
  CellIntCustom,
  CellLeft,
  TableInMem,
  TableSubSimple,
} from 'motion-components';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';
import { CellFloatNegativeRed, CellIntNegativeRed } from 'components/cell-helpers';
import { endOfDay, startOfDay } from 'date-fns';

const gqlGetQuery = gql`
  query getCollectorStockTransactionsStartEndSum(
    $limit: Int
    $offset: Int
    $order: String
    $where: String
  ) {
    getCollectorStockTransactionsStartEndSum(
      limit: $limit
      offset: $offset
      order: $order
      where: $where
    ) {
      start {
        piecesA
        piecesB
        piecesC
        piecesD
        piecesE
        piecesF
        weightTotal
      }
      collections {
        piecesA
        piecesB
        piecesC
        piecesD
        piecesE
        piecesF
        weightTotal
      }
      deliveries {
        piecesA
        piecesB
        piecesC
        piecesD
        piecesE
        piecesF
        weightTotal
      }
      sums {
        piecesA
        piecesB
        piecesC
        piecesD
        piecesE
        piecesF
        weightTotal
      }
      end {
        piecesA
        piecesB
        piecesC
        piecesD
        piecesE
        piecesF
        weightTotal
      }
    }
  }
`;

const header = {
  piecesA: 'A',
  piecesB: 'B',
  piecesC: 'C',
  piecesD: 'D',
  piecesE: 'E',
  piecesF: 'F',
  weightTotal: 'Weight',
};

const Datarow = ({ title, data }) => {
  return (
    <Row className="mb-2">
      <Col>{title}</Col>
      <Col>{data?.piecesA}</Col>
      <Col>{data?.piecesB}</Col>
      <Col>{data?.piecesC}</Col>
      <Col>{data?.piecesD}</Col>
      <Col>{data?.piecesE}</Col>
      <Col>{data?.piecesF}</Col>
      <Col>{data?.weightTotal}</Col>
    </Row>
  );
};

const AggrGrid = ({ data }) => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  console.log(data);
  const start = data?.start;
  const collections = data?.collections;
  const deliveries = data?.deliveries;
  const sums = data?.sums;
  const end = data?.end;
  // const aggrData = [start, collections, deliveries, sums, end];
  function formatData(period, data) {
    return {
      period: period,
      piecesA: data?.piecesA,
      piecesB: data?.piecesB,
      piecesC: data?.piecesC,
      piecesD: data?.piecesD,
      piecesE: data?.piecesE,
      piecesF: data?.piecesF,
      weightTotal: data?.weightTotal,
    };
  }
  const aggrData = [
    formatData(t('Starting Stock'), start),
    formatData(t('Collections sums'), collections),
    formatData(t('Deliveries sums'), deliveries),
    formatData(t('Balance (Col.-Deliv.)'), sums),
    formatData(t('Ending Stock'), end),
  ];
  console.log(aggrData);

  const aggrColumns = [
    {
      Header: '-',
      accessor: 'period',
      Cell: CellLeft,
      width: '100',
    },
    {
      Header: 'A',
      accessor: 'piecesA',
      Cell: CellIntNegativeRed,
      width: '100',
    },
    {
      Header: 'B',
      accessor: 'piecesB',
      Cell: CellIntNegativeRed,
      width: '100',
    },
    {
      Header: 'C',
      accessor: 'piecesC',
      Cell: CellIntNegativeRed,
      width: '100',
    },
    {
      Header: 'D',
      accessor: 'piecesD',
      Cell: CellIntNegativeRed,
      width: '100',
    },
    {
      Header: 'E',
      accessor: 'piecesE',
      Cell: CellIntNegativeRed,
      width: '100',
    },
    {
      Header: 'F',
      accessor: 'piecesF',
      Cell: CellIntNegativeRed,
      width: '100',
    },
    ...(appStore.isEcoanvelope
      ? [
          {
            Header: t('Theoretical Weight'),
            accessor: 'weightTotal',
            Cell: CellFloatNegativeRed,
            width: '100',
          },
        ]
      : []),
  ];

  return <TableSubSimple columns={aggrColumns} data={aggrData} />;
};
/* ------------------------ Page ----------------------------- */
export const StockTransactionsAggregateSection = ({
  platformId,
  startDate: iStartDate,
  endDate: iEndDate,
}) => {
  const { t } = useTranslation();
  const startDate = startOfDay(iStartDate);
  const endDate = endOfDay(iEndDate);
  console.log('$$$', endDate, iEndDate);
  const {
    loading,
    data: rawData,
    error,
    refetch,
  } = useQuery(gqlGetQuery, {
    variables: { where: JSON.stringify({ platformId: platformId, startDate, endDate }) },
    fetchPolicy: 'network-only',
  });
  const data = rawData?.getCollectorStockTransactionsStartEndSum;
  const start = data?.start;
  const collections = data?.collections;
  const deliveries = data?.deliveries;
  const sums = data?.sums;
  const end = data?.end;

  return (
    <div className="mt-4 mb-4">
      <h5>
        {t('Transactions Summary for time period')}
        {loading && (
          <Spinner className="m-4 text-primary" animation="border" role="status" size="sm" />
        )}
      </h5>
      {data && <AggrGrid data={data} />}
    </div>
  );
};
