import { t as o } from 'i18next';
import { commonFields } from './common-fields';

function t(s) {
  return s;
}

const fields = [
  { name: 'id', displayName: 'Id' },
  { name: 'isSystemUser', displayName: t('System User') },
  { name: 'userName', displayName: t('Login Name') },
  { name: 'userPassword', displayName: t('Password') },
  { name: 'firstName', displayName: t('User Name') },
  { name: 'lastName', displayName: t('User Last Name') },
  { name: 'email', displayName: t('e-mail') },
  { name: 'tel', displayName: t('Phone') },
  { name: 'mobile1', displayName: t('Mobile') },
  { name: 'position', displayName: t('Position') },
  { name: 'notes', displayName: t('Notes') },
  { name: 'signatureFileId', displayName: t('Signature File Id') },
  { name: 'collectorId', displayName: t('Collector'), getNameFn: 'getCollectorNameById' },
  { name: 'valoriserId', displayName: t('Valorizer'), getNameFn: 'getValoriserNameById' },
  {
    name: 'collectionpointId',
    displayName: t('Collection Point'),
    getNameFn: 'getCollectionpointNameById',
  },

  ...commonFields,
];

const childs = [{ tableName: 'users_userroles' }];

const users = {
  name: 'users',
  displayName: 'Users',
  fields: fields,
  childTables: childs,
};

export { users };
