import { useLazyQuery } from '@apollo/client';
import { exportXlsModal } from 'motion-components';
import { gqlGetQuery } from '../graphql';
import { buildWhereFromFilterValues } from './filters';

export const exportColumns = [
  // { fieldName: "id", fieldTitle: "Id", fieldType: "TEXT" },
  { fieldName: 'id', fieldTitle: 'id', fieldType: 'TEXT' },
  { fieldName: 'name', fieldTitle: 'Platform', fieldType: 'TEXT' },
  { fieldName: 'collector.name', fieldTitle: 'Collector', fieldType: 'TEXT' },
  { fieldName: 'address', fieldTitle: 'Address', fieldType: 'TEXT' },
  { fieldName: 'city', fieldTitle: 'City', fieldType: 'TEXT' },
  { fieldName: 'county.name', fieldTitle: 'County', fieldType: 'TEXT' },
  { fieldName: 'phone', fieldTitle: 'Phone', fieldType: 'TEXT' },
  { fieldName: 'mobile', fieldTitle: 'Mobile', fieldType: 'TEXT' },
  { fieldName: 'fax', fieldTitle: 'Fax', fieldType: 'TEXT' },
  { fieldName: 'email', fieldTitle: 'E-mail', fieldType: 'TEXT' },
  { fieldName: 'email2', fieldTitle: 'E-mail II', fieldType: 'TEXT' },
  { fieldName: 'website', fieldTitle: 'Web Site Address', fieldType: 'TEXT' },
  { fieldName: 'contactperson', fieldTitle: 'Contact Person', fieldType: 'TEXT' },
  { fieldName: 'notes', fieldTitle: 'Notes', fieldType: 'TEXT' },
  { fieldName: 'isActive', fieldTitle: 'Active', fieldType: 'TEXT' },
  { fieldName: 'firstDate', fieldTitle: 'First Date', fieldType: 'DATETIME' },
  { fieldName: 'lastDate', fieldTitle: 'Last Date', fieldType: 'DATETIME' },
  { fieldName: 'firstUser', fieldTitle: 'First Usr', fieldType: 'TEXT' },
  { fieldName: 'lastUser', fieldTitle: 'Last User', fieldType: 'TEXT' },
];

// export function useGetQuery(): any {
//   const [get, { loading }] = useLazyQuery(gqlGetQuery, { onCompleted: transformData });

//   const [result, setResult] = useState<any>([get, { loading, data: undefined }]);

//   function transformData(data) {
//     const recs = data.getFormsDefinitions.data.map(values => {
//       return {
//         ...values,
//         solvingPeriod: periodQtyUnitToString(values.solvingPeriodQty, values.solvingPeriodUnit),
//         restoringPeriod: periodQtyUnitToString(values.restoringPeriodQty, values.restoringPeriodUnit),
//         pausingPeriod: periodQtyUnitToString(values.pausingPeriodQty, values.pausingPeriodUnit),
//         repeatingPeriod: periodQtyUnitToString(values.repeatingPeriodQty, values.repeatingPeriodUnit),
//       };
//     });

//     const trsData = { getFormsDefinitions: { data: recs, recordCount: data?.getFormsDefinitions?.recordCount } };
//     setResult([get, { loading, data: trsData }]);
//   }

//   return result;
// }

export async function handleExport(client, queryState: any): Promise<void> {
  function useGetQuery() {
    return useLazyQuery(gqlGetQuery);
    //return [null, { loading: null, data: null }];
  }
  const filterVariables = {
    where: buildWhereFromFilterValues(queryState?.filterValues),
  };
  await exportXlsModal({
    apolloClient: client,
    exportFileName: 'export CollectorsPlatforms',
    getLazyQuery: useGetQuery,
    columnsDef: exportColumns,
    filterVariables: filterVariables,
  });
}
