import React from 'react';
import { Modal, Button, ModalProps, Form } from 'react-bootstrap';
import { createModal } from 'react-modal-promise';
import { Formik, Form as FForm } from 'formik';
import { toastWarning } from 'utils/toasts';
import { useTranslation } from 'react-i18next';
import { FieldSelectCounty } from './fs-county';
import FieldDatePicker from 'motion-components/lib/common/formik/field-datepicker';

type Props = {
  open: boolean;
  close: (result: any) => void;
  title: string;
  /** body of dialog */
  text: string | React.ReactNode;
  /** accept text */
  acceptText?: string;
  /** deny text */
  denyText?: string;
  type?: string;
  collectorId?: number;
} & ModalProps;

const ModalInput = ({
  open,
  close,
  title,
  text,
  acceptText = 'OK',
  denyText = 'CANCEL',
  type = 'text',
  label = 'Valorification Restricted Until Date',
}: Props) => {
  const { t } = useTranslation();
  const handleOk = (values) => {
    if (values.date === '') {
      toastWarning(`${t('You need to choose a Date')}`);
    } else {
      const input = {
        date: values.date,
      };
      close(input);
    }
  };
  return (
    <>
      <Modal show={open} centered backdrop="static">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{ date: new Date(new Date().getFullYear(), 11, 31) }}
          onSubmit={(values) => {
            handleOk(values);
          }}
        >
          <Form as={FForm}>
            <Modal.Body>
              <h5>{text}</h5>
              <br />
              <FieldDatePicker
                //as={FormCol}
                label={t(label)}
                name="date"
              ></FieldDatePicker>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                {t(acceptText)}
              </Button>
              <Button variant="danger" onClick={() => close(false)}>
                {t(denyText)}
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

const promiseModalSelectDate = createModal(ModalInput);

export default promiseModalSelectDate;
