import _ from 'lodash';
import {
  CellCenter,
  CellRight,
  FieldInput,
  FieldIntegerInput,
  FieldNumericInput,
  FieldNumericInputNoLabel,
  TableSubSimple,
} from 'motion-components';
import React, { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTireTypesQuery } from './UseTireTypesQuery';
import './tires-section.scss';

export const TiresSection = ({ isNew, data }) => {
  const { t } = useTranslation();
  const { tireTypesData: dataTT } = useTireTypesQuery();

  const modifiedDataTireTypes = (dataQty, dataTireTypes) => {
    const modifiedArray = [];
    dataTireTypes.map((dataTT) => {
      const index = _.findIndex(dataQty, ['tiretypeId', dataTT.id]);
      if (index !== -1) {
        modifiedArray.push(dataQty[index]);
      } else {
        modifiedArray.push({ pieces: null, tireType: dataTT });
      }
    });

    return modifiedArray;
  };

  const columns = useMemo(
    () => [
      // { Header: 'Id', accessor: 'id' },
      {
        Header: t('Tires type'),
        accessor: 'name',
        Cell: ({ row }) => (
          <div className={`text-center ${isNew ? 'mt-2' : null}`}>
            {row.original?.name ?? row.original.tireType?.name ?? ''}
          </div>
        ),
      },
      {
        Header: t('Pieces'),
        accessor: 'pieces',
        Cell: !isNew
          ? ({ row }) => {
              return <div className="text-center">{row?.original.pieces}</div>;
            }
          : ({ row }) => {
              return (
                <FieldNumericInputNoLabel
                  //hasLabel={false}
                  name={`collectorsStocktakingLns[${row.index}].pieces`}
                  required={false}
                />
              );
            },
      },
    ],
    [t],
  );

  return (
    <>
      {data && dataTT && (
        <div>
          <TableSubSimple
            id="tires-inheader"
            title={t('')}
            columns={columns}
            data={modifiedDataTireTypes(data.collectorsStocktakingLns, dataTT)}
          />
        </div>
      )}
    </>
  );
};
