import React from 'react';
import { FieldSelectDB } from 'motion-components';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { FsProps } from 'motion-components';
import { UserTypeValues } from 'shared/constants';

const gqlGetValues = gql`
  query getUsers($where: String, $offset: Int, $limit: Int, $order: String) {
    getUsers(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        id: id
        userName
        firstName
        lastName
      }
      recordCount
    }
  }
`;

function useGetValues() {
  return useLazyQuery(gqlGetValues, { fetchPolicy: 'network-only' });
}

export const FieldSelectDriver = ({
  label = 'Driver',
  name = 'driverUserId',
  collectorId = undefined,
  defaultValues = {},
  ...rest
}: FsProps & { collectorId?: number }) => {
  const where = JSON.stringify({
    usertypeId: UserTypeValues.CollectorDriver,
    collectorId: collectorId,
  });
  return (
    <FieldSelectDB
      {...rest}
      label={label}
      name={name}
      // @ts-ignore
      sbLabel={defaultValues?.driver ?? undefined}
      hook={useGetValues}
      where={where}
      formatLabel={(rec) => `${rec.userName} (${rec.firstName} ${rec.lastName})`}
    />
  );
};
