import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery, useApolloClient } from '@apollo/client';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import {
  FormCard,
  YupNumeric,
  YupDate,
  YupString,
  YupBoolean,
  promiseModalDialog,
  UserTooltip,
  objParseInt,
  objParseFloat,
  YupObject,
  promiseModalInput,
  YupNumericReq,
  YupDateReq,
  ButtonCancel,
} from 'motion-components';
import * as Yup from 'yup';
import _, * as Lo from 'lodash';
import { toastFailure, toastSuccess } from 'utils/toasts';
import { useHandleError } from 'services';
import {
  gqlGetByIdQuery,
  gqlAdd,
  gqlUpdate,
  gqlDelete,
  FormInput,
  gqlAcceptTireDelivery,
  gqlRejectTireDelivery,
} from '../graphql';
import { isNumeric } from 'utils/helpers';

import { FormFields } from './form-fields';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';
import { TiredeliveriesFilesSection } from './files-section';
import { getTiredeliveryStatusBadge } from 'common/badges-helpfunc';
import { TireDeliveriesStatus, UnloadingTypes } from 'shared/constants';
import { FormFieldsNewRequest } from './form-fields-new-request';
import { Button } from 'react-bootstrap';
import { FormFieldsApproveRequest } from './form-fields-approve';
import { getRouteByName } from 'app/routes';
import moment from 'moment-business-days';

export const TiredeliveryFormPage = () => {
  const { t } = useTranslation();
  const location = useLocation<any>();
  const { handleGraphqlError } = useHandleError('TiredeliveriesFormPage');
  const { appStore } = useRootStore();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const idNum = isNumeric(id) ? parseInt(id) : -1;
  const isNew = idNum < 0;

  const formShape = {
    id: YupNumeric,
    statusId: YupNumeric,
    typeId: isNew ? YupNumericReq : YupNumeric,
    status: YupObject,
    plannedDeliveryDate: isNew ? YupDateReq : YupDate,
    requestNo: YupString,
    collectorId: YupNumeric,
    platformId: YupNumericReq,
    valoriserId: YupNumericReq,
    requestDate: YupDate,
    requestedBy: YupString,
    approvedDate: YupDate,
    approvedBy: YupString,
    approvalNotes: YupString,
    deliveryNoteNo: YupString,
    loadUnloadNoteNo: YupString,
    deliveredDate: YupDate,
    vehPlateNo: YupString,
    trailerPlateNo: YupString,
    driver: YupString,
    driverUserId: YupNumeric,
    licenseTransport: YupString,
    licenseTransportExpDate: YupDate,
    biNumber: YupString,
    issuedBy: YupString,
    transporter: YupString,
    representative: YupString,
    weighingNoteNo: YupString,
    grossWeight: YupNumeric,
    tareWeight: YupNumeric,
    deductWeight: YupNumeric,
    netWeight: YupNumeric,
    collectorNotes: YupString,
    collectorWeightsBy: YupString,
    weightNotes: YupString,
    valoriserWeightsBy: YupString,
    valRestrictedUntilDate: YupDate,
    valorisationDate: YupDate,
    valorisationNotes: YupString,
    valorisationEnteredBy: YupString,
    valorisationMethodId: YupNumeric,
    distance: YupNumeric,
    ecoanvelopeNotes: YupString,
    collectorDeliveryNoteNo: YupString,
    collectorWeighingNoteNo: YupString,
    collectorDeliveredDate: YupString,
    collectorGrossWeight: YupString,
    collectorTareWeight: YupNumeric,
    collectorDeductWeight: YupNumeric,
    collectorNetWeight: YupNumeric,
    valoriserWeighingNoteNo: YupString,
    valoriserDeliveredDate: YupString,
    valoriserDeliveryNoteNo: YupString,
    valoriserGrossWeight: YupNumeric,
    valoriserTareWeight: YupNumeric,
    valoriserDeductWeight: YupNumeric,
    valoriserNetWeight: YupNumeric,
    isActive: YupBoolean,
    firstDate: YupDate,
    lastDate: YupDate,
    firstUser: YupString,
    lastUser: YupString,
    collector: null,
    collectorsPlatform: null,
    valoriser: null,
    tiredeliveriesQty: Yup.array().of(
      Yup.object().shape({
        pieces: YupNumeric,
        tiretypeId: YupNumeric,
      }),
    ),
  };

  const validationSchema = Yup.object().shape(formShape);
  const pickProperties = (values) => Lo.pick(values, Lo.keys(formShape));
  type FormValuesType = Yup.InferType<typeof validationSchema>;

  const [addRecord] = useMutation(gqlAdd);
  const [updateRecord] = useMutation(gqlUpdate);
  const [deleteRecord] = useMutation(gqlDelete);
  const [accept] = useMutation(gqlAcceptTireDelivery);
  const [reject] = useMutation(gqlRejectTireDelivery);

  const plannedDate = new Date();
  plannedDate.setDate(moment(new Date()).businessAdd(2).date());
  //plannedDate.setDate(plannedDate.getDate());
  const newRecord = useMemo<FormValuesType>(
    () =>
      ({
        id: -1,
        collectorId: location?.state?.collectorId,
        platformId: null,
        requestDate: new Date(),
        plannedDeliveryDate: plannedDate,
        tiredeliveriesQty: null,
        typeId: null,
      } as FormValuesType),
    [location?.state?.collectorId],
  );

  const [get, { data: rawData, error, refetch: refetchBase }] = useLazyQuery(gqlGetByIdQuery, {
    fetchPolicy: 'network-only',
  });
  const data = isNew ? newRecord : rawData ? rawData[Object.keys(rawData)[0]] : undefined;

  // for Re-render sub components
  const [dataRefetched, setDataRefetched] = useState(1);
  const refetch = () => {
    refetchBase();
    setDataRefetched(dataRefetched + 1);
  };

  /* fetch data */
  useEffect(() => {
    if (!isNew) get({ variables: { id: idNum } });
  }, [get, id, idNum, isNew]);

  /**
   * * ΑΠΟΘΗΚΕΥΣΗ - UPDATE/CREATE
   */
  const handleSubmit = (values: FormValuesType, actions) => {
    actions.setSubmitting(false);
    let mutValues = Lo.pick(values, Lo.keys(new FormInput()));
    mutValues = objParseInt(
      [
        'id',
        'statusId',
        'collectorId',
        'platformId',
        'valoriserId',
        'valorisationMethodId',
        'typeId',
      ],
      mutValues,
    );
    mutValues = objParseFloat(
      [
        'collectorGrossWeight',
        'collectorTareWeight',
        'collectorDeductWeight',
        'collectorNetWeight',
        'valoriserGrossWeight',
        'valoriserTareWeight',
        'valoriserDeductWeight',
        'valoriserNetWeight',
        'distance',
      ],
      mutValues,
    );
    // Αν είναι νέα εγγραφή τοτε γράφω όλα τα πεδία
    if (isNew) {
      console.log('[Tiredeliveries] New:', mutValues);
      const emptyFields =
        !mutValues.tiredeliveriesQty ||
        mutValues.tiredeliveriesQty.findIndex((qty) => qty?.pieces > 0) === -1;
      if (emptyFields) {
        toastFailure(t('You have to submit the pieces of at least 1 Tire Type!'));
      } else {
        appStore.setBusy();
        addRecord({ variables: { data: { ...mutValues } } })
          .then((d) => {
            const id = d.data[Object.keys(d.data)[0]]?.id;
            toastSuccess(`${t('Record Created Successfully')} [${id}]`);
            history.replace({
              pathname: generatePath(getRouteByName('Tiredelivery'), { id: id }),
              state: {
                pathname: location.pathname,
                ...location.state,
              },
            });
          })
          .catch((error) => {
            handleGraphqlError('mutation-add', error);
          })
          .finally(() => {
            appStore.clearBusy();
          });
      }
    } else {
      // Αν είναι update εγγραφής τοτε γράφω μονο τα αλλαγμένα πεδία
      console.log('[Tiredeliveries] Update:', mutValues);
      //delete mutValues.tiredeliveriesQty;
      const picked = _.map(mutValues.tiredeliveriesQty, (obj) => {
        return _.pick(obj, ['id', 'pieces', 'tiretypeId']);
      });
      const modifiedValues = {
        ...mutValues,
        tiredeliveriesQty: picked,
      };
      updateRecord({ variables: { data: { ...modifiedValues, id: idNum } } })
        .then((d) => {
          toastSuccess(
            `${t('Record Updated Successfully')} [${d.data[Object.keys(d.data)[0]].id}]`,
          );
          refetch();
        })
        .catch((error) => {
          handleGraphqlError('mutation-update', error);
        });
    }
  };

  /**
   * *  DELETE
   */
  const handleDelete = useCallback(async () => {
    const res = await promiseModalDialog({
      title: t('Delete Record'),
      text: t('The record will be permanently deleted. Do you want to continue?'),
    });
    if (res) {
      deleteRecord({ variables: { id: idNum } })
        .then(() => {
          toastSuccess(t('The record has been successfully deleted'));
          history.goBack();
        })
        .catch((error) => {
          handleGraphqlError('mutation-delete', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRecord, idNum]);

  /**
   * * APPROVE/REJECT
   */
  const handleApprove = async (answer) => {
    if (answer === 'accept') {
      accept({ variables: { tireDeliveryId: idNum } })
        .then((d) => {
          toastSuccess(`${t('Successfully Accepted Request')} [${d.data.acceptTireDelivery}]`);
          refetch();
        })
        .catch((error) => {
          handleGraphqlError('mutation-accept', error);
        });
    } else {
      // reject
      const res = await promiseModalInput({
        title: t('Justification'),
        text: t('Specify reason of rejection'),
      });
      if (res) {
        console.log(res);
        reject({ variables: { tireDeliveryId: idNum, rejectReason: res } })
          .then(() => {
            refetch();
          })
          .catch((error) => {
            handleGraphqlError('mutation-reject', error);
          });
      }
    }
  };

  function showFilesSection() {
    return (
      data &&
      !isNew &&
      !(
        data?.statusId === TireDeliveriesStatus.Request ||
        data?.statusId === TireDeliveriesStatus.Rejected
      )
    );
  }

  // --------------------------------------------------
  // Render
  // --------------------------------------------------
  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <FormCard
        title={
          isNew ? (
            `${t('New Tires Delivery Request')} `
          ) : (
            <>
              <span>{`${t('Tires Delivery')}: ${data?.requestNo}`}</span>
              &nbsp;&nbsp;
              <div
                className={'badge pb-2 pt-1 mr-2 '}
                style={{
                  backgroundColor: getTiredeliveryStatusBadge(data?.status?.value),
                  color: 'white',
                }}
              >
                {t(data?.status?.name)}
              </div>
            </>
          )
        }
        onSubmit={handleSubmit}
        //onDelete={handleDelete}
        onReturn={null}
        //permitDelete={!isNew && appStore.isEcoanvelope}
        initialValues={pickProperties(data)}
        validationSchema={validationSchema}
        enableReinitialize
        idx={idNum}
        isNew={isNew}
      >
        {isNew && <FormFieldsNewRequest data={data} />}
        {data && data?.statusId === TireDeliveriesStatus.Request && (
          <FormFieldsApproveRequest data={data} handleApprove={handleApprove} />
        )}
        {data && data?.statusId > TireDeliveriesStatus.Request && (
          <>
            <FormFields
              isNew={isNew}
              updateRecord={updateRecord}
              refetch={refetch}
              data={data}
              handleApprove={handleApprove}
              dataRefetched={dataRefetched}
            />
            {/* Document Files */}
            {/* {showFilesSection() && (
              <div className="mt-4">
                <TiredeliveriesFilesSection
                  tiredeliveryId={data.id}
                  forceRerender={dataRefetched}
                />
              </div>
            )} */}
          </>
        )}
      </FormCard>
    );
  }
};
