import React, { useMemo, useCallback, useState } from 'react';
import { Card, Row } from 'react-bootstrap';
import { useQuery, useApolloClient } from '@apollo/client';
import { gqlGetUsers } from './graphql';
import { useRootStore } from 'store';
import { generatePath, useHistory } from 'react-router';
import { getRouteByName } from 'app/routes';
import LoadingOverlay from 'react-loading-overlay';
import { useLocation } from 'react-router-dom';
import {
  FieldSelectCollector,
  FieldSelectUserRole,
  FieldSelectUserType,
  FieldSelectValoriser,
} from 'components';
import { useHandleError } from 'services';
import {
  FormFilterCard,
  FieldInput,
  TablePaginatedUC,
  ButtonNewRecord,
  FlexRow,
  FormCol,
  CellActive,
  EyeColumn,
  exportXlsModal,
  FieldSelectIsActive,
} from 'motion-components';
import { useTranslation } from 'react-i18next';

import { exportColumns, useExportUsers } from './export-columns';
import { t } from 'i18next';
import { UserTypeValues } from 'shared/constants';

const initFilters = { isActive: true };

/* ----------- Page ------------------ */
export const UsersPage = () => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const { handleGraphqlError } = useHandleError('users');
  /* ----------------- Route State -------------------------- */
  const location = useLocation<any>();
  const history = useHistory();
  // Στο state του routing αποθηκεύω:
  //    1. την τρέχουσα σελίδα του table pagination (pageIndex)
  //  & 2. τα values των φίλτρων (filterValues)

  /* ------------------- Query ------------------------------- */
  const tablePageSize = appStore.pageSize;
  const [initPageIndex, setInitPageIndex] = useState(location?.state?.pageIndex ?? 0);
  const [queryParams, setQueryParams] = useState({
    offset: tablePageSize * initPageIndex,
    limit: tablePageSize,
    order: 'userName ASC',
    where: buildWhereFromFilterValues(location?.state?.filterValues ?? initFilters),
  });
  const {
    loading,
    data: rawData,
    error,
    refetch,
  } = useQuery(gqlGetUsers, {
    variables: { ...queryParams },
    fetchPolicy: 'network-only',
  });
  const data = rawData?.getUsers?.data;
  const dataCount = rawData?.getUsers?.recordCount;

  const filtersChanged = useCallback(
    (values) => {
      console.log('filtersChanged', values);
      // Φτιάξε το where για το graphql
      const where = buildWhereFromFilterValues(values);

      // Σύνθεσε τα vars του graphql
      const newQueryParams = {
        ...queryParams,
        offset: 0,
        where: where,
      };
      setQueryParams(newQueryParams); // αποθήκευσε τα vars
      refetch(newQueryParams); // φερε τα δεδομένα απο τον σερβερ
      setInitPageIndex(0); // αρχικοποίησε το gridTable στην 1η σελίδα

      // Ενημέρωσε το State του Route
      history.replace(location.pathname, { filterValues: values, pageIndex: 0 });
    },
    [history, location.pathname, queryParams, refetch],
  );

  /* handle Pagination */
  const gridPageChanged = useCallback(
    (pageIndex, pageSize: number) => {
      console.log('gridPageChanged', pageIndex, pageSize);
      setInitPageIndex(pageIndex);
      const newQueryParams = { ...queryParams, offset: pageSize * pageIndex, limit: pageSize };
      setQueryParams(newQueryParams);
      refetch(newQueryParams);

      // Ενημέρωσε το State του Route
      const state = {
        ...location.state,
        pageIndex,
      };
      history.replace(location.pathname, state);
    },
    [history, location.pathname, location.state, queryParams, refetch],
  );

  const canCreateNew = appStore.isUserAdmin || appStore.isEcoanvelope;
  const handleCreateNew = useCallback(() => {
    history.push({
      pathname: generatePath(getRouteByName('User'), { id: -1 }),
      state: {
        pathname: location.pathname,
        ...location.state,
      },
    });
  }, [history, location.pathname, location.state]);

  // function useGetUsers() {
  //   return useLazyQuery(gqlGetUsers);
  //   //return [null, { loading: null, data: null }];
  // }

  const handleExport = useCallback(
    async (client: any) => {
      await exportXlsModal({
        apolloClient: client,
        getLazyQuery: useExportUsers, //useGetUsers,
        filterVariables: queryParams,
        exportFileName: t('Users'),
        columnsDef: exportColumns(t),
      });
    },
    [queryParams, t],
  );

  //console.log('loading', loading);
  //console.log('rawData', rawData);
  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <Card className="shadow">
        <Card.Header>
          <FlexRow>
            <Card.Title className="m-0">{t('Users')}</Card.Title>
            {canCreateNew && <ButtonNewRecord onClick={handleCreateNew} size="sm" />}
          </FlexRow>
        </Card.Header>
        <Card.Body className="nopadding">
          {/* Φίλτρα */}
          <Filters
            onChange={filtersChanged}
            initialValues={location?.state?.filterValues ?? initFilters}
          />
          {/* TableGrid */}
          <LoadingOverlay active={loading} spinner text={t('Please wait...')}>
            {rawData ? (
              <DataGrid
                data={data}
                dataCount={dataCount}
                initPageIndex={initPageIndex}
                onPageChange={gridPageChanged}
                handleExport={handleExport}
              />
            ) : (
              <div style={{ height: '200px' }}></div>
            )}
          </LoadingOverlay>
        </Card.Body>
      </Card>
    );
  }
};

/* ----------- Grid ------------------ */
const DataGrid = ({ data, dataCount, initPageIndex, onPageChange, handleExport }) => {
  //console.log(props.dataX);
  const location = useLocation<any>();
  const history = useHistory();
  const { appStore } = useRootStore();
  const rowClicked = useCallback(
    (row) => {
      history.push({
        pathname: generatePath(getRouteByName('User'), { id: row.id }),
        state: {
          pathname: location.pathname,
          ...location.state,
        },
      });
    },
    [history, location.pathname, location.state],
  );

  const userRolesCell = useCallback(({ row }) => {
    const shortByName = function (a, b) {
      const nameA = a.userRole.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.userRole.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) return -1;
      else if (nameA > nameB) return 1;
      else return 0;
    };
    const roles = row.original.userUserroles
      .slice()
      .filter((rec) => rec.isActive)
      .sort(shortByName);
    return (
      <div className="text-left">
        <ul style={{ paddingLeft: '1.8em' }}>
          {roles.map((el, index) => {
            return <li key={index}>{t(el.userRole.name)}</li>;
          })}
        </ul>
      </div>
    );
  }, []);
  const client = useApolloClient();

  const columns = useMemo(
    () => [
      EyeColumn(rowClicked),
      { Header: t('User'), accessor: 'userName', width: '7%' },
      { Header: t('Name'), accessor: 'firstName', width: '7%' },
      { Header: t('Surname'), accessor: 'lastName', width: '7%' },
      { Header: t('User Type'), accessor: 'userType.name', Cell: userTypeCell, width: '20%' },
      { Header: t('Telephone'), accessor: 'tel', width: '7%' },
      { Header: t('Email'), accessor: 'email', width: '10%' },
      {
        Header: t('User Roles'),
        accessor: 'userType',
        Cell: userRolesCell,
        width: '10%',
      },
      { Header: t('Active'), accessor: 'isActive', Cell: CellActive, width: '5%' },
    ],
    [rowClicked, userRolesCell],
  );
  return (
    <TablePaginatedUC
      title={t('Users')}
      columns={columns}
      data={data}
      dataCount={dataCount}
      // initPageIndex={initPage}
      // onSortByChange={handleSortByChange}
      onExport={() => {
        handleExport(client);
      }}
      onPageChange={onPageChange}
      initPageIndex={initPageIndex}
      id={'UsersTable'}
      appStore={appStore}
    />
  );
};

const userTypeCell = ({ row }) => {
  //
  function SecondLine() {
    // Συλλέκτης
    if (
      row.original?.usertypeId === UserTypeValues.Collector ||
      row.original?.usertypeId === UserTypeValues.CollectorDriver
    ) {
      return <div>({row.original?.collector?.name ?? t('Undefined Collector')})</div>;
    }
    // Ανακυκλωτής
    else if (row.original?.usertypeId === UserTypeValues.Valoriser) {
      return (
        <div>
          (
          {row.original?.usersValorisers.length > 0
            ? row.original?.usersValorisers.map((val) => {
                return <div>{val.valoriser.name}</div>;
              })
            : row.original?.valoriser?.name ?? t('Undefined Valoriser')}
          )
        </div>
      );
    }
    // Σημείο Παραλαβής Αποβλήτων
    else if (row.original?.usertypeId === UserTypeValues.CollectionPoint) {
      return <div>({row.original?.collectionpoint?.name ?? t('Undefined Collection point')})</div>;
    }
    // EcoAnvelope ?
    else return <div></div>;
  }
  return (
    <div className="text-left">
      <div>{row.original?.userType?.name}</div>

      <SecondLine />
    </div>
  );
};

/* ------------------- Διαχείριση Φίλτρων ------------------------------ */
function buildWhereFromFilterValues(values): string {
  // Φτιάξε το where για το graphql
  let where: any = {};
  if (values) {
    if (values.searchText) {
      const searchText = '%' + values.searchText + '%';
      const likeObj = { $like: searchText };
      where = {
        $or: [{ userName: likeObj }, { lastName: likeObj }, { firstName: likeObj }],
      };
    }
    if (values.userRoleId) where.userRoleId = values.userRoleId;
    if (values.usertypeId) where.usertypeId = values.usertypeId;
    if (values.collectorId) where = { ...where, collectorId: values.collectorId };
    if (values.valoriserId) where = { ...where, valoriserId: values.valoriserId };
    if (values.isActive === true || values.isActive === false) {
      where = { ...where, isActive: values.isActive };
    }
  }
  return JSON.stringify(where);
}

/* ----------- Filters ------------------ */
const Filters = ({ onChange, initialValues }) => {
  //
  const { appStore } = useRootStore();
  function handleFiltersSubmit(values, { setSubmitting }) {
    setTimeout(() => {
      setSubmitting(false);
      onChange(values);
    }, 400);
  }
  console.log('filters-init ', initialValues);

  const filters = {
    searchText: initialValues?.searchText ?? '',
    usertypeId: initialValues?.usertypeId ?? '',
    userRoleId: initialValues?.userRoleId ?? '',
    collectorId: initialValues?.collectorId ?? '',
    valoriserId: initialValues?.valoriserId ?? '',
    isActive: initialValues?.isActive ?? undefined,
  };
  //const { values } = useFormikContext();
  return (
    <FormFilterCard
      title={t('Search Criteria')}
      onSubmit={handleFiltersSubmit}
      initialValues={{
        searchText: filters?.searchText,
        usertypeId: filters?.usertypeId,
        userRoleId: filters?.userRoleId,
        collectorId: filters?.collectorId,
        valoriserId: filters?.valoriserId,
        isActive: filters?.isActive,
      }}
    >
      <Row>
        <FieldInput
          as={FormCol}
          name="searchText"
          label={t('Search by (username, name, surname)')}
          type="search"
        />
        {(appStore.isEcoanvelope || appStore.isCollector) && (
          <FieldSelectUserType as={FormCol} label={t('User Type')}></FieldSelectUserType>
        )}
        {appStore.isEcoanvelope && <FieldSelectCollector label={t('Collector')} as={FormCol} />}
        {appStore.isEcoanvelope && <FieldSelectValoriser label={t('Valoriser')} as={FormCol} />}
        <FieldSelectUserRole as={FormCol} label={t('User Role')}></FieldSelectUserRole>
        <FieldSelectIsActive label={t('Active Records')} />
      </Row>
    </FormFilterCard>
  );
};
