import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  CellLeft,
  promiseModalDialog,
  TableSubSimple,
  toastFailure,
  toastSuccess,
} from 'motion-components';
import { Button } from 'react-bootstrap';
import { FilePicker } from 'react-file-picker';
import { asyncUploadFile2S3withSignedUrl, uploadFile2S3withSignedUrl } from 'utils/s3helpfunc';
import { FaTrashAlt, FaFolderOpen, FaFileDownload, FaBug } from 'react-icons/fa';
import { getFileExtension } from 'utils/helpers';
import { useRootStore } from 'store';
import { DocumentTypes, TireDeliveriesStatus, UserTypeValues } from 'shared/constants';
import { doOcrFile } from 'utils/ocr-textract-func';
import { IWeighingNoteReadings, parseWeighingNoteTextractLines } from './parse-weighing-notes';
import { IWeighingValues, showWeighingNoteReadingsForm } from './weighing-note-readings-form';
import moment from 'moment';
import { format, parseISO } from 'date-fns';
import { useFormikContext } from 'formik';

// --------------------------------------------------------------------
const gqlAddFilesRepository = gql`
  mutation addFilesrepository($fileName: String!, $mimeMediaType: String!) {
    addFilesrepository(fileName: $fileName, mimeMediaType: $mimeMediaType) {
      id
      url
      s3Key
    }
  }
`;

const UseUploadToS3 = (setUploadStatus) => {
  //
  const [addFile] = useMutation(gqlAddFilesRepository);

  const uploadFile = async (fileObj: File): Promise<{ fileId: number; s3Key: string } | null> => {
    console.log(fileObj);
    // Εχει επιλεγεί αρχείο...
    // φτιάξε το filerepository record
    // και πάρε το url για να αναιβάσεις το αρχείο
    const res = await addFile({
      // Mutation
      variables: { fileName: fileObj.name, mimeMediaType: fileObj.type },
    });
    const { id, url, s3Key } = res?.data?.addFilesrepository;
    console.log(id, url, s3Key);
    if (id && url && s3Key) {
      setUploadStatus(0);
      const upl = await asyncUploadFile2S3withSignedUrl(
        fileObj,
        url,
        (percent) => {
          setUploadStatus(percent);
        }, //onUploadProgress
      ).catch(function (err) {
        console.error('Augh, there was an error!', err.statusText);
        setUploadStatus(-1);
        return null;
      });
      setUploadStatus(-1);
      return { fileId: id, s3Key };
    } else return null;
  };

  return { uploadFile };
};

// -----------------------------------------------------------------------

const getTiredeliveriesFilesDoc = gql`
  query getTiredeliveriesFiles($where: String, $offset: Int, $limit: Int, $order: String) {
    getTiredeliveriesFiles(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        id
      }
      recordCount
    }
  }
`;

const UseFindTireDeliveriesFile = () => {
  const [getTiredeliveriesFiles, { data }] = useLazyQuery(getTiredeliveriesFilesDoc);
  const findTireDeliveriesFile = async (deliveryId, documentTypeId) => {
    const ret = await getTiredeliveriesFiles({
      variables: {
        where: JSON.stringify({
          tiredeliveryId: deliveryId,
          documenttypeId: documentTypeId,
        }),
      },
    });
    return ret?.data?.getTiredeliveriesFiles?.data[0]?.id;
  };
  return { findTireDeliveriesFile };
};
// -----------------------------------------------------------------------
const gqlUpdateTiredeliveriesFile = gql`
  mutation updateTiredeliveriesFile($data: TiredeliveriesFileInput) {
    updateTiredeliveriesFile(data: $data) {
      id
    }
  }
`;

const UseUpdateTiredeliveriesFile = () => {
  const [updateRecord] = useMutation(gqlUpdateTiredeliveriesFile);
  const updateTiredeliveriesFile = async (tiredeliveriesFileId, fileId) => {
    const ret = await updateRecord({
      variables: { data: { fileId: fileId, id: tiredeliveriesFileId } },
    });
    console.log('$$$', ret);
    return ret;
  };
  return { updateTiredeliveriesFile };
};
// -----------------------------------------------------------------------
export function parseValuesFromStrings(r: IWeighingNoteReadings): IWeighingValues {
  function parseKilos(text: string): number {
    // 10.000,50 => 10000,50
    if (text.includes(',') && text.includes('.')) text = text.replaceAll('.', '');

    // 10000,50 => 10000.50
    if (text.includes(',')) text = text.replaceAll(',', '.');
    const value = parseFloat(text);
    if (Number.isNaN(value)) return null;

    // tonnage to Kilos
    if (value < 100) return value * 1000;

    return value;
  }

  function parseDate(text: string): Date {
    var dt = moment(
      text,
      r.noteType === 'ROMCIM SA HOGHIZ' ? 'MM/DD/YYYY HH:mm:ss' : 'DD/MM/YYYY HH:mm:ss',
    ).toDate();
    if (dt.toString() === 'Invalid Date') return null;
    else return dt;
  }

  /* function testDate(text: string) {
    var mdt = moment(text, 'MM/DD/YYYY HH:mm:ss');
    console.log('$$$D Date ', text, mdt.toDate());
  }
  testDate('10/9/2022');
  testDate('10/9/22');
  testDate('10/9/22 09:45');
  testDate('10-9-2022');
  testDate('10-9-22');
  testDate('10-9-22 09:45');
  testDate('10-99-22 09:45');
  testDate('10/28/2022 12:05'); */

  const v: IWeighingValues = {
    weighingNoteNo: r.noteNumber,
    deliveredDate: parseDate(r.date),
    tareWeight: r.tare !== undefined ? parseKilos(r.tare) : r.tare,
    grossWeight: r.gross !== undefined ? parseKilos(r.gross) : r.gross,
    deductWeight: 0,
    netWeight: r.net !== undefined ? parseKilos(r.net) : r.net,
  };
  return v;
}
// -----------------------------------------------------------------------

export const gqlUpdate = gql`
  mutation updateTiredelivery($data: TiredeliveryInput) {
    updateTiredelivery(data: $data) {
      id
    }
  }
`;

const gqlUploadTireDeliveryWeights = gql`
  mutation uploadTireDeliveryWeights($data: WeightsInput) {
    uploadTireDeliveryWeights(data: $data)
  }
`;

const gqlDenyTireDelivery = gql`
  mutation denyTireDelivery($data: WeightsInput, $isSameWeights: Boolean) {
    denyTireDelivery(data: $data, isSameWeights: $isSameWeights)
  }
`;

export const UseUpdateTiredeliveriesWeights = () => {
  const { t } = useTranslation();
  const [updateRecord] = useMutation(gqlUploadTireDeliveryWeights);
  const [denyRecord] = useMutation(gqlDenyTireDelivery);
  const updateTiredeliveriesWeights = async (
    deliveryId: number,
    weighingFields: IWeighingValues,
  ) => {
    const ret = await updateRecord({
      variables: {
        data: {
          tireDeliveryId: deliveryId,
          deliveredDate: weighingFields.deliveredDate,
          weighingNote: weighingFields.weighingNoteNo,
          gross: parseFloat(weighingFields.grossWeight),
          tare: parseFloat(weighingFields.tareWeight),
          deduct: parseFloat(weighingFields.deductWeight),
          net: parseFloat(weighingFields.netWeight),
        },
      },
    }).catch((err) => {
      throw t(err?.message);
    });
    console.log('$$$', ret);
    return ret;
  };
  const denyTiredeliveriesWeights = async (
    deliveryId: number,
    weighingFields: IWeighingValues,
    isSameWeights: Boolean,
  ) => {
    const ret = await denyRecord({
      variables: {
        data: {
          tireDeliveryId: deliveryId,
          deliveredDate: weighingFields.deliveredDate,
          weighingNote: weighingFields.weighingNoteNo,
          gross: parseFloat(weighingFields.grossWeight),
          tare: parseFloat(weighingFields.tareWeight),
          deduct: parseFloat(weighingFields.deductWeight),
          net: parseFloat(weighingFields.netWeight),
        },
        isSameWeights: isSameWeights,
      },
    }).catch((err) => {
      throw t(err?.message);
    });
    console.log('$$$', ret);
    return ret;
  };
  return { updateTiredeliveriesWeights, denyTiredeliveriesWeights };
};
// -----------------------------------------------------------------------

// -----------------------------------------------------------------------
// -----------------------------------------------------------------------
export const WeighingNoteButton = ({
  deliveryId,
  onClick,
  statusId = TireDeliveriesStatus.Approved,
  disabled = false,
  data = null,
}) => {
  //
  const { dirty, submitForm } = useFormikContext();
  const { appStore } = useRootStore();
  const { t } = useTranslation();

  const [uploadStatus, setUploadStatus] = useState(-1);
  const { uploadFile } = UseUploadToS3(setUploadStatus);
  const { updateTiredeliveriesFile } = UseUpdateTiredeliveriesFile();

  const { findTireDeliveriesFile } = UseFindTireDeliveriesFile();

  const { updateTiredeliveriesWeights, denyTiredeliveriesWeights } =
    UseUpdateTiredeliveriesWeights();

  async function handleFileSelect(fileObject: File) {
    // 1. Upload File To S3 and get FileRepo Record Id
    appStore.setBusy();
    if (dirty) {
      submitForm();
    }
    const { fileId, s3Key } = await uploadFile(fileObject);
    if (fileId) toastSuccess(`${t('File Uploaded Succesfully')} (id=${fileId})`);
    else {
      toastFailure(t('Failed to upload file'));
      return;
    }
    // 2. Update TireDeliveries_Files ()
    // 2.a get tireDeliveriesFileId
    const tireDeliveriesFileId = await findTireDeliveriesFile(
      deliveryId,
      appStore.isCollector ? DocumentTypes.WeighingNote : DocumentTypes.WeightNoteValoriser,
    );
    console.log('tireDeliveriesFileId', tireDeliveriesFileId);

    // 3. OCR
    const fileName = fileObject.name;
    const s3FileName = s3Key;
    if (!['jpg', 'jpeg', 'png', 'pdf'].includes(getFileExtension(fileName))) {
      appStore.clearBusy();
      toastFailure(t('ERROR! Only Images of type jpeg, png or pdf can be read by OCR'));
      return;
    }
    const { lines, words } = await doOcrFile(s3FileName);
    if (!lines) {
      appStore.clearBusy();
      toastFailure(t('ERROR! Cannot recognize the weighing note context'));
      return;
    }
    const readings = parseWeighingNoteTextractLines(lines);
    console.log('$$$ OCR $$$', fileName, readings);

    // 4. Popup
    const readingsFields = parseValuesFromStrings(readings);
    console.log('$$$ OCR $$$ readingsFields', readingsFields);
    appStore.clearBusy();
    const weighingFields = await showWeighingNoteReadingsForm({
      weighingReadings: readings,
      weighingValues: readingsFields,
    });
    if (!weighingFields) return;
    console.log('weighingFields', weighingFields);

    // 5. Save Weights

    const modifyValue: any = (weight) => {
      return weight === null || weight === '' ? null : parseInt(weight);
    };

    if (
      (statusId === TireDeliveriesStatus.UnconfirmedCol && appStore.isValoriser) ||
      (statusId === TireDeliveriesStatus.UnconfirmedVal && appStore.isCollector)
    ) {
      // Check if Same Weights
      const isSameWeights =
        (appStore.isCollector &&
          format(parseISO(data.valoriserDeliveredDate), 'dd/MM/yyyy') ===
            format(weighingFields.deliveredDate, 'dd/MM/yyyy') &&
          data.valoriserWeighingNoteNo === weighingFields.weighingNoteNo &&
          data.valoriserGrossWeight === modifyValue(weighingFields.grossWeight) &&
          data.valoriserTareWeight === modifyValue(weighingFields.tareWeight) &&
          data.valoriserDeductWeight === modifyValue(weighingFields.deductWeight) &&
          data.valoriserNetWeight === modifyValue(weighingFields.netWeight)) ||
        (appStore.isValoriser &&
          format(parseISO(data.collectorDeliveredDate), 'dd/MM/yyyy') ===
            format(weighingFields.deliveredDate, 'dd/MM/yyyy') &&
          data.collectorWeighingNoteNo === weighingFields.weighingNoteNo &&
          data.collectorGrossWeight === modifyValue(weighingFields.grossWeight) &&
          data.collectorTareWeight === modifyValue(weighingFields.tareWeight) &&
          data.collectorDeductWeight === modifyValue(weighingFields.deductWeight) &&
          data.collectorNetWeight === modifyValue(weighingFields.netWeight));
      await denyTiredeliveriesWeights(deliveryId, weighingFields as IWeighingValues, isSameWeights)
        .catch((err) => {
          toastFailure(err);
        })
        .then(() => {
          toastSuccess(t('Successfully entered weights'));
        });
    } else {
      await updateTiredeliveriesWeights(deliveryId, weighingFields as IWeighingValues)
        .catch((err) => {
          toastFailure(err);
        })
        .then(() => {
          toastSuccess(t('Successfully entered weights'));
        });
    }

    // 2.b mutate tireDeliveriesFileId
    await updateTiredeliveriesFile(tireDeliveriesFileId, fileId);

    onClick(); // call parent
  }

  const w0 = {
    noteType: 'undefined',
    noteNumber: 'W123',
    date: '01.02.22',
    tare: '10.5',
    gross: '21',
    net: '10,5',
  };
  const wTara2 = {
    date: '25/05/22 08:45:31',
    gross: '26060 kg',
    net: '11380 kg',
    noteNumber: '262656',
    noteType: 'ROMCIM SA MEDGIDIA',
    tare: '14680 kg',
  };
  const w = wTara2;
  const wV = parseValuesFromStrings(w);

  return (
    <>
      <FilePicker
        onChange={(fileObject) => handleFileSelect(fileObject)}
        onError={(errMsg) => {
          console.log(errMsg);
          toastFailure(errMsg);
        }}
      >
        {statusId === TireDeliveriesStatus.UnconfirmedCol ||
        statusId === TireDeliveriesStatus.UnconfirmedVal ? (
          <Button disabled={disabled} variant="warning" className="ml-3 mr-4">
            <FaFolderOpen className="mb-1 mr-3" />
            <span>{t('Re-enter Weights')}</span>
          </Button>
        ) : (
          <Button disabled={disabled} variant="warning" className="ml-3 mr-4">
            <FaFolderOpen className="mb-1 mr-3" />
            <span>{t('Upload Weighing Note')}</span>
          </Button>
        )}
      </FilePicker>
      {/* Test Button */}
      {/* <Button
        variant="dark"
        size="lg"
        onClick={async () => {
          const ret = await showWeighingNoteReadingsForm({
            weighingReadings: w,
            weighingValues: wV,
          });
          console.log('$$$$ Modal Results', ret);
        }}
        className="ml-4 mr-4"
      >
        <FaBug className="mb-1 mr-3" />
        Test
      </Button> */}
    </>
  );
};

export const WeighingNoteButtonWOFormik = ({
  deliveryId,
  onClick,
  statusId = TireDeliveriesStatus.Approved,
  disabled = false,
  data = null,
}) => {
  //
  const { appStore } = useRootStore();
  const { t } = useTranslation();

  const [uploadStatus, setUploadStatus] = useState(-1);
  const { uploadFile } = UseUploadToS3(setUploadStatus);
  const { updateTiredeliveriesFile } = UseUpdateTiredeliveriesFile();

  const { findTireDeliveriesFile } = UseFindTireDeliveriesFile();

  const { updateTiredeliveriesWeights, denyTiredeliveriesWeights } =
    UseUpdateTiredeliveriesWeights();

  async function handleFileSelect(fileObject: File) {
    // 1. Upload File To S3 and get FileRepo Record Id
    appStore.setBusy();
    const { fileId, s3Key } = await uploadFile(fileObject);
    if (fileId) toastSuccess(`${t('File Uploaded Succesfully')} (id=${fileId})`);
    else {
      toastFailure(t('Failed to upload file'));
      return;
    }
    // 2. Update TireDeliveries_Files ()
    // 2.a get tireDeliveriesFileId
    const tireDeliveriesFileId = await findTireDeliveriesFile(
      deliveryId,
      appStore.isCollector ? DocumentTypes.WeighingNote : DocumentTypes.WeightNoteValoriser,
    );
    console.log('tireDeliveriesFileId', tireDeliveriesFileId);

    // 3. OCR
    const fileName = fileObject.name;
    const s3FileName = s3Key;
    if (!['jpg', 'jpeg', 'png', 'pdf'].includes(getFileExtension(fileName))) {
      appStore.clearBusy();
      toastFailure(t('ERROR! Only Images of type jpeg, png or pdf can be read by OCR'));
      return;
    }
    const { lines, words } = await doOcrFile(s3FileName);
    if (!lines) {
      appStore.clearBusy();
      toastFailure(t('ERROR! Cannot recognize the weighing note context'));
      return;
    }
    const readings = parseWeighingNoteTextractLines(lines);
    console.log('$$$ OCR $$$', fileName, readings);

    // 4. Popup
    const readingsFields = parseValuesFromStrings(readings);
    console.log('$$$ OCR $$$ readingsFields', readingsFields);
    appStore.clearBusy();
    const weighingFields = await showWeighingNoteReadingsForm({
      weighingReadings: readings,
      weighingValues: readingsFields,
    });
    if (!weighingFields) return;
    console.log('weighingFields', weighingFields);

    // 5. Save Weights

    const modifyValue: any = (weight) => {
      return weight === null || weight === '' ? null : parseInt(weight);
    };

    if (
      statusId === TireDeliveriesStatus.UnconfirmedCol ||
      statusId === TireDeliveriesStatus.UnconfirmedVal
    ) {
      // Check if Same Weights
      const isSameWeights =
        (appStore.isCollector &&
          format(parseISO(data.valoriserDeliveredDate), 'dd/MM/yyyy HH:mm') ===
            format(weighingFields.deliveredDate, 'dd/MM/yyyy HH:mm') &&
          data.valoriserWeighingNoteNo === weighingFields.weighingNoteNo &&
          data.valoriserGrossWeight === modifyValue(weighingFields.grossWeight) &&
          data.valoriserTareWeight === modifyValue(weighingFields.tareWeight) &&
          data.valoriserDeductWeight === modifyValue(weighingFields.deductWeight) &&
          data.valoriserNetWeight === modifyValue(weighingFields.netWeight)) ||
        (appStore.isValoriser &&
          format(parseISO(data.collectorDeliveredDate), 'dd/MM/yyyy HH:mm') ===
            format(weighingFields.deliveredDate, 'dd/MM/yyyy HH:mm') &&
          data.collectorWeighingNoteNo === weighingFields.weighingNoteNo &&
          data.collectorGrossWeight === modifyValue(weighingFields.grossWeight) &&
          data.collectorTareWeight === modifyValue(weighingFields.tareWeight) &&
          data.collectorDeductWeight === modifyValue(weighingFields.deductWeight) &&
          data.collectorNetWeight === modifyValue(weighingFields.netWeight));
      await denyTiredeliveriesWeights(deliveryId, weighingFields as IWeighingValues, isSameWeights)
        .catch((err) => {
          toastFailure(err);
        })
        .then(() => {
          toastSuccess(t('Successfully entered weights'));
        });
    } else {
      await updateTiredeliveriesWeights(deliveryId, weighingFields as IWeighingValues)
        .catch((err) => {
          toastFailure(err);
        })
        .then(() => {
          toastSuccess(t('Successfully entered weights'));
        });
    }

    // 2.b mutate tireDeliveriesFileId
    await updateTiredeliveriesFile(tireDeliveriesFileId, fileId);

    //toastSuccess(t('Successfully entered weights'));
    onClick(); // call parent
  }

  const w0 = {
    noteType: 'undefined',
    noteNumber: 'W123',
    date: '01.02.22',
    tare: '10.5',
    gross: '21',
    net: '10,5',
  };
  const wTara2 = {
    date: '25/05/22 08:45:31',
    gross: '26060 kg',
    net: '11380 kg',
    noteNumber: '262656',
    noteType: 'ROMCIM SA MEDGIDIA',
    tare: '14680 kg',
  };
  const w = wTara2;
  const wV = parseValuesFromStrings(w);

  return (
    <>
      <FilePicker
        onChange={(fileObject) => handleFileSelect(fileObject)}
        onError={(errMsg) => {
          console.log(errMsg);
          toastFailure(errMsg);
        }}
      >
        {statusId === TireDeliveriesStatus.UnconfirmedCol ||
        statusId === TireDeliveriesStatus.UnconfirmedVal ? (
          <Button disabled={disabled} variant="warning" className="ml-3 mr-4">
            <FaFolderOpen className="mb-1 mr-3" />
            <span>{t('Re-enter Weights')}</span>
          </Button>
        ) : (
          <Button disabled={disabled} variant="warning" className="ml-3 mr-4">
            <FaFolderOpen className="mb-1 mr-3" />
            <span>{t('Upload Weighing Note')}</span>
          </Button>
        )}
      </FilePicker>
      {/* Test Button */}
      {/* <Button
        variant="dark"
        size="lg"
        onClick={async () => {
          const ret = await showWeighingNoteReadingsForm({
            weighingReadings: w,
            weighingValues: wV,
          });
          console.log('$$$$ Modal Results', ret);
        }}
        className="ml-4 mr-4"
      >
        <FaBug className="mb-1 mr-3" />
        Test
      </Button> */}
    </>
  );
};
