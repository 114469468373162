import React from 'react';
import { Row } from 'react-bootstrap';
import {
  FormCol,
  FormFilterCard,
  FieldInput,
  FieldSelectIsActive,
  FieldSelectYesNo,
} from 'motion-components';
import { useTranslation } from 'react-i18next';
import {
  FieldSelectCollectionpointCategory,
  FieldSelectCollectionpointType,
  FieldSelectCounty,
  FieldSelectRegion,
} from 'components';
import { useField } from 'formik';

/* ----------------------- Διαχείριση Φίλτρων ------------------------------ */
export function buildWhereFromFilterValues(values): string {
  // Φτιάξε το where για το graphql
  let where = {};
  if (values) {
    if (values.searchText) {
      const searchText = '%' + values.searchText + '%';
      const likeObj = { $like: searchText };
      where = {
        ...where,
        $or: [
          { name: likeObj },
          { vatin: likeObj },
          { address: likeObj },
          { phone: likeObj },
          { mobile: likeObj },
        ],
      };
    }
    if (values.isActive !== undefined && values.isActive !== '') {
      where = { ...where, isActive: values.isActive };
    }
    if (values.needsConfirmation !== undefined && values.needsConfirmation !== '') {
      if (values.needsConfirmation)
        where = { ...where, needsConfirmation: values.needsConfirmation };
      else where = { ...where, needsConfirmation: { $or: [null, values.needsConfirmation] } };
    }
    if (values.isBlackListed !== undefined && values.isBlackListed !== '') {
      where = { ...where, isBlackListed: values.isBlackListed };
    }
    if (values.categoryId) where = { ...where, categoryId: values.categoryId };
    if (values.typeId) where = { ...where, typeId: values.typeId };

    // filter alias (regionId)
    if (values.regionId) where = { ...where, regionId: values.regionId };

    // County Multi Select
    if (values.countyId && Array.isArray(values.countyId) && values.countyId.length > 0) {
      const countyIds = values.countyId.map((v) => v.value);
      where = { ...where, countyId: countyIds };
    }
  }
  return JSON.stringify(where);
}

/* ----------------------- Filter Form ------------------------------ */
export const Filters = ({ onChange, initialValues }) => {
  const { t } = useTranslation();
  //
  function handleFiltersSubmit(values, { setSubmitting }) {
    setTimeout(() => {
      setSubmitting(false);
      onChange(values);
    }, 400);
  }
  //console.log("filters-init ", initialValues);

  const filters = {
    searchText: initialValues?.searchText ?? '',
    isActive: initialValues?.isActive ?? undefined,
    needsConfirmation: initialValues?.needsConfirmation ?? undefined,
    isBlackListed: initialValues?.isBlackListed ?? undefined,
    categoryId: initialValues?.categoryId ?? null,
    typeId: initialValues?.typeId ?? null,
    regionId: initialValues?.regionId ?? undefined,
    countyId: initialValues?.countyId ?? [],
  };

  return (
    <FormFilterCard
      title={t('Filter Criteria')}
      onSubmit={handleFiltersSubmit}
      initialValues={filters}
    >
      <FilterFields />
    </FormFilterCard>
  );
};

export const FilterFields = () => {
  const { t } = useTranslation();
  const [field, { value }] = useField('regionId');
  return (
    <>
      <Row>
        <FieldInput
          as={FormCol}
          name="searchText"
          label={t('Search (Name, VAT Number, Address, Phone)')}
          type="search"
        />
        <FieldSelectCollectionpointType label={t('Type')} as={FormCol} />
        <FieldSelectRegion label={t('Region')} as={FormCol} />
        <FieldSelectYesNo name="needsConfirmation" label={t('Needs Confirmation')} />
      </Row>
      <Row>
        <FieldSelectYesNo name="isBlackListed" label={t('Blacklisted')} />
        <FieldSelectCollectionpointCategory label={t('Category')} as={FormCol} />
        <FieldSelectCounty label={t('County')} as={FormCol} isMulti={true} regionId={value} />
        <FieldSelectIsActive label={t('Active Records')} />
      </Row>
    </>
  );
};
