import { JwtToken } from './../shared/jwt';
import { observable, action, configure, computed } from 'mobx';
import { persist } from 'mobx-persist';

import { log } from 'services';
import { parseISO, differenceInSeconds } from 'date-fns';
import { jwtToken, userHasCPElevatedPerms, userIsAdmin, userIsUserAdmin } from 'shared/authorizations';
import { decodeJwtToken } from 'shared/jwt';
import { UserTypeValues } from 'shared/constants';

configure({ enforceActions: 'always' }); // strict mode

const initialUserLogin: any = {
  expirationDate: '',
  jwt: null,
  userId: null,
  userFirstName: '',
  userLastName: '',
  userMustChangePassword: false,
};

//------------------------------------------------------
// *    --- Functions ---
//

// *    --- Store Class ---
//
export class AppStore {
  @observable initialized = false;
  @persist('object') @observable userLogin = { ...initialUserLogin };
  @persist @observable user = '';
  @persist @observable pageSize = 10;
  @observable jwtExpireLeft = 0;
  @action setPageSize(n: number): void {
    if (n > 0) this.pageSize = n;
  }

  /**
   * Global Busy Logic
   */
  @observable private busyCnt = 0;
  @observable private busyReg = false;
  @observable private progressCnt = 0;
  @observable private progressTitleString = '';

  @computed get progressTitle(): string {
    return this.progressTitleString;
  }
  @computed get busy(): boolean {
    return this.busyCnt > 0 || this.busyReg;
  }
  @computed get progress(): number {
    return Math.ceil(this.progressCnt);
  }
  @action regBusy(value: boolean): void {
    //log.log('[AppStore]: regBusy +', value);
    this.busyReg = value;
  }
  @action setBusy(): void {
    this.busyCnt++;
    //log.log('[AppStore]: Busy +', this.busyCnt);
  }
  @action setProgress(value: number): void {
    this.progressCnt = value;
    //log.log('[AppStore]: Busy +', this.busyCnt);
  }
  @action clearBusy(): void {
    this.busyCnt--;
    //log.log('[AppStore]: Busy -', this.busyCnt);
  }
  @action clearProgress(): void {
    this.progressCnt = 0;
    //log.log('[AppStore]: Busy -', this.busyCnt);
  }

  // *
  // * --------------- Getters -------------------------------
  @computed get isLogIn(): boolean {
    return !!this.userLogin.jwt;
  }

  buildContext = function (): any {
    return { jwtToken: decodeJwtToken(this.userLogin.jwt) };
  };

  @computed get isAdmin(): boolean {
    const context: any = this.buildContext();
    const val = userIsAdmin(context);
    return val && val.valueOf();
  }

  @computed get isUserAdmin(): boolean {
    const context: any = this.buildContext();
    const val = userIsUserAdmin(context);
    return val && val.valueOf();
  }

  @computed get hasCPElevatedPerms(): boolean {
    const context: any = this.buildContext();
    const val = userHasCPElevatedPerms(context);
    return val && val.valueOf();
  }

  @computed get usertypeId(): number {
    const context: any = this.buildContext();
    const jwt = jwtToken(context);

    return jwt.userTypeId;
  }

  @computed get isEcoanvelope(): boolean {
    return this.usertypeId === UserTypeValues.Ecoanvelope;
  }

  @computed get isCollector(): boolean {
    return this.usertypeId === UserTypeValues.Collector;
  }

  @computed get isSubcontractor(): boolean {
    const context: any = this.buildContext();
    const jwt = jwtToken(context);

    return jwt.isSubcontractor;
  }

  @computed get isCollectorDriver(): boolean {
    return this.usertypeId === UserTypeValues.CollectorDriver;
  }

  @computed get isCollectionPoint(): boolean {
    return this.usertypeId === UserTypeValues.CollectionPoint;
  }

  @computed get isValoriser(): boolean {
    return this.usertypeId === UserTypeValues.Valoriser;
  }

  @computed get userRolesIds(): Array<any> {
    const jwt = decodeJwtToken(this.userLogin.jwt);
    return jwt.userRolesIds;
  }

  @computed get isMultiValoriser(): boolean {
    const jwt = decodeJwtToken(this.userLogin.jwt);
    return !!jwt.userValorisers && jwt.userValorisers.length > 0;
  }

  @computed get associatedId(): number {
    const jwt = decodeJwtToken(this.userLogin.jwt);
    return jwt.associatedId;
  }

  @computed get isBlacklisted(): boolean {
    const jwt = decodeJwtToken(this.userLogin.jwt);
    return jwt.isBlacklisted;
  }

  @computed get collectorId(): number {
    if (this.isCollector) return this.associatedId;
    else return undefined;
  }

  @computed get collectionpointId(): number {
    if (this.isCollectionPoint) return this.associatedId;
    else return undefined;
  }

  @computed get valoriserId(): number {
    if (this.isValoriser) return this.associatedId;
    else return undefined;
  }

  @computed get userFullName(): string {
    const jwt = decodeJwtToken(this.userLogin.jwt);
    return jwt.userFullName;
  }

  @computed get calcJwtExpireLeft(): number {
    if (!this.isLogIn) return 0;
    return differenceInSeconds(parseISO(this.userLogin.expirationDate), new Date());
  }
  // * --------------- Methods -------------------------------

  @action setProgressTitle(value: string): void {
    this.progressTitleString = value;
    //log.log('[AppStore]: Busy +', this.busyCnt);
  }
  @action clearProgressTitle(): void {
    this.progressTitleString = '';
    //log.log('[AppStore]: Busy -', this.busyCnt);
  }

  @action login(user: string, userData: any): void {
    this.user = user;
    this.userLogin = { ...initialUserLogin, ...userData };
    // Save to SessionStorage
    const dt = new Date(Date.now());
    sessionStorage.setItem('date', dt.toLocaleDateString() + ' ' + dt.toLocaleTimeString());
  }

  @action logOut(): void {
    this.user = '';
    this.userLogin = { ...initialUserLogin };
  }

  @action private setJwtExpiredLeft(): void {
    this.jwtExpireLeft = this.calcJwtExpireLeft;
    if (this.jwtExpireLeft < 0) this.logOut();
  }

  @action initialize(): void {
    this.setJwtExpiredLeft();
    this.initialized = true;
  }

  constructor() {
    this.init();
  }

  @action private init() {
    log.log(`[AppStore] init`);
    console.log(`[AppStore] init values= `, this.user, this.userLogin);
    this.user = '';
    this.userLogin = { ...initialUserLogin };
    // setInterval(() => {
    //   this.setJwtExpiredLeft();
    // }, 1000);
  }
}
