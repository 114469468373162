import React, { useEffect, useState } from 'react';
import { getFileUrl, useGetFileUrl } from 'common/db-help-func';
import { useApolloClient } from '@apollo/client';
//import { Col, Image } from 'react-bootstrap';
import Image from 'react-image-enlarger';

/*
export const FileRepoImage = ({ fileId, ...rest }) => {
  const client = useApolloClient();
  // Signature Url
  const [url, setUrl] = useState('');
  useEffect(() => {
    if (fileId) {
      getFileUrl(client, fileId).then(result => {
        setUrl(result);
      });
    }
  }, [client, fileId]);

  return <Image src={url} {...rest} />;
};
*/
export const FileRepoImage = ({
  fileId,
  maxWidth = '100%',
  maxHeight = '4rem',
  minHeight = null,
  ...rest
}) => {
  const url = useGetFileUrl(fileId);

  const [zoomed, setZoomed] = useState(false);

  return (
    <Image
      // style={{ maxWidth, maxHeight, objectFit: 'contain', objectPosition: '0% 0%' }}
      style={{ maxWidth, maxHeight, objectFit: 'contain', objectPosition: '0% 0%', minHeight }}
      zoomed={zoomed}
      src={url}
      onClick={() => setZoomed(true)}
      onRequestClose={() => setZoomed(false)}
      {...rest}
    />
  );
};
