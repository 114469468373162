import React from 'react';
import { Row } from 'react-bootstrap';
import {
  buildWhereForDates,
  FormCol,
  FormFilterCard,
  FieldInput,
  FieldDateRanger,
  FieldSelectIsActive,
} from 'motion-components';
import { useTranslation } from 'react-i18next';
import { FieldSelectRegion } from 'components';
import { FieldSelectReportFrequency } from 'components/fs-report-frequency';

/* ----------------------- Διαχείριση Φίλτρων ------------------------------ */
export function buildWhereFromFilterValues(values): string {
  // Φτιάξε το where για το graphql
  let where = {};
  if (values) {
    if (values.isActive !== undefined && values.isActive !== '') {
      where = { ...where, isActive: values.isActive };
    }
    if (values.frequencyId) where = { ...where, frequencyId: values.frequencyId };
  }
  return JSON.stringify(where);
}

/* ----------------------- Filter Form ------------------------------ */
export const Filters = ({ onChange, initialValues }) => {
  const { t } = useTranslation();
  //
  function handleFiltersSubmit(values, { setSubmitting }) {
    setTimeout(() => {
      setSubmitting(false);
      onChange(values);
    }, 400);
  }
  //console.log("filters-init ", initialValues);

  const filters = {
    isActive: initialValues?.isActive ?? undefined,
    frequencyId: initialValues?.frequencyId ?? null,
  };

  return (
    <FormFilterCard
      title={t('Filter Criteria')}
      onSubmit={handleFiltersSubmit}
      initialValues={filters}
    >
      <Row>
        <FieldSelectReportFrequency label={t('Frequency')} as={FormCol} />
        <FieldSelectIsActive label={t('Active Records')} />
      </Row>
    </FormFilterCard>
  );
};
