import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery, useApolloClient } from '@apollo/client';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import {
  FormCard,
  YupNumeric,
  YupDate,
  YupString,
  YupBoolean,
  promiseModalDialog,
  UserTooltip,
  objParseInt,
  objParseFloat,
  FormCol4,
  FormCol6,
  YupDateReq,
  YupNumericReq,
} from 'motion-components';
import * as Yup from 'yup';
import * as Lo from 'lodash';
import { toastFailure, toastSuccess, toastWarning } from 'utils/toasts';
import { useHandleError } from 'services';
import {
  gqlGetByIdQuery,
  gqlAdd,
  gqlUpdate,
  gqlDelete,
  FormInput,
  gqlGetCollectionpointById,
} from '../graphql';
import { isNumeric } from 'utils/helpers';
import { useDb } from 'services/db-service';
import { FormFields } from './form-fields';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';
import { getRouteByName } from 'app/routes';
import { gqlGetQuerySimple } from 'pages/tire_types/graphql';
import _ from 'lodash';
import { getTirecollectionStatusBadge } from 'common/badges-helpfunc';
import { TirecollectionFilesSection } from './files-section';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { FaStarOfLife } from 'react-icons/fa';
import { triggermailservice } from 'services/triggermailservice';
import { TirecollectionStatus } from 'shared/constants';
import { DeclarePointMissingButton } from './declarePointMissing-button';
import { CancelCollectionOrderButton } from './cancelOrder-button';
import { PrintLoadUnloadNoteButton } from './print-notes/print-loadUnload-button';
import { NewTirecollectionFilesSection } from './createnew-files-section';
import { parseISO } from 'date-fns';

export const TirecollectionFormPage = () => {
  const { t } = useTranslation();
  const location = useLocation<any>();
  const { handleGraphqlError } = useHandleError('TirecollectionsFormPage');
  const { appStore } = useRootStore();
  const { canAddTireColRequest } = useDb();
  const [files, setFiles] = useState<Array<any>>([]); //put fileId properties here after getFiles
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const idNum = isNumeric(id) ? parseInt(id) : -1;
  const isNew = idNum < 0;

  const formShape = {
    id: YupNumeric,
    statusId: YupNumeric,
    collectionpointId: YupNumeric,
    collectorId: appStore.isCollectionPoint ? YupNumericReq : YupNumeric,
    platformId: appStore.isCollector && isNew ? YupNumericReq : YupNumeric,
    requestDate: YupDate,
    collectionDate: appStore.isCollector && isNew ? YupDateReq : YupDate,
    isActive: YupBoolean,
    firstDate: YupDate,
    lastDate: YupDate,
    firstUser: YupString,
    lastUser: YupString,
    colDocNum: YupString,
    orderNotes: YupString,
    orderedBy: YupString,
    ordEnteredBy: YupString,
    vehPlateNo: YupString,
    ordEnteredByUsertypeId: YupNumeric,
    collectionNotes: YupString,
    colEnteredBy: YupString,
    colEnteredByUsertypeId: YupNumeric,
    collectionpoint: null,
    tirecollectionsQty: null,
    tirecollectionFiles: null,
  };

  const validationSchema = Yup.object().shape(formShape);
  const pickProperties = (values) => Lo.pick(values, Lo.keys(formShape));
  type FormValuesType = Yup.InferType<typeof validationSchema>;
  const [getCp, { data: rawDataCP }] = useLazyQuery(gqlGetCollectionpointById, {
    fetchPolicy: 'network-only',
  });
  const dataCP = rawDataCP ? rawDataCP[Object.keys(rawDataCP)[0]] : undefined;

  /* fetch data */
  useEffect(() => {
    if (!!isNew) getCp({ variables: { id: location.state.collectionPointId } });
  }, [getCp, isNew, location.state.collectionPointId]);

  const newRecord = useMemo<FormValuesType>(
    () =>
      ({
        id: -1,
        collectionpointId: location.state.collectionPointId,
        collectorId: null,
        platformId: null,
        requestDate: new Date(),
        orderNotes: null,
        orderedBy: null,
        collectionpoint: !!dataCP ? dataCP : null,
        tirecollectionsQty: null,
      } as FormValuesType),
    [dataCP, location.state.collectionPointId],
  );

  const [getTireTypes, { data: rawDataTT, loading: loadingTT }] = useLazyQuery(gqlGetQuerySimple, {
    fetchPolicy: 'network-only',
    variables: {
      order: 'sortOrder ASC',
      where: JSON.stringify({
        isActive: true,
      }),
    },
  });
  const dataTT = rawDataTT ? rawDataTT.getTireTypes.data : undefined;

  useEffect(() => {
    getTireTypes();
  }, [getTireTypes, isNew]);

  const modifiedDataTireTypes = (dataQty, dataTireTypes) => {
    const modifiedArray = [];
    dataTireTypes.map((dataTT) => {
      const index = _.findIndex(dataQty, ['tiretypeId', dataTT.id]);
      if (index !== -1) {
        modifiedArray.push({
          id: dataQty[index].id,
          expectedPieces: dataQty[index].expectedPieces,
          pieces: dataQty[index].pieces,
          tireTypeId: dataTT.id,
        });
      } else {
        modifiedArray.push({ id: -1, tireTypeId: dataTT.id });
      }
    });

    return modifiedArray;
  };

  const [addRecord] = useMutation(gqlAdd);
  const [updateRecord] = useMutation(gqlUpdate);
  const [deleteRecord] = useMutation(gqlDelete);
  const [get, { data: rawData, error, refetch }] = useLazyQuery(gqlGetByIdQuery, {
    fetchPolicy: 'network-only',
  });
  const data = isNew
    ? newRecord
    : rawData && rawDataTT
    ? {
        ...rawData[Object.keys(rawData)[0]],
        tirecollectionsQty: modifiedDataTireTypes(
          rawData[Object.keys(rawData)[0]].tirecollectionsQty,
          dataTT,
        ),
      }
    : undefined;

  /* fetch data */
  useEffect(() => {
    if (!isNew) get({ variables: { id: idNum } });
  }, [get, id, idNum, isNew]);

  /**
   * * ΑΠΟΘΗΚΕΥΣΗ - UPDATE/CREATE
   */
  const handleSubmit = async (values: FormValuesType, actions) => {
    actions.setSubmitting(false);
    let mutValues = Lo.pick(values, Lo.keys(new FormInput()));
    mutValues = objParseInt(
      ['id', 'statusId', 'collectionpointId', 'collectorId', 'platformId'],
      mutValues,
    );
    mutValues = objParseFloat([], mutValues);
    const result = await canAddTireColRequest(mutValues.collectionpointId).catch((error) => {
      toastFailure(t(`Error while checking Collection Requests`));
    });

    // Αν είναι νέα εγγραφή τοτε γράφω όλα τα πεδία
    if (isNew) {
      if (result.canAdd || appStore.isCollector) {
        console.log('collectionDate= ', mutValues.collectionDate);
        if (mutValues.collectionDate > new Date()) {
          toastWarning(t('Collection Date is invalid!'));
          return;
        }
        const tireQty: any = mutValues.tirecollectionsQty;
        if (
          tireQty === null ||
          (tireQty.length > 0 && tireQty.find((rec) => rec?.expectedPieces > 0) === undefined)
        ) {
          toastWarning(t('You must fill in the quantity for at least one of the Tire Types!'));
          return;
        }
        if (
          appStore.isCollector &&
          (files === null ||
            (files.length > 0 &&
              files.find((rec) => rec.id === 1 && rec.fileId === null) !== undefined))
        ) {
          toastWarning(t('You must upload the Loading-Unloading document!'));
          return;
        }
        console.log('[Tirecollections] New:', mutValues);
        const filesToAdd = [];
        files.map((file) => {
          if (file.fileId) {
            filesToAdd.push({ documenttypeId: file.id, fileId: file.fileId });
          }
        });
        addRecord({
          variables: {
            data: {
              ...mutValues,
              tirecollectionFiles: appStore.isCollector ? filesToAdd : undefined,
            },
          },
        })
          .then((d) => {
            const id = d.data[Object.keys(d.data)[0]]?.id;
            toastSuccess(`${t('Record Created Successfully')} [${id}]`);
            history.push({
              pathname: generatePath(getRouteByName('Tirecollection'), { id: id }),
              state: {
                pathname: location.pathname,
                ...location.state,
              },
            });
            // Send Mails From Repo (triggermailservice)
            triggermailservice();
          })
          .catch((error) => {
            handleGraphqlError('mutation-add', error);
          });
      } else {
        toastWarning(t('You have a pending Tire Collection Order!'));
        history.push({
          pathname: generatePath(getRouteByName('Tirecollections')),
          state: {
            pathname: location.pathname,
            ...location.state,
          },
        });
      }
    } else {
      // Αν είναι update εγγραφής τοτε γράφω μονο τα αλλαγμένα πεδία
      console.log('[Tirecollections] Update:', mutValues);
      const tireQty: any = mutValues.tirecollectionsQty;
      if (
        tireQty === null ||
        (tireQty.length > 0 && tireQty.find((rec) => rec?.pieces > 0) === undefined)
      ) {
        toastWarning(t('You must fill in the quantity for at least one of the Tire Types!'));
        return;
      }
      if (
        appStore.isCollector &&
        files.find((rec) => rec.documenttypeId === 1 && rec.fileId === null) !== undefined
      ) {
        toastWarning(t('You must upload the Loading-Unloading document and the Truck Photo!'));
        return;
      }

      updateRecord({ variables: { data: { ...mutValues, id: idNum } } })
        .then((d) => {
          toastSuccess(
            `${t('Record Updated Successfully')} [${d.data[Object.keys(d.data)[0]].id}]`,
          );
          refetch();
        })
        .catch((error) => {
          handleGraphqlError('mutation-update', error);
        });
    }
  };

  /**
   * *  DELETE
   */
  const handleDelete = useCallback(async () => {
    const res = await promiseModalDialog({
      title: t('Delete Record'),
      text: t('The record will be permanently deleted. Do you want to continue?'),
    });
    if (res) {
      deleteRecord({ variables: { id: idNum } })
        .then(() => {
          toastSuccess(t('The record has been successfully deleted'));
          history.goBack();
        })
        .catch((error) => {
          handleGraphqlError('mutation-delete', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRecord, idNum]);

  const handleConfirm = () => {
    history.push({
      pathname: generatePath(getRouteByName('Collectionpoint'), { id: data.collectionpoint.id }),
      state: {
        pathname: location.pathname,
        ...location.state,
      },
    });
  };

  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <FormCard
        title={
          isNew ? (
            `${t(
              appStore.isCollectionPoint
                ? t('New Tire Collection Request')
                : t('New Tire Collection'),
            )}`
          ) : (
            <>
              <span>{`${t('Tire Collection')}: ${data?.orderNo}`}</span>
              &nbsp;&nbsp;
              <div
                className={
                  'badge pb-2 pt-1 mr-2 ' + getTirecollectionStatusBadge(data?.status?.value)
                }
              >
                {t(data?.status?.name)}
              </div>
            </>
          )
        }
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        onReturn={null}
        permitDelete={false}
        initialValues={pickProperties(data)}
        validationSchema={validationSchema}
        enableReinitialize
        idx={idNum}
        isNew={isNew}
        injectFooterElements={() => (
          <Row>
            {!isNew &&
              (appStore.isEcoanvelope || appStore.isCollector) &&
              data?.statusId !== TirecollectionStatus.CANCELLED && (
                <div style={{ marginTop: '5px' }}>
                  <CancelCollectionOrderButton
                    data={data}
                    onClick={refetch}
                  ></CancelCollectionOrderButton>
                </div>
              )}
            {!isNew && !!data && data?.statusId !== TirecollectionStatus.CANCELLED && (
              <div>
                <PrintLoadUnloadNoteButton
                  disabled={false}
                  tireCollectionId={data.id}
                ></PrintLoadUnloadNoteButton>
              </div>
            )}
          </Row>
        )}
      >
        {data && (
          <>
            {!!data?.collectionpoint?.needsConfirmation && !appStore.isCollectionPoint && (
              <Alert
                variant="warning"
                className="py-3 mb-2 d-flex flex-row justify-content-center align-items-baseline"
                style={{ color: '#595959' }}
              >
                <h6 className="m-0">
                  <FaStarOfLife size="1rem" className="pb-1 mr-4" />
                  {t(
                    'This Collection Point needs confirmation. Please go to its page to confirm its data',
                  )}
                  :
                </h6>
                <span className="mx-2"></span>
                <Button onClick={handleConfirm} variant="secondary">
                  {data.collectionpoint.name}
                </Button>
              </Alert>
            )}
            <FormFields
              isNew={isNew}
              updateRecord={updateRecord}
              refetch={refetch}
              data={data}
              dataTireTypes={dataTT}
            />
          </>
        )}

        {/* Files Section  */}
        <Col sm={12} lg={8} xl={8} style={{ marginTop: '.5rem' }}>
          {!isNew && data && (
            <TirecollectionFilesSection
              tirecollectionId={data.id}
              readOnly={
                /* (!appStore.isEcoanvelope && data.statusId !== TirecollectionStatus.PENDING) || */
                data.statusId === TirecollectionStatus.CANCELLED || appStore.isCollectionPoint
              }
              files={files}
              setFiles={setFiles}
            />
          )}
          {appStore.isCollector && isNew && (
            <NewTirecollectionFilesSection readOnly={false} files={files} setFiles={setFiles} />
          )}
        </Col>

        <div className="float-right">
          {!isNew && data && (
            <UserTooltip data={data} /* auditTable={'Tirecollections'} */ auditId={data.id} />
          )}
        </div>
      </FormCard>
    );
  }
};
