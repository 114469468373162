import React from 'react';
import { FieldSelectDB } from 'motion-components';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';

const gqlGet = gql`
  query getUserTypes($where: String, $offset: Int, $limit: Int, $order: String) {
    getUserTypes(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        id: value
        description: name
      }
      recordCount
    }
  }
`;

const FieldSelectUserType = ({
  label = 'User Type',
  name = 'usertypeId',
  excludeSystemUserRoles = false,
  defaultValues = {},
  ...rest
}: any) => {
  function useGet() {
    const options: any = { fetchPolicy: 'network-only' };
    if (excludeSystemUserRoles) options.variables = { where: JSON.stringify({ isSystem: 0 }) };
    return useLazyQuery(gqlGet, options);
  }

  return (
    <FieldSelectDB
      {...rest}
      label={label}
      name={name}
      sbLabel={(defaultValues as any)?.userType?.name ?? undefined}
      hook={useGet}
    />
  );
};

export { FieldSelectUserType };
