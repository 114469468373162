import React from 'react';
import { FieldSelectDB } from 'motion-components';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { FsProps } from 'motion-components';
import { t } from 'i18next';

const gqlGetValues = gql`
  query getValues($where: String, $offset: Int, $limit: Int, $order: String) {
    getValorisers(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        id
        description: name
      }
      recordCount
    }
  }
`;

function useGetValues() {
  return useLazyQuery(gqlGetValues, { fetchPolicy: 'cache-first' });
}

type Props = {
  isMulti?: boolean;
} & FsProps;

export const FieldSelectValoriser = ({
  label = t('Valoriser'),
  name = 'valoriserId',
  defaultValues = {},
  isMulti = false,
  ...rest
}: Props) => {
  return (
    <FieldSelectDB
      {...rest}
      label={label}
      name={name}
      // @ts-ignore
      sbLabel={defaultValues?.valoriser?.name ?? undefined}
      hook={useGetValues}
      isMulti={isMulti}
    />
  );
};
