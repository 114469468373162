import gql from 'graphql-tag';

export const gqlGetUsers = gql`
  query getUsers($where: String, $offset: Int, $limit: Int, $order: String) {
    getUsers(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        id
        userName
        lastName
        firstName
        email
        tel
        signatureFileId
        isActive
        usertypeId
        userType {
          id
          name
        }
        collectorId
        collector {
          id
          name
        }
        valoriserId
        isMultipleVal
        canSeeCol
        canSeeDel
        valoriser {
          id
          name
        }
        collectionpointId
        collectionpoint {
          id
          name
        }
        userUserroles {
          id
          userroleId
          isActive
          userRole {
            id
            name
            isSystem
          }
        }
        usersValorisers {
          userId
          valoriserId
          valoriser {
            id
            name
          }
        }
      }
      recordCount
    }
  }
`;

export const gqlGetUser = gql`
  query getUserById($id: Int) {
    getUserById(id: $id) {
      id
      usertypeId
      userType {
        id
        name
      }
      userName
      lastName
      firstName
      email
      tel
      mobile1
      position
      notes
      signatureFileId
      isActive
      firstDate
      lastDate
      firstUser
      lastUser
      signatureFileRec {
        id
        fileName
        mimeMediaType
      }
      collectorId
      collector {
        id
        name
      }
      valoriserId
      isMultipleVal
      canSeeCol
      canSeeDel
      valoriser {
        id
        name
      }
      collectionpointId
      collectionpoint {
        id
        name
      }
      userUserroles {
        id
        userroleId
        userId
        isActive
        userRole {
          id
          name
          isActive
          isSystem
        }
      }
      drvLicenseNumber
      drvLicenseExpDate
      drvLicenseTransport
      drvLicenseTransportExpDate
      drvBINumber
      drvIssuedBy
      drvTransporter
      defVehiclePlate
      defTrailerPlate
    }
  }
`;

export const gqlGetDriver = gql`
  query getUserById($id: Int) {
    getUserById(id: $id) {
      id
      usertypeId
      userName
      fullName
      drvLicenseNumber
      drvLicenseExpDate
      defVehiclePlate
      defTrailerPlate
      drvTransporter
      drvLicenseTransport
drvLicenseTransportExpDate
drvBINumber
drvIssuedBy
    }
  }
`;

export const gqlAddUser = gql`
  mutation addUser($data: UserInput) {
    addUser(data: $data) {
      id
    }
  }
`;

export const gqlUpdateUser = gql`
  mutation updateUser($data: UserInput) {
    updateUser(data: $data) {
      id
    }
  }
`;

export const gqlDeleteUser = gql`
  mutation deleteUser($id: Int) {
    deleteUser(id: $id)
  }
`;

export const gqlClearPassword = gql`
  mutation userClearPassword($userName: String!) {
    userClearPassword(userName: $userName)
  }
`;

export const gqlGetUsersValorisers = gql`
  query getUsersValorisers($where: String, $offset: Int, $limit: Int, $order: String) {
    getUsersValorisers(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        userId
        user {
          id
          userName
        }
        valoriserId
        valoriser {
          id
          name
        }
      }
      recordCount
    }
  }
`;

export const gqlAddUsersValoriser = gql`
  mutation addUsersValoriser($data: UsersValoriserInput) {
    addUsersValoriser(data: $data) {
      valoriserId
    }
  }
`;

export const gqlDeleteUsersValoriser = gql`
  mutation deleteUsersValoriser($userId: Int, $valoriserId: Int) {
    deleteUsersValoriser(userId: $userId, valoriserId: $valoriserId)
  }
`;

