import { NavLinkItem, ButtonLogout } from 'motion-components';
import React, { FC, useState } from 'react';
import { Col, Form, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { FaUser } from 'react-icons/fa';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { getRouteByName } from './routes';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';
import { MainMenu } from './menu-items';

type Props = {
  onLogoutClick: () => void;
};

const Header: FC<Props> = ({ onLogoutClick }) => {
  /*-------------------- Body -----------------------------------------------*/
  const { appStore, authStore } = useRootStore();
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const { t, i18n } = useTranslation();

  /*-------------------- Handlers -------------------------------------------*/

  const handleLogoutClick = () => {
    setExpanded(false);
    onLogoutClick();
  };

  const handleToggle = (state: boolean) => {
    setExpanded(state);
  };

  const handleSelect = () => {
    setExpanded(false);
  };

  const handleUserClick = () => {
    console.log('handleUserClick', appStore.userLogin);
    if (!appStore.isCollectionPoint)
      history.push(generatePath(getRouteByName('User'), { id: authStore.userId }));
  };
  /*-------------------- Render ---------------------------------------------*/
  return (
    <header>
      <Navbar
        expand="xl"
        bg="dark"
        variant="dark"
        fixed="top"
        onToggle={handleToggle}
        onSelect={handleSelect}
        expanded={expanded}
      >
        <Navbar.Brand as={Link} to={getRouteByName('Home')}>
          <img
            alt=""
            src={process.env.PUBLIC_URL + '/logo-white.png'}
            height="32"
            className="d-inline-block align-top mx-1"
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        {/* LOGGED IN  */}
        {appStore.isLogIn && (
          <Navbar.Collapse id="responsive-navbar-nav">
            <MainMenu />

            {/* LANGUAGES */}
            <Nav>
              <Col style={{ float: 'right' }}>
                <Navbar.Text style={{ marginTop: '6px' }} className="btn btn-link pr-1 py-0">
                  <div
                    style={{ color: 'white', opacity: i18n.language === 'en' ? 1 : 0.6 }}
                    onClick={() => {
                      i18n.changeLanguage('en');
                    }}
                  >
                    {'EN'}
                    <img
                      alt=""
                      src={process.env.PUBLIC_URL + '/ukFlagEmoji.png'}
                      height={'20'}
                      className="d-inline-block align-top mx-1"
                      style={{ marginTop: '-3px', paddingLeft: '1px' }}
                    />
                  </div>
                </Navbar.Text>

                <Navbar.Text style={{ marginTop: '6px' }} className="btn btn-link pr-3 py-0">
                  <div
                    style={{ color: 'white', opacity: i18n.language !== 'en' ? 1 : 0.6 }}
                    onClick={() => {
                      i18n.changeLanguage('ro');
                    }}
                  >
                    {'RO'}
                    <img
                      alt=""
                      src={process.env.PUBLIC_URL + '/romaniaFlagEmoji.png'}
                      height={'20'}
                      className="d-inline-block align-top mx-1"
                      style={{ marginTop: '-3px', paddingLeft: '1px' }}
                    />
                  </div>
                </Navbar.Text>
              </Col>
            </Nav>

            {/* User Name */}
            {
              <Nav>
                <Navbar.Text
                  className={appStore.isCollectionPoint ? 'pr-3 py-0' : 'btn btn-link pr-3 py-0'}
                  onClick={handleUserClick}
                >
                  <div className="d-flex flex-row justify-content-start align-items-center">
                    <FaUser size="1.6rem" className="pr-1" />
                    <div className="d-flex flex-column" style={{ maxWidth: 140, color: 'white' }}>
                      <small className="text-truncate text-left">{appStore.user}</small>
                      <small className="text-truncate text-left pb-2">
                        {appStore.userFullName}
                      </small>
                    </div>
                  </div>
                </Navbar.Text>
              </Nav>
            }
            <Form inline>{<ButtonLogout onClick={handleLogoutClick} />}</Form>
          </Navbar.Collapse>
        )}
        {/* NOT LOGGED IN  */}
        {!appStore.isLogIn && (
          <Navbar.Collapse id="responsive-navbar-nav">
            {/* LANGUAGES */}
            <Nav>
              <Col style={{ float: 'right' }}>
                <Navbar.Text style={{ marginTop: '6px' }} className="btn btn-link pr-1 py-0">
                  <div
                    style={{ color: 'white', opacity: i18n.language === 'en' ? 1 : 0.6 }}
                    onClick={() => {
                      i18n.changeLanguage('en');
                    }}
                  >
                    {'EN'}
                    <img
                      alt=""
                      src={process.env.PUBLIC_URL + '/ukFlagEmoji.png'}
                      height={'20'}
                      className="d-inline-block align-top mx-1"
                      style={{ marginTop: '-3px', paddingLeft: '1px' }}
                    />
                  </div>
                </Navbar.Text>

                <Navbar.Text style={{ marginTop: '6px' }} className="btn btn-link pr-3 py-0">
                  <div
                    style={{ color: 'white', opacity: i18n.language !== 'en' ? 1 : 0.6 }}
                    onClick={() => {
                      i18n.changeLanguage('ro');
                    }}
                  >
                    {'RO'}
                    <img
                      alt=""
                      src={process.env.PUBLIC_URL + '/romaniaFlagEmoji.png'}
                      height={'20'}
                      className="d-inline-block align-top mx-1"
                      style={{ marginTop: '-3px', paddingLeft: '1px' }}
                    />
                  </div>
                </Navbar.Text>
              </Col>
            </Nav>
          </Navbar.Collapse>
        )}
      </Navbar>
    </header>
  );
};

export default Header;
