import { useApolloClient, useQuery } from '@apollo/client';
import ModalFlex from 'components/ModalFlex';
import { saveAs } from 'file-saver';
import { useWhyDidYouUpdate } from 'hooks';
import _ from 'lodash';
import { gqlGetByIdQuery } from 'pages/tiredeliveries/graphql';
import React from 'react';
import { createModal } from 'react-modal-promise';
import { PDFDocument, StandardFonts } from 'pdf-lib';
import { format, parseISO } from 'date-fns';
import { getFileUrl, useGetFileUrl } from 'common/db-help-func';
import { wrap } from '../loadUnload/PrintReport.modal';
import fontkit from '@pdf-lib/fontkit';
//* ----- Report ----------------------------------------------------------------------------------------

enum TireTypesY {
  A = 440,
  B = 430,
  C = 420,
  D = 410,
  E = 400,
  F = 490,
}
export function getPosition(string, subString, index) {
  return string.split(subString, index).join(subString).length;
}

const addText = async (record, pdfDoc) => {
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  pdfDoc.registerFontkit(fontkit);
  const latoBytes = await fetch(process.env.PUBLIC_URL + '/fonts/RobotoCondensed-Regular.ttf').then(
    (res) => res.arrayBuffer(),
  );
  const latoBoldBytes = await fetch(
    process.env.PUBLIC_URL + '/fonts/RobotoCondensed-Bold.ttf',
  ).then((res) => res.arrayBuffer());

  const lato = await pdfDoc.embedFont(latoBytes);
  const latoBold = await pdfDoc.embedFont(latoBoldBytes);
  //Delivery Note Number
  if (
    !!record.deliveryNoteNo &&
    !record.deliveryNoteNo.substring(0, getPosition(record.deliveryNoteNo, ' ', 2))
  ) {
    !!record.deliveryNoteNo &&
      firstPage.drawText(record.deliveryNoteNo, {
        x: 254,
        y: 556,
        size: 9,
        font: lato,
      });
  } else {
    !!record.deliveryNoteNo &&
      firstPage.drawText(
        record.deliveryNoteNo.substring(0, getPosition(record.deliveryNoteNo, ' ', 2)),
        {
          x: 254,
          y: 556,
          size: 9,
          font: lato,
        },
      );
    !!record.deliveryNoteNo &&
      firstPage.drawText(
        record.deliveryNoteNo.substring(getPosition(record.deliveryNoteNo, ' ', 2) + 1),
        {
          x: 320,
          y: 556,
          size: 9,
          font: lato,
        },
      );
  }

  //Delivery Date
  !!record.plannedDeliveryDate &&
    firstPage.drawText(format(parseISO(record.plannedDeliveryDate), 'dd/MM/yyyy'), {
      x: 254,
      y: 537,
      size: 9,
      font: lato,
    });

  //Collector Name
  !!record.colName &&
    firstPage.drawText(record.colName, {
      x: 69,
      y: 714,
      size: 8,
      font: lato,
    });

  //Collector Platform
  !!record.colPlatName &&
    firstPage.drawText(record.colPlatName, {
      x: 69,
      y: 692,
      size: 8,
      font: lato,
    });

  //Collector CUI
  !!record.collector.vatin &&
    firstPage.drawText(record.collector.vatin, {
      x: 100,
      y: 667,
      size: 8,
      font: lato,
    });

  //Collector Trade Register
  !!record.colTradeRegister &&
    firstPage.drawText(record.colTradeRegister, {
      x: 125,
      y: 653,
      size: 8,
      font: lato,
    });

  //Collector Contract No
  !!record.colContract &&
    firstPage.drawText(record.colContract.substring(0, record.colContract.indexOf(',')), {
      x: 140,
      y: 606,
      size: 8,
      font: lato,
    });

  //Collector Additional Contract Info
  !!record.colContract &&
    firstPage.drawText(record.colContract.substring(record.colContract.indexOf(',') + 1), {
      x: 80,
      y: 594,
      size: 8,
      font: lato,
    });

  //Collector Driver
  !!record.driver &&
    firstPage.drawText(record.driver, {
      x: 295,
      y: 302,
      size: 8,
      font: lato,
    });

  //BI/CI
  !!record.biNumber &&
    firstPage.drawText(record.biNumber, {
      x: 265,
      y: 279,
      size: 8,
      font: lato,
    });

  //BI/CI
  !!record.issuedBy &&
    firstPage.drawText(record.issuedBy, {
      x: 275,
      y: 255,
      size: 8,
      font: lato,
    });

  //Plate Number
  !!record.vehPlateNo &&
    firstPage.drawText(record.vehPlateNo, {
      x: 295,
      y: 232,
      size: 8,
      font: lato,
    });

  //Plate Number
  !!record.trailerPlateNo &&
    firstPage.drawText(record.trailerPlateNo, {
      x: 265,
      y: 209,
      size: 8,
      font: lato,
    });

  //Valoriser Name
  !!record.valName &&
    firstPage.drawText(record.valName, {
      x: 395,
      y: 714,
      size: 8,
      font: lato,
    });

  //Workstation
  if (record.valWorkstation?.length > 39) {
    wrap(record.valWorkstation, 39).map((rec, i) => {
      firstPage.drawText(rec, {
        x: 395,
        y: 691 - i * 9,
        size: 8,
        font: lato,
      });
    });
  } else {
    !!record.valWorkstation &&
      firstPage.drawText(record.valWorkstation, {
        x: 395,
        y: 691,
        size: 8,
        font: lato,
      });
  }

  //Valoriser CUI
  !!record.valoriser.vatin &&
    firstPage.drawText(record.valoriser.vatin, {
      x: 422,
      y: 666,
      size: 8,
      font: lato,
    });

  //Valoriser Trade Register
  !!record.valTradeRegister &&
    firstPage.drawText(record.valTradeRegister, {
      x: 449,
      y: 655,
      size: 8,
      font: lato,
    });

  // Loading Unloading Number
  !!record.loadUnloadNoteNo &&
    firstPage.drawText(record.loadUnloadNoteNo, {
      x: 125,
      y: 372,
      size: 9,
      font: lato,
    });

  // Delivery Request Number
  !!record.requestNo &&
    firstPage.drawText(record.requestNo, {
      x: 420,
      y: 392,
      size: 9,
      font: lato,
    });

  // Unloading Type
  !!record.typeId &&
    firstPage.drawText(record?.type?.name, {
      x: 420,
      y: 337,
      size: 11,
      font: latoBold,
    });

  //Tire Quantities
  record.tiredeliveriesQty.map((tireQty) => {
    !!tireQty.pieces &&
      firstPage.drawText(tireQty.tireType.name + ' - ' + tireQty.pieces.toString(), {
        x: 218,
        y: TireTypesY[tireQty.tireType.name],
        size: 8,
        font: lato,
      });
  });
};

async function embedImages(signatureUrl, stampUrl, pdfDoc) {
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  if (!!signatureUrl) {
    const signatureImageBytes = await fetch(signatureUrl, { cache: 'no-cache' }).then((res) =>
      res.arrayBuffer(),
    );

    const signatureImage = await pdfDoc.embedPng(signatureImageBytes);

    //const signatureDims = signatureImage.scale(0.6);

    firstPage.drawImage(signatureImage, {
      x: 70,
      y: 222,
      width: 50,
      height: 50,
    });
  }

  if (!!stampUrl) {
    const stampImageBytes = await fetch(stampUrl, { cache: 'no-cache' }).then((res) =>
      res.arrayBuffer(),
    );

    const stampImage = await pdfDoc.embedPng(stampImageBytes);

    //const stampDims = stampImage.scale(0.6);

    firstPage.drawImage(stampImage, {
      x: 140,
      y: 222,
      width: 50,
      height: 50,
    });
  }
}

//* ----- Modal ----------------------------------------------------------------------------------------
//
type Props = {
  tireDeliveryId: number;
  isOpen: boolean; // for modal
  onResolve: () => void; // for modal
};

const PrintReport = ({ isOpen, onResolve, tireDeliveryId }: Props) => {
  const client = useApolloClient();
  const { data, loading } = useQuery(gqlGetByIdQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { id: tireDeliveryId },
    onCompleted: async (data) => {
      const record = data.getTiredeliveryById;
      const url = process.env.REACT_APP_S3BUCKET + '/documents/DeliveryNoteV5.pdf';
      const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      await addText(record, pdfDoc);
      let signatureUrl;
      let stampUrl;
      signatureUrl = await getFileUrl(client, record.collectorsPlatform.signatureFileId).catch(
        (err) => {
          console.log('signatureUrl error, ', err);
        },
      );
      if (!signatureUrl) {
        signatureUrl = await getFileUrl(client, record.collector.signatureFileId).catch((err) => {
          console.log('signatureUrl error, ', err);
        });
      }

      stampUrl = await getFileUrl(client, record.collectorsPlatform.stampFileId).catch((err) => {
        console.log('stampUrl error, ', err);
      });
      if (!stampUrl) {
        stampUrl = await getFileUrl(client, record.collector.stampFileId).catch((err) => {
          console.log('stampUrl error, ', err);
        });
      }
      await embedImages(signatureUrl, stampUrl, pdfDoc);

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes]);
      saveAs(blob, `${record.deliveryNoteNo}.pdf`);
      onResolve();
    },
  });

  useWhyDidYouUpdate('[PrintReport]', { data, loading });
  return <ModalFlex isOpen={isOpen} />;
};

export default createModal<Props, void>(PrintReport);
