import gql from 'graphql-tag';

export class FormInput {
  id: number;
  name: string;
  isActive: boolean;
  firstDate: Date;
  lastDate: Date;
  firstUser: string;
  lastUser: string;
  sortOrder: number;
}

export const gqlGetQuery = gql`
  query getCollectionpointsCategories($limit: Int, $offset: Int, $order: String, $where: String) {
    getCollectionpointsCategories(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        firstDate
        firstUser
        id
        isActive
        lastDate
        lastUser
        name
      }
      recordCount
    }
  }
`;

export const gqlGetByIdQuery = gql`
  query getCollectionpointsCategoryById($id: Int) {
    getCollectionpointsCategoryById(id: $id) {
      firstDate
      firstUser
      id
      isActive
      lastDate
      lastUser
      name
    }
  }
`;

export const gqlAdd = gql`
  mutation addCollectionpointsCategory($data: CollectionpointsCategoryInput) {
    addCollectionpointsCategory(data: $data) {
      id
    }
  }
`;

export const gqlUpdate = gql`
  mutation updateCollectionpointsCategory($data: CollectionpointsCategoryInput) {
    updateCollectionpointsCategory(data: $data) {
      id
    }
  }
`;

export const gqlDelete = gql`
  mutation deleteCollectionpointsCategory($id: Int) {
    deleteCollectionpointsCategory(id: $id)
  }
`;
