import gql from 'graphql-tag';

export class FormInput {
  id: number;
  name: string;
  typicalWeight: number;
  notes: string;
  isActive: boolean;
  firstDate: Date;
  lastDate: Date;
  firstUser: string;
  lastUser: string;
}

export const gqlGetQuery = gql`
  query getTireTypes($limit: Int, $offset: Int, $order: String, $where: String) {
    getTireTypes(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        firstDate
        firstUser
        id
        sortOrder
        isActive
        lastDate
        lastUser
        name
        typicalWeight
        notes
      }
      recordCount
    }
  }
`;

export const gqlGetQuerySimple = gql`
  query getTireTypes($limit: Int, $offset: Int, $order: String, $where: String) {
    getTireTypes(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        id
        name
      }
      recordCount
    }
  }
`;

export const gqlGetByIdQuery = gql`
  query getTireTypeById($id: Int) {
    getTireTypeById(id: $id) {
      firstDate
      firstUser
      id
      isActive
      lastDate
      lastUser
      name
      typicalWeight
      notes
    }
  }
`;

export const gqlAdd = gql`
  mutation addTireType($data: TireTypeInput) {
    addTireType(data: $data) {
      firstDate
      firstUser
      id
      isActive
      lastDate
      lastUser
      name
      typicalWeight
      notes
    }
  }
`;

export const gqlUpdate = gql`
  mutation updateTireType($data: TireTypeInput) {
    updateTireType(data: $data) {
      firstDate
      firstUser
      id
      isActive
      lastDate
      lastUser
      name
      typicalWeight
      notes
    }
  }
`;

export const gqlDelete = gql`
  mutation deleteTireType($id: Int) {
    deleteTireType(id: $id)
  }
`;
