import React, { useMemo, useCallback } from 'react';
import { useRootStore } from 'store';
import {
  BootstrapSwitchButton,
  ButtonNewRecord,
  CellCheckbox,
  CellLeft,
  CellYesNoNull,
  EyeColumn,
  GridLinkButton,
  TableSubSimple,
  toastSuccess,
} from 'motion-components';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHandleError } from 'services';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { getRouteByName, Paths } from 'app/routes';
import { gqlGetQuery } from '../graphql';
import LoadingOverlay from 'react-loading-overlay';

export const ColSubcontractorsTable = ({
  collectorId,
  dataCount = 0,
  initPageIndex = 1,
  onPageChange = () => {},
  handleExport = () => {},
  refetch,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<any>();
  const { authStore, appStore } = useRootStore();
  const { handleGraphqlError } = useHandleError('ColSubcontractorsTable ');

  const {
    loading,
    data: rawData,
    error,
    refetch: refetchCollectors,
  } = useQuery(gqlGetQuery, {
    variables: { where: JSON.stringify({ collectorId: collectorId }), order: 'name ASC' },
    fetchPolicy: 'network-only',
  });
  const data = rawData?.getCollectors?.data;

  const handleCreateNew = useCallback(() => {
    history.push({
      pathname: generatePath(getRouteByName('Collector'), { id: -1 }),
      state: {
        pathname: location.pathname,
        collectorId: collectorId,
        ...location.state,
      },
    });
  }, [history, location.pathname, location.state]);

  const rowClicked = useCallback(
    (row) => {
      history.push({
        pathname: generatePath(getRouteByName('Collector'), { id: row.id }),
        state: {
          pathname: location.pathname,
          ...location.state,
        },
      });
    },
    [history, location.pathname, location.state],
  );

  const columns = useMemo(
    () => [
      EyeColumn(rowClicked),
      {
        Header: t('Collector'),
        accessor: 'name',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Address'),
        accessor: 'address',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('City'),
        accessor: 'city',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('County'),
        accessor: 'county.name',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Contact Person'),
        accessor: 'contactperson',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Active'),
        accessor: 'isActive',
        Cell: CellYesNoNull,
        width: '100',
        isVisible: false,
      },
    ],
    [authStore.isUsersAdmin, t],
  );
  return (
    <div>
      <LoadingOverlay active={loading} spinner text={t('Please wait...')}>
        {!!data ? (
          <>
            <h5 className="form-group-title mb-0 d-flex flex-row">
              {t('Subcontractors')}
              {appStore.isEcoanvelope && (
                <div style={{ marginLeft: 'auto', marginRight: '20px' }}>
                  <ButtonNewRecord onClick={handleCreateNew} size="sm" />
                </div>
              )}
            </h5>
            <TableSubSimple title={t('Subcontractors')} columns={columns} data={data} />
            {data.length === 0 && <h4 className="text-center">{t('No Records')}</h4>}
          </>
        ) : (
          <h4 className="text-center"></h4>
        )}
      </LoadingOverlay>
    </div>
  );
};
