import { useLazyQuery } from '@apollo/client';
import { exportXlsModal } from 'motion-components';
import { gqlGetQuery } from '../graphql';
import { buildWhereFromFilterValues } from './filters';

export const exportColumns = [
  { fieldName: 'valoriserName', fieldTitle: 'Valoriser', fieldType: 'TEXT' },
  { fieldName: 'transactionId', fieldTitle: 'Transaction Id', fieldType: 'TEXT' },
  { fieldName: 'referenceDate', fieldTitle: 'Date', fieldType: 'DATETIME' },
  { fieldName: 'transactionInfo', fieldTitle: 'Transaction Info', fieldType: 'TEXT' },
  { fieldName: 'piecesA', fieldTitle: 'A', fieldType: 'TEXT' },
  { fieldName: 'piecesB', fieldTitle: 'B', fieldType: 'TEXT' },
  { fieldName: 'piecesC', fieldTitle: 'C', fieldType: 'TEXT' },
  { fieldName: 'piecesD', fieldTitle: 'D', fieldType: 'TEXT' },
  { fieldName: 'piecesE', fieldTitle: 'E', fieldType: 'TEXT' },
  { fieldName: 'piecesF', fieldTitle: 'F', fieldType: 'TEXT' },
  { fieldName: 'weightTotal', fieldTitle: 'Weight', fieldType: 'TEXT' },
];

// export function useGetQuery(): any {
//   const [get, { loading }] = useLazyQuery(gqlGetQuery, { onCompleted: transformData });

//   const [result, setResult] = useState<any>([get, { loading, data: undefined }]);

//   function transformData(data) {
//     const recs = data.getFormsDefinitions.data.map(values => {
//       return {
//         ...values,
//         solvingPeriod: periodQtyUnitToString(values.solvingPeriodQty, values.solvingPeriodUnit),
//         restoringPeriod: periodQtyUnitToString(values.restoringPeriodQty, values.restoringPeriodUnit),
//         pausingPeriod: periodQtyUnitToString(values.pausingPeriodQty, values.pausingPeriodUnit),
//         repeatingPeriod: periodQtyUnitToString(values.repeatingPeriodQty, values.repeatingPeriodUnit),
//       };
//     });

//     const trsData = { getFormsDefinitions: { data: recs, recordCount: data?.getFormsDefinitions?.recordCount } };
//     setResult([get, { loading, data: trsData }]);
//   }

//   return result;
// }

export async function handleExport(client, queryState: any): Promise<void> {
  function useGetQuery() {
    return useLazyQuery(gqlGetQuery);
    //return [null, { loading: null, data: null }];
  }
  const filterVariables = {
    where: buildWhereFromFilterValues(queryState?.filterValues),
  };
  await exportXlsModal({
    apolloClient: client,
    exportFileName: 'platform-transactions',
    getLazyQuery: useGetQuery,
    columnsDef: exportColumns,
    filterVariables: filterVariables,
  });
}
