import { useLazyQuery } from '@apollo/client';
import { exportXlsModal } from 'motion-components';
import { gqlGetQuery } from '../graphql';
import { buildWhereFromFilterValues } from './filters';

export const exportColumns = [
  // { fieldName: "id", fieldTitle: "Id", fieldType: "TEXT" },
  { fieldName: 'id', fieldTitle: 'ID', fieldType: 'TEXT' },
  { fieldName: 'logDate', fieldTitle: 'Ημερομηνία', fieldType: 'DATETIME' },
  { fieldName: 'logType', fieldTitle: 'Τύπος Συμβάντος', fieldType: 'TEXT' },
  { fieldName: 'originatedFrom', fieldTitle: 'Πηγή Συμβάντος', fieldType: 'TEXT' },
  { fieldName: 'grouping', fieldTitle: 'Ομαδοποίηση', fieldType: 'TEXT' },
  { fieldName: 'message', fieldTitle: 'Μήνυμα', fieldType: 'TEXT' },
  { fieldName: 'user', fieldTitle: 'Χρήστης', fieldType: 'TEXT' },
  { fieldName: 'clientIp', fieldTitle: 'Ip Πηγής', fieldType: 'TEXT' },
];

// export function useGetQuery(): any {
//   const [get, { loading }] = useLazyQuery(gqlGetQuery, { onCompleted: transformData });

//   const [result, setResult] = useState<any>([get, { loading, data: undefined }]);

//   function transformData(data) {
//     const recs = data.getFormsDefinitions.data.map(values => {
//       return {
//         ...values,
//         solvingPeriod: periodQtyUnitToString(values.solvingPeriodQty, values.solvingPeriodUnit),
//         restoringPeriod: periodQtyUnitToString(values.restoringPeriodQty, values.restoringPeriodUnit),
//         pausingPeriod: periodQtyUnitToString(values.pausingPeriodQty, values.pausingPeriodUnit),
//         repeatingPeriod: periodQtyUnitToString(values.repeatingPeriodQty, values.repeatingPeriodUnit),
//       };
//     });

//     const trsData = { getFormsDefinitions: { data: recs, recordCount: data?.getFormsDefinitions?.recordCount } };
//     setResult([get, { loading, data: trsData }]);
//   }

//   return result;
// }

export async function handleExport(client, queryState: any): Promise<void> {
  function useGetQuery() {
    return useLazyQuery(gqlGetQuery);
    //return [null, { loading: null, data: null }];
  }
  const filterVariables = {
    where: buildWhereFromFilterValues(queryState?.filterValues),
  };
  await exportXlsModal({
    apolloClient: client,
    exportFileName: 'export Αρχείο Συμβάντων',
    getLazyQuery: useGetQuery,
    columnsDef: exportColumns,
    filterVariables: filterVariables,
  });
}
