import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  FieldDatepicker,
  FieldInput,
  FormCol,
  FormRow,
  FieldCheckBox,
  FieldNumericInput,
} from 'motion-components';
import { useFormikContext } from 'formik';
import { useHandleError } from 'services';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';

export const FormFields = ({ isNew, updateRecord, refetch, data }) => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();

  //
  const frmkCntx = useFormikContext();
  const { values } = frmkCntx;
  const { authStore } = useRootStore();
  const { handleGraphqlError } = useHandleError('PiBaseFormFields');

  function calcUserCanEdit() {
    const canEdit = appStore.isEcoanvelope;
    const canEditReasoning = '';
    return { canEdit, canEditReasoning };
  }
  const { canEdit, canEditReasoning } = calcUserCanEdit();

  return (
    <>
      <Row>
        <Col>
          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Tire Type')}
            name="name"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldNumericInput
            disabled={!canEdit}
            as={Col}
            label={t('Typical Weight in Kg')}
            name="typicalWeight"
            type="number"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Notes')}
            name="notes"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldCheckBox disabled={!canEdit} as={FormCol} label={t('Active')} name="isActive" />
        </Col>
      </Row>
    </>
  );
};
