import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery, useApolloClient } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import {
  FormCard,
  YupNumeric,
  YupDate,
  YupString,
  YupBoolean,
  promiseModalDialog,
  UserTooltip,
  objParseInt,
  objParseFloat,
} from 'motion-components';
import * as Yup from 'yup';
import * as Lo from 'lodash';
import { toastSuccess } from 'utils/toasts';
import { useHandleError } from 'services';
import { gqlGetByIdQuery, gqlAdd, gqlUpdate, gqlDelete, FormInput } from '../graphql';
import { isNumeric } from 'utils/helpers';

import { FormFields } from './form-fields';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';

export const ValorisationMethodFormPage = () => {
  const { t } = useTranslation();
  const { handleGraphqlError } = useHandleError('Valorisation_methodsFormPage');
  const { appStore } = useRootStore();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const idNum = isNumeric(id) ? parseInt(id) : -1;
  const isNew = idNum < 0;

  const formShape = {
    id: YupNumeric,
    name: YupString,
    isActive: YupBoolean,
    firstDate: YupDate,
    lastDate: YupDate,
    firstUser: YupString,
    lastUser: YupString,
  };

  const validationSchema = Yup.object().shape(formShape);
  const pickProperties = (values) => Lo.pick(values, Lo.keys(formShape));
  type FormValuesType = Yup.InferType<typeof validationSchema>;

  const [addRecord] = useMutation(gqlAdd);
  const [updateRecord] = useMutation(gqlUpdate);
  const [deleteRecord] = useMutation(gqlDelete);
  const [get, { data: rawData, error, refetch }] = useLazyQuery(gqlGetByIdQuery, {
    fetchPolicy: 'network-only',
  });
  const data = rawData ? rawData[Object.keys(rawData)[0]] : undefined;

  /* fetch data */
  useEffect(() => {
    if (!isNew) get({ variables: { id: idNum } });
  }, [get, id, idNum, isNew]);

  /**
   * * ΑΠΟΘΗΚΕΥΣΗ - UPDATE/CREATE
   */
  const handleSubmit = (values: FormValuesType, actions) => {
    actions.setSubmitting(false);
    let mutValues = Lo.pick(values, Lo.keys(new FormInput()));
    mutValues = objParseInt(['id'], mutValues);
    mutValues = objParseFloat([], mutValues);
    // Αν είναι νέα εγγραφή τοτε γράφω όλα τα πεδία
    if (isNew) {
      console.log('[Valorisation_methods] New:', mutValues);
      addRecord({ variables: { data: { ...mutValues } } })
        .then((d) => {
          toastSuccess(
            `${t('Record Created Successfully')} [${d.data[Object.keys(d.data)[0]]?.id}]`,
          );
          history.goBack();
        })
        .catch((error) => {
          handleGraphqlError('mutation-add', error);
        });
    } else {
      // Αν είναι update εγγραφής τοτε γράφω μονο τα αλλαγμένα πεδία
      console.log('[Valorisation_methods] Update:', mutValues);
      updateRecord({ variables: { data: { ...mutValues, id: idNum } } })
        .then((d) => {
          toastSuccess(
            `${t('Record Updated Successfully')} [${d.data[Object.keys(d.data)[0]].id}]`,
          );
          refetch();
        })
        .catch((error) => {
          handleGraphqlError('mutation-update', error);
        });
    }
  };

  /**
   * *  DELETE
   */
  const handleDelete = useCallback(async () => {
    const res = await promiseModalDialog({
      title: `${t('Delete Record')}`,
      text: `${t('The record will be permanently deleted. Do you want to continue?')}`,
    });
    if (res) {
      deleteRecord({ variables: { id: idNum } })
        .then(() => {
          toastSuccess(t('The record has been successfully deleted'));
          history.goBack();
        })
        .catch((error) => {
          handleGraphqlError('mutation-delete', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRecord, idNum]);

  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <FormCard
        title={
          isNew ? `${t('New Valorisation Method')}` : `${t('Valorisation Method')}: ${data?.name}`
        }
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        onReturn={null}
        permitDelete={false}
        initialValues={pickProperties(data)}
        validationSchema={validationSchema}
        enableReinitialize
        idx={idNum}
        isNew={isNew}
      >
        <FormFields isNew={isNew} updateRecord={updateRecord} refetch={refetch} data={data} />
        <div className="float-right">
          {data && (
            <UserTooltip data={data} /* auditTable={'Valorisation_methods'} */ auditId={data.id} />
          )}
        </div>
      </FormCard>
    );
  }
};
