import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormCol, FieldCheckBox } from 'motion-components';
import { useTranslation } from 'react-i18next';
import { FieldSelectReports } from 'components/fs-report';
import { FieldSelectReportFrequency } from 'components/fs-report-frequency';

export const FormFields = ({ isNew, updateRecord, refetch, data }) => {
  const { t } = useTranslation();
  //

  function calcUserCanEdit() {
    return { canEdit: true };
  }
  const { canEdit } = calcUserCanEdit();

  return (
    <>
      <Row>
        <Col>
          <FieldSelectReports disabled={!canEdit} as={FormCol} />
          <FieldSelectReportFrequency disabled={!canEdit} as={FormCol} />
          <FieldCheckBox disabled={!canEdit} as={FormCol} label={t('Active')} name="isActive" />
        </Col>
      </Row>
    </>
  );
};
