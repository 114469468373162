import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useRootStore } from 'store';
import {
  CellDateTime,
  CellLeft,
  EyeColumn,
  TablePaginatedUC,
  usePaginatedQuery,
} from 'motion-components';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useHandleError } from 'services';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { gqlGetQuery } from 'pages/tirecollections/graphql';
import { getTirecollectionStatusBadge } from 'common/badges-helpfunc';
import { getRouteByName } from 'app/routes';

export const CollectionpointTirecollectionsTable = ({ collectionpointId, refetch }) => {
  const { t } = useTranslation();
  const { appStore, authStore } = useRootStore();
  const { handleGraphqlError } = useHandleError('CollectionpointTirecollectionsTable');
  const location = useLocation<any>();
  const history = useHistory();

  function buildWhereFromFilterValues(values): string {
    // Φτιάξε το where για το graphql
    let where = { collectionpointId: collectionpointId };
    return JSON.stringify(where);
  }

  const query = usePaginatedQuery(
    gqlGetQuery,
    buildWhereFromFilterValues,
    'id DESC',
    appStore,
    true,
    {
      collectionpointId: collectionpointId,
    },
    false,
  );
  const {
    loading,
    data,
    dataCount,
    error,
    initPageIndex,
    filtersChanged,
    gridPageChanged,
    queryState,
  } = query;

  const tablePageSize = appStore.pageSize;

  const rowClicked = useCallback(
    (row) => {
      history.push({
        pathname: generatePath(getRouteByName('Tirecollection'), { id: row.id }),
        state: {
          pathname: location.pathname,
          ...location.state,
        },
      });
    },
    [history, location.pathname, location.state],
  );

  const columns = useMemo(
    () => [
      // { Header: "id", accessor: "id", Cell: CellCenter, width: "100", isVisible: false, },
      EyeColumn(rowClicked),
      {
        Header: t('Collection Request Number'),
        accessor: 'orderNo',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },

      {
        Header: t('Status'),
        accessor: 'statusId',
        Cell: ({ row }) => {
          const rec = row.original;
          return (
            <div className="text-left">
              <div
                className={
                  'badge pb-2 pt-1 mr-2 ' + getTirecollectionStatusBadge(rec?.status.value)
                }
              >
                {t(rec?.status.name)}
              </div>
            </div>
          );
        },
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Collection Point'),
        accessor: 'collectionpoint.name',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('County'),
        accessor: 'collectionpoint.county.name',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },

      {
        Header: t('Collector'),
        accessor: 'collector.name',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Platform'),
        accessor: 'collectorsPlatform.name',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Request Date'),
        accessor: 'requestDate',
        Cell: CellDateTime,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Collection Date'),
        accessor: 'collectionDate',
        Cell: CellDateTime,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Collected Tires'),
        accessor: 'tirecollectionsQty',
        Cell: ({ row }) => {
          const qties = row?.original?.tirecollectionsQty ?? [];
          const qties2 = qties
            .filter((rec) => rec.pieces && rec.pieces > 0)
            .map((rec) => {
              return `${rec?.tireType?.name}: ${rec.pieces}`;
            })
            .join(', ');
          return <div>{qties2}</div>;
        },
        width: '100',
      },
      {
        Header: t('Collection Document Number'),
        accessor: 'colDocNum',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
    ],
    [rowClicked, t],
  );
  return (
    <LoadingOverlay active={loading} spinner text={t('Please wait...')}>
      {data?.length > 0 ? (
        <>
          <h5 className="form-group-title mb-0 ">{t('Tire Collections')}</h5>
          <TablePaginatedUC
            title={t('Tire Collections')}
            columns={columns}
            data={data}
            dataCount={dataCount}
            onPageChange={gridPageChanged}
            initPageIndex={initPageIndex}
            id={'TireCollectionsTable'}
            appStore={appStore}
          />
          {/* <TableInMem columns={columns} data={data} id="grid" appStore={appStore} /> */}
        </>
      ) : (
        <div style={{ height: '400px' }}>
          <h4 className="text-center">{t('No Records')}</h4>
        </div>
      )}
    </LoadingOverlay>
  );
};
