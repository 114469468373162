import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  FieldInput,
  UserTooltip,
  ButtonApprove,
  ButtonReject,
  FieldDatepicker,
} from 'motion-components';
import { useFormikContext } from 'formik';
import { useHandleError } from 'services';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';
import {
  FieldSelectCollector,
  FieldSelectPlatform,
  FieldSelectValMethod,
  FieldSelectValoriser,
} from 'components';
import { TiresSection } from './tires-section';

export const FormFieldsApproveRequest = ({ data, handleApprove }) => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();

  //
  const frmkCntx = useFormikContext();
  const { values } = frmkCntx;
  const { authStore } = useRootStore();

  const { handleGraphqlError } = useHandleError('CollectorInventoryCountsFormFields');

  function calcUserCanEdit() {
    const canEdit = true;
    const canEditReasoning = '';
    return { canEdit, canEditReasoning };
  }
  // const canEdit =
  //   !(values as any)?.isFinalized && (authStore.userCanDraft(userRoleId) || authStore.userCanFinalize(userRoleId));
  const { canEdit, canEditReasoning } = calcUserCanEdit();

  return (
    <>
      {/* Header */}
      <Row>
        {/* 1st Column */}
        <Col>
          <FieldDatepicker
            disabled={true}
            as={Col}
            label={t('Stock Date')}
            name="stockDate"
            dateFormat="d/M/yyyy"
          />
          <FieldSelectPlatform label={t('Platform')} as={Col} disabled={true} />
        </Col>

        {/* 2nd Column : Tires*/}
        <Col>
          <div className="mt-4">
            <TiresSection isNew={false} data={data} />
          </div>
        </Col>
        {/* 3rd Column : Action Buttons */}

        {appStore.isEcoanvelope && (
          <Col>
            <FieldInput
              as={Col}
              name="requestedByUser.userName"
              disabled={true}
              placeholder={data?.requestedByUser?.userName}
              label={t('Requested By')}
            />
            <FieldDatepicker
              disabled={true}
              as={Col}
              label={t('Request Date')}
              name="requestDate"
              showTimeSelect
              dateFormat="d/M/yyyy HH:mm"
              timeFormat="HH:mm"
              timeIntervals={15}
            />
            <div className="mt-4 ml-4 mr-4">
              <Row>
                <ButtonApprove
                  onClick={() => handleApprove('accept')}
                  text={t('Approve Inventory Count')}
                  className="col-12"
                  variant="success"
                />
              </Row>
              <Row className="mt-3">
                <ButtonReject
                  onClick={() => handleApprove('reject')}
                  text={t('Reject Inventory Count')}
                  className="col-12"
                />
              </Row>
            </div>
          </Col>
        )}

        {/* 4th Column : Blank */}
        <Col></Col>
      </Row>
    </>
  );
};
