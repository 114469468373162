import React from 'react';
// Apollo Client Imports
import { ApolloProvider, ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { rootStore, useRootStore } from 'store';
import { persistLogError } from 'services';
import { toast } from 'react-toastify';
import { createUploadLink } from 'apollo-upload-client';
//import { InMemoryCache } from 'apollo-cache-inmemory';
import { useTranslation } from 'react-i18next';

//------------------------------------------------------------
export const AppApolloProvider: React.FC = (props: any) => {
  const { appStore } = useRootStore();
  const { i18n, t } = useTranslation();
  const errorHandler = (err) => {
    const { graphQLErrors, networkError, operation, forward } = err;
    console.log('[Apollo.onError]', err);
    const operationName = operation?.operationName ?? 'unknown'; // i.e. updateBatteriescollection
    const statusCode = (networkError as any)?.statusCode ?? 'unknown'; // i.e. 400 Bad Request
    if (graphQLErrors) {
      const errors = graphQLErrors.filter(
        (v, i, a) =>
          a.findIndex(
            (t) =>
              t.message === v.message &&
              JSON.stringify(t.locations) === JSON.stringify(v.locations) &&
              t.path === v.path,
          ) === i,
      );
      // eslint-disable-next-line array-callback-return
      errors.map(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations,
          )}, Path: ${path}, Operation:${operation}`,
        );
        if (message === 'webapi Context creation failed: jwt must be provided') return null;
        if (!message.includes('Too many connections'))
          persistLogError(client, 'App', `Apollo.onError.${operationName}`, message);
      });
    }

    if (networkError) {
      if (statusCode === 400) {
        // Bad Request `Ο σέρβερ επέστρεψε: Ζητήθηκε λάθος λειτουργία (${statusCode})`
        toast((t('Server responded: Wrong operation requested %1') as any).format(statusCode), {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 6000,
          draggable: false,
        });
        persistLogError(client, 'App', `Apollo.onError.${operationName}`, `${networkError}`);
      }
      // networkError.message = 'Hello'; // <=== override message
      const msg = networkError?.result?.errors[0]?.message;
      networkError.message = msg.replace('Context creation failed: ', ''); // <=== override message
    }

    //err.response.errors = undefined; // <=== ignore error

    // Δες για FORCE LOGOUT μήνυμα απο τον server
    const msg = networkError?.result?.errors[0]?.message;
    if (!!msg && msg.includes('[Force Logout]')) {
      console.log('received FORCE LOGOUT');
      // Logout User
      if (appStore.isLogIn) {
        localStorage.clear();
        appStore.logOut();
      }
      //

      toast(msg.replace('Context creation failed: ', '').replace('[Force Logout]', ''), {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2 * 6000,
        draggable: false,
      });
    }
  };

  // * APOLLO CONFIG 1 : Plain HttpLink
  // const httpLink = new HttpLink({
  //   uri: process.env.REACT_APP_WEBAPI,
  //   credentials: 'same-origin',
  // });

  // with Upload
  const httpLink = new createUploadLink({
    uri: process.env.REACT_APP_WEBAPI,
    credentials: 'same-origin',
  });

  // * APOLLO CONFIG 2 : Cache
  //const cache = new InMemoryCache();
  // * APOLLO CONFIG 3 : Authentication Middleware (optional)
  const authMiddleware = new ApolloLink((operation, forward) => {
    // get the authentication token from local storage if it exists
    const tokenValue = rootStore.appStore.userLogin.jwt;
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: tokenValue ? `bearer ${tokenValue}` : null,
        language: i18n.language ?? 'en',
      },
    }));
    return forward(operation);
  });

  // * APOLLO CONFIG 4 : Error Handler (optional)
  const errorLink = onError(errorHandler);
  // * APOLLO make Links array
  //const links = [httpLink];
  const links = [errorLink, authMiddleware, httpLink];
  // * ApolloClient Instance
  const client = new ApolloClient({
    link: ApolloLink.from(links),
    cache: new InMemoryCache(),
    defaultOptions: { watchQuery: { errorPolicy: 'all' }, query: { errorPolicy: 'all' } },
    connectToDevTools: true,
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
