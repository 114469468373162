import React, { useEffect } from 'react';
import ModalContainer from 'react-modal-promise';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RootStoreProvider, useRootStore } from 'store';
import { AppRoute } from './app-route';
import { Observer } from 'mobx-react-lite';
import { gitnum } from 'gitnum';
//import * as app from '../../package.json';

import { useIdleTimer } from 'react-idle-timer';
import { AppApolloProvider } from './app-apolloprovider';

const App = () => {
  console.log('Running application version:', gitnum.version);
  const { appStore } = useRootStore();

  function confirmDialog(msg) {
    return new Promise(function (resolve, reject) {
      const confirmed = window.confirm(msg);

      return confirmed ? resolve(true) : reject(false);
    });
  }

  useEffect(() => {
    // Clear on app load για να ζητήσει login
    const dt = sessionStorage.getItem('date');
    if (!dt) {
      console.log('clear login data');
      localStorage.clear();
      appStore.logOut();
    }
  }, [appStore]);

  // Start --- Idle Timer => Logout on Inactivity -----------------------------
  const handleOnIdle = (event) => {
    // Καλείται όταν λήξει το timeout
    console.log('User is Inactive for too long', event);
    //console.log('last active', getLastActiveTime());

    // Logout User
    if (appStore.isLogIn) {
      localStorage.clear();
      appStore.logOut();
    }
  };

  // const handleOnActive = event => {
  //   console.log('user is active', event);
  //   console.log('time remaining', getRemainingTime());
  // };

  // const handleOnAction = event => {
  //   console.log('user did something', event);
  // };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 60, // logout after 60 minutes of inactivity
    onIdle: handleOnIdle,
    // onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500,
  });
  // End --- Idle Timer => Logout on Inactivity -----------------------------

  /*-------------------- Render ---------------------------------------------*/
  return (
    <AppApolloProvider>
      {/* <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload, latestVersion, currentVersion }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            const msg = `Θα γίνει αναβάθμιση της εφαρμογής στην νεα έκδοση (${latestVersion}) απο την έκδοση (${currentVersion}) που έχετε τώρα`;
            confirmDialog(msg)
              .then(() => {
                if ('serviceWorker' in navigator) {
                  navigator.serviceWorker.getRegistrations().then(function(registrations) {
                    for (const registration of registrations) {
                      registration.update().then(() => {
                        //persistLogInfo(client, 'App', `App.CasheBuster.registration`, msg);
                        refreshCacheAndReload();
                      });
                    }
                  });
                } else {
                  //persistLogInfo(client, 'App', `App.CasheBuster`, msg);
                  refreshCacheAndReload();
                }
              })
              .catch(err => console.log('Upgrade Canceled by User'));
          }

          return (
            <BrowserRouter>
              <RootStoreProvider>
                <ToastContainer style={{ width: 500 }} />
                <ModalContainer />
                <Observer>{() => appStore.initialized && <AppRoute />}</Observer>
              </RootStoreProvider>
            </BrowserRouter>
          );
        }}
      </CacheBuster>
      */}
      <BrowserRouter>
        <RootStoreProvider>
          <ToastContainer style={{ width: 500 }} />
          <ModalContainer />
          <Observer>{() => appStore.initialized && <AppRoute />}</Observer>
        </RootStoreProvider>
      </BrowserRouter>
    </AppApolloProvider>
  );
};

export default App;
