import React from 'react';
import { Field } from 'formik';
import { CustomSelectComponent, FsProps } from 'motion-components';
import { useTranslation } from 'react-i18next';

export const FieldSelectApplogType = ({ label, name = 'logType', ...rest }: FsProps) => {
  //
  const { t } = useTranslation();
  label = label ? label : t('Event Type');
  const options = [
    { value: 'Info', label: 'Info' },
    { value: 'Error', label: 'Error' },
  ];

  return <Field name={name} title={label} component={CustomSelectComponent} options={options} />;
};
