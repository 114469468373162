import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import printReport from './PrintReport.modal';
import { FaFile } from 'react-icons/fa';

export const PrintDeliveryNoteButton = ({ deliveryId, disabled = false }) => {
  const { t } = useTranslation();

  const handleSubmit = async () => {
    await printReport({ tireDeliveryId: deliveryId });
  };
  return (
    <>
      <Button disabled={false} onClick={handleSubmit} style={{ marginTop: '5px' }}>
        <FaFile className="mb-1 mr-3" />
        <span className="ml-1">{t('Print Document')}</span>
      </Button>
    </>
  );
};
