import gql from 'graphql-tag';

export const gqlGetQuery = gql`
  query getCollectorsStocks($limit: Int, $offset: Int, $order: String, $where: String) {
    getCollectorsStocks(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        collectorId
        collectorName
        piecesA
        piecesB
        piecesC
        piecesD
        piecesE
        piecesF
        platformId
        platformName
        weightTotal
      }
      recordCount
    }
  }
`;
