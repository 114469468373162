import React, { useEffect } from 'react';
import {
  BootstrapSwitchButton,
  FieldCheckBox,
  FieldInput,
  FieldLabel,
  FormCol,
  FormCol2,
} from 'motion-components';
import { useFormikContext } from 'formik';
import { useHandleError } from 'services';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';
import {
  FieldSelectCollectionpointCategory,
  FieldSelectCollectionpointType,
  FieldSelectCounty,
} from 'components';
import { Form } from 'react-bootstrap';
import { gqlUpdateCollectorsCollectionpoint } from '../graphql';
import { useMutation } from '@apollo/client';

export const FormFields = ({ isNew, updateRecord, refetch, data }) => {
  const { t } = useTranslation();
  //
  const frmkCntx = useFormikContext();
  const { values, setFieldValue } = frmkCntx;
  const { appStore, authStore } = useRootStore();

  const { handleGraphqlError } = useHandleError('CollectionpointsFormFields');

  function calcUserCanEdit() {
    const canEdit = appStore.isLogIn
      ? (appStore.isCollector && appStore.hasCPElevatedPerms) ||
        appStore.isEcoanvelope ||
        isNew ||
        appStore.isCollectionPoint
      : isNew;
    const canEditReasoning = '';
    return { canEdit, canEditReasoning };
  }
  // const canEdit =
  //   !(values as any)?.isFinalized && (authStore.userCanDraft(userRoleId) || authStore.userCanFinalize(userRoleId));
  const { canEdit, canEditReasoning } = calcUserCanEdit();

  const [updateColCp] = useMutation(gqlUpdateCollectorsCollectionpoint);

  const hasContractChanged = async (checked) => {
    appStore.setBusy();
    await updateColCp({
      variables: {
        data: { id: data.collectorsCollectionpoints[0].id, hasContract: checked },
      },
    }).then(() => {
      refetch();
      appStore.clearBusy();
    });
  };

  return (
    <>
      <div className="d-flex flex-row">
        <FormCol>
          <FieldInput
            disabled={!isNew && !appStore.isEcoanvelope}
            label={t('Collection Point Name')}
            name="name"
            type="text"
            placeholder={t('- specify a value - ')}
            required={true}
          />
          <FieldSelectCollectionpointType label={t('Type')} disabled={!canEdit} />
          <FieldInput
            disabled={!isNew && !appStore.isEcoanvelope && !appStore.isAdmin}
            label={t('CUI/CNP')}
            name="vatin"
            type="text"
            placeholder={t('- specify a value - ')}
            required={true}
          />
          <FieldSelectCollectionpointCategory label={t('Category')} disabled={!canEdit} />
        </FormCol>

        <FormCol>
          <FieldInput
            disabled={!canEdit}
            label={t('Address')}
            name="address"
            type="text"
            placeholder={t('- specify a value - ')}
            required={true}
          />

          <FieldInput
            disabled={!canEdit}
            label={t('City')}
            name="city"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldSelectCounty label={t('County')} required={true} disabled={!canEdit} />
        </FormCol>
        <FormCol>
          {!isNew && (
            <FieldCheckBox
              disabled={!appStore.isAdmin}
              as={FormCol2}
              label={t('Active')}
              name="isActive"
            />
          )}
          {!isNew && (
            <FieldCheckBox
              disabled={!appStore.isEcoanvelope}
              as={FormCol2}
              label={t('Blacklisted')}
              name="isBlackListed"
              offStyle="primary"
              onStyle="danger"
            />
          )}
          {!isNew && appStore.isCollector && (
            <Form.Group as={FormCol2}>
              <FieldLabel text={t('Has Contract')}></FieldLabel>
              <div>
                <BootstrapSwitchButton
                  checked={data?.collectorsCollectionpoints[0].hasContract || false}
                  onlabel={t('Yes')}
                  offlabel={t('No')}
                  width="80"
                  onstyle={'primary'}
                  offstyle={'warning'}
                  disabled={!appStore.isAdmin}
                  onChange={(checked: boolean) => {
                    hasContractChanged(checked);
                  }}
                />
              </div>
            </Form.Group>
          )}
        </FormCol>
      </div>
    </>
  );
};
