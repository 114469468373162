import React, { useMemo, useCallback } from 'react';
import {
  TablePaginatedUC,
  CellLeft,
  CellCenter,
  CellYesNo,
  CellYesNoNull,
  CellDateTime,
  CellInt,
  CellFloatCustom,
  CellIntCustom,
  CellCheckbox,
  EyeColumn,
  BootstrapSwitchButton,
  CellYesNoReverseColors,
} from 'motion-components';
import { useLocation } from 'react-router-dom';
import { generatePath, useHistory } from 'react-router';

import LoadingOverlay from 'react-loading-overlay';
import { useRootStore } from 'store';
import { getRouteByName } from 'app/routes';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { gqlSetAutoApprove } from '../graphql';
import { useHandleError } from 'services';

/* ----------- Grid ------------------ */

export const DataGrid = ({
  data,
  refetch,
  dataCount,
  initPageIndex,
  onPageChange,
  loading,
  handleExport,
}) => {
  const location = useLocation<any>();
  const history = useHistory();
  const { appStore } = useRootStore();
  const { t } = useTranslation();
  const { handleGraphqlError } = useHandleError('Valorisers Grid');
  const [setAutoApprove] = useMutation(gqlSetAutoApprove);
  const rowClicked = useCallback(
    (row) => {
      history.push({
        pathname: generatePath(getRouteByName('Valoriser'), { id: row.id }),
        state: {
          pathname: location.pathname,
          ...location.state,
        },
      });
    },
    [history, location.pathname, location.state],
  );

  const handleSwitch = (valoriserId, value) => {
    setAutoApprove({ variables: { valoriserId: valoriserId, value: value } });
    //refetch();
  };

  const valMethodsCell = useCallback(({ row }) => {
    const shortByName = function (a, b) {
      const nameA = a.valorisationMethod.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.valorisationMethod.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) return -1;
      else if (nameA > nameB) return 1;
      else return 0;
    };
    const roles = row.original.valorisersValorisationmethods
      .slice()
      .filter((rec) => rec.isActive)
      .sort(shortByName);
    return (
      <div className="text-left">
        <ul style={{ paddingLeft: '1.8em' }}>
          {roles.map((el, index) => {
            return <li key={index}>{el.valorisationMethod.name}</li>;
          })}
        </ul>
      </div>
    );
  }, []);

  const doUpdateRecord = useCallback(
    (data) => {
      setAutoApprove({ variables: data })
        .then(() => {
          //const id = d.data.key[Object.keys(d.data)[0]].id;
          if (refetch) refetch();
        })
        .catch((error) => {
          handleGraphqlError('mutation-update', error);
        });
    },
    [handleGraphqlError, refetch, setAutoApprove],
  );

  const autoApproveDeliveriesChanged = useCallback(
    (checked, row) => {
      const data: any = { valoriserId: row.id, value: checked };
      doUpdateRecord(data);
    },
    [doUpdateRecord],
  );

  const columns = useMemo(
    () => [
      // { Header: "id", accessor: "id", Cell: CellCenter, width: "100", isVisible: false, },
      EyeColumn(rowClicked),
      {
        Header: t('Valoriser'),
        accessor: 'name',
        Cell: CellLeft,
        width: '50',
        isVisible: false,
      },
      {
        Header: t('County'),
        accessor: 'county.name',
        Cell: CellLeft,
        width: '50',
        isVisible: false,
      },
      {
        Header: t('Address'),
        accessor: 'address',
        Cell: CellLeft,
        width: '50',
        isVisible: false,
      },
      {
        id: 'phone',
        Header: t('Phone'),
        accessor: 'phone',
        Cell: ({ row }) => (
          <>
            <div className="text-center">{row.original.phone}</div>
            <div className="text-center">{row.original.mobile}</div>
          </>
        ),
        width: '50',
        isVisible: false,
      },
      {
        Header: t('Contact Person'),
        accessor: 'contactperson',
        Cell: CellLeft,
        width: '50',
        isVisible: false,
      },
      {
        Header: t('Valoriser Group'),
        accessor: 'valorisersGroup.name',
        Cell: CellLeft,
        width: '50',
        isVisible: false,
      },
      {
        Header: t('Valorisation Methods'),
        accessor: 'valorisersValorisationmethods',
        Cell: valMethodsCell,
        width: '50',
      },
      {
        Header: t('Auto Approve Deliveries'),
        accessor: 'autoApproveDeliveries',
        //Cell: CellCheckbox(autoApproveDeliveriesChanged, !appStore.isEcoanvelope),
        //Cell: CellYesNoNull,
        Cell: CellCheckbox(autoApproveDeliveriesChanged, !appStore.isEcoanvelope),
        width: '30',
        isVisible: true,
      },
      {
        Header: t('Max Deliveries Per Day'),
        accessor: 'maxDeliveriesPerDay',
        Cell: CellCenter,
        width: '25',
        isVisible: true,
      },
      {
        Header: t('Yearly Weight Target (t)'),
        accessor: 'deliveriesYearlyWeightTarget',
        Cell: CellFloatCustom(8, 2),
        width: '25',
        isVisible: true,
      },
      {
        Header: t('Unavailable'),
        accessor: 'isUnavailable',
        Cell: CellYesNoReverseColors,
        width: '25',
        isVisible: false,
      },
      {
        Header: t('Active'),
        accessor: 'isActive',
        Cell: CellYesNoNull,
        width: '25',
        isVisible: false,
      },
    ],
    [appStore.isEcoanvelope, autoApproveDeliveriesChanged, t],
  );

  return (
    <LoadingOverlay active={loading} spinner text={t('Please wait...')}>
      {data ? (
        <TablePaginatedUC
          id="valorisers"
          appStore={appStore}
          key="valorisers"
          title={'Λίστα'}
          columns={columns}
          data={data}
          dataCount={dataCount}
          // initPageIndex={initPage}
          // onSortByChange={handleSortByChange}
          onExport={handleExport}
          onPageChange={onPageChange}
          initPageIndex={initPageIndex}
          resizable={true}
        />
      ) : (
        <div style={{ height: '50px' }}></div>
      )}
    </LoadingOverlay>
  );
};
