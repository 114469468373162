import React from 'react';
import { FormFilterCard, FieldDateRanger, FormCol } from 'motion-components';
import { toastSuccess, toastFailure } from 'utils/toasts';
import { Card, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRootStore } from 'store';
import moment from 'moment';
import { format } from 'date-fns';
import { useDbHelpFunc } from 'common/db-help-func';
import { FieldSelectReports } from 'components/fs-report';

function formatDate(dt: Date) {
  return format(dt, 'yyyy-MM-dd');
}

/**
 *
 * @returns ExcepReports Page
 */
export const ExcelReports = () => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const dbHelpFunc = useDbHelpFunc();

  async function handleFiltersSubmit(values, { setSubmitting }) {
    // Validate Filters
    let errors = [];

    if (!values.reportId) errors.push(t('Select a Report Type'));
    if (!values.fromDate || !values.tillDate) errors.push(t('Specify a valid time period'));
    if (errors.length > 0) {
      const s = errors.map((v) => {
        return (
          <>
            {v} <br />
          </>
        );
      });
      toastFailure(<div> {s} </div>);
      return;
    }
    // Fetch Report
    appStore.setBusy();
    // 'https://t7psnmbge6.execute-api.eu-west-1.amazonaws.com/pro/create-report?reportName=deliveriesAnalytical&fromDate=2023-05-01&tillDate=2023-05-31&userName=admin';
    const baseUri = process.env.REACT_APP_WEBAPI.replace('/graphql', '');
    let searchParams = new URLSearchParams({
      reportName: values.reportId,
      fromDate: formatDate(values.fromDate),
      tillDate: formatDate(values.tillDate),
      userName: appStore.user,
    });

    // Encrypt Params
    const key = await dbHelpFunc.encodeString(searchParams.toString());
    if (key) {
      searchParams = new URLSearchParams({ key: key });
    }
    const uri = `${baseUri}/create-report?${searchParams.toString()}`;
    appStore.clearBusy();
    toastSuccess(t('The report has been generated!'));
    //toastSuccess(t('Please wait. The report is being prepared'));
    window.location.assign(uri);

    setSubmitting(false);
  }

  return (
    <Card className="shadow">
      <Card.Header>
        <Card.Title className="m-0">{t('Reports')}</Card.Title>
        <p style={{ padding: '0.3rem 0.0rem' }}>
          {t(
            "Please, select a report type and the time period of interest and then press 'Search'",
          )}
          <br />
          {t('The server will generate the report, and an excel file will be downloaded to you.')}
        </p>
      </Card.Header>
      <FormFilterCard
        onClear={() => {}}
        title={t('Report Parameters')}
        onSubmit={handleFiltersSubmit}
        initialValues={{
          reportId: 'deliveriesAnalytical',
          fromDate: moment().startOf('month').toDate(),
          tillDate: moment().endOf('month').toDate(),
        }}
      >
        <Row>
          <FieldSelectReports as={FormCol} />
          <FieldDateRanger
            as={FormCol}
            label={t('Time Period from')}
            label2={t('till')}
            name="fromDate"
            name2="tillDate"
          />
        </Row>
      </FormFilterCard>
    </Card>
  );
};
