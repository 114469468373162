import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { toastSuccess } from 'utils/toasts';
import { FaUnlock, FaLock } from 'react-icons/fa';
import { promiseModalDialog } from 'motion-components';

const gqlSetAutoApproveAll = gql`
  mutation setAutoApproveAll($value: Boolean) {
    setAutoApproveAll(value: $value)
  }
`;

const UseAutoApproveAll = () => {
  const [updateRecord] = useMutation(gqlSetAutoApproveAll);
  const autoApproveAll = async (value) => {
    const ret = await updateRecord({
      variables: { value: value },
    });
    console.log('$$$', ret);
    return ret;
  };
  return { autoApproveAll };
};

export const AutoApproveAllButton = ({ onClick, value, disabled = false }) => {
  const { t } = useTranslation();
  const { autoApproveAll } = UseAutoApproveAll();

  const handleSubmit = async () => {
    const res = await promiseModalDialog({
      title:
        value === true ? t('Enable Auto Approve Deliveries') : t('Disable Auto Approve Deliveries'),
      text:
        value === true
          ? t('Are you sure you want to enable auto approve deliveries for all Valorisers?')
          : t('Are you sure you want to disable auto approve deliveries for all Valorisers?'),
    });
    if (res) {
      await autoApproveAll(value);
      toastSuccess(
        value === true
          ? t('Successfully enabled auto approve deliveries')
          : t('Successfully disabled auto approve deliveries'),
      );
      onClick();
    }
  };
  return (
    <>
      <Button
        disabled={disabled}
        variant={value === true ? 'success' : 'danger'}
        onClick={handleSubmit}
        className="ml-3 mr-4 mt-3"
      >
        {value === true ? <FaUnlock className="mb-1 mr-3" /> : <FaLock className="mb-1 mr-3" />}
        <span>
          {value === true
            ? t('Enable Auto Approve Deliveries')
            : t('Disable Auto Approve Deliveries')}
        </span>
      </Button>
    </>
  );
};
