import React, { useEffect, useMemo, useCallback } from 'react';
import { gql, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { gqlGetUserRole, gqlAddUserRole, gqlUpdateUserRole, gqlDeleteUserRole } from './graphql';
import { useParams, useHistory } from 'react-router-dom';
import { YupStringReq, FormCard, YupBoolean, YupNumeric, UserTooltip } from 'motion-components';
import * as Yup from 'yup';
import * as Lo from 'lodash';
import { isNumeric } from 'utils/helpers';
import { toastSuccess } from 'utils/toasts';
import { promiseModalDialog } from 'motion-components';
import { useRootStore } from 'store';
import { useHandleError } from 'services';
import { useTranslation } from 'react-i18next';

import UserRoleForm from './user-role-form';

/*
    User Role Page
*/
export const UserRolePage = () => {
  const { t } = useTranslation();
  const { handleGraphqlError } = useHandleError('user-role');
  const { authStore } = useRootStore();
  const client = useApolloClient();

  const formShape = {
    id: YupNumeric,
    name: YupStringReq,
    isActive: YupBoolean,
    isSystem: YupBoolean,
  };

  const validationSchema = Yup.object().shape(formShape);
  const pickProperties = (values) => Lo.pick(values, Lo.keys(formShape));
  type FormValuesType = Yup.InferType<typeof validationSchema>;

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const idNum = isNumeric(id) ? parseInt(id) : -1;
  const isNew = idNum < 0;

  const newRecord = useMemo<FormValuesType>(
    () =>
      ({
        id: -1,
        name: null,
        isActive: true,
      } as FormValuesType),
    [],
  );

  const [addRecord] = useMutation(gqlAddUserRole);
  const [updateRecord] = useMutation(gqlUpdateUserRole);
  const [deleteRecord] = useMutation(gqlDeleteUserRole);
  const [get, { data: rawData, error, refetch }] = useLazyQuery(gqlGetUserRole);
  const data = isNew ? newRecord : rawData ? rawData.getUserRoleById : undefined;

  /* fetch data */
  useEffect(() => {
    if (!isNew) {
      get({ variables: { id: idNum } });
    }
  }, [get, id, idNum, isNew]);

  /*
   * Form Validation
   */

  const isUserRoleNameUnique = useCallback(
    async (userRoleName: string, userRoleId: number) => {
      const where = {
        name: userRoleName,
        id: { $ne: userRoleId },
      };

      const ret = await client.query({
        query: gql`
          query getUserRoles($where: String) {
            getUserRoles(where: $where) {
              recordCount
            }
          }
        `,
        variables: {
          where: JSON.stringify(where),
        },
        fetchPolicy: 'network-only',
      });
      return (ret?.data?.getUserRoles?.recordCount ?? 0) === 0;
    },
    [client],
  );

  const formValidation = useCallback(
    async (values) => {
      const msgFieldIsRequired = 'Το πεδίο είναι υποχρεωτικό';
      const error: any = {};

      // UserName === Unique
      if (!(await isUserRoleNameUnique(values.name, values.id))) {
        error.userName = 'Το όνομα αυτό υπάρχει. Δηλώστε άλλο όνομα.';
      }

      return error;
    },
    [isUserRoleNameUnique],
  );

  /**
   * * ΑΠΟΘΗΚΕΥΣΗ - UPDATE/CREATE
   */
  const handleSubmit = (values: FormValuesType, actions) => {
    actions.setSubmitting(false);

    // Αν είναι νέα εγγραφή τοτε γράφω όλα τα πεδία
    if (isNew) {
      console.log('[User] New:', values);
      addRecord({ variables: { data: { ...values } } })
        .then((d) => {
          toastSuccess(`Επιτυχής Προσθήκη Εγγραφής [${d.data.addUserRole.id}]`);
          history.goBack();
        })
        .catch((error) => {
          handleGraphqlError('mutation-add', error);
        });
    } else {
      // Αν είναι update εγγραφής τοτε γράφω μονο τα αλλαγμένα πεδία
      console.log('[User] Update:', values);
      updateRecord({ variables: { data: { ...values, id: idNum } } })
        .then((d) => {
          actions.setFieldValue('userPassword', undefined);
          toastSuccess(`Επιτυχής Διόρθωση Εγγραφής [${d.data.updateUserRole.id}]`);
          refetch();
        })
        .catch((error) => {
          handleGraphqlError('mutation-update', error);
        });
    }
  };

  /**
   * *  DELETE
   */
  const handleDelete = useCallback(async () => {
    const res = await promiseModalDialog({
      title: t('Delete File Record'), //'Διαγραφή Εγγραφής Αρχείου',
      text: t('Do you really want to delete the file;'), //'Είστε σίγουροι ότι θέλετε να διαγράψετε το Αρχείο;',
    });
    if (res) {
      deleteRecord({ variables: { id: idNum } })
        .then(() => {
          toastSuccess(t('The record has been successfully deleted'));
          history.goBack();
        })
        .catch((error) => {
          handleGraphqlError('mutation-delete', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRecord, idNum]);

  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <FormCard
        isNew={isNew}
        title={isNew ? `${t('New User Role')}` : `${t('User Role')}: ${data?.name}`}
        onSubmit={handleSubmit}
        onReturn={null}
        onDelete={handleDelete}
        permitDelete={authStore.isUsersAdmin && !data?.isSystem}
        initialValues={pickProperties(data)}
        validationSchema={validationSchema}
        validate={formValidation}
        enableReinitialize
        idx={idNum}
      >
        <UserRoleForm data={data} isNew={isNew} />
        <div className="float-right">
          {data && !isNew && <UserTooltip data={data} /* auditTable={'user_roles'} */ />}
        </div>
      </FormCard>
    );
  }
};
