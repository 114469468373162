import React from 'react';
import { FieldSelectDB } from 'motion-components';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';

const gqlGetValues = gql`
  query getTirecollectionsStatuses($where: String, $offset: Int, $limit: Int, $order: String) {
    getTirecollectionsStatuses(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        id: value
        description: name
      }
      recordCount
    }
  }
`;

function useGetValues() {
  return useLazyQuery(gqlGetValues, { fetchPolicy: 'cache-first' });
}

export const FieldSelectTirecollectionsStatuses = ({
  label = 'Status',
  name = 'statusId',
  defaultValues = {},
  ...rest
}: any) => {
  return (
    <FieldSelectDB
      {...rest}
      label={label}
      name={name}
      sbLabel={(defaultValues as any)?.status?.name ?? undefined}
      hook={useGetValues}
    />
  );
};
