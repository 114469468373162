import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FieldDatepicker, FieldInput, FormCol, FormRow, FieldCheckBox } from 'motion-components';
import { useFormikContext } from 'formik';
import { useHandleError } from 'services';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';

export const FormFields = ({ isNew, updateRecord, refetch, data }) => {
  const { t } = useTranslation();
  //
  const frmkCntx = useFormikContext();
  const { values } = frmkCntx;
  const { authStore } = useRootStore();

  const { handleGraphqlError } = useHandleError('Collectionpoints_typesFormFields');

  function calcUserCanEdit() {
    const canEdit = true;
    const canEditReasoning = '';
    return { canEdit, canEditReasoning };
  }
  // const canEdit =
  //   !(values as any)?.isFinalized && (authStore.userCanDraft(userRoleId) || authStore.userCanFinalize(userRoleId));
  const { canEdit, canEditReasoning } = calcUserCanEdit();

  return (
    <>
      <Row>
        <Col>
          <FieldInput
            disabled={!canEdit}
            as={FormCol}
            label={t('Collection Point Type')}
            name="name"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldCheckBox disabled={!canEdit} as={FormCol} label={t('Active')} name="isActive" />
        </Col>
      </Row>
    </>
  );
};
