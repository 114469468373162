import React, { useEffect, useMemo } from 'react';
import {
  FormCol,
  FormRow,
  FieldInput,
  FieldCheckBox,
  promiseModalInput,
  FieldDatepicker,
  toastSuccess,
  TableSubSimple,
  FlexRow,
  promiseModalDialog,
} from 'motion-components';
import { useRootStore } from 'store';
import { useFormikContext } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import { FileRepoField } from 'components/filerepo-field';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { FileRepoImage } from 'components/filerepo-image';
// import { FileRepoImage } from 'components/filerepo-image';
import { useTranslation } from 'react-i18next';
import {
  FieldContainer,
  FieldIsActive,
  FieldSelectUserType,
  FieldSelectValoriser,
} from 'components';
import { FieldSelectCollector } from 'components/fs-collector';
import { UserTypeValues } from 'shared/constants';
import { gqlAddUsersValoriser, gqlDeleteUsersValoriser, gqlGetUsersValorisers } from './graphql';
import promiseModalSelectValoriser from 'components/modal-select-valoriser';
import { useHandleError } from 'services';
import { FaMinusCircle } from 'react-icons/fa';
import { FieldSelectCollectionpoint } from 'components/fs-collectionpoint';

const UserForm = ({ isNew, data, children }) => {
  const { t } = useTranslation();
  const { handleGraphqlError } = useHandleError('UserForm');
  const { appStore, authStore } = useRootStore();
  const { values, setFieldValue } = useFormikContext<any>();
  const [addUsersValoriser] = useMutation(gqlAddUsersValoriser);
  const [deleteUsersValoriser] = useMutation(gqlDeleteUsersValoriser);

  const [get, { data: rawData, error, refetch }] = useLazyQuery(gqlGetUsersValorisers, {
    fetchPolicy: 'no-cache',
  });

  const dataValorisers = rawData?.getUsersValorisers?.data;

  /* fetch data */
  useEffect(() => {
    if (values?.usertypeId === UserTypeValues.Valoriser && values?.isMultipleVal === true) {
      get({ variables: { where: JSON.stringify({ userId: data.id }) } });
    }
  }, [get, values]);

  useEffect(() => {
    values.isMultipleVal === true && setFieldValue('valoriserId', null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.isMultipleVal]);

  const handleChangePw = async () => {
    const password = await promiseModalInput({
      title: t('Change Password'),
      text: t('Enter new password code:'),
      type: 'password',
    });
    if (password) {
      console.log('pw is ', password);
      setFieldValue('userPassword', password);
    }
  };

  const handleSubmit = async () => {
    const res = await promiseModalSelectValoriser({
      title: t(`Add Valoriser`),
      text: ``,
    });
    if (res) {
      console.log(res);
      addUsersValoriser({ variables: { data: { userId: data.id, valoriserId: res.valoriserId } } })
        .then(() => {
          toastSuccess(t(`Record successfully added`));
          refetch();
        })
        .catch((error) => {
          handleGraphqlError('mutation-add', error);
        });
    }
  };

  const handleDeleteValoriser = async (row, data) => {
    const res = await promiseModalDialog({
      title: t(`Remove Valoriser`),
      text: `${t('Are you sure you want to remove the valoriser')}: ${row.valoriser.name}?`,
    });
    if (res) {
      deleteUsersValoriser({ variables: { userId: data.id, valoriserId: row.valoriserId } })
        .then(() => {
          toastSuccess(t(`Record successfully removed`));
          refetch();
        })
        .catch((error) => {
          handleGraphqlError('mutation-delete', error);
        });
    }
  };

  const columns = useMemo(
    () => [
      { Header: t(`Valorisers`), accessor: 'valoriser.name' },
      ...(appStore.isEcoanvelope
        ? [
            {
              Header: '',
              accessor: 'action',
              Cell: ({ row }) =>
                appStore.isEcoanvelope && (
                  <div className="text-center">
                    <FaMinusCircle
                      onClick={() => handleDeleteValoriser(row.original, data)}
                      style={{ color: 'red', cursor: 'pointer' }}
                    />
                  </div>
                ),
            },
          ]
        : []),
    ],
    [appStore.isEcoanvelope, dataValorisers, data, t],
  );

  const gqlAddFilesRepository = gql`
    mutation addFilesrepository($file: Upload!) {
      addFilesrepository(file: $file)
    }
  `;
  const [addFile] = useMutation(gqlAddFilesRepository);

  const canEdit =
    appStore.isEcoanvelope || authStore.isUsersAdmin || data?.userId === authStore.userId;

  return (
    <>
      <FormRow>
        <FormCol>
          <FieldInput
            disabled={!isNew}
            as={FieldContainer}
            label={t('User')}
            name="userName"
            placeholder={`- ${t('specify an email address')} -`}
            required
          />
          <FieldInput
            as={FieldContainer}
            label={t('First Name')}
            name="firstName"
            placeholder={`- ${t('name')} -`}
            disabled={!canEdit}
            required
          />
          <FieldInput
            as={FieldContainer}
            label={t('Last Name')}
            name="lastName"
            placeholder={`- ${t('surname')} -`}
            disabled={!canEdit}
            required
          />
          <FieldInput
            as={FieldContainer}
            label={t('Job Position')}
            name="position"
            placeholder={`- ${t('position')} -`}
            disabled={!canEdit}
          />
        </FormCol>
        <FormCol>
          <FieldInput
            as={FieldContainer}
            label={t('E-mail')}
            name="email"
            placeholder={`- ${t('e-mail')} -`}
            disabled={!canEdit}
          />
          <FieldInput
            as={FieldContainer}
            label={t('Telephone')}
            name="tel"
            placeholder={`- ${t('telephone')} -`}
            disabled={!canEdit}
          />
          <FieldInput
            as={FieldContainer}
            label={t('Mobile')}
            name="mobile1"
            placeholder={`- ${t('mobile')} -`}
            disabled={!canEdit}
          />
        </FormCol>
        <FormCol>
          <FieldIsActive as={FieldContainer} disabled={!canEdit} />

          <FieldSelectUserType
            label={t('User Type')}
            disabled={!authStore.isUsersAdmin}
            defaultValues={data}
            as={FieldContainer}
          />

          {[UserTypeValues.CollectorDriver, UserTypeValues.Collector].includes(
            values.usertypeId,
          ) && (
            <FieldSelectCollector
              label={t('Collector')}
              disabled={!authStore.isUsersAdmin}
              defaultValues={data}
              as={FieldContainer}
            />
          )}
          {UserTypeValues.CollectorDriver === values.usertypeId && (
            <>
              <FieldInput
                as={FieldContainer}
                label={t('Driver License Number')}
                name="drvLicenseNumber"
                placeholder={`- ${t('License')} -`}
                disabled={!canEdit}
              />
              <FieldDatepicker
                as={FieldContainer}
                label={t('License Expiration Date')}
                name="drvLicenseExpDate"
                disabled={!canEdit}
              />
              <FieldInput
                as={FieldContainer}
                label={t('Vehicle Plate Number')}
                name="defVehiclePlate"
                placeholder={`- ${t('Plate Number')} -`}
                disabled={!canEdit}
              />
              <FieldInput
                as={FieldContainer}
                label={t('Trailer Plate Number')}
                name="defTrailerPlate"
                placeholder={`- ${t('Plate Number')} -`}
                disabled={!canEdit}
              />
            </>
          )}

          {UserTypeValues.Valoriser === values.usertypeId && (
            <>
              <FieldSelectValoriser
                label={t('Valoriser')}
                disabled={!authStore.isUsersAdmin || values?.isMultipleVal === true}
                defaultValues={data}
                as={FieldContainer}
              />
            </>
          )}
          {UserTypeValues.CollectionPoint === values.usertypeId && (
            <FieldSelectCollectionpoint
              label={t('Collection Point')}
              disabled={!authStore.isUsersAdmin}
              defaultValues={data}
              as={FieldContainer}
            />
          )}
        </FormCol>
        <FormCol>
          {values.usertypeId === UserTypeValues.CollectorDriver && isNew && (
            <FieldInput
              as={FormCol}
              required
              type="password"
              tooltip={t('Enter Password')}
              label={t('Password')}
              name="userPassword"
              placeholder={t('- user password -')}
            />
          )}
          {values.usertypeId === UserTypeValues.CollectorDriver && (
            <>
              <FieldCheckBox
                disabled={!appStore.isUserAdmin}
                name="canSeeCol"
                label={t('Can See Collections')}
              />
              <FieldCheckBox
                disabled={!appStore.isUserAdmin}
                name="canSeeDel"
                label={t('Can See Deliveries')}
              />
              <FieldInput
                as={FieldContainer}
                label={t('Transporter')}
                name="drvTransporter"
                placeholder={`- ${t('transporter')} -`}
                disabled={!canEdit}
              />
              <FieldInput
                as={FieldContainer}
                label={t('Transport License')}
                name="drvLicenseTransport"
                placeholder={`- ${t('License')} -`}
                disabled={!canEdit}
              />
              <FieldDatepicker
                as={FieldContainer}
                label={t('Transport License Expiration Date')}
                name="drvLicenseTransportExpDate"
                disabled={!canEdit}
              />
              <FieldInput
                as={FieldContainer}
                label={t('B.I/C.I')}
                name="drvBINumber"
                //placeholder={`- ${t('Plate Number')} -`}
                disabled={!canEdit}
              />
              <FieldInput
                as={FieldContainer}
                label={t('Issued By')}
                name="drvIssuedBy"
                //placeholder={`- ${t('transporter')} -`}
                disabled={!canEdit}
              />
            </>
          )}
          <FlexRow>
            {values?.usertypeId === UserTypeValues.Valoriser && (
              <FieldCheckBox
                disabled={!appStore.isEcoanvelope}
                name="isMultipleVal"
                label={t('Has Multiple Valorisers')}
              />
            )}

            {values?.isMultipleVal === true && appStore.isEcoanvelope && (
              <Button className="mt-4" variant="primary" onClick={handleSubmit}>
                {t('Add Valoriser')}
              </Button>
            )}
          </FlexRow>

          {values?.isMultipleVal === true &&
            dataValorisers &&
            dataValorisers.length > 0 &&
            values?.usertypeId === UserTypeValues.Valoriser && (
              <FormCol>
                <div className="mt-4">
                  <TableSubSimple
                    title={t('Valorisers')}
                    //onRefresh={() => refetch()}
                    //onExport={handleExport}
                    //onCreate={!appStore.isHellaktor ? null : () => handleSubmit()}
                    columns={columns}
                    data={dataValorisers}
                    //hasSearch={false}
                    //hasFooter={false}
                  />
                </div>
              </FormCol>
            )}
        </FormCol>
      </FormRow>

      {!isNew && (
        <Row>
          <FileRepoField
            label={t('User Signature File')}
            fileIdFieldName="signatureFileId"
            fileRec={data?.signatureFileRec}
          />
          <FileRepoImage fileId={data?.signatureFileRec?.id} height="64px" />
        </Row>
      )}

      {children}
    </>
  );
};

export default UserForm;
