import gql from 'graphql-tag';
import { useDbBase } from './db-base-service';

/*
    hook function
*/
export const useAuditTrailDb = (client) => {
  const dbBase = useDbBase(client);

  async function getIdNameQueryById(queryProc: string, id: number) {
    const gqlDoc = gql`
      query ${queryProc}($id: Int) {
        ${queryProc}(id: $id) {
          id
          name
        }
      }
    `;
    const res = await dbBase.execQuery(gqlDoc, { id: id }).catch((err) => {
      throw new Error(err);
    });
    if (res.data[queryProc] === null) {
      if ((res as any).errors) {
        throw new Error((res as any).errors[0].message);
      }
    }
    return res.data[queryProc].name;
  }

  async function getCollectionPointNameById(id: number) {
    return getIdNameQueryById('getCollectionpointById', id);
  }

  async function getCollectorNameById(id: number) {
    return getIdNameQueryById('getCollectorById', id);
  }

  async function getValoriserNameById(id: number) {
    return getIdNameQueryById('getValoriserById', id);
  }

  // export -------------------------------------------------------
  return {
    getCollectionPointNameById,
    getCollectorNameById,
    getValoriserNameById,
  };
};
