import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useRootStore } from 'store';
import {
  BootstrapSwitchButton,
  CellCenter,
  CellCheckbox,
  CellLeft,
  FormCol,
  TableSubSimple,
  toastSuccess,
  CustomSelectComponent,
} from 'motion-components';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHandleError } from 'services';
import { useTranslation } from 'react-i18next';
import { FieldSelectPlatform } from 'components/fs-platform';
import { gqlGetDistancesQuery, gqlUpdateCollectorsDistance } from '../graphql';
import { Formik } from 'formik';
import LoadingOverlay from 'react-loading-overlay';
import { Button, Form } from 'react-bootstrap';

export const CollectorDistancesTable = ({
  dataCol,
  dataCount = 0,
  initPageIndex = 1,
  onPageChange = () => {},
  handleExport = () => {},
  refetch,
}) => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const { appStore } = useRootStore();
  const { handleGraphqlError } = useHandleError('CollectorDistancesTable');
  const [platformId, setPlatformId] = useState(dataCol?.collectorsPlatforms[0]?.id ?? null);
  const [updateDistance] = useMutation(gqlUpdateCollectorsDistance);
  const {
    loading,
    data: rawData,
    error,
    refetch: refetchDistances,
  } = useQuery(gqlGetDistancesQuery, {
    variables: { where: JSON.stringify({ platformId: platformId }), order: 'valoriser.name ASC' },
    fetchPolicy: 'network-only',
  });
  const data = rawData?.getCollectorsDistances?.data;

  const [editable, setEditable] = useState<boolean>(false);
  const [tableValues, setTableValues] = useState([]);

  const handleGetTableValues = (id, value) => {
    setTableValues((prevValues) => prevValues.filter((row) => row.id !== id));
    setTableValues((prevValues) => [...prevValues, { id: id, distance: parseFloat(value) }]);
  };

  useEffect(() => {
    refetchDistances();
  }, [platformId]);

  const updateTableValues = async () => {
    appStore.setBusy();

    const result = await Promise.all(
      tableValues.map(async (row, i): Promise<any> => {
        await updateDistance({
          variables: { data: row },
        });
      }),
    );
    if (result) {
      appStore.clearBusy();
      refetch();
      refetchDistances();
      setEditable(false);
    }
  };

  const handleCancelEdit = async () => {
    setEditable(false);
    setTableValues([]);
  };

  const columns = useMemo(
    () => [
      { id: 'select', Header: t('Valoriser'), accessor: 'valoriser.name', Cell: CellLeft },
      {
        Header: t('Distance (km)'),
        accessor: 'distance',
        Cell: ({ row }) =>
          editable ? (
            <Form.Control
              className="text-center"
              type="number"
              defaultValue={row.original.distance}
              onChange={(e) => handleGetTableValues(row.original.id, e.target.value)}
            ></Form.Control>
          ) : (
            <div style={{ height: 'calc(1em + 0.5rem + 2px)' }} className="text-center">
              {row.original.distance}
            </div>
          ),
      },
    ],
    [authStore.isUsersAdmin, editable, platformId, data],
  );
  return (
    <div>
      <div className="form-group-title-onlybg mb-0">
        <h5 style={{ color: 'whitesmoke' }}>{t('Distances')}</h5>
        <br />
        <Formik initialValues={{ platformId: platformId }} onSubmit={(values) => {}}>
          <FieldSelectPlatform
            collectorId={dataCol.id}
            onChange={(e) => {
              setPlatformId(e.value);
            }}
            as={FormCol}
            label={t('Platform')}
          />
        </Formik>
      </div>
      <LoadingOverlay active={loading} spinner text={t('Please wait...')}>
        {!!data ? (
          <>
            <TableSubSimple title={t('Distances')} columns={columns} data={data} />
            {appStore.isEcoanvelope &&
              (!editable ? (
                <Button
                  size="sm"
                  style={{ width: '100px', marginTop: '10px', fontSize: '15px' }}
                  onClick={() => setEditable(true)}
                >
                  {t('Edit')}
                </Button>
              ) : (
                <div className="d-flex flex-row">
                  <Button
                    size="sm"
                    variant="success"
                    style={{ width: '100px', marginTop: '10px', fontSize: '15px' }}
                    onClick={updateTableValues}
                  >
                    {t('Save')}
                  </Button>
                  <span className="mx-2"></span>
                  <Button
                    size="sm"
                    variant="danger"
                    style={{ width: '100px', marginTop: '10px', fontSize: '15px' }}
                    onClick={handleCancelEdit}
                  >
                    {t('Cancel')}
                  </Button>
                </div>
              ))}
          </>
        ) : (
          <div style={{ height: '600px' }}></div>
        )}
      </LoadingOverlay>
    </div>
  );
};
