import React, { useCallback, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FieldDatepicker, FieldInput } from 'motion-components';
import { useTranslation } from 'react-i18next';
import { GetNewDeliveryNoteNumberButton } from '../auto-number/auto-deliveryNote-number-button';
import { GetNewLoadUnloadNoteNumberButton } from '../auto-number/auto-loadUnload-number-button';
import { PrintDeliveryNoteButton } from '../print-notes/deliveryNote/print-deliveryNote-button';
import { PrintLoadUnloadNoteButton } from '../print-notes/loadUnload/print-loadUnload-button';
import { FieldSelectDriver } from 'components/fs-driver';
import { useFormikContext } from 'formik';
import { useLazyQuery } from '@apollo/client';
import { gqlGetDriver } from 'pages/users/graphql';
import { FaUserPlus } from 'react-icons/fa';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { getRouteByName } from 'app/routes';
import { useRootStore } from 'store';

export const TabTruckLoading = ({ data, refetch, readonly }) => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const { values, setFieldValue } = useFormikContext<any>();
  const location = useLocation<any>();
  const history = useHistory();
  function calcUserCanEdit() {
    const canEdit = !readonly;
    const canEditReasoning = '';
    return { canEdit, canEditReasoning };
  }

  const [get] = useLazyQuery(gqlGetDriver, {
    onCompleted(data) {
      const driver = data.getUserById;
      setFieldValue('vehPlateNo', driver.defVehiclePlate);
      setFieldValue('trailerPlateNo', driver.defTrailerPlate);
      setFieldValue('driver', driver.fullName);
      setFieldValue('licenseTransport', driver.drvLicenseTransport);
      setFieldValue('licenseTransportExpDate', driver.drvLicenseTransportExpDate);
      setFieldValue('transporter', driver.drvTransporter);
      setFieldValue('biNumber', driver.drvBINumber);
      setFieldValue('issuedBy', driver.drvIssuedBy);
    },
  });
  //const driver = rawData ? rawData.getUserById : undefined;

  /* fetch driver */
  useEffect(() => {
    if (!!values.driverUserId && values.driverUserId !== data.driverUserId) {
      get({ variables: { id: values.driverUserId } });
    }
  }, [values.driverUserId]);

  const handleNewDriver = useCallback(() => {
    history.push({
      pathname: generatePath(getRouteByName('User'), { id: -1 }),
      state: {
        pathname: location.pathname,
        ...location.state,
      },
    });
  }, [history, location.pathname, location.state]);

  // const canEdit =
  //   !(values as any)?.isFinalized && (authStore.userCanDraft(userRoleId) || authStore.userCanFinalize(userRoleId));
  const { canEdit, canEditReasoning } = calcUserCanEdit();
  return (
    <>
      <h5 className="form-group-title-bg">{t('Collector Transportation')}</h5>
      <Row>
        <Col>
          <div className="d-flex flex-row align-items-center">
            <FieldInput
              disabled={!appStore.isEcoanvelope}
              as={Col}
              label={t('Delivery Note Number')}
              name="deliveryNoteNo"
              type="text"
              placeholder={t('- specify a value - ')}
              required={false}
            />
            {/* <GetNewDeliveryNoteNumberButton
              disabled={!canEdit}
              deliveryId={data.id}
              onClick={refetch}
            ></GetNewDeliveryNoteNumberButton> */}
            <span className="mx-2"></span>
            <PrintDeliveryNoteButton
              disabled={!canEdit || !data.deliveryNoteNo}
              deliveryId={data.id}
            ></PrintDeliveryNoteButton>
          </div>

          <div className="d-flex flex-row align-items-center">
            <FieldInput
              disabled={!appStore.isEcoanvelope}
              as={Col}
              label={t('Loading Unloading Number')}
              name="loadUnloadNoteNo"
              type="text"
              placeholder={t('- specify a value - ')}
              required={false}
            />
            {/* <GetNewLoadUnloadNoteNumberButton
              disabled={!canEdit}
              deliveryId={data.id}
              onClick={refetch}
            ></GetNewLoadUnloadNoteNumberButton> */}
            <span className="mx-2"></span>
            <PrintLoadUnloadNoteButton
              disabled={!canEdit || !data.loadUnloadNoteNo}
              deliveryId={data.id}
            ></PrintLoadUnloadNoteButton>
          </div>

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Distance in km')}
            name="distance"
            type="number"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <div className="d-flex flex-row align-items-center">
            <FieldSelectDriver
              as={Col}
              defaultValues={data}
              name="driverUserId"
              disabled={!canEdit}
              label={t('Select Driver')}
              collectorId={values.collectorId}
            />
            <Button
              disabled={false}
              variant="success"
              onClick={handleNewDriver}
              style={{ marginTop: '5px' }}
            >
              <FaUserPlus className="mb-1 mr-3" />
              <span className="ml-1">{t('Add New Driver')}</span>
            </Button>
          </div>

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Transporter')}
            name="transporter"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Vehicle Plate')}
            name="vehPlateNo"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />
          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Trailer Plate Number')}
            name="trailerPlateNo"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Driver')}
            name="driver"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Transport License')}
            name="licenseTransport"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldDatepicker
            disabled={!canEdit}
            as={Col}
            label={t('Transport License Expiration Date')}
            name="licenseTransportExpDate"
            dateFormat="d/M/yyyy"
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('B.I/C.I')}
            name="biNumber"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Issued By')}
            name="issuedBy"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Collector Notes')}
            name="collectorNotes"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Employee from Collector who declared the delivery')}
            name="collectorWeightsBy"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />
        </Col>
        <Col>
          <FieldDatepicker
            disabled={!canEdit}
            as={Col}
            label={t('Delivered Date')}
            name="collectorDeliveredDate"
            //showTimeSelect
            dateFormat="d/M/yyyy"
            timeFormat="HH:mm"
            timeIntervals={15}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Weighing Note Number')}
            name="collectorWeighingNoteNo"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Gross')}
            name="collectorGrossWeight"
            type="string"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Tare')}
            name="collectorTareWeight"
            type="string"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Deduction')}
            name="collectorDeductWeight"
            type="string"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Net')}
            name="collectorNetWeight"
            type="string"
            placeholder={t('- specify a value - ')}
            required={false}
          />
        </Col>
      </Row>
    </>
  );
};
