import React from 'react';
import { FlexRow, usePaginatedQuery } from 'motion-components';
import { Card } from 'react-bootstrap';
import { useRootStore } from 'store';
import { useHandleError } from 'services';
import { gqlGetValoriserQuery, gqlGetQuery } from '../graphql';
import { buildWhereFromFilterValues } from './filters';
import { DataGrid } from './grid';
import { handleExport } from './export-columns';
import { useApolloClient } from '@apollo/client';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFirstDayOfMonth, getLastDayOfMonth } from 'common/date-utils';
import { useQuery } from '@apollo/react-hooks';

/* ----------------------------------------------------------- */
/* ------------------------ Page ----------------------------- */
export const ValorisersStockTransactionsGridPage = () => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const { handleGraphqlError } = useHandleError('ValorisersStockTransactionsGridPage');
  const { valoriserId } = useParams<{ valoriserId: string }>();
  const { data: valoriserDataRaw } = useQuery(gqlGetValoriserQuery, {
    variables: { id: parseInt(valoriserId) },
    fetchPolicy: 'cache-and-network',
  });
  const valoriserData = valoriserDataRaw?.getValoriserById;

  const location = useLocation<any>();
  const history = useHistory();

  /* ------------------- Query ------------------------------- */
  const query = usePaginatedQuery(gqlGetQuery, buildWhereFromFilterValues, 'name', appStore, true, {
    valoriserId: valoriserId,
  });
  const {
    loading,
    data,
    dataCount,
    error,
    initPageIndex,
    filtersChanged,
    gridPageChanged,
    queryState,
  } = query;

  const client = useApolloClient();

  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <Card className="shadow">
        <Card.Header>
          <FlexRow>
            <Card.Title className="m-0">
              {t("Valorisers's Stock Detailed")}
              {valoriserData && (
                <span className="ml-3">
                  {t('Valoriser')}: <span className="text-primary">{valoriserData.name}</span>,
                </span>
              )}
            </Card.Title>
          </FlexRow>
        </Card.Header>
        <Card.Body className="nopadding">
          {/* Grid */}
          <h5>{t('Detailed Transactions')}</h5>
          <DataGrid
            loading={loading}
            data={data}
            dataCount={dataCount}
            initPageIndex={initPageIndex}
            onPageChange={gridPageChanged}
            handleExport={() => {
              handleExport(client, queryState);
            }}
          />
        </Card.Body>
      </Card>
    );
  }
};
