import gql from 'graphql-tag';

export class FormInput {
  id: number;
  name: string;
  email: string;
  isActive: boolean;
  firstDate: Date;
  lastDate: Date;
  firstUser: string;
  lastUser: string;
}

export const gqlGetQuery = gql`
  query getValorisersGroups($limit: Int, $offset: Int, $order: String, $where: String) {
    getValorisersGroups(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        firstDate
        firstUser
        id
        isActive
        lastDate
        lastUser
        name
        email
      }
      recordCount
    }
  }
`;

export const gqlGetByIdQuery = gql`
  query getValorisersGroupById($id: Int) {
    getValorisersGroupById(id: $id) {
      firstDate
      firstUser
      id
      isActive
      lastDate
      lastUser
      name
      email
    }
  }
`;

export const gqlAdd = gql`
  mutation addValorisersGroup($data: ValorisersGroupInput) {
    addValorisersGroup(data: $data) {
      firstDate
      firstUser
      id
      isActive
      lastDate
      lastUser
      name
      email
    }
  }
`;

export const gqlUpdate = gql`
  mutation updateValorisersGroup($data: ValorisersGroupInput) {
    updateValorisersGroup(data: $data) {
      firstDate
      firstUser
      id
      isActive
      lastDate
      lastUser
      name
      email
    }
  }
`;

export const gqlDelete = gql`
  mutation deleteValorisersGroup($id: Int) {
    deleteValorisersGroup(id: $id)
  }
`;
