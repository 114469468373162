import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery, useApolloClient } from '@apollo/client';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import {
  FormCard,
  YupNumeric,
  YupDate,
  YupString,
  YupBoolean,
  promiseModalDialog,
  UserTooltip,
  objParseInt,
  objParseFloat,
  YupNumericReq,
  promiseModalInput,
} from 'motion-components';
import * as Yup from 'yup';
import * as Lo from 'lodash';
import { toastSuccess } from 'utils/toasts';
import { useHandleError } from 'services';
import {
  gqlGetByIdQuery,
  FormInput,
  gqlAcceptCollectorInventoryCount,
  gqlRejectCollectorInventoryCount,
  gqlAdd,
  gqlDelete,
} from '../graphql';
import { isNumeric } from 'utils/helpers';

import { FormFields } from './form-fields';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';
import { FormFieldsNewRequest } from './form-fields-new-request';
import { FormFieldsApproveRequest } from './form-fields-approve';
import { getInventoryCountStatusBadge } from 'common/badges-helpfunc';
import { getRouteByName } from 'app/routes';

export const CollectorsStocktakingHdrFormPage = () => {
  const { t } = useTranslation();
  const { handleGraphqlError } = useHandleError('Collectors_stocktaking_hdrFormPage');
  const { appStore } = useRootStore();
  const history = useHistory();
  const location = useLocation<any>();
  const { id } = useParams<{ id: string }>();
  const idNum = isNumeric(id) ? parseInt(id) : -1;
  const isNew = idNum < 0;

  const formShape = {
    id: YupNumeric,
    collectorId: YupNumeric,
    platformId: YupNumericReq,
    stockDate: YupDate,
    requestDate: YupDate,
    requestedById: YupNumeric,
    approvedDate: YupDate,
    approvedById: YupNumeric,
    approvedNotes: YupString,
    approved: Yup.boolean().nullable(),
    collectorsStocktakingLns: Yup.array().of(
      Yup.object().shape({
        pieces: YupNumeric,
      }),
    ),
  };

  const validationSchema = Yup.object().shape(formShape);
  const pickProperties = (values) => Lo.pick(values, Lo.keys(formShape));
  type FormValuesType = Yup.InferType<typeof validationSchema>;

  const [addRecord] = useMutation(gqlAdd);
  const [deleteRecord] = useMutation(gqlDelete);
  const [accept] = useMutation(gqlAcceptCollectorInventoryCount);
  const [reject] = useMutation(gqlRejectCollectorInventoryCount);

  const newRecord = useMemo<FormValuesType>(
    () =>
      ({
        id: -1,
        collectorId: appStore.isCollector ? location.state.collectorId : null,
        platformId: null,
        stockDate: new Date().setHours(0, 0, 0, 0),
        collectorsStocktakingLns: null,
        approved: null,
      } as FormValuesType),
    [location.state.collectorId],
  );

  const [get, { data: rawData, error, refetch }] = useLazyQuery(gqlGetByIdQuery, {
    fetchPolicy: 'network-only',
  });
  const data = isNew ? newRecord : rawData ? rawData[Object.keys(rawData)[0]] : undefined;

  /* fetch data */
  useEffect(() => {
    if (!isNew) get({ variables: { id: idNum } });
  }, [get, id, idNum, isNew]);

  /**
   * * ΑΠΟΘΗΚΕΥΣΗ - UPDATE/CREATE
   */
  const handleSubmit = (values: FormValuesType, actions) => {
    actions.setSubmitting(false);
    let mutValues = Lo.pick(values, Lo.keys(new FormInput()));
    mutValues = objParseInt(
      ['id', 'collectorId', 'platformId', 'requestedById', 'approvedById'],
      mutValues,
    );
    mutValues = objParseFloat([], mutValues);
    // Αν είναι νέα εγγραφή τοτε γράφω όλα τα πεδία
    if (isNew) {
      console.log('[Collectors_stocktaking_hdr] New:', mutValues);
      addRecord({ variables: { data: { ...mutValues } } })
        .then((d) => {
          toastSuccess(
            `${t('Record Created Successfully')} [${d.data[Object.keys(d.data)[0]]?.id}]`,
          );
          if (appStore.isEcoanvelope) {
            history.push({
              pathname: generatePath(getRouteByName('CollectorsInventoryCounts')),
              state: {
                pathname: location.pathname,
                ...location.state,
              },
            });
          } else history.goBack();
        })
        .catch((error) => {
          handleGraphqlError('mutation-add', error);
        });
    }
  };

  /**
   * * APPROVE/REJECT
   */
  const handleApprove = async (answer) => {
    if (answer === 'accept') {
      accept({ variables: { inventoryCountId: idNum } })
        .then((d) => {
          toastSuccess(
            `${t('Successfully Accepted Request')} [${d.data.acceptCollectorInventoryCount}]`,
          );
          refetch();
        })
        .catch((error) => {
          handleGraphqlError('mutation-accept', error);
        });
    } else {
      // reject
      const res = await promiseModalInput({
        title: t('Justification'),
        text: t('Specify reason of rejection'),
      });
      if (res) {
        console.log(res);
        reject({ variables: { inventoryCountId: idNum, rejectReason: res } })
          .then(() => {
            refetch();
          })
          .catch((error) => {
            handleGraphqlError('mutation-reject', error);
          });
      }
    }
  };

  /**
   * *  DELETE
   */
  const handleDelete = useCallback(async () => {
    const res = await promiseModalDialog({
      title: `${t('Delete Record')}`,
      text: `${t('The record will be permanently deleted. Do you want to continue?')}`,
    });
    if (res) {
      deleteRecord({ variables: { id: idNum } })
        .then(() => {
          toastSuccess(t('The record has been successfully deleted'));
          history.goBack();
        })
        .catch((error) => {
          handleGraphqlError('mutation-delete', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRecord, idNum]);

  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <FormCard
        title={
          isNew ? (
            `${t('New Inventory Count')}`
          ) : (
            <>
              <span>{`${t('Inventory Count')}: ${data?.collectorsPlatform.name}`}</span>
              &nbsp;&nbsp;
              <div
                className={'badge pb-2 pt-1 mr-2 ' + getInventoryCountStatusBadge(data?.approved)}
              >
                {t(
                  data?.approved === true
                    ? t('Approved')
                    : data?.approved === false
                    ? t('Rejected')
                    : t('Awaiting for Approval'),
                )}
              </div>
            </>
          )
        }
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        onReturn={null}
        permitDelete={false}
        initialValues={pickProperties(data)}
        validationSchema={validationSchema}
        enableReinitialize
        idx={idNum}
        isNew={isNew}
      >
        {isNew && <FormFieldsNewRequest data={data} />}
        {!isNew && data && data?.approved === null && (
          <FormFieldsApproveRequest data={data} handleApprove={handleApprove} />
        )}
        {data && data?.approved !== null && (
          <FormFields isNew={isNew} refetch={refetch} data={data} />
        )}
      </FormCard>
    );
  }
};
