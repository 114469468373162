import gql from 'graphql-tag';

export class FormInput {
  id: number;
  name: string;
  isActive: boolean;
  firstDate: Date;
  lastDate: Date;
  firstUser: string;
  lastUser: string;
}

export const gqlGetQuery = gql`
  query getRegions($limit: Int, $offset: Int, $order: String, $where: String) {
    getRegions(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        id
        name
        isActive
        firstDate
        firstUser
        lastDate
        lastUser
      }
      recordCount
    }
  }
`;

export const gqlGetByIdQuery = gql`
  query getRegionById($id: Int) {
    getRegionById(id: $id) {
      id
      name
      isActive
      firstDate
      firstUser
      lastDate
      lastUser
    }
  }
`;

export const gqlAdd = gql`
  mutation addRegion($data: RegionInput) {
    addRegion(data: $data) {
      id
      isActive
      name
      firstDate
      firstUser
      lastDate
      lastUser
    }
  }
`;

export const gqlUpdate = gql`
  mutation updateRegion($data: RegionInput) {
    updateRegion(data: $data) {
      id
      isActive
      name
      firstDate
      firstUser
      lastDate
      lastUser
    }
  }
`;

export const gqlDelete = gql`
  mutation deleteRegion($id: Int) {
    deleteRegion(id: $id)
  }
`;
