import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { toastSuccess } from 'utils/toasts';
import { FaEyeSlash } from 'react-icons/fa';
import { promiseModalDialog } from 'motion-components';

const gqlDeclarePointMissing = gql`
  mutation declarePointMissing($tirecollectionId: Int) {
    declarePointMissing(tirecollectionId: $tirecollectionId)
  }
`;

const UseDeclarePointMissing = () => {
  const [updateRecord] = useMutation(gqlDeclarePointMissing);
  const declarePointMissing = async (data) => {
    const ret = await updateRecord({
      variables: data,
    });
    console.log('$$$', ret);
    return ret;
  };
  return { declarePointMissing };
};

export const DeclarePointMissingButton = ({ data, onClick, disabled = false }) => {
  const { t } = useTranslation();
  const { declarePointMissing } = UseDeclarePointMissing();

  const handleSubmit = async () => {
    const res = await promiseModalDialog({
      title: `${t('Collection Point nonexistent')}`,
      text: `${t('Are you sure the Collection Point does not exist?')}`,
    });
    if (res) {
      await declarePointMissing({ tirecollectionId: data.id }).then(() => {
        toastSuccess(
          `${t('Successfullly declared the Collection Point with ID')} ${
            data.collectionpointId
          } ${t('as nonexistent')}`,
        );
        onClick();
      });
    }
  };

  return (
    <>
      <Button disabled={disabled} onClick={handleSubmit} className="ml-3 mr-4" variant="warning">
        <FaEyeSlash className="mb-1 mr-3" />
        <span>{t('The Collection Point does not exist!')}</span>
      </Button>
    </>
  );
};
