import React, { useEffect, useCallback, useMemo, useState } from 'react';
import {
  FieldDatepicker,
  FieldInput,
  FieldTextArea,
  FormCol,
  FormCol6,
  FormRow,
} from 'motion-components';
import { useFormikContext } from 'formik';
import { useHandleError } from 'services';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';

export const FormFields = ({ isNew, updateRecord, refetch, data }) => {
  //
  const { t } = useTranslation();

  const frmkCntx = useFormikContext();
  const { values } = frmkCntx;
  const { authStore } = useRootStore();

  const { handleGraphqlError } = useHandleError('PiBaseFormFields');

  function calcUserCanEdit() {
    const canEdit = false;
    const canEditReasoning = '';
    return { canEdit, canEditReasoning };
  }
  const { canEdit, canEditReasoning } = calcUserCanEdit();

  return (
    <>
      <FormRow>
        <FieldDatepicker
          disabled={!canEdit}
          as={FormCol}
          label={t('Date')}
          name="logDate"
          showTimeSelect
          dateFormat="d/M/yyyy HH:mm"
          timeFormat="HH:mm"
          timeIntervals={15}
        />

        <FieldInput
          disabled={!canEdit}
          as={FormCol}
          label={t('Event Type')}
          name="logType"
          required={false}
        />

        <FieldInput
          disabled={!canEdit}
          as={FormCol}
          label={t('Event Source')}
          name="originatedFrom"
          required={false}
        />

        <FieldInput
          disabled={!canEdit}
          as={FormCol}
          label={t('Grouping')}
          name="grouping"
          required={false}
        />
      </FormRow>
      <FormRow>
        <FieldTextArea
          disabled={!canEdit}
          as={FormCol6}
          label={t('Message')}
          name="message"
          required={false}
          rows={6}
        />

        <FieldInput
          disabled={!canEdit}
          as={FormCol}
          label={t('User')}
          name="user"
          required={false}
        />

        <FieldInput
          disabled={!canEdit}
          as={FormCol}
          label={t('Ip Address')}
          name="clientIp"
          required={false}
        />
      </FormRow>
    </>
  );
};
