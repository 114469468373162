import { useLazyQuery } from '@apollo/client';
import { gqlGetQuery } from '../graphql';
import { buildWhereFromFilterValues } from './filters';
import { useState } from 'react';
import { exportXlsModal } from 'motion-components';
import promiseModalSelectColumns from 'components/modal-select-columns';
import _ from 'lodash';

export const exportColumns = (t) => {
  return [
    // { fieldName: "id", fieldTitle: t("Id", fieldType: "TEXT" },
    { fieldName: 'id', fieldTitle: t('ID'), fieldType: 'TEXT' },
    { fieldName: 'status.name', fieldTitle: t('Status'), fieldType: 'TEXT' },
    { fieldName: 'requestNo', fieldTitle: t('Delivery Request Number'), fieldType: 'TEXT' },
    { fieldName: 'collector.name', fieldTitle: t('Collector'), fieldType: 'TEXT' },
    {
      fieldName: 'collectorsPlatform.name',
      fieldTitle: t("Collector's Platform"),
      fieldType: 'TEXT',
    },
    { fieldName: 'valoriser.name', fieldTitle: t('Valoriser'), fieldType: 'TEXT' },
    {
      fieldName: 'plannedDeliveryDate',
      fieldTitle: t('Planned Delivery Date'),
      fieldType: 'DATE',
    },
    {
      fieldName: 'requestedByUser',
      fieldTitle: t('Employee who entered the request'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'approvedDate',
      fieldTitle: t('Approved or Rejected Date'),
      fieldType: 'DATE',
    },
    {
      fieldName: 'approvedBy',
      fieldTitle: t('Person who approved/rejected the request'),
      fieldType: 'TEXT',
    },
    { fieldName: 'approvalNotes', fieldTitle: t('Approval Notes'), fieldType: 'TEXT' },
    {
      fieldName: 'tiredeliveriesQty[numberFromFind1].pieces',
      fieldFind: 'record.tiredeliveriesQty.findIndex((qty) => qty.tiretypeId === 15)',
      fieldTitle: t('Tires A'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tiredeliveriesQty[numberFromFind2].pieces',
      fieldFind: 'record.tiredeliveriesQty.findIndex((qty) => qty.tiretypeId === 17)',
      fieldTitle: t('Tires B'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tiredeliveriesQty[numberFromFind3].pieces',
      fieldFind: 'record.tiredeliveriesQty.findIndex((qty) => qty.tiretypeId === 18)',
      fieldTitle: t('Tires C'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tiredeliveriesQty[numberFromFind4].pieces',
      fieldFind: 'record.tiredeliveriesQty.findIndex((qty) => qty.tiretypeId === 20)',
      fieldTitle: t('Tires D'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tiredeliveriesQty[numberFromFind5].pieces',
      fieldFind: 'record.tiredeliveriesQty.findIndex((qty) => qty.tiretypeId === 22)',
      fieldTitle: t('Tires E'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tiredeliveriesQty[numberFromFind6].pieces',
      fieldFind: 'record.tiredeliveriesQty.findIndex((qty) => qty.tiretypeId === 26)',
      fieldTitle: t('Tires F'),
      fieldType: 'TEXT',
    },
    { fieldName: 'deliveryNoteNo', fieldTitle: t('Delivery Note Number'), fieldType: 'TEXT' },
    { fieldName: 'loadUnloadNoteNo', fieldTitle: t('Loading Unloading Number'), fieldType: 'TEXT' },
    { fieldName: 'distance', fieldTitle: t('Distance in km'), fieldType: 'TEXT' },
    { fieldName: 'vehPlateNo', fieldTitle: t('Vehicle Plate'), fieldType: 'TEXT' },
    { fieldName: 'driver', fieldTitle: t('Driver'), fieldType: 'TEXT' },
    { fieldName: 'deliveredDate', fieldTitle: t('Delivered Date'), fieldType: 'DATE' },
    { fieldName: 'weighingNoteNo', fieldTitle: t('Weighing Note Number'), fieldType: 'TEXT' },
    { fieldName: 'grossWeight', fieldTitle: t('Gross'), fieldType: 'TEXT' },
    { fieldName: 'tareWeight', fieldTitle: t('Tare'), fieldType: 'TEXT' },
    { fieldName: 'deductWeight', fieldTitle: t('Deduction'), fieldType: 'TEXT' },
    { fieldName: 'netWeight', fieldTitle: t('Net'), fieldType: 'TEXT' },
    { fieldName: 'collectorNotes', fieldTitle: t('Collector Notes'), fieldType: 'TEXT' },
    {
      fieldName: 'collectorWeightsBy',
      fieldTitle: t('Employee from Collector who declared the delivery'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'collectorWeighingNoteNo',
      fieldTitle: t('Collector Weighing Note Number'),
      fieldType: 'TEXT',
    },
    { fieldName: 'collectorGrossWeight', fieldTitle: t('Collector Gross'), fieldType: 'TEXT' },
    { fieldName: 'collectorTareWeight', fieldTitle: t('Collector Tare'), fieldType: 'TEXT' },
    { fieldName: 'collectorDeductWeight', fieldTitle: t('Collector Deduction'), fieldType: 'TEXT' },
    { fieldName: 'collectorNetWeight', fieldTitle: t('Collector Net'), fieldType: 'TEXT' },
    { fieldName: 'weightNotes', fieldTitle: t('Valoriser Notes'), fieldType: 'TEXT' },
    {
      fieldName: 'valoriserWeightsBy',
      fieldTitle: t('Employee from Valoriser who entered the weighing'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'valoriserWeighingNoteNo',
      fieldTitle: t('Valoriser Weighing Note Number'),
      fieldType: 'TEXT',
    },
    { fieldName: 'valoriserGrossWeight', fieldTitle: t('Valoriser Gross'), fieldType: 'TEXT' },
    { fieldName: 'valoriserTareWeight', fieldTitle: t('Valoriser Tare'), fieldType: 'TEXT' },
    { fieldName: 'valoriserDeductWeight', fieldTitle: t('Valoriser Deduction'), fieldType: 'TEXT' },
    { fieldName: 'valoriserNetWeight', fieldTitle: t('Valoriser Net'), fieldType: 'TEXT' },
    {
      fieldName: 'valRestrictedUntilDate',
      fieldTitle: t('Valorification Restricted Until Date'),
      fieldType: 'DATE',
    },
    { fieldName: 'valorisationDate', fieldTitle: t('Valorisation Date'), fieldType: 'DATE' },
    { fieldName: 'valorisationNotes', fieldTitle: t('Valorisation Notes'), fieldType: 'TEXT' },
    {
      fieldName: 'valorisationEnteredBy',
      fieldTitle: t('Valorisation Entered By'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'valorisationMethod.name',
      fieldTitle: t('Valorisation Method'),
      fieldType: 'TEXT',
    },
    /* { fieldName: 'ecoanvelopeNotes', fieldTitle: t('Legacy Notes'), fieldType: 'TEXT' }, */
    { fieldName: 'isActive', fieldTitle: t('Active Record'), fieldType: 'TEXT' },
    { fieldName: 'firstDate', fieldTitle: t('First Date'), fieldType: 'DATETIME' },
    { fieldName: 'lastDate', fieldTitle: t('Last Date'), fieldType: 'DATETIME' },
    { fieldName: 'firstUser', fieldTitle: t('First User'), fieldType: 'TEXT' },
    { fieldName: 'lastUser', fieldTitle: t('Last User'), fieldType: 'TEXT' },
  ];
};

export function useGetQuery(): any {}

export async function handleExport(client, queryState: any, t): Promise<void> {
  function useGetQuery() {
    const [get, { loading }] = useLazyQuery(gqlGetQuery, { onCompleted: transformData });
    const [result, setResult] = useState<any>([get, { loading, data: undefined }]);
    function transformData(data) {
      const recs = data.getTiredeliveries.data.map((values) => {
        return {
          ...values,
          isActive: t(values.isActive ? 'TRUE' : 'FALSE'),
        };
      });

      const trsData = {
        getTiredeliveries: { data: recs, recordCount: data?.getTiredeliveries?.recordCount },
      };
      setResult([get, { loading, data: trsData }]);
    }

    return result;
    //return [null, { loading: null, data: null }];
  }

  const res = await promiseModalSelectColumns({
    title: `${t('Select Columns')}`,
    text: `${t('Select Columns')}`,
    data: exportColumns(t),
  });

  if (res !== false) {
    const filteredColumns = _.reject(
      exportColumns(t),
      (col) => res.find((rec) => rec.id === col.fieldName && rec.state === false) !== undefined,
    );
    const filterVariables = {
      where: buildWhereFromFilterValues(queryState?.filterValues),
    };
    await exportXlsModal({
      apolloClient: client,
      exportFileName: 'export Tiredeliveries',
      getLazyQuery: useGetQuery,
      columnsDef: filteredColumns,
      filterVariables: filterVariables,
    });
  }
}
