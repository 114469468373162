const axios = require('axios');

export async function doOcrFile(s3FileName: string): Promise<any> {
  // a client can be shared by different commands.

  try {
    const uri = process.env.REACT_APP_OCR_SERVICE + '?s3FileName=' + encodeURIComponent(s3FileName);
  const res = await axios
    .get(uri)
    .catch((error) => console.error(`getOcrData (uri=${uri}) error `, error));
  console.log(`getOcrData  (uri=${uri}) called`, res);
    console.log('data $$$$:)', res);
    // process data.
      return res.data;
  } catch (error) {
    console.log('err', error);
    // error handling.
  } finally {
    // finally.
  }
}
