import React from 'react';
import { Row } from 'react-bootstrap';
import {
  buildWhereForDates,
  FormCol,
  FormFilterCard,
  FieldInput,
  FieldDateRanger,
  FieldSelectIsActive,
} from 'motion-components';
import { useTranslation } from 'react-i18next';
import { FieldSelectDeliveryStatus } from 'components';
import { TireDeliveriesStatus } from 'shared/constants';

/* ----------------------- Διαχείριση Φίλτρων ------------------------------ */
export function buildWhereFromFilterValues(values): string {
  // Φτιάξε το where για το graphql
  let where: any = {
    statusId: { $eq: TireDeliveriesStatus.Unreconciled },
    // ,isActive:1
  };
  if (values) {
    if (values.startDate || values.endDate) {
      where = {
        ...where,
        ...buildWhereForDates(values.startDate, values.endDate, 'requestDate'),
      };
    }
    if (values.isActive !== undefined && values.isActive !== '') {
      where = { ...where, isActive: values.isActive };
    }

    if (values.searchText) {
      const searchText = '%' + values.searchText + '%';
      const likeObj = { $like: searchText };
      where = {
        ...where,
        $or: [{ requestNo: likeObj }, { deliveryNoteNo: likeObj }, { weighingNoteNo: likeObj }],
      };
    }
  }
  return JSON.stringify(where);
}

/* ----------------------- Filter Form ------------------------------ */
export const Filters = ({ onChange, initialValues }) => {
  const { t } = useTranslation();
  //
  function handleFiltersSubmit(values, { setSubmitting }) {
    setTimeout(() => {
      setSubmitting(false);
      onChange(values);
    }, 400);
  }
  //console.log("filters-init ", initialValues);

  const filters = {
    startDate: initialValues?.startDate ?? null,
    endDate: initialValues?.endDate ?? null,
    searchText: initialValues?.searchText ?? '',
    isActive: initialValues?.isActive ?? null,
    //someId: initialValues?.someId ?? null,
  };

  return (
    <FormFilterCard
      title={t('Filter Criteria')}
      onSubmit={handleFiltersSubmit}
      initialValues={filters}
    >
      <Row>
        <FieldInput
          as={FormCol}
          name="searchText"
          label={t('Search (Request Number)')}
          type="search"
        />
        <FieldDateRanger
          as={FormCol}
          label={t('Date from')}
          label2={t('till')}
          name="startDate"
          name2="endDate"
        />
        {/* <FieldSelectDeliveryStatus as={FormCol} />*/}
        <FieldSelectIsActive label={t('Active Records')} />
      </Row>
    </FormFilterCard>
  );
};
