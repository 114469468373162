import React, { useMemo, useCallback, useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  CellLeft,
  promiseModalDialog,
  TableSubSimple,
  toastFailure,
  toastSuccess,
} from 'motion-components';
import { Button } from 'react-bootstrap';
import { FilePicker } from 'react-file-picker';
import { uploadFile2S3withSignedUrl } from 'utils/s3helpfunc';
import { FaTrashAlt, FaFolderOpen, FaFileDownload, FaUser } from 'react-icons/fa';
import { getFileExtension } from 'utils/helpers';
import { promiseModalShowS3Image } from './weighing-note/show-s3-image-modal';
import { useRootStore } from 'store';
import promiseModalSelectUserFile from 'components/modal-select-user-file';
import { doOcrFile } from 'utils/ocr-textract-func';
import { parseWeighingNoteTextractLines } from './weighing-note/parse-weighing-notes';
import {
  UseUpdateTiredeliveriesWeights,
  parseValuesFromStrings,
} from './weighing-note/weighingnote-button';
import {
  IWeighingValues,
  showWeighingNoteReadingsForm,
} from './weighing-note/weighing-note-readings-form';
import { format, parseISO } from 'date-fns';
import { TireDeliveriesStatus } from 'shared/constants';

const getFiles = gql`
  query getTiredeliveriesFiles($where: String, $offset: Int, $limit: Int, $order: String) {
    getTiredeliveriesFiles(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        id
        tiredeliveryId
        documenttypeId
        notes
        isActive
        firstDate
        lastDate
        firstUser
        lastUser
        documentType {
          id
          name
        }
        fileId
        filesrepository {
          id
          fileName
          s3Key
        }
        lastUserRec {
          id
          fullName
          usertypeId
          userType {
            id
            name
          }
        }
        usertypeId
        userType {
          id
          name
        }
      }
      recordCount
    }
  }
`;

const gqlAddFilesRepository = gql`
  mutation addFilesrepository($fileName: String!, $mimeMediaType: String!) {
    addFilesrepository(fileName: $fileName, mimeMediaType: $mimeMediaType) {
      id
      url
    }
  }
`;

const gqlGetFilesRepositoryFileUrlById = gql`
  query getFilesrepositoryFileUrlById($id: Int) {
    getFilesrepositoryFileUrlById(id: $id)
  }
`;

const gqlDeleteFilesRepository = gql`
  mutation deleteFilesrepository($id: Int!) {
    deleteFilesrepository(id: $id)
  }
`;

const gqlUpdateTiredeliveriesFile = gql`
  mutation updateTiredeliveriesFile($data: TiredeliveriesFileInput) {
    updateTiredeliveriesFile(data: $data) {
      id
    }
  }
`;

export const TiredeliveriesFilesSection = ({
  refetchBase,
  tiredeliveryId,
  readOnly,
  forceRerender,
  tireDelivery,
}) => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const {
    loading,
    data: rawData,
    refetch,
  } = useQuery(getFiles, {
    variables: { where: JSON.stringify({ tiredeliveryId }), order: 'documentTypeId ASC' },
    fetchPolicy: 'no-cache',
  });
  const data =
    rawData && rawData.getTiredeliveriesFiles ? rawData.getTiredeliveriesFiles.data : undefined;

  useEffect(() => {
    if (forceRerender) refetch();
  }, [forceRerender]);

  // * ------ ΕΙΔΙΚΟ COMPONENT ΜΕ ΤΑ ΚΟΥΜΠΙΑ ΓΙΑ ΤΗΝ ΚΟΛΩΝΑ ΤΩΝ ΕΝΕΡΓΕΙΩΝ ---------
  const [updateRecord] = useMutation(gqlUpdateTiredeliveriesFile);
  const { updateTiredeliveriesWeights, denyTiredeliveriesWeights } =
    UseUpdateTiredeliveriesWeights();
  const [uploadStatus, setUploadStatus] = useState(-1);
  const client = useApolloClient();
  const [addFile] = useMutation(gqlAddFilesRepository);
  const [deleteFile] = useMutation(gqlDeleteFilesRepository);
  /**
  // * ----- HANDLER: Upload File
  */
  const handleFileSelect = useCallback(
    async (row: any, fileObj: File) => {
      console.log(fileObj);
      // Εχει επιλεγεί αρχείο...
      const res = await addFile({
        // Mutation
        variables: { fileName: fileObj.name, mimeMediaType: fileObj.type },
      });
      const { id, url } = res?.data?.addFilesrepository;
      console.log(id, url);
      if (id && url) {
        setUploadStatus(0);
        uploadFile2S3withSignedUrl(
          fileObj,
          url,
          async () => {
            toastSuccess(t('File Uploaded Succesfully')); //'To Aρχείο Μεταφέρθηκε στον server');
            // Update Local Record
            await updateRecord({ variables: { data: { fileId: id, id: row.id } } });
            setUploadStatus(-1);
            refetch();
            refetchBase();
          }, //onFileUploaded
          () => {
            toastFailure(t('Failed to upload file')); //('Παρουσιάστηκε Κάποιο Πρόβλημα στο Ανέβασμα του Αρχείου');
            setUploadStatus(-1);
          }, //onUploadError
          (percent) => {
            setUploadStatus(percent);
          }, //onUploadProgress
        );
      }
    },
    [addFile, refetch, t, updateRecord],
  );

  /**
  // * ----- HANDLER: Open File (κανω το query με promise)
  */
  const handleFileOpen = useCallback(
    async (fileId) => {
      console.log('***OPEN FILE***');
      client
        .query({
          query: gqlGetFilesRepositoryFileUrlById,
          variables: { id: fileId },
        })
        .then((res) => {
          const url = res?.data?.getFilesrepositoryFileUrlById;
          if (url) {
            window.open(url);
          } else {
            toastFailure(t('Error during file download')); //'Πρόβλημα Στο Κατέβασμα του Αρχείου'
          }
        })
        .catch((err) => toastFailure(t('Error during file download ') + err)); //`Πρόβλημα στο κατέβασμα του αρχείου err:${err}`));
    },
    [client, t],
  );

  /*
      Delete File
  */
  const handleFileDelete = useCallback(
    async (row) => {
      const fileId = row.fileId;
      const rowId = row.id;

      const res = await promiseModalDialog({
        title: t('Delete File Record'), //'Διαγραφή Εγγραφής Αρχείου',
        text: t('Do you really want to delete the file;'), //'Είστε σίγουροι ότι θέλετε να διαγράψετε το Αρχείο;',
      });
      if (!res) return;
      // Update Local Record
      updateRecord({ variables: { data: { fileId: null, id: rowId } } });

      setTimeout(async () => {
        await deleteFile({ variables: { id: fileId } }).catch((error) => {});
        //toastSuccess('Το Αρχείο Διαγράφηκε');
        refetch();
        refetchBase();
      }, 150);
    },
    [deleteFile, refetch, t, updateRecord],
  );

  async function handleSelectUserFile(row) {
    const res = await promiseModalSelectUserFile({
      title: `${t('Select a File from your storage')}`,
    });
    if (res) {
      appStore.setBusy();
      if (row.original.documenttypeId === 3) {
        // 3. OCR
        const fileName = res.fileName;
        const s3FileName = res.s3Key;
        if (!['jpg', 'jpeg', 'png', 'pdf'].includes(getFileExtension(fileName))) {
          appStore.clearBusy();
          toastFailure(t('ERROR! Only Images of type jpeg, png or pdf can be read by OCR'));
          return;
        }
        const { lines, words } = await doOcrFile(s3FileName);
        if (!lines) {
          appStore.clearBusy();
          toastFailure(t('ERROR! Cannot recognize the weighing note context'));
          return;
        }
        const readings = parseWeighingNoteTextractLines(lines);
        console.log('$$$ OCR $$$', fileName, readings);

        // 4. Popup
        const readingsFields = parseValuesFromStrings(readings);
        console.log('$$$ OCR $$$ readingsFields', readingsFields);
        appStore.clearBusy();
        const weighingFields = await showWeighingNoteReadingsForm({
          weighingReadings: readings,
          weighingValues: readingsFields,
        });
        if (!weighingFields) return;
        console.log('weighingFields', weighingFields);

        // 5. Save Weights

        const modifyValue: any = (weight) => {
          return weight === null || weight === '' ? null : parseInt(weight);
        };

        if (
          tireDelivery.statusId === TireDeliveriesStatus.UnconfirmedCol ||
          tireDelivery.statusId === TireDeliveriesStatus.UnconfirmedVal
        ) {
          // Check if Same Weights
          const isSameWeights =
            (appStore.isCollector &&
              format(parseISO(tireDelivery.valoriserDeliveredDate), 'dd/MM/yyyy HH:mm') ===
                format(weighingFields.deliveredDate, 'dd/MM/yyyy HH:mm') &&
              tireDelivery.valoriserWeighingNoteNo === weighingFields.weighingNoteNo &&
              tireDelivery.valoriserGrossWeight === modifyValue(weighingFields.grossWeight) &&
              tireDelivery.valoriserTareWeight === modifyValue(weighingFields.tareWeight) &&
              tireDelivery.valoriserDeductWeight === modifyValue(weighingFields.deductWeight) &&
              tireDelivery.valoriserNetWeight === modifyValue(weighingFields.netWeight)) ||
            (appStore.isValoriser &&
              format(parseISO(tireDelivery.collectorDeliveredDate), 'dd/MM/yyyy HH:mm') ===
                format(weighingFields.deliveredDate, 'dd/MM/yyyy HH:mm') &&
              tireDelivery.collectorWeighingNoteNo === weighingFields.weighingNoteNo &&
              tireDelivery.collectorGrossWeight === modifyValue(weighingFields.grossWeight) &&
              tireDelivery.collectorTareWeight === modifyValue(weighingFields.tareWeight) &&
              tireDelivery.collectorDeductWeight === modifyValue(weighingFields.deductWeight) &&
              tireDelivery.collectorNetWeight === modifyValue(weighingFields.netWeight));
          await denyTiredeliveriesWeights(
            tireDelivery.id,
            weighingFields as IWeighingValues,
            isSameWeights,
          );
        } else {
          await updateTiredeliveriesWeights(tireDelivery.id, weighingFields as IWeighingValues);
        }
      }

      // Update Local Record

      await updateRecord({ variables: { data: { fileId: res.fileId, id: row.original.id } } });
      refetch();
      refetchBase();
      appStore.clearBusy();
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: t('id'),
        accessor: 'id',
        Cell: ({ row }) => <div className="text-center  mt-2">{row.original?.id}</div>,
        width: '50px',
      },
      {
        Header: t('Document Type'),
        accessor: 'documentType.name',
        Cell: ({ row }) => (
          <div className="text-left  mt-2">{t(row.original?.documentType?.name)}</div>
        ),
        width: '200',
      },
      {
        Header: t('User Type'),
        accessor: 'userType.name',
        Cell: ({ row }) => <div className="text-left  mt-2">{t(row.original?.userType?.name)}</div>,
        width: '100',
      },
      {
        Header: t('File'),
        accessor: 'fileId',
        Cell: ({ row }) => {
          return (
            <CellLocalCommands
              handleFileDelete={handleFileDelete}
              handleFileOpen={handleFileOpen}
              handleFileSelect={handleFileSelect}
              handleSelectUserFile={handleSelectUserFile}
              readOnly={readOnly}
              row={row}
              deliveryStatusId={tireDelivery.statusId}
            />
          );
        },
        width: '600',
      },
      {
        Header: t('User'),
        accessor: 'lastUser',
        Cell: ({ row }) => {
          if (row.original?.fileId > 0)
            return (
              <div className="text-left  mt-2">
                {row.original?.lastUser} ({row.original?.lastUserRec?.fullName})
                <br />
                {row.original?.lastUserRec?.userType?.name}
              </div>
            );
          else return <></>;
        },
        width: '200',
      },
    ],
    [handleFileDelete, handleFileOpen, handleFileSelect, t],
  );

  return (
    <div>
      <h5 className="form-group-title mb-0">{t('Documents')}</h5>
      {data && <TableSubSimple title={t('Files')} columns={columns} data={data} />}
    </div>
  );
};

const CellLocalCommands = ({
  row,
  handleFileDelete,
  handleFileOpen,
  handleFileSelect,
  handleSelectUserFile,
  readOnly,
  deliveryStatusId,
}) => {
  const { t } = useTranslation();
  const { appStore, authStore } = useRootStore();
  const { original } = row; // !!! To original ΕΙΝΑΙ το αρχικό DcdpFile
  const fileExist = original?.fileId && original?.fileId > 0;
  const isAssociate = original.usertypeId === appStore.usertypeId;
  const documentTypeId = original.documenttypeId;
  //console.log('[DcdpFilesGrid] row :', original);
  return (
    <div className="d-flex">
      {/* -------------   BUTTON ΕΠΙΛΟΓΗΣ ΑΡΧΕΙΟΥ ------------------ */}
      {!fileExist &&
        (!readOnly ||
          (isAssociate && (documentTypeId === 4 || documentTypeId === 6)) ||
          original.usertypeId === null) &&
        deliveryStatusId !== TireDeliveriesStatus.Rejected && (
          <FilePicker
            onChange={(fileObject) => handleFileSelect(original, fileObject)}
            onError={(errMsg) => {
              console.log(errMsg);
              toastFailure(errMsg);
            }}
          >
            <Button variant="primary" size="sm" className="mr-2">
              <FaFolderOpen className="mb-1" />
              <span className="ml-2">{t('Select File')}</span>
            </Button>
          </FilePicker>
        )}

      {/* -------------   BUTTON ΕΠΙΛΟΓΗΣ ΑΡΧΕΙΟΥ ΧΡΗΣΤΗ ------------------ */}
      {(!readOnly ||
        (isAssociate && (documentTypeId === 4 || documentTypeId === 6 || documentTypeId === 3)) ||
        original.usertypeId === null) &&
        !fileExist &&
        deliveryStatusId !== TireDeliveriesStatus.Rejected && (
          <Button variant="primary" size="sm" onClick={() => handleSelectUserFile(row)}>
            <FaUser className="mb-1" />
            <span className="ml-2">{t('my Files')}</span>
          </Button>
        )}

      {/* -------------   BUTTON Download ------------------ */}
      {fileExist && (
        <Button
          className="mr-3"
          variant="success"
          size="sm"
          onClick={() => handleFileOpen(original.fileId)}
        >
          <FaFileDownload className="mb-1" />
          <span className="ml-2">{t('Download')}</span>
        </Button>
      )}

      {/* -------------   BUTTON ΔΙΑΓΡΑΦΗΣ ------------------ */}
      {fileExist &&
        (!readOnly ||
          (isAssociate && (documentTypeId === 4 || documentTypeId === 6)) ||
          (appStore.usertypeId === original.lastUserRec.usertypeId &&
            original.usertypeId === null)) && (
          <Button
            className="mr-3"
            variant="danger"
            size="sm"
            onClick={() => handleFileDelete(original)}
          >
            <FaTrashAlt className="mb-1" />
            <span className="ml-2">{t('Delete')}</span>
          </Button>
        )}

      {/* -------------   BUTTON ΑΝΟΙΓΜΑΤΟΣ ------------------ */}
      {fileExist && (
        <Button
          variant="link"
          size="sm"
          style={{ color: 'blue' }}
          onClick={async () => {
            const filename: string = original?.filesrepository?.fileName;
            if (['jpg', 'jpeg', 'png'].includes(getFileExtension(filename))) {
              const res = await promiseModalShowS3Image({
                fileId: original.fileId,
                fileName: original?.filesrepository?.s3Key,
              });
            } else handleFileOpen(original.fileId);
          }}
        >
          {original?.filesrepository?.fileName}
        </Button>
      )}
    </div>
  );
};
