import { useApolloClient } from '@apollo/react-hooks';
import { formatDateTime } from 'common/date-utils';
import { useCallback } from 'react';
import { getAuditTableFieldObj } from './audit-trail-dictionary';
import { useAuditTrailDb } from './audit-trail-db-service';
import { t } from 'i18next';

function isIsoDate(str) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  else return true;
  // const d = new Date(str);
  // return d.toISOString() === str;
}

export function fieldValue2String(tableName: string, fieldName: string, fieldValue: any) {
  let ret = '';
  if (typeof fieldValue === 'string' && isIsoDate(fieldValue)) {
    ret = formatDateTime(fieldValue);
  } else if (typeof fieldValue === 'object' && fieldValue === null) {
    ret = '';
  } else if (typeof fieldValue === 'boolean') {
    ret = fieldValue ? t('Yes') : t('No');
  } else ret = JSON.stringify(fieldValue);

  return ret;
}

export function fieldValue2StringExtra(
  auditTrailDb: any,
  tableName: string,
  fieldName: string,
  fieldValue: any,
) {
  let ret = fieldValue2String(tableName, fieldName, fieldValue);

  // Δες αν το πεδίο είναι reference και πάρε το όνομα που κάνει reference
  if (fieldName.endsWith('Id')) {
    // Πάρε δήλωση field
    const fld = getAuditTableFieldObj(tableName, fieldName);
    if (fld?.getNameFn) {
      ret = ret + ` ${fld.getNameFn}`;
      if (auditTrailDb && auditTrailDb[fld.getNameFn]) ret = ret + ` *** `;
    }
  }
  return ret;
}

export function useFieldValue2StringExtra() {
  const client = useApolloClient();
  const auditTrailDb = useAuditTrailDb(client);

  const fieldValue2StringExtra = useCallback(
    async (tableName: string, fieldName: string, fieldValue: any) => {
      let ret = fieldValue2String(tableName, fieldName, fieldValue);

      // Δες αν το πεδίο είναι reference και πάρε το όνομα που κάνει reference
      if (fieldName.endsWith('Id')) {
        // Πάρε δήλωση field
        const fld = getAuditTableFieldObj(tableName, fieldName);
        if (fld?.getNameFn) {
          if (auditTrailDb && auditTrailDb[fld.getNameFn]) {
            if (typeof fieldValue === 'number') {
              const fn = auditTrailDb[fld.getNameFn];
              const valueLinkName = await fn(fieldValue);
              ret = `${valueLinkName} (${ret})`;
            }
          }
        }
      }
      // Δες αν το πεδίο είναι password
      else if (fieldName.toUpperCase().includes('PASSWORD')) {
        ret = '*********';
      }
      return ret;
    },
    [auditTrailDb],
  );
  return { fieldValue2StringExtra };
}

export function auditTypeColor(auditType): string {
  if (auditType === 'update') return 'orange';
  else if (auditType === 'create') return 'green';
  else if (auditType === 'delete') return 'red';
  else return 'black';
}
