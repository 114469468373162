/*
  Φιλική Ονομασία Πινάκων και Πεδίων
*/

import { tiredeliveries } from './dictionary-tables/tiredeliveries';
import { users } from './dictionary-tables/users';

const tables = [users, tiredeliveries];

// Find table or field
function getArrayRowByName(tables: Array<any>, name: string) {
  const table = tables.filter((value) => value.name.toUpperCase() === name.toUpperCase());
  if (table.length === 0) return undefined;
  else return table[0];
}

// Translate table name
function getAuditTableDisplayName(tableName: string) {
  const table = getArrayRowByName(tables, tableName);
  if (!table) return tableName;
  // don't translate
  else return table.displayName;
}

// Translate table field name
function getAuditTableFieldDisplayName(tableName: string, fieldName: string) {
  const table = getArrayRowByName(tables, tableName);
  if (!table) return fieldName; // don't translate

  const field = getArrayRowByName(table.fields, fieldName);
  if (!field) return fieldName;
  // don't translate
  else return field.displayName;
}

// Translate table field name
function getAuditTableFieldObj(tableName: string, fieldName: string) {
  const table = getArrayRowByName(tables, tableName);
  if (!table) return fieldName; // don't translate

  const field = getArrayRowByName(table.fields, fieldName);
  if (!field) return undefined;
  // don't translate
  else return field;
}

// Childs (array) of Table
function getAuditTableChilds(tableName: string) {
  const table = getArrayRowByName(tables, tableName);

  return table?.childTables;
}

export {
  getAuditTableDisplayName,
  getAuditTableFieldDisplayName,
  getAuditTableFieldObj,
  getAuditTableChilds,
};
