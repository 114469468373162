import React, { useCallback, useEffect, useState } from 'react';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { collectionpointLogin, userLogin } from './graphql';
import { rootStore } from 'store';
import LoginForm from './login-form';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useHandleError, usePersistentLog } from 'services';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import LoginCpForm from './loginCP-form';
import { promiseModalDialog, toastFailure } from 'motion-components';
import { gqlGetCollectionpoints } from 'pages/tirecollections/graphql';
import { getRouteByName } from 'app/routes';
import { FaArrowLeft } from 'react-icons/fa';

const LoginCpPage = () => {
  /*-------------------- Body -----------------------------------------------*/
  const { t } = useTranslation();
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const history = useHistory();
  const location = useLocation<any>();
  //const { from } = location.state || { from: { pathname: '/' } };
  const state: any = location.state || { from: { pathname: '/' } };
  const from = state.from;
  const { handleGraphqlError } = useHandleError('LoginCpPage');
  const { logError, logInfo } = usePersistentLog('LoginCpPage');
  const [count, setCount] = useState<number>(0);
  console.log('[LoginCpPage] invoked from =', from);
  /*! -------------------- Handlers -------------------------------------------*/
  /**
   * * 1. ΠΑΤΗΘΗΚΕ ΤΟ SUBMIT -> ΕΚΤΕΛΩ ΤΟ QUERY (Callback)
   */

  const createWhere = (vatNumber) => {
    // anonymous entry by VAT or Phone
    const vatin = vatNumber;

    // βρες το σημείο συλλογής
    // trasnform 2106858455 => 210 6858455
    const phonenumber = vatNumber;
    const phonenumber3 = phonenumber.slice(0, 3) + ' ' + phonenumber.slice(3, phonenumber.length);
    const phonenumber4 = phonenumber.slice(0, 4) + ' ' + phonenumber.slice(4, phonenumber.length);
    const phonenumber5 = phonenumber.slice(0, 5) + ' ' + phonenumber.slice(5, phonenumber.length);
    const where = {
      $or: [
        { vatin: vatin },
        { phone: phonenumber },
        { mobile: phonenumber },
        { phone: phonenumber3 },
        { mobile: phonenumber3 },
        { phone: phonenumber4 },
        { mobile: phonenumber4 },
        { phone: phonenumber5 },
        { mobile: phonenumber5 },
      ],
      isActive: true,
    };

    return JSON.stringify(where);
  };

  async function onGetCollectionpointsCompleted(data) {
    const records = data.getCollectionpoints.data;
    setCount(data.getCollectionpoints.recordCount);
    console.log('fetch completed', records);
    if (records.length === 0) {
      const res = await promiseModalDialog({
        title: t('No Collection Point was found!'),
        text: t('Would you like to create a new Collection Point?'),
        acceptText: t('Yes'),
        denyText: t('No, search again'),
      });
      if (res) {
        history.push({
          pathname: generatePath(getRouteByName('Collectionpoint'), { id: -1 }),
          state: {
            pathname: location.pathname,
            ...location.state,
          },
        });
      }
    } else if (records.length === 1) {
      handleCollectionpointLogin(records[0].id);
    }
  }
  const [get, { data: rawData }] = useLazyQuery(gqlGetCollectionpoints, {
    onCompleted: (data) => {
      onGetCollectionpointsCompleted(data);
    },
    fetchPolicy: 'cache-and-network',
  });
  const dataCP = rawData ? rawData.getCollectionpoints.data : undefined;

  // --------------------------------------------------------------------------
  const handleCollectionpointLogin = useCallback(
    async (id) => {
      const res = await collectionpointLogin(client, id).catch((err) => {
        handleGraphqlError('userLogin-collectionpointLogin', err, t('Login Error'));
        setError(err);
        setLoading(false);
        return null;
      });
      if (res == null) return;

      console.log('####### Login', res);

      rootStore.appStore.login(res.userName, res);
      // Persist Version to Database -----
      const msg = `web-app version: ${(global as any).appVersion}`;
      console.log('############ AppVersion', msg);
      logInfo('AppVersion', msg);

      // --------------------------- -----
      setLoading(false);
      history.replace(from);
    },
    [client, from, handleGraphqlError, history, logInfo, t],
  );
  /*-------------------- Render ---------------------------------------------*/

  return (
    <div className="d-flex align-items-center justify-content-center" style={{ height: '85vh' }}>
      <div>
        <LoginCpForm
          onSubmit={(values) => {
            get({
              variables: {
                where: createWhere(values.name),
              },
            });
          }}
          message={error}
          loading={loading}
          dataCP={dataCP}
          count={count}
          setCount={setCount}
          loginCP={handleCollectionpointLogin}
        />
        {count <= 1 && (
          <Button
            block
            variant="success"
            size="sm"
            style={{ fontSize: '0.8rem' }}
            onClick={() => {
              history.push('login');
            }}
          >
            <FaArrowLeft size="1rem" className="pb-2 mr-2" />
            {t('Go Back')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default LoginCpPage;
LoginCpPage.whyDidYouRender = true;
