import React, { useMemo, useCallback } from 'react';
import {
  TablePaginatedUC,
  CellLeft,
  CellCenter,
  CellYesNo,
  CellYesNoNull,
  CellDateTime,
  CellInt,
  CellFloatCustom,
  CellIntCustom,
  CellCheckbox,
  EyeColumn,
} from 'motion-components';
import { useLocation } from 'react-router-dom';
import { generatePath, useHistory } from 'react-router';

import LoadingOverlay from 'react-loading-overlay';
import { useRootStore } from 'store';
import { getRouteByName } from 'app/routes';
import { useTranslation } from 'react-i18next';
import { gqlSetAutoApproveCollector } from '../graphql';
import { useMutation } from '@apollo/client';
import { useHandleError } from 'services';

/* ----------- Grid ------------------ */

export const DataGrid = ({
  data,
  refetch,
  dataCount,
  initPageIndex,
  onPageChange,
  loading,
  handleExport,
}) => {
  const location = useLocation<any>();
  const history = useHistory();
  const { appStore } = useRootStore();
  const { t } = useTranslation();
  const { handleGraphqlError } = useHandleError('Collectors Grid');
  const [setAutoApprove] = useMutation(gqlSetAutoApproveCollector);
  const rowClicked = useCallback(
    (row) => {
      history.push({
        pathname: generatePath(getRouteByName('Collector'), { id: row.id }),
        state: {
          pathname: location.pathname,
          ...location.state,
        },
      });
    },
    [history, location.pathname, location.state],
  );

  // const autoApproveDeliveriesChanged = useCallback((checked, row) => {
  //   alert('Not Implemented Yet!');
  // }, []);
  const doUpdateRecord = useCallback(
    (data) => {
      setAutoApprove({ variables: data })
        .then(() => {
          //const id = d.data.key[Object.keys(d.data)[0]].id;
          if (refetch) refetch();
        })
        .catch((error) => {
          handleGraphqlError('mutation-update', error);
        });
    },
    [handleGraphqlError, refetch, setAutoApprove],
  );

  const autoApproveDeliveriesChanged = useCallback(
    (checked, row) => {
      const data: any = { collectorId: row.id, value: checked };
      doUpdateRecord(data);
    },
    [doUpdateRecord],
  );

  const columns = useMemo(
    () => [
      // { Header: t("id", accessor: "id", Cell: CellCenter, width: "100", isVisible: false, },
      EyeColumn(rowClicked),
      {
        Header: t('Collector'),
        accessor: 'name',
        Cell: CellLeft,
        width: '70',
        isVisible: false,
      },
      {
        Header: t('Address'),
        accessor: 'address',
        Cell: CellLeft,
        width: '50',
        isVisible: false,
      },
      {
        Header: t('City'),
        accessor: 'city',
        Cell: CellLeft,
        width: '35',
        isVisible: false,
      },
      {
        Header: t('County'),
        accessor: 'county.name',
        Cell: CellLeft,
        width: '35',
        isVisible: false,
      },
      ...(!appStore.isCollectionPoint
        ? [
            {
              Header: t('Auto Approve Deliveries'),
              accessor: 'autoApproveDeliveries',
              Cell: CellCheckbox(autoApproveDeliveriesChanged, !appStore.isEcoanvelope),
              //Cell: CellYesNoNull,
              width: '30',
              isVisible: true,
            },
            {
              Header: t('Yearly Weight Target (t)'),
              accessor: 'deliveriesYearlyWeightTarget',
              Cell: CellFloatCustom(8, 2),
              width: '30',
              isVisible: true,
            },
            {
              Header: t('Active'),
              accessor: 'isActive',
              Cell: CellYesNoNull,
              width: '20',
              isVisible: false,
            },
          ]
        : []),
    ],
    [appStore.isEcoanvelope, autoApproveDeliveriesChanged, t],
  );

  return (
    <LoadingOverlay active={loading} spinner text={t('Please wait...')}>
      {data ? (
        <TablePaginatedUC
          id="collectors"
          appStore={appStore}
          key="collectors"
          title={'Λίστα'}
          columns={columns}
          data={data}
          dataCount={dataCount}
          // initPageIndex={initPage}
          // onSortByChange={handleSortByChange}
          onExport={handleExport}
          onPageChange={onPageChange}
          initPageIndex={initPageIndex}
          resizable={true}
        />
      ) : (
        <div style={{ height: '200px' }}></div>
      )}
    </LoadingOverlay>
  );
};
