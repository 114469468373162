import { t } from 'i18next';
import React, { useState } from 'react';
import { Form, Button, Card, InputGroup, Spinner } from 'react-bootstrap';
import { FaUser, FaLock, FaEye } from 'react-icons/fa';
import { Formik, Form as FForm } from 'formik';
import { string, object, InferType } from 'yup'; // for only what you need
import { useHistory } from 'react-router-dom';
import { ButtonBack, GridLinkButton, TableInMem, toastFailure } from 'motion-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { gqlGetCollectionpoints } from 'pages/tirecollections/graphql';
/**
 * Setup Yup Validation
 */
const validationSchema = object().shape({
  name: string().required(),
});

export type FormDataType = InferType<typeof validationSchema>;

type Props = {
  message?: string | null;
  loading?: boolean;
  onSubmit: (values, action) => void;
  dataCP?: any;
  count?: number;
  setCount?: any;
  loginCP?: any;
};

const LoginCpForm = ({
  message = '',
  loading = false,
  onSubmit,
  dataCP,
  count,
  setCount,
  loginCP,
}: Props) => {
  /*-------------------- Body -----------------------------------------------*/
  const [show, setShow] = useState(false);
  const history = useHistory();

  /*-------------------- Render ---------------------------------------------*/

  const LoginForm = ({ formikBag }) => {
    return (
      <Form as={FForm}>
        <div className="text-hdr-normal font-weight-bold mb-4">
          {t('Type your VAT Number or your Phone Number and press Login')}
        </div>
        <br />
        <Form.Group>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <FaUser />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className="rounded-right"
              id="name"
              type="text"
              placeholder={t('VAT Number or Phone Number')}
              {...formikBag.getFieldProps('name')}
              isInvalid={!!formikBag.touched.name && !!formikBag.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {t('The field is required.')}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Button
          block
          variant="primary"
          size="sm"
          style={{ fontSize: '0.8rem' }}
          type="submit"
          disabled={!(formikBag.isValid && formikBag.dirty)}
        >
          {loading ? <Spinner animation="border" size="sm" className="mx-2" /> : <span></span>}
          <span>{t('Login')}</span>
        </Button>
      </Form>
    );
  };
  return (
    <Formik initialValues={{ name: '' }} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(formikBag) => (
        <Card className="bg-light shadow-sm p-3 mb-5" style={{ minWidth: 640 }}>
          <Card.Body>
            <Card.Title className="text-center mb-4 mt-1" as="h4">
              <img
                alt=""
                src={process.env.PUBLIC_URL + '/logo.png'}
                height={96}
                className="mx-auto d-block"
              />
            </Card.Title>
            <p className="text-danger text-center">{message && ''}</p>
            <>
              {count > 1 ? (
                <CollectionPointsSection handleOk={loginCP} data={dataCP} setCount={setCount} />
              ) : (
                <LoginForm formikBag={formikBag} />
              )}
            </>
          </Card.Body>
        </Card>
      )}
    </Formik>
  );
};
export default LoginCpForm;

const CollectionPointsSection = ({ data, handleOk, setCount }) => {
  const history = useHistory();
  const columns = [
    {
      /* ΚΟΛΩΝΑ ΜΕ RedEye και Click handler */
      id: 'select',
      Header: () => <FontAwesomeIcon icon={faFile} />,
      Cell: ({ row }) => (
        <div
          className="text-center"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleOk(row.original.id);
          }}
        >
          <Button style={{ width: '70px' }} variant="primary" type="button">
            {' Select '}
          </Button>
        </div>
      ),
    },
    // { Header: 'Id', accessor: 'id' },
    { Header: 'Collection Point Name', accessor: 'name' },
    {
      Header: 'VAT Number',
      accessor: 'vatin',
    },
    {
      Header: 'Phone Number',
      accessor: 'phone',
      Cell: ({ row }) => (
        <>
          <div className="text-center">{row.original.phone}</div>
          <div className="text-center">{row.original.mobile}</div>
        </>
      ),
    },
    {
      Header: 'Address',
      accessor: 'address',
      Cell: ({ row }) => (
        <>
          <div className="text-center">{row.original.city}</div>
          <div className="text-center">{row.original.address}</div>
        </>
      ),
    },
  ];

  return (
    <>
      {/* TableGrid */}

      <TableInMem
        title={t('Choose the collection point you are looking for')}
        //onRefresh={() => refetch()}
        //onExport={handleExport}
        propPageSize={10}
        onCreate={undefined}
        columns={columns}
        data={data}
        hasSearch={false}
        hasFooter={false}
      />
      <br />
      <br />
      <ButtonBack style={{ marginRight: 'auto' }} onClick={() => setCount(0)} />
      {/* <LoadingOverlay active={loading} spinner text="Please wait..."></LoadingOverlay> */}
    </>
  );
};
