import { FlexRow, usePaginatedQuery } from 'motion-components';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useHandleError } from 'services';
import { useRootStore } from 'store';
import { buildWhereFromFilterValues, Filters } from './filters';
import { gqlGetQuery } from '../graphql';
import { DataGrid } from './grid';
import { useTranslation } from 'react-i18next';

/* ----------- Page ------------------ */
export const AuditTrailsPage = () => {
  //
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const { handleGraphqlError } = useHandleError('AuditTrailsPage');

  // init filter values ΦΑΣΗ ΕΡΓΟΥ
  const location = useLocation<any>();
  const history = useHistory();
  console.log('$$$ location', location);

  /* ------------------- Query ------------------------------- */
  const query = usePaginatedQuery(gqlGetQuery, buildWhereFromFilterValues, '', appStore, true, {
    startDate: new Date(),
  });
  const {
    loading,
    data,
    dataCount,
    error,
    initPageIndex,
    filtersChanged,
    gridPageChanged,
    queryState,
  } = query;

  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <Card className="shadow">
        <Card.Header>
          <FlexRow>
            <Card.Title className="m-0">{t('Audit Trail (Data History)')}</Card.Title>
          </FlexRow>
        </Card.Header>
        <Card.Body className="nopadding">
          {/* Φίλτρα */}
          <Filters onChange={filtersChanged} initialValues={queryState?.filterValues ?? {}} />
          {/* Grid */}
          <DataGrid
            loading={loading}
            data={data}
            dataCount={dataCount}
            initPageIndex={initPageIndex}
            onPageChange={gridPageChanged}
            doAddFormValues={undefined}
            handleExport={undefined}
          />
        </Card.Body>
      </Card>
    );
  }
};
