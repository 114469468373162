import { useIdleTimer } from 'react-idle-timer';
import gql from 'graphql-tag';

export class FormInput {
  id: number;
  statusId: number;
  typeId: number;
  requestNo: string;
  collectorId: number;
  platformId: number;
  valoriserId: number;
  requestDate: Date;
  requestedBy: string;
  approvedDate: Date;
  approvedBy: string;
  approvalNotes: string;
  deliveryNoteNo: string;
  loadUnloadNoteNo: string;
  deliveredDate: Date;
  plannedDeliveryDate: Date;
  vehPlateNo: string;
  trailerPlateNo: string;
  driver: string;
  driverUserId: number;
  licenseTransport: string;
  licenseTransportExpDate: Date;
  biNumber: string;
  issuedBy: string;
  transporter: string;
  representative: string
  weighingNoteNo: string;
  grossWeight: number;
  tareWeight: number;
  deductWeight: number;
  netWeight: number;
  collectorNotes: string;
  collectorWeightsBy: string;
  weightNotes: string;
  valoriserWeightsBy: string;
  valRestrictedUntilDate: Date;
  valorisationDate: Date;
  valorisationNotes: string;
  valorisationEnteredBy: string;
  valorisationMethodId: number;
  distance: number;
  ecoanvelopeNotes: string;
  collectorDeliveryNoteNo: string;
  collectorWeighingNoteNo: string;
  collectorDeliveredDate: string;
  collectorGrossWeight: string;
  collectorTareWeight: number;
  collectorDeductWeight: number;
  collectorNetWeight: number;
  valoriserWeighingNoteNo: string;
  valoriserDeliveredDate: string;
  valoriserDeliveryNoteNo: string;
  valoriserGrossWeight: number;
  valoriserTareWeight: number;
  valoriserDeductWeight: number;
  valoriserNetWeight: number;
  isActive: boolean;
  firstDate: Date;
  lastDate: Date;
  firstUser: string;
  lastUser: string;
  tiredeliveriesQty: any;
}

export const gqlGetQuery = gql`
  query getTiredeliveries($limit: Int, $offset: Int, $order: String, $where: String) {
    getTiredeliveries(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        isFilesOk
        approvalNotes
        typeId
        type {
          id
          name
        }
        approvedBy
        approvedDate
        collectorId
        collector {
          id
          name
        }
        collectorNotes
        collectorsPlatform {
          id
          name
        }
        deductWeight
        deliveredDate
        plannedDeliveryDate
        collectorWeightsBy
        deliveryNoteNo
        distance
        driver
        licenseTransport
        representative
        ecoanvelopeNotes
        firstDate
        firstUser
        grossWeight
        id
        isActive
        lastDate
        lastUser
        collectorDeliveredDate
      collectorWeighingNoteNo
      collectorDeductWeight
      collectorDeliveryNoteNo
      collectorGrossWeight
      collectorNetWeight
      collectorTareWeight
      valoriserDeliveredDate
      valoriserWeighingNoteNo
      valoriserDeductWeight
      valoriserDeliveryNoteNo
      valoriserGrossWeight
      valoriserNetWeight
      valoriserTareWeight
        loadUnloadNoteNo
        netWeight
        platformId
        requestDate
        requestNo
        requestedBy
        status {
          id
          name
          value
        }
        statusId
        tareWeight
        valRestrictedUntilDate
        valorisationDate
        valorisationEnteredBy
        valorisationMethodId
        valorisationNotes
        valoriser {
          id
          name
        }
        valorisationMethod {
          id
          name
        }
        valoriserId
        vehPlateNo
        weighingNoteNo
        weightNotes
        valoriserWeightsBy
        tiredeliveriesQty {
          id
          pieces

          tireType {
            id
            name
          }
          tiretypeId
          weight
        }
      }
      recordCount
    }
  }
`;

export const gqlGetByIdQuery = gql`
  query getTiredeliveryById($id: Int) {
    getTiredeliveryById(id: $id) {
      isFilesOk
      approvalNotes
      typeId
      type {
        id
        name
      }
      approvedBy
      approvedDate
      collectorId
      collector {
        id
        name
        address
        city
        vatin
        tradeRegister
        county {
          id
          name
        }
        signatureFileId
      signatureFileRec {
        id
        fileName
        mimeMediaType
      }
      stampFileId
      stampFileRec {
        id
        fileName
        mimeMediaType
      }
      }
      collectorNotes
      collectorsPlatform {
        id
        name
        address
        city
        county {
          id
          name
        }
        signatureFileId
        signatureFileRec {
        id
        fileName
        mimeMediaType
      }
      stampFileId
      stampFileRec {
        id
        fileName
        mimeMediaType
      }
      }
      deductWeight
      deliveredDate
      plannedDeliveryDate
      collectorWeightsBy
      deliveryNoteNo
      distance
      driver
      driverUserId
      licenseTransport
      licenseTransportExpDate
      biNumber
      issuedBy
      transporter
      representative
      ecoanvelopeNotes
      firstDate
      firstUser
      grossWeight
      id
      isActive
      lastDate
      lastUser
      collectorDeliveredDate
      collectorWeighingNoteNo
      collectorDeductWeight
      collectorDeliveryNoteNo
      collectorGrossWeight
      collectorNetWeight
      collectorTareWeight
      valoriserDeliveredDate
      valoriserWeighingNoteNo
      valoriserDeductWeight
      valoriserDeliveryNoteNo
      valoriserGrossWeight
      valoriserNetWeight
      valoriserTareWeight
      loadUnloadNoteNo
      netWeight
      platformId
      requestDate
      requestNo
      requestedBy
      status {
        id
        value
        name
      }
      statusId
      tareWeight
      tiredeliveriesFiles {
        fileId
        documenttypeId
        documentType {
          id
          name
        }
        filesrepository {
          id
          fileName
          mimeMediaType
          s3Key
        }
        tiredeliveryId
      }
      tiredeliveriesQty {
        id
        weight
        pieces
        tiretypeId
        tireType {
          id
          name
        }
      }
      valRestrictedUntilDate
      valorisationDate
      valorisationEnteredBy
      valorisationMethodId
      valorisationNotes
      valoriser {
        id
        name
        vatin
        tradeRegister
        workstation
        address
        city
        county {
          id
          name
        }
      }
      valoriserId
      vehPlateNo
      trailerPlateNo
      weighingNoteNo
      weightNotes
      valoriserWeightsBy
      colName
      colContract
      colEnvPermit
      colEnvPermitExpDate
      colAddress
      colCity
      colCounty
      colPlatName
      colDriverName
      colTradeRegister
      valName
      valContract
      valEnvPermit
      valEnvPermitExpDate
      valAddress
      valCity
      valCounty
      valWorkstation
      valTradeRegister
    }
  }
`;

export const gqlAdd = gql`
  mutation addTiredeliveryRequest($data: TiredeliveryInput) {
    addTiredeliveryRequest(data: $data) {
      id
    }
  }
`;

export const gqlUpdate = gql`
  mutation updateTiredelivery($data: TiredeliveryInput) {
    updateTiredelivery(data: $data) {
      id
    }
  }
`;

export const gqlDelete = gql`
  mutation deleteTiredelivery($id: Int) {
    deleteTiredelivery(id: $id)
  }
`;

export const gqlAcceptTireDelivery = gql`
  mutation acceptTireDelivery($tireDeliveryId: Int!) {
    acceptTireDelivery(tireDeliveryId: $tireDeliveryId)
  }
`;

export const gqlRejectTireDelivery = gql`
  mutation rejectTireDelivery($tireDeliveryId: Int!, $rejectReason: String!) {
    rejectTireDelivery(tireDeliveryId: $tireDeliveryId, rejectReason: $rejectReason)
  }
`;
