import { gitnum } from 'gitnum';
import React from 'react';
import { Navbar } from 'react-bootstrap';
import './footer.scss';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer>
      <Navbar className="justify-content-between pb-1" bg="dark" variant="dark" fixed="bottom">
        <Navbar.Text className="p-0">
          <small>
            <a href="https://ecoanvelope.ro/" target="_blank" rel="noopener noreferrer">
              Eco Anvelope | {t('Tires Recycling')}
            </a>{' '}
            {'\u00A92022'}
          </small>
        </Navbar.Text>
        <Navbar.Text className="p-0">
          <small>
            created by{' '}
            <a href="http://www.motion.gr" target="_blank" rel="noopener noreferrer">
              Motion Hellas Ltd.
            </a>{' '}
            {/* Version */}
            <span
              style={{ color: 'white', cursor: 'pointer' }}
              onClick={() => {
                window.location.reload(); // like Ctrl + F5
                alert(
                  `${t('Version')}: ` +
                    gitnum.describe +
                    '\nBuild:  ' +
                    gitnum.git +
                    '\nBranch: ' +
                    gitnum.branch +
                    '\nCommit: ' +
                    gitnum.commit +
                    '\nDate:   ' +
                    gitnum.date,
                );
              }}
            >
              &nbsp;&nbsp;{t('Version')}&nbsp;{gitnum.version}
            </span>
          </small>
        </Navbar.Text>
      </Navbar>
    </footer>
  );
};

export default Footer;
