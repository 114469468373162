import gql from 'graphql-tag';

export const gqlGetQuery = gql`
  query getValoriserStockTransactions($limit: Int, $offset: Int, $order: String, $where: String) {
    getValoriserStockTransactions(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        transactionId
        referenceDate
        transactionInfo
        valoriserId
        valoriserName
        piecesA
        piecesB
        piecesC
        piecesD
        piecesE
        piecesF
        weightTotal
      }
      recordCount
    }
  }
`;

export const gqlGetValoriserQuery = gql`
  query getValoriseryId($id: Int) {
    getValoriserById(id: $id) {
      id
      name
    }
  }
`;