import gql from 'graphql-tag';

export class FormInput {
  passthrough: boolean;
  id: number;
  name: string;
  vatin: string;
  isBlackListed: boolean;
  collectorId: number;
  categoryId: number;
  typeId: number;
  address: string;
  city: string;
  countyId: number;
  phone: string;
  mobile: string;
  fax: string;
  email: string;
  email2: string;
  website: string;
  contactperson: string;
  notes: string;
  workDaysFrom: Date;
  workDaysTill: Date;
  saturdayDaysFrom: Date;
  saturdayDaysTill: Date;
  sundayDaysFrom: Date;
  sundayDaysTill: Date;
  isActive: boolean;
}

export const gqlGetQuery = gql`
  query getCollectionpoints($limit: Int, $offset: Int, $order: String, $where: String) {
    getCollectionpoints(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        needsConfirmation
        address
        fullAddress
        categoryId
        city
        collectionpointsCategory {
          id
          name
        }
        collectionpointsType {
          id
          name
        }
        contactperson
        county {
          id
          name
        }
        collectorsCollectionpoints {
          collectionpointId
          collectorId
          hasContract
      }
        countyId
        email
        email2
        fax
        firstDate
        firstUser
        id
        isActive
        isBlackListed
        lastDate
        lastUser
        mobile
        name
        notes
        phone
        saturdayDaysFrom
        saturdayDaysTill
        sundayDaysFrom
        sundayDaysTill
        typeId
        vatin
        website
        workDaysFrom
        workDaysTill
      }
      recordCount
    }
  }
`;

export const gqlGetByIdQuery = gql`
  query getCollectionpointById($id: Int) {
    getCollectionpointById(id: $id) {
      needsConfirmation
      address
      categoryId
      city
      collectionpointsCategory {
        id
        name
      }
      collectionpointsType {
        id
        name
      }
      collectorsCollectionpoints {
        collectionpointId
        collectorId
        hasContract
        collector {
          id
          name
          address
          city
          countyId
          county {
            id
            name
          }
          phone
          email
          contactperson
          isActive
        }
        firstDate
        firstUser
        id
        isActive
        lastDate
        lastUser
      }
      contactperson
      county {
        id
        name
      }
      countyId
      email
      email2
      fax
      firstDate
      firstUser
      id
      isActive
      isBlackListed
      lastDate
      lastUser
      mobile
      name
      notes
      phone
      saturdayDaysFrom
      saturdayDaysTill
      sundayDaysFrom
      sundayDaysTill
      typeId
      vatin
      website
      workDaysFrom
      workDaysTill
    }
  }
`;

export const gqlAdd = gql`
  mutation addCollectionpoint($data: CollectionpointInput) {
    addCollectionpoint(data: $data) {
      data {
        id
        name
        vatin
        address
        countyId
        county {
          id
          name
        }
        phone
        mobile
      }
      isNew
    }
  }
`;

export const gqlUpdate = gql`
  mutation updateCollectionpoint($data: CollectionpointInput) {
    updateCollectionpoint(data: $data) {
      id
    }
  }
`;

export const gqlDelete = gql`
  mutation deleteCollectionpoint($id: Int) {
    deleteCollectionpoint(id: $id)
  }
`;

export const gqlLinkCollectionpoint = gql`
  mutation linkCollectionpoint($collectionPointId: Int) {
    linkCollectionpoint(collectionPointId: $collectionPointId)
  }
`;

export const gqlUpdateCollectorsCollectionpoint = gql`
  mutation updateCollectorsCollectionpoint($data: CollectorsCollectionpointInput) {
    updateCollectorsCollectionpoint(data:$data) {
      id
    }
  }
`
