import React, { useMemo, useCallback, useState } from 'react';
import { Card, Row } from 'react-bootstrap';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { gqlGetUserRoles } from './graphql';
import { useRootStore } from 'store';
import { generatePath, useHistory } from 'react-router';
import { getRouteByName } from 'app/routes';
import LoadingOverlay from 'react-loading-overlay';
import { useLocation } from 'react-router-dom';
import { useHandleError } from 'services';
import {
  FormFilterCard,
  FieldInput,
  TablePaginatedUC,
  ButtonNewRecord,
  FlexRow,
  FormCol,
  CellActive,
  EyeColumn,
  usePaginatedQuery,
  exportXlsModal,
} from 'motion-components';
import { useTranslation } from 'react-i18next';

/* ----------- Page ------------------ */
export const UserRolesPage = () => {
  const { t } = useTranslation();
  const { appStore, authStore } = useRootStore();

  const { handleGraphqlError } = useHandleError('user-roles');
  /* ----------------- Route State -------------------------- */
  const location = useLocation<any>();
  const history = useHistory();
  // Στο state του routing αποθηκεύω:
  //    1. την τρέχουσα σελίδα του table pagination (pageIndex)
  //  & 2. τα values των φίλτρων (filterValues)

  /* ------------------- Query ------------------------------- */
  const query = usePaginatedQuery(gqlGetUserRoles, buildWhereFromFilterValues, 'name', appStore);
  const {
    loading,
    data,
    dataCount,
    error,
    initPageIndex,
    filtersChanged,
    gridPageChanged,
    queryState,
  } = query;

  const canCreateNew = appStore.isEcoanvelope;
  const handleCreateNew = useCallback(() => {
    history.push({
      pathname: generatePath(getRouteByName('UserRole'), { id: -1 }),
      state: {
        pathname: location.pathname,
        ...location.state,
      },
    });
  }, [history, location.pathname, location.state]);

  const client = useApolloClient();

  //console.log('loading', loading);
  //console.log('rawData', rawData);
  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <Card className="shadow">
        <Card.Header>
          <FlexRow>
            <Card.Title className="m-0">{t('User Roles')}</Card.Title>
            {canCreateNew && <ButtonNewRecord onClick={handleCreateNew} size="sm" />}
          </FlexRow>
        </Card.Header>
        <Card.Body className="nopadding">
          {/* Φίλτρα */}
          <Filters onChange={filtersChanged} initialValues={location?.state?.filterValues ?? {}} />
          {/* TableGrid */}
          <LoadingOverlay active={loading} spinner text="Please wait...">
            {data ? (
              <DataGrid
                data={data}
                dataCount={dataCount}
                initPageIndex={initPageIndex}
                onPageChange={gridPageChanged}
                handleExport={() => {
                  handleExport(client, queryState);
                }}
              />
            ) : (
              <div style={{ height: '200px' }}></div>
            )}
          </LoadingOverlay>
        </Card.Body>
      </Card>
    );
  }
};

/* ----------- Grid ------------------ */

const DataGrid = ({ data, dataCount, initPageIndex, onPageChange, handleExport }) => {
  const { t } = useTranslation();
  //console.log(props.dataX);
  const location = useLocation<any>();
  const history = useHistory();
  const { appStore } = useRootStore();
  const rowClicked = useCallback(
    (row) => {
      history.push({
        pathname: generatePath(getRouteByName('UserRole'), { id: row.id }),
        state: {
          pathname: location.pathname,
          ...location.state,
        },
      });
    },
    [history, location.pathname, location.state],
  );

  const columns = useMemo(
    () => [
      EyeColumn(rowClicked),
      { Header: t('User Role'), accessor: 'name' },
      { Header: t('Fixed'), accessor: 'isSystem', Cell: CellActive },
      { Header: t('Active'), accessor: 'isActive', Cell: CellActive },
    ],
    [rowClicked, t],
  );
  return (
    <TablePaginatedUC
      columns={columns}
      data={data}
      dataCount={dataCount}
      // initPageIndex={initPage}
      // onSortByChange={handleSortByChange}
      onExport={handleExport}
      onPageChange={onPageChange}
      initPageIndex={initPageIndex}
      id={'UsersTable'}
      appStore={appStore}
    />
  );
};

/* ------------------- Διαχείριση Φίλτρων ------------------------------ */
function buildWhereFromFilterValues(values): string {
  // Φτιάξε το where για το graphql
  let where: any = {};
  if (values) {
    if (values.searchText) {
      const searchText = '%' + values.searchText + '%';
      const likeObj = { $like: searchText };
      where = { name: likeObj };
    }
  }
  return JSON.stringify(where);
}
/* ----------- Filters ------------------ */
const Filters = ({ onChange, initialValues }) => {
  const { t } = useTranslation();
  //
  function handleFiltersSubmit(values, { setSubmitting }) {
    setTimeout(() => {
      setSubmitting(false);
      onChange(values);
    }, 400);
  }
  console.log('filters-init ', initialValues);

  const filters = { searchText: initialValues?.searchText ?? '' };
  //const { values } = useFormikContext();
  return (
    <FormFilterCard
      title={t('Filters Criteria')}
      onSubmit={handleFiltersSubmit}
      initialValues={{
        searchText: filters?.searchText,
      }}
    >
      <Row>
        <FieldInput as={FormCol} name="searchText" label={t('Search')} type="search" />
      </Row>
    </FormFilterCard>
  );
};

/* --------------------- Export -------------------*/
export const exportColumns = [
  { fieldName: 'id', fieldTitle: 'Id', fieldType: 'INT' },
  { fieldName: 'name', fieldTitle: 'User Role', fieldType: 'TEXT' },
  { fieldName: 'isSystem', fieldTitle: 'Fixed', fieldType: 'BOOL' },
  { fieldName: 'isActive', fieldTitle: 'Active', fieldType: 'BOOL' },
];

async function handleExport(client, queryState) {
  function useGetQuery() {
    return useLazyQuery(gqlGetUserRoles);
    //return [null, { loading: null, data: null }];
  }
  const filterVariables = { where: buildWhereFromFilterValues(queryState?.filterValues) };
  await exportXlsModal({
    apolloClient: client,
    exportFileName: 'User Roles',
    getLazyQuery: useGetQuery,
    columnsDef: exportColumns,
    filterVariables: filterVariables,
  });
}
