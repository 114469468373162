import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { promiseModalDialog } from 'motion-components';
import { toastSuccess } from 'utils/toasts';
import { FaCheck, FaRecycle } from 'react-icons/fa';
import promiseModalTireValorisation from './tireValorisation-modal';

const gqlTireValorisation = gql`
  mutation tireValorisation($data: ValorisationInput) {
    tireValorisation(data: $data)
  }
`;

const UseTireValorisation = () => {
  const [updateRecord] = useMutation(gqlTireValorisation);
  const tireValorisation = async (data) => {
    const ret = await updateRecord({
      variables: { data: data },
    });
    console.log('$$$', ret);
    return ret;
  };
  return { tireValorisation };
};

export const TireValorisationButton = ({ data, onClick, disabled = false }) => {
  const { t } = useTranslation();
  const { tireValorisation } = UseTireValorisation();

  const handleSubmit = async () => {
    const res = await promiseModalTireValorisation({
      title: t('Tire Valorisation'),
      valorisationMethodId: data.valorisationMethodId,
      valoriserId: data.valoriserId,
    });
    if (res) {
      await tireValorisation({ tireDeliveryId: data.id, ...res });
      toastSuccess(t('Successfull tire valorisation'));
      onClick();
    }
  };
  return (
    <>
      <Button disabled={disabled} onClick={handleSubmit} className="ml-3 mr-4">
        <FaRecycle className="mb-1 mr-3" />
        <span>{t('Valorisation of Tires')}</span>
      </Button>
    </>
  );
};
