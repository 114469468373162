import { useLazyQuery } from '@apollo/client';
import { exportXlsModal } from 'motion-components';
import { gqlGetQuery } from '../graphql';
import { buildWhereFromFilterValues } from './filters';
import { useTranslation } from 'react-i18next';

export const exportColumns = (t) => {
  return [
    // { fieldName: "id", fieldTitle: "Id", fieldType: "TEXT" },
    { fieldName: 'id', fieldTitle: 'ID', fieldType: 'TEXT' },
    /* { fieldName: 'isHidden', fieldTitle: 'Is Hidden', fieldType: 'TEXT' }, */
    { fieldName: 'name', fieldTitle: t('Valoriser'), fieldType: 'TEXT' },
    { fieldName: 'vatin', fieldTitle: 'CUI/CNP', fieldType: 'TEXT' },
    { fieldName: 'tradeRegister', fieldTitle: 'Reg. Com. J', fieldType: 'TEXT' },
    /*   { fieldName: 'valoriserGroupId', fieldTitle: 'Valoriser Group', fieldType: 'TEXT' }, */
    { fieldName: 'address', fieldTitle: t('Address'), fieldType: 'TEXT' },
    { fieldName: 'city', fieldTitle: t('City'), fieldType: 'TEXT' },
    { fieldName: 'county.name', fieldTitle: t('County'), fieldType: 'TEXT' },
    { fieldName: 'phone', fieldTitle: t('Phone'), fieldType: 'TEXT' },
    { fieldName: 'mobile', fieldTitle: t('Mobile'), fieldType: 'TEXT' },
    { fieldName: 'fax', fieldTitle: 'Fax', fieldType: 'TEXT' },
    { fieldName: 'email', fieldTitle: t('E-mail'), fieldType: 'TEXT' },
    { fieldName: 'website', fieldTitle: t('Web Site Address'), fieldType: 'TEXT' },
    { fieldName: 'contactperson', fieldTitle: t('Contact Person'), fieldType: 'TEXT' },
    { fieldName: 'partnerName', fieldTitle: t('Partner Name'), fieldType: 'TEXT' },
    { fieldName: 'workstation', fieldTitle: t('Workstation'), fieldType: 'TEXT' },
    { fieldName: 'contractNo', fieldTitle: t('Contract Number'), fieldType: 'TEXT' },
    {
      fieldName: 'contractExpirationDate',
      fieldTitle: t('Contract Expiration Date'),
      fieldType: 'DATE',
    },
    {
      fieldName: 'environmentalPermit',
      fieldTitle: t('Environmental Permit'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'envPermitExpirationDate',
      fieldTitle: t('Permit Expiration Date'),
      fieldType: 'DATETIME',
    },
    {
      fieldName: 'deliveriesYearlyWeightTarget',
      fieldTitle: t('Yearly Weight Target (t)'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'autoApproveDeliveries',
      fieldTitle: t('Auto Approve Deliveries'),
      fieldType: 'BOOL',
    },
    {
      fieldName: 'maxDeliveriesPerDay',
      fieldTitle: t('Max Deliveries Per Day'),
      fieldType: 'TEXT',
    },
    { fieldName: 'isActive', fieldTitle: t('Active'), fieldType: 'BOOL' },
    { fieldName: 'notes', fieldTitle: t('Notes'), fieldType: 'TEXT' },
    /*   { fieldName: 'workDaysFrom', fieldTitle: 'Monday to Friday from', fieldType: 'DATETIME' },
    { fieldName: 'workDaysTill', fieldTitle: 'Monday to Friday till', fieldType: 'DATETIME' },
    { fieldName: 'saturdayDaysFrom', fieldTitle: 'Saturday from', fieldType: 'DATETIME' },
    { fieldName: 'saturdayDaysTill', fieldTitle: 'Saturday till', fieldType: 'DATETIME' },
    { fieldName: 'sundayDaysFrom', fieldTitle: 'Sunday from', fieldType: 'DATETIME' },
    { fieldName: 'sundayDaysTill', fieldTitle: 'Sunday till', fieldType: 'DATETIME' }, */

    /* { fieldName: 'firstDate', fieldTitle: 'First Date', fieldType: 'DATETIME' },
    { fieldName: 'lastDate', fieldTitle: 'Last Date', fieldType: 'DATETIME' },
    { fieldName: 'firstUser', fieldTitle: 'First Usr', fieldType: 'TEXT' },
    { fieldName: 'lastUser', fieldTitle: 'Last User', fieldType: 'TEXT' }, */

    /* { fieldName: 'isUnavailable', fieldTitle: 'Is Unavailable', fieldType: 'BOOL' },
    { fieldName: 'unavailableReason', fieldTitle: 'Unavailable Reason', fieldType: 'TEXT' }, */
  ];
};

// export function useGetQuery(): any {
//   const [get, { loading }] = useLazyQuery(gqlGetQuery, { onCompleted: transformData });

//   const [result, setResult] = useState<any>([get, { loading, data: undefined }]);

//   function transformData(data) {
//     const recs = data.getFormsDefinitions.data.map(values => {
//       return {
//         ...values,
//         solvingPeriod: periodQtyUnitToString(values.solvingPeriodQty, values.solvingPeriodUnit),
//         restoringPeriod: periodQtyUnitToString(values.restoringPeriodQty, values.restoringPeriodUnit),
//         pausingPeriod: periodQtyUnitToString(values.pausingPeriodQty, values.pausingPeriodUnit),
//         repeatingPeriod: periodQtyUnitToString(values.repeatingPeriodQty, values.repeatingPeriodUnit),
//       };
//     });

//     const trsData = { getFormsDefinitions: { data: recs, recordCount: data?.getFormsDefinitions?.recordCount } };
//     setResult([get, { loading, data: trsData }]);
//   }

//   return result;
// }

export async function handleExport(client, queryState: any, t): Promise<void> {
  function useGetQuery() {
    return useLazyQuery(gqlGetQuery);
    //return [null, { loading: null, data: null }];
  }
  const filterVariables = {
    where: buildWhereFromFilterValues(queryState?.filterValues),
  };
  await exportXlsModal({
    apolloClient: client,
    exportFileName: 'export Valorisers',
    getLazyQuery: useGetQuery,
    columnsDef: exportColumns(t),
    filterVariables: filterVariables,
  });
}
