import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { generatePath, useHistory } from 'react-router';
import { toastWarning, toastSuccess } from 'utils/toasts';
import { getRouteByName } from 'app/routes';
import Card from 'react-bootstrap/Card';
import { useHandleError } from 'services';
import { gqlAdd } from '../graphql';
import { SelectCollectionpointForm } from 'components/select-collectionpoint';
import { Button } from 'react-bootstrap';
import { ButtonBack } from 'motion-components';
import { useLocation } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useRootStore } from 'store';

export const CreateNewTirescollection = () => {
  const history = useHistory();
  const { appStore } = useRootStore();
  const location = useLocation<any>();
  const { t } = useTranslation();
  const { handleGraphqlError } = useHandleError('newtirescollection');
  const [addRecord] = useMutation(gqlAdd);
  const [count, setCount] = useState<number>(0);
  const handleSubmit = (collectionpointId) => {
    console.log('handleSubmit', collectionpointId);
    history.push({
      pathname: generatePath(getRouteByName('Tirecollection'), { id: -1 }),
      state: {
        ...location.state,
        pathname: location.pathname,
        collectionPointId: collectionpointId,
      },
    });
  };

  const handleNewCP = () => {
    history.push({
      pathname: generatePath(getRouteByName('Collectionpoint'), { id: -1 }),
      state: {
        pathname: location.pathname,
        ...location.state,
      },
    });
  };

  return (
    <Card bg="light" className="rounded-0">
      <Card.Header className="d-flex flex-row justify-content-between">
        <Card.Title className="m-0">
          {t(
            appStore?.isCollectionPoint
              ? t('New Tire Collection Request')
              : t('New Tire Collection'),
          )}
        </Card.Title>
        {count > 0 && (
          <div className="d-flex flex-row">
            <Button variant="success" onClick={handleNewCP} style={{ borderRadius: '20px' }}>
              <FaPlus className="mb-1" /> {t('New Collection Point')}
            </Button>
            <span className="mx-2" />
            <ButtonBack style={{ marginLeft: 'auto' }} onClick={() => setCount(0)} />
          </div>
        )}
      </Card.Header>
      <Card.Body>
        <SelectCollectionpointForm
          count={count}
          setCount={setCount}
          onValueSet={(id) => handleSubmit(id)}
        />
      </Card.Body>
    </Card>
  );
};
