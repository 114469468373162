import React, { useMemo, useCallback, useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  CellLeft,
  promiseModalDialog,
  TableSubSimple,
  toastFailure,
  toastSuccess,
} from 'motion-components';
import { Button } from 'react-bootstrap';
import { FilePicker } from 'react-file-picker';
import { uploadFile2S3withSignedUrl } from 'utils/s3helpfunc';
import { FaTrashAlt, FaFolderOpen, FaUser } from 'react-icons/fa';
import { useRootStore } from 'store';
import promiseModalSelectUserFile from 'components/modal-select-user-file';
import _ from 'lodash';

const getFiles = gql`
  query getDocumentTypes($where: String, $offset: Int, $limit: Int, $order: String) {
    getDocumentTypes(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        id
        name
      }
      recordCount
    }
  }
`;

const gqlAddFilesRepository = gql`
  mutation addFilesrepository($fileName: String!, $mimeMediaType: String!) {
    addFilesrepository(fileName: $fileName, mimeMediaType: $mimeMediaType) {
      id
      url
    }
  }
`;

const gqlGetFilesRepositoryFileUrlById = gql`
  query getFilesrepositoryFileUrlById($id: Int) {
    getFilesrepositoryFileUrlById(id: $id)
  }
`;

const gqlDeleteFilesRepository = gql`
  mutation deleteFilesrepository($id: Int!) {
    deleteFilesrepository(id: $id)
  }
`;

const gqlUpdateTirecollectionsFile = gql`
  mutation updateTirecollectionsFile($data: TirecollectionsFileInput) {
    updateTirecollectionsFile(data: $data) {
      id
    }
  }
`;

export const NewTirecollectionFilesSection = ({ readOnly, files, setFiles }) => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const {
    loading,
    data: rawData,
    refetch,
  } = useQuery(getFiles, {
    variables: { where: JSON.stringify({ useInCollections: 1 }), order: 'id ASC' },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const data = rawData && rawData.getDocumentTypes ? rawData.getDocumentTypes.data : undefined;
    const updatedFiles = [];
    if (data !== undefined) {
      data.map((rec) => {
        updatedFiles.push({ ...rec, fileId: null, fileName: null });
      });
      setFiles(updatedFiles);
    }
  }, [rawData]);

  const handleUpdateFile = useCallback(
    (id, fileId, fileName) => {
      console.log(`id= ${id} and fileId = ${fileId}`);
      const file = files.find((rec) => rec.id === fileId);
      console.log('file= ', file);
      setFiles((prevFiles) => prevFiles.filter((row) => row.id !== fileId));
      setFiles((prevFiles) => [
        ...prevFiles,
        { ...file, id: fileId, fileId: id, fileName: fileName },
      ]);
      setFiles((prevFiles) => _.sortBy(prevFiles, 'id'));
    },
    [files],
  );

  // * ------ ΕΙΔΙΚΟ COMPONENT ΜΕ ΤΑ ΚΟΥΜΠΙΑ ΓΙΑ ΤΗΝ ΚΟΛΩΝΑ ΤΩΝ ΕΝΕΡΓΕΙΩΝ ---------
  //const [updateRecord] = useMutation(gqlUpdateTirecollectionsFile);

  const [uploadStatus, setUploadStatus] = useState(-1);
  const client = useApolloClient();
  const [addFile] = useMutation(gqlAddFilesRepository);
  const [deleteFile] = useMutation(gqlDeleteFilesRepository);
  /**
  // * ----- HANDLER: Upload File
  */
  const handleFileSelect = async (row: any, fileObj: File) => {
    console.log(fileObj);
    // Εχει επιλεγεί αρχείο...
    const res = await addFile({
      // Mutation
      variables: { fileName: fileObj.name, mimeMediaType: fileObj.type },
    });
    const { id, url } = res?.data?.addFilesrepository;
    console.log(id, url);
    if (id && url) {
      setUploadStatus(0);
      uploadFile2S3withSignedUrl(
        fileObj,
        url,
        async () => {
          toastSuccess(t('File Uploaded Succesfully')); //'To Aρχείο Μεταφέρθηκε στον server');
          // Update Local Record
          //await updateRecord({ variables: { data: { fileId: id, id: row.id } } });
          handleUpdateFile(id, row.id, fileObj.name);
          setUploadStatus(-1);
        }, //onFileUploaded
        () => {
          toastFailure(t('Failed to upload file')); //('Παρουσιάστηκε Κάποιο Πρόβλημα στο Ανέβασμα του Αρχείου');
          setUploadStatus(-1);
        }, //onUploadError
        (percent) => {
          setUploadStatus(percent);
        }, //onUploadProgress
      );
    }
  };

  /**
  // * ----- HANDLER: Open File (κανω το query με promise)
  */
  const handleFileOpen = useCallback(
    async (fileId) => {
      console.log('***OPEN FILE***');
      client
        .query({
          query: gqlGetFilesRepositoryFileUrlById,
          variables: { id: fileId },
        })
        .then((res) => {
          const url = res?.data?.getFilesrepositoryFileUrlById;
          if (url) {
            window.open(url);
          } else {
            toastFailure(t('Error during file download')); //'Πρόβλημα Στο Κατέβασμα του Αρχείου'
          }
        })
        .catch((err) => toastFailure(t('Error during file download ') + err)); //`Πρόβλημα στο κατέβασμα του αρχείου err:${err}`));
    },
    [client, t],
  );

  /*
      Delete File
  */
  const handleFileDelete = async (row) => {
    const fileId = row.fileId;
    const rowId = row.id;

    const res = await promiseModalDialog({
      title: t('Delete File Record'), //'Διαγραφή Εγγραφής Αρχείου',
      text: t('Do you really want to delete the file;'), //'Είστε σίγουροι ότι θέλετε να διαγράψετε το Αρχείο;',
    });
    if (!res) return;
    // Update Local Record
    //updateRecord({ variables: { data: { fileId: null, id: rowId } } });
    handleUpdateFile(null, rowId, null);
    setTimeout(async () => {
      await deleteFile({ variables: { id: fileId } }).catch((error) => {});
      //toastSuccess('Το Αρχείο Διαγράφηκε');
      refetch();
    }, 150);
  };

  async function handleSelectUserFile(row) {
    const res = await promiseModalSelectUserFile({
      title: `${t('Select a File from your storage')}`,
    });

    if (res) {
      console.log('res= ', res);
      // Update Local Record
      //await updateRecord({ variables: { data: { fileId: res.fileId, id: row.original.id } } });
      handleUpdateFile(res.fileId, row.original.id, res.fileName);
      refetch();
    }
  }

  const CellLocalCommands = ({ row, readOnly }) => {
    const { original } = row; // !!! To original ΕΙΝΑΙ το αρχικό DcdpFile
    const fileExist = original?.fileId && original?.fileId > 0;

    //console.log('[DcdpFilesGrid] row :', original);
    return (
      <div className="d-flex">
        {/* -------------   BUTTON ΕΠΙΛΟΓΗΣ ΑΡΧΕΙΟΥ ------------------ */}
        {!fileExist && !readOnly && (
          <FilePicker
            onChange={(fileObject) => handleFileSelect(original, fileObject)}
            onError={(errMsg) => {
              console.log(errMsg);
              toastFailure(errMsg);
            }}
          >
            <Button variant="primary" size="sm">
              <FaFolderOpen className="mb-1" />
              <span className="ml-2">{t('Select File')}</span>
            </Button>
          </FilePicker>
        )}

        {/* -------------   BUTTON ΕΠΙΛΟΓΗΣ ΑΡΧΕΙΟΥ ΧΡΗΣΤΗ ------------------ */}
        {!fileExist && !readOnly && (
          <Button
            variant="primary"
            size="sm"
            className="ml-2"
            onClick={() => handleSelectUserFile(row)}
          >
            <FaUser className="mb-1" />
            <span className="ml-2">{t('my Files')}</span>
          </Button>
        )}

        {/* -------------   BUTTON ΔΙΑΓΡΑΦΗΣ ------------------ */}
        {fileExist && !readOnly && (
          <Button variant="danger" size="sm" onClick={() => handleFileDelete(original)}>
            <FaTrashAlt className="mb-1" />
            <span className="ml-2">{t('Delete')}</span>
          </Button>
        )}

        {/* -------------   BUTTON ΑΝΟΙΓΜΑΤΟΣ ------------------ */}
        {fileExist && (
          <Button
            variant="link"
            size="sm"
            style={{ color: 'blue' }}
            onClick={() => handleFileOpen(original.fileId)}
          >
            {original?.fileName}
          </Button>
        )}
      </div>
    );
  };

  const columns = useMemo(
    () => [
      /* {
        Header: t('id'),
        accessor: 'id',
        Cell: ({ row }) => <div className="text-center  mt-2">{row.original?.id}</div>,
      }, */
      {
        Header: t('Document Type'),
        accessor: 'name',
        Cell: ({ row }) => <div className="text-left  mt-2">{t(row.original?.name)}</div>,
      },

      {
        Header: t('File'),
        accessor: 'fileId',
        Cell: ({ row }) => {
          return <CellLocalCommands readOnly={readOnly} row={row} />;
        },
      },
    ],
    [CellLocalCommands, t, files],
  );

  return (
    <div>
      <h5 className="form-group-title mb-0">{t('Documents')}</h5>
      {files && <TableSubSimple title={t('Files')} columns={columns} data={files} />}
    </div>
  );
};
