import React from 'react';
import { Row } from 'react-bootstrap';
import {
  buildWhereForDates,
  FormCol,
  FormFilterCard,
  FieldInput,
  FieldDateRanger,
  FieldSelectIsActive,
} from 'motion-components';
import { useTranslation } from 'react-i18next';
import { FieldSelectCollector, FieldSelectDeliveryStatus, FieldSelectValoriser } from 'components';
import { TireDeliveriesStatus } from 'shared/constants';
import { useRootStore } from 'store';

/* ----------------------- Διαχείριση Φίλτρων ------------------------------ */
export function buildWhereFromFilterValues(values): string {
  // Φτιάξε το where για το graphql
  let where: any = {};
  if (values) {
    if (values.startDate || values.endDate) {
      where = {
        ...where,
        ...buildWhereForDates(values.startDate, values.endDate, 'deliveredDate'),
      };
    }

    if (values.startDateRes || values.endDateRes) {
      where = {
        ...where,
        ...buildWhereForDates(values.startDateRes, values.endDateRes, 'lastDate'),
      };
    }
    if (values.startDateVal || values.endDateVal) {
      where = {
        ...where,
        ...buildWhereForDates(values.startDateVal, values.endDateVal, 'valorisationDate'),
      };
    }

    if (values.searchText) {
      const searchText = '%' + values.searchText + '%';
      const likeObj = { $like: searchText };
      where = {
        ...where,
        $or: [{ requestNo: likeObj }, { deliveryNoteNo: likeObj }, { weighingNoteNo: likeObj }],
      };
    }
    if (values.collectorId) where = { ...where, collectorId: values.collectorId };
    if (values.valoriserId) where = { ...where, valoriserId: values.valoriserId };
  }
  return JSON.stringify(where);
}

/* ----------------------- Filter Form ------------------------------ */
export const Filters = ({ onChange, initialValues }) => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  function handleFiltersSubmit(values, { setSubmitting }) {
    setTimeout(() => {
      setSubmitting(false);
      onChange(values);
    }, 400);
  }
  //console.log("filters-init ", initialValues);

  const filters = {
    startDate: initialValues?.startDate ?? null,
    endDate: initialValues?.endDate ?? null,
    startDateVal: initialValues?.startDateVal ?? null,
    endDateVal: initialValues?.endDateVal ?? null,
    searchText: initialValues?.searchText ?? '',
    isActive: initialValues?.isActive ?? null,
    valoriserId: initialValues?.valoriserId ?? null,
    collectorId: initialValues?.collectorId ?? null,
  };

  return (
    <FormFilterCard
      title={t('Filter Criteria')}
      onSubmit={handleFiltersSubmit}
      initialValues={filters}
    >
      <>
        <Row>
          <FieldInput
            as={FormCol}
            name="searchText"
            label={t('Search (Request Number)')}
            type="search"
          />
          <FieldDateRanger
            as={FormCol}
            label={t('Delivering Date from')}
            label2={t('till')}
            name="startDate"
            name2="endDate"
          />
          {!appStore.isCollector && <FieldSelectCollector label={t('Collector')} as={FormCol} />}
        </Row>
        <Row>
          <FieldDateRanger
            as={FormCol}
            label={t('Valorisation Date from')}
            label2={t('till')}
            name="startDateVal"
            name2="endDateVal"
          />

          <FieldSelectValoriser label={t('Valoriser')} as={FormCol} />
        </Row>
      </>
    </FormFilterCard>
  );
};
