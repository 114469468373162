import { FlexSpinner } from 'motion-components';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ModalFlex = ({ isOpen }: { isOpen: boolean }) => {
  const { t } = useTranslation();
  return (
    <Modal show={isOpen} size="sm" centered backdrop="static">
      <Modal.Header>
        <Modal.Title>{t('Printing Document')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FlexSpinner />
      </Modal.Body>
    </Modal>
  );
};

export default ModalFlex;
