import gql from 'graphql-tag';

export class FormInput {
  id: number;
  userId: number;
  reportId: string;
  frequencyId: string;
  lastExecDate: Date;
  nextExecDate: Date;
  isActive: boolean;
}

export const gqlGetQuery = gql`
  query getReportSubscriptions($limit: Int, $offset: Int, $order: String, $where: String) {
    getReportSubscriptions(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        id
        userId
        user {
          id
          fullName
        }
        reportId
        frequencyId
        lastExecDate
        nextExecDate
        isActive
        firstDate
        firstUser
        lastDate
        lastUser
      }
      recordCount
    }
  }
`;

export const gqlGetByIdQuery = gql`
  query getReportSubscriptionById($id: Int) {
    getReportSubscriptionById(id: $id) {
      id
      userId
      user {
        id
        fullName
      }
      reportId
      frequencyId
      lastExecDate
      nextExecDate
      isActive
      firstDate
      firstUser
      lastDate
      lastUser
    }
  }
`;

export const gqlAdd = gql`
  mutation addReportSubscription($data: ReportSubscriptionInput) {
    addReportSubscription(data: $data) {
      id
      userId
      reportId
      frequencyId
      lastExecDate
      nextExecDate
      isActive
    }
  }
`;

export const gqlUpdate = gql`
  mutation updateReportSubscription($data: ReportSubscriptionInput) {
    updateReportSubscription(data: $data) {
      id
      userId
      reportId
      frequencyId
      lastExecDate
      nextExecDate
      isActive
    }
  }
`;

export const gqlDelete = gql`
  mutation deleteReportSubscription($id: Int) {
    deleteReportSubscription(id: $id)
  }
`;
