import React from 'react';
import { Row } from 'react-bootstrap';
import * as Lo from 'lodash';

import {
  FormCol,
  FormFilterCard,
  FieldInput,
  FieldDateRanger,
  buildWhereForDates,
} from 'motion-components';
import { FieldSelectAuditAction } from './fs-action';
import { FieldSelectAuditTableName } from './fs-tablename';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { getAuditTableChilds } from '../audit-trail-dictionary';

/* ------------------- Διαχείριση Φίλτρων ------------------------------ */
export function buildWhereFromFilterValues(values): string {
  // Φτιάξε το where για το graphql
  if (!values) return '{}';
  let where = {};

  if (values.startDate || values.endDate)
    where = { ...where, ...buildWhereForDates(values.startDate, values.endDate, 'auditDate') };

  if (values.searchText) {
    const searchText = '%' + values.searchText + '%';
    const likeObj = { $like: searchText };
    where = {
      ...where,
      $or: [{ user: likeObj }],
    };
  }

  if (values.auditAction) where = { ...where, auditType: values.auditAction };
  // table and/or id filter
  if (values.tableRowId && !values.tableName) where = { ...where, tableRowId: values.tableRowId };
  else if (values.tableName && !values.tableRowId)
    where = { ...where, tableName: values.tableName };
  else if (values.tableName && values.tableRowId) {
    const childs = getAuditTableChilds(values.tableName);
    let subWhere = {};
    if (childs.length === 0) {
      subWhere = { tableName: values.tableName, tableRowId: values.tableRowId };
    } else {
      const subTableFilters = childs.map((rec) => {
        return {
          tableName: rec.tableName,
          tableRowId: {
            $in: `#(SELECT id FROM ${rec.tableName} WHERE ${rec.keyName}=${values.tableRowId})`,
          },
        };
      });
      subWhere = {
        $or: [{ tableName: values.tableName, tableRowId: values.tableRowId }, ...subTableFilters],
      };
    }

    if (!Lo.isEmpty(where)) where = { $and: [where, subWhere] };
    else where = subWhere;
  }

  return JSON.stringify(where);
}

/* ----------- Filters ------------------ */
type OnChangeFunc = (values: any) => void;
type Props = {
  onChange: OnChangeFunc;
  initialValues: any;
};
export const Filters: (props: Props) => JSX.Element = ({ onChange, initialValues }) => {
  const { t } = useTranslation();

  function handleFiltersSubmit(values, { setSubmitting }) {
    setTimeout(() => {
      setSubmitting(false);
      onChange(values);
    }, 400);
  }
  //console.log('filters-init ', initialValues);

  const filters = {
    startDate: initialValues?.startDate ?? null,
    endDate: initialValues?.endDate ?? null,
    tableRowId: initialValues?.tableRowId ?? null,
    tableName: initialValues?.tableName ?? null,
    auditAction: initialValues?.auditAction ?? null,
    searchText: initialValues?.searchText ?? null,
  };
  return (
    <FormFilterCard
      title={t('Filter Criteria')}
      onSubmit={handleFiltersSubmit}
      initialValues={filters}
    >
      <FormFields />
    </FormFilterCard>
  );
};

const FormFields = () => {
  return (
    <>
      <Row>
        <FieldDateRanger
          as={FormCol}
          label={t('Data change from')}
          label2={t('till')}
          name="startDate"
          name2="endDate"
        />
        <FieldInput as={FormCol} name="searchText" label={t('Search (User Name)')} type="search" />
        <FieldSelectAuditTableName as={FormCol} />
        <FieldInput as={FormCol} name="tableRowId" label={t('Element Id')} type="search" />
        <FieldSelectAuditAction as={FormCol} name="auditAction" label={t('Action')} />
      </Row>
    </>
  );
};
