import {
  addComma,
  arrayIncludes,
  charToNumber,
  findLineIdxIncludes,
  findLineIncludes,
  findNextLineIncludes,
  removeSpaces,
  stringFollowingSubString,
  stringUptoSubString,
} from 'common/strings-helpfunc';
import { type } from 'os';

// weihing-note: Heidelberg Chiscadaga.jpg
export function parseHeidelbergCementChiscadaga(lines: [string], readings: any) {
  readings.noteType = 'Heidelberg Cement';
  const numberLine = findLineIncludes(lines, 'din');
  if (numberLine) {
    // Read Note Number from "Bon de cântar Nr. 310 din 10.02.22"
    let noteStr = stringUptoSubString(numberLine, 'din').trim()
    if (noteStr) noteStr = noteStr.substring(noteStr.lastIndexOf(' ') + 1)
    if (noteStr) noteStr = noteStr.trim(noteStr);
    readings.noteNumber = charToNumber(noteStr);

    // // Read Date from "Bon de cântar Nr. 310 din 10.02.22"
    // let dateStr = stringFollowingSubString(numberLine, 'din');
    // if (dateStr) dateStr = dateStr.trim(dateStr);
    // readings.date = dateStr;
  }

  /* Πάρε γραμμή με din.  -> date */
  const dateLine = findLineIncludes(lines, 'din');
  if (dateLine) {
    let dateStr = stringFollowingSubString(dateLine, 'din');
    if (dateStr) dateStr = dateStr.trim(dateStr);
    readings.date = dateStr;
  }

  let nextIndex = 2;
  const weighingLineIdx = findLineIdxIncludes(lines, 'uzate');
  if (weighingLineIdx && weighingLineIdx > 0) {
    if (weighingLineIdx + nextIndex <= lines.length) {
      const line = lines[weighingLineIdx + nextIndex];
      if (line.includes(" ")) {
        readings.gross = addComma(charToNumber(line.substring(0, line.indexOf(' '))));
        readings.tare = addComma(charToNumber(line.substring(line.indexOf(' ') + 1)));
      } else {
        readings.gross = addComma(charToNumber(line));
      }
      nextIndex++;
    }
    if (weighingLineIdx + nextIndex <= lines.length && !readings.tare) {
      const line = lines[weighingLineIdx + nextIndex];
      if (line.includes(" ")) {
        readings.tare = addComma(charToNumber(line.substring(0, line.indexOf(' '))));
        readings.net = addComma(charToNumber(line.substring(line.indexOf(' ') + 1)));
      } else {
        readings.tare = addComma(charToNumber(line));
      }
      nextIndex++;
    }
    if (weighingLineIdx + nextIndex <= lines.length && !readings.net) readings.net = addComma(charToNumber(lines[weighingLineIdx + 4]));
  }
}

// weihing-note: Heidelberg Fieni.jpg
export function parseHeidelbergCementFieni(lines: [string], readings: any) {
  readings.noteType = 'Heidelberg Cement FIENI';
  if (arrayIncludes(lines, 'FIENI')) {
    const numberLine = findLineIncludes(lines, 'NOTA DE CANTAR');
  if (numberLine) {
    // Read Note Number from "NOTA DE CANTAR nr. 5- /data. 07.01.22"
    let noteStr = stringFollowingSubString(numberLine, 'nr.');
    console.log('$$$noteStr following nr= ', noteStr)
    if (noteStr) noteStr = stringUptoSubString(noteStr, '/data');
    console.log('$$$noteStr up to date= ', noteStr)
    if (noteStr) noteStr = noteStr.trim(noteStr);
    readings.noteNumber = noteStr;

    // Read Date from "NOTA DE CANTAR nr. 5- /data. 07.01.22"
    let dateStr = stringFollowingSubString(numberLine, 'data');
    if (dateStr) dateStr = dateStr.trim(dateStr);
    readings.date = dateStr;
    }
  }
  else {
    const numberLine = findLineIncludes(lines, 'data');
  if (numberLine) {
    // Read Note Number from " 3  data  07.01.22"
    let noteStr = stringUptoSubString(numberLine, 'data');
    console.log('$$$noteStr up to date= ', noteStr)
    if (noteStr) noteStr = noteStr.trim(noteStr);
    readings.noteNumber = noteStr;

    // Read Date from " 3  data  07.01.22"
    let dateStr = stringFollowingSubString(numberLine, 'data');
    if (dateStr) dateStr = dateStr.trim(dateStr);
    readings.date = dateStr;
  }
  }


  const weighingLineIdx = findLineIdxIncludes(lines, 'Neta');
  let nextIndex = arrayIncludes(lines, 'FIENI') ? 1 : 2;
  if (lines[weighingLineIdx + nextIndex] === '1') nextIndex++;
  if (weighingLineIdx && weighingLineIdx > 0) {
    if (weighingLineIdx + nextIndex <= lines.length) {
      readings.gross = removeSpaces(lines[weighingLineIdx + nextIndex]);
      nextIndex++;
    }
    if (weighingLineIdx + nextIndex <= lines.length) {
      readings.tare = removeSpaces(lines[weighingLineIdx + nextIndex]);
      nextIndex++;
    }
    if (weighingLineIdx + nextIndex <= lines.length) {
      readings.net = removeSpaces(lines[weighingLineIdx + nextIndex]);
      nextIndex++;
    }
  }
}

// weihing-note: Holcim Alesd.jpg
export function parseHolcimAlesd(lines: [string], readings: any) {
  readings.noteType = 'Holcim Alesd';
  const noteLineIdx = findLineIdxIncludes(lines, 'bon cântar:');
  if (noteLineIdx && noteLineIdx > 0) {
    if (noteLineIdx + 1 <= lines.length) readings.noteNumber = lines[noteLineIdx + 1];
  }

  /*
      Πάρε όλα τα 14ψήφια με ':' Δηλ. ΧΧ.ΧΧ.XXXX XX:XX
      Το πρώτο είναι το Date εισόδου
    */
  const datesArray = lines.filter((line) => line.trim().length === 16 && line.includes(':'));
  if (datesArray && datesArray.length >= 1) readings.date = datesArray[0];

  /*
      Πάρε όλα τα 5ψήφια/4ψήφια με ',' Δηλ. ΧΧ,ΧΧ
      Το πρώτο είναι το Gross μετά Tare και μετα Net
    */
  const weightsArray = lines.filter((line) => (line.trim().length === 5 || line.trim().length === 4) && line.includes(','));
  if (weightsArray && weightsArray.length >= 1) readings.gross = weightsArray[0];
  if (weightsArray && weightsArray.length >= 2) readings.tare = weightsArray[1];
  if (weightsArray && weightsArray.length >= 3) readings.net = weightsArray[2];
}

// weihing-note: Heidelberg Tasca 2.jpg  + Romcim Medgidia.jpg
export function parseRomcimMedgidia(lines: [string], readings: any) {
  readings.noteType = 'ROMCIM SA MEDGIDIA';

  const frsTichet = findLineIdxIncludes(lines, 'Tichet');
  if (frsTichet) {
    const secPart = lines.slice(frsTichet + 1);
    readings.noteNumber = findNextLineIncludes(secPart, 'Tichet', 1);
  }

  readings.date = findNextLineIncludes(lines, 'Data', 1);

  readings.gross = findNextLineIncludes(lines, 'Intrare', 1);
  readings.tare = findNextLineIncludes(lines, 'leisre', 1);
  readings.net = findNextLineIncludes(lines, 'Net', 1);
}

export function parseRomcimHoghiz(lines: [string], readings: any) {
  readings.noteType = 'ROMCIM SA HOGHIZ';

  const numberLine = findLineIncludes(lines, 'nr.');
  if (numberLine) {
    // Read Note Number from "Tichet de cantar nr. 82893 / 11/10/2022 10:16"
    let noteStr = stringFollowingSubString(numberLine, 'nr.');
    console.log('$$$noteStr following nr= ', noteStr)
    if (noteStr) {
      readings.noteNumber = noteStr.substring(0, noteStr.indexOf('/')).trim();
      readings.date = noteStr.substring(noteStr.indexOf('/') + 1).trim();
    }
    console.log('$$$noteStr up to date= ', noteStr)
  }


  /*
      Πάρε όλα με 'kg'
      Το πρώτο είναι το Gross μετά Tare και μετα Net
    */
  const weightsArray = lines.filter((line) => (line.includes('kg')));
  if (weightsArray && weightsArray.length >= 1) readings.gross = stringUptoSubString(weightsArray[0], 'kg').trim();
  if (weightsArray && weightsArray.length >= 2) readings.tare = stringUptoSubString(weightsArray[1], 'kg').trim();
  if (weightsArray && weightsArray.length >= 3) readings.net = stringUptoSubString(weightsArray[2], 'kg').trim();
}


// weihing-note: Heidelberg Tasca 1.jpg
export function parseHeidelbergCementTasca(lines: [string], readings: any) {
  readings.noteType = 'Heidelberg Cement Tasca';
  const numberLine = findLineIncludes(lines, 'Bon de');
  if (numberLine) {
    // Read Note Number from "Bon de cântar Nr. 310 din 10.02.22"
    let noteStr = stringFollowingSubString(numberLine, 'Nr.')
    if (!noteStr) noteStr = stringFollowingSubString(numberLine, 'nr:')
    if (!noteStr) noteStr  = findNextLineIncludes(lines, 'nr:', 1);
    if (noteStr) noteStr = stringUptoSubString(noteStr, 'din');
    if (noteStr) noteStr = noteStr.trim(noteStr);
    readings.noteNumber = noteStr;
  }

  /* Πάρε γραμμή με din.  -> date */
  const dateLine = findLineIncludes(lines, 'din');
  if (dateLine) {
    let dateStr = stringFollowingSubString(dateLine, 'din');
    if (dateStr) dateStr = dateStr.trim(dateStr);
    readings.date = dateStr;
  }

  /*
      Πάρε όλα τα 5ψήφια με ',' Δηλ. ΧΧ,ΧΧ
      Το πρώτο είναι το Gross μετά Tare και μετα Net
    */
  const weightsArray = lines.filter((line) => (removeSpaces(line).length === 5 || removeSpaces(line).length === 4) && line.includes(','));
  if (weightsArray && weightsArray.length >= 1) readings.gross = removeSpaces(weightsArray[0]);
  if (weightsArray && weightsArray.length >= 2) readings.tare = removeSpaces(weightsArray[1]);
  if (weightsArray && weightsArray.length >= 3) readings.net = removeSpaces(weightsArray[2]);
}

// weihing-note: HER COMTRANS 1 + HER COMTRANS 2
export function parseHerComtrans(lines: [string], readings: any) {
  readings.noteType = 'Her Comtrans';

  const noteNumber = findNextLineIncludes(lines, 'AUT', 1);
  if (noteNumber.split(" ").length > 1) {
    readings.noteNumber = noteNumber.split(" ")[1]
  } else readings.noteNumber = findNextLineIncludes(lines, 'AUT', 2);

  const date = findNextLineIncludes(lines, 'PRIMA CANT', -1).split(" ")[0];
  dates.map((d) => {
    if (date.includes(d.char)) readings.date = date.replace(d.char, d.number);
   })

  const grossName = findNextLineIncludes(lines, 'PRIMA CANT', 0);
  if (grossName.split(" ").length > 2) {
    readings.gross = grossName.split(" ")[2]
  } else readings.gross = findNextLineIncludes(lines, 'PRIMA CANT', 1);

  readings.tare = findNextLineIncludes(lines, 'A DOUA CANT', 1);
  if (findNextLineIncludes(lines, 'NET', 0) !== undefined) {
    readings.net = findNextLineIncludes(lines, 'NET', 1);
  } else {
    readings.net = findNextLineIncludes(lines, 'A DOUA CANT', 2);
  }


}

// weihing-note: new Her Comntrans
export function parseHerComtrans2(lines: [string], readings: any) {
  readings.noteType = 'Her Comtrans';

    const numberLine = findLineIncludes(lines, 'NOTA DE CANTAR');
  if (numberLine) {
    // Read Note Number from "NOTA DE CANTAR nr. 5- /data. 07.01.22"
    let noteStr = stringFollowingSubString(numberLine, 'nr');
    console.log('$$$noteStr following nr= ', noteStr)
    if (noteStr) noteStr = stringUptoSubString(noteStr, '/data');
    console.log('$$$noteStr up to date= ', noteStr)
    if (noteStr) noteStr = noteStr.trim(noteStr);
    readings.noteNumber = noteStr;

    // Read Date from "NOTA DE CANTAR nr. 5- /data. 07.01.22"
    let dateStr = stringFollowingSubString(numberLine, 'data');
    if (dateStr) dateStr = dateStr.trim(dateStr);
    readings.date = dateStr;
    }

  const weighingLineIdx = findLineIdxIncludes(lines, 'Neta');
  let nextIndex = 1
  if (lines[weighingLineIdx + nextIndex] === '1') nextIndex++;
  if (weighingLineIdx && weighingLineIdx > 0) {
    if (weighingLineIdx + nextIndex <= lines.length) {
      readings.gross = removeSpaces(lines[weighingLineIdx + nextIndex]);
      nextIndex++;
    }
    if (weighingLineIdx + nextIndex <= lines.length) {
      readings.tare = removeSpaces(lines[weighingLineIdx + nextIndex]);
      nextIndex++;
    }
    if (weighingLineIdx + nextIndex <= lines.length) {
      readings.net = removeSpaces(lines[weighingLineIdx + nextIndex]);
      nextIndex++;
    }
  }
}

// weihing-note: MSC Rubber 1.jpg
export function parseMSCRubber(lines: [string], readings: any) {
  readings.noteType = 'MSC Rubber Fundulea';
  const numberLine = findLineIncludes(lines, 'Tichet de');
  if (numberLine) {
    // Read Note Number from "Ticket de cântar nr. 310"
    let noteStr = stringFollowingSubString(numberLine, 'nr.')
    readings.noteNumber = removeSpaces(noteStr);
  }

  /*
      Πάρε όλα με 'kg' ή 'KG'
      Το πρώτο είναι το Gross μετά Tare και μετα Net
    */
  if (lines.filter((line) => (line.includes('KG'))).length > 0) {
    const weightsArray = lines.filter((line) => (line.includes('kg') || line.includes('KG')));
    console.log('$$$weightsArray')
    if (weightsArray && weightsArray.length >= 1) {
      readings.gross = stringUptoSubString(weightsArray[0], 'KG').trim();
      if(readings.gross.includes(':')) readings.gross = stringFollowingSubString(weightsArray[0], ':').trim();

    }
    if (weightsArray && weightsArray.length >= 2) {
      readings.tare = stringUptoSubString(weightsArray[1], 'KG').trim();
      if(readings.tare.includes(':')) readings.tare = stringFollowingSubString(weightsArray[1], ':').trim();
      readings.date = stringFollowingSubString(weightsArray[1], 'KG');
    }
    if (weightsArray && weightsArray.length >= 3) {
      readings.net = stringUptoSubString(weightsArray[2], 'kg').trim();
      if(readings.net.includes(':')) readings.net = stringFollowingSubString(weightsArray[2], ':').trim();
    }
  }

  else {
    const weightsArray = lines.filter((line) => (line.includes('kg')));
    if (weightsArray && weightsArray.length >= 1) {
      readings.gross = stringUptoSubString(weightsArray[0], 'kg').trim();
      if(readings.gross.includes(':')) readings.gross = stringFollowingSubString(weightsArray[0], ':').trim();
    }
    if (weightsArray && weightsArray.length >= 2) {
      readings.tare = stringUptoSubString(weightsArray[1], 'kg').trim();
      if(readings.tare.includes(':')) readings.tare = stringFollowingSubString(weightsArray[1], ':').trim();
      readings.date = stringFollowingSubString(weightsArray[1], 'kg');
    }
    if (weightsArray && weightsArray.length >= 3) {
      readings.net = stringUptoSubString(weightsArray[2], 'kg').trim();
      if(readings.net.includes(':')) readings.net = stringFollowingSubString(weightsArray[2], ':').trim();
    }
  }


}
export interface IWeighingNoteReadings {
  noteType: string;
  noteNumber: string;
  date: string;
  tare: string;
  gross: string;
  net: string;
}
export function parseWeighingNoteTextractLines(lines: [string]): IWeighingNoteReadings {
  const readings: IWeighingNoteReadings = {
    noteType: 'undefined',
    noteNumber: '',
    date: '',
    tare: '',
    gross: '',
    net: '',
  };
  if ((arrayIncludes(lines, 'HEIDELBERGCEMENT') || arrayIncludes(lines, 'Heidelberg')) && (arrayIncludes(lines, 'FIENI') || arrayIncludes(lines, 'Fieni'))) {
    parseHeidelbergCementFieni(lines, readings);
  } else if (arrayIncludes(lines, 'Heidelberg') && arrayIncludes(lines, 'Tasca')) {
    parseHeidelbergCementTasca(lines, readings);
  } else if (arrayIncludes(lines, 'Heidelberg') || arrayIncludes(lines, 'Chiscadaga')) {
    parseHeidelbergCementChiscadaga(lines, readings);
  } else if (arrayIncludes(lines, 'Alesd')) {
    parseHolcimAlesd(lines, readings);
  } else if (arrayIncludes(lines, 'ROMCIM') && arrayIncludes(lines, 'MEDGIDIA')) {
    parseRomcimMedgidia(lines, readings);
  } else if (arrayIncludes(lines, 'ROMCIM') && arrayIncludes(lines, 'HOGHIZ')) {
    parseRomcimHoghiz(lines, readings);
  } else if (arrayIncludes(lines, 'GENERAL')) {
    parseHerComtrans(lines, readings);
  } else if (arrayIncludes(lines, 'Comtrans')) {
    parseHerComtrans2(lines, readings);
  } else if (arrayIncludes(lines, 'RUBBER')) {
    parseMSCRubber(lines, readings);
  }
  console.log('$$$$$$$$ setWeights', readings);
  return readings;
}


const dates = [
  { char: 'IAN', number: '01' },
  { char: 'FEB', number: '02' },
  { char: 'MAR', number: '03' },
  { char: 'APR', number: '04' },
  { char: 'MAI', number: '05' },
  { char: 'IUN', number: '06' },
  { char: 'IUL', number: '07' },
  { char: 'AUG', number: '08' },
  { char: 'SEP', number: '09' },
  { char: 'OCT', number: '10' },
  { char: 'NOI', number: '11' },
  { char: 'DEC', number: '12' },
]