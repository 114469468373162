import { t } from 'i18next';
import React, { useState } from 'react';
import { Form, Button, Card, InputGroup, Spinner } from 'react-bootstrap';
import { FaUser, FaLock, FaEye, FaSignInAlt } from 'react-icons/fa';
import { Formik, Form as FForm } from 'formik';
import { string, object, InferType } from 'yup'; // for only what you need
import { useHistory } from 'react-router-dom';
import { GridLinkButton } from 'motion-components';
import './login-form.scss';

/**
 * Setup Yup Validation
 */
const validationSchema = object().shape({
  name: string().required(),
  password: string().required(),
});

export type FormDataType = InferType<typeof validationSchema>;

type Props = {
  message?: string | null;
  loading?: boolean;
  onSubmit: (values, action) => void;
};

const LoginForm = ({ message = '', loading = false, onSubmit }: Props) => {
  /*-------------------- Body -----------------------------------------------*/
  const [show, setShow] = useState(false);
  const history = useHistory();

  /*-------------------- Render ---------------------------------------------*/
  return (
    <Formik
      initialValues={{ name: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikBag) => (
        <Card className="bg-light shadow-sm p-3 mb-5 login-frame">
          <Card.Body>
            <Card.Title className="text-center mb-4 mt-1" as="h4">
              <img
                alt=""
                src={process.env.PUBLIC_URL + '/logo.png'}
                height={96}
                className="mx-auto d-block"
              />
            </Card.Title>
            <p className="text-danger text-center">{message && ''}</p>

            <Form as={FForm}>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FaUser />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    className="rounded-right"
                    id="name"
                    type="text"
                    placeholder={t('Username')}
                    {...formikBag.getFieldProps('name')}
                    isInvalid={!!formikBag.touched.name && !!formikBag.errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t('The username is required.')}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FaLock />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    className="login-pw"
                    id="password"
                    type={show ? 'text' : 'password'}
                    placeholder={t('Password')}
                    {...formikBag.getFieldProps('password')}
                    isInvalid={!!formikBag.touched.password && !!formikBag.errors.password}
                  />
                  <InputGroup.Append>
                    <Button className="rounded-right" onClick={() => setShow((value) => !value)}>
                      <FaEye />
                    </Button>
                  </InputGroup.Append>
                  <Form.Control.Feedback type="invalid">
                    {t('The Password is required.')}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Button
                block
                variant="primary"
                size="sm"
                style={{ fontSize: '0.8rem' }}
                type="submit"
                disabled={!(formikBag.isValid && formikBag.dirty)}
              >
                {loading ? (
                  <Spinner animation="border" size="sm" className="mx-2" />
                ) : (
                  // <FaSignInAlt size="1rem" className="mr-3" />
                  <></>
                )}
                <span>{t('Login')}</span>
              </Button>
            </Form>
          </Card.Body>
          {/* <a onClick={() => history.push(generatePath(getRouteByName('ForgotPw')))} className="forgot-pw" href="#">
            <p> {t('I forgot my password!')} </p>{' '}
          </a> */}
          <GridLinkButton
            onClick={() => {
              history.push('/forgotpw');
            }}
          >
            {t('I forgot my password!')}
          </GridLinkButton>
        </Card>
      )}
    </Formik>
  );
};
export default LoginForm;
