import { useLazyQuery } from '@apollo/client';
import { exportXlsModal } from 'motion-components';
import { gqlGetQuery } from '../graphql';
import { buildWhereFromFilterValues } from './filters';
import { useState } from 'react';

export const exportColumns = (t) => {
  return [
    // { fieldName: "id", fieldTitle: t("Id"), fieldType: "TEXT" },
    { fieldName: 'id', fieldTitle: t('ID'), fieldType: 'TEXT' },
    { fieldName: 'status.name', fieldTitle: t('Status'), fieldType: 'TEXT' },
    { fieldName: 'collectionpoint.name', fieldTitle: t('Collection Point'), fieldType: 'TEXT' },
    { fieldName: 'collectionpoint.vatin', fieldTitle: t('CUI/CNP'), fieldType: 'TEXT' },
    { fieldName: 'collectionpoint.address', fieldTitle: t('Address'), fieldType: 'TEXT' },
    { fieldName: 'collectionpoint.city', fieldTitle: t('City'), fieldType: 'TEXT' },
    { fieldName: 'collectionpoint.county.name', fieldTitle: t('County'), fieldType: 'TEXT' },
    { fieldName: 'collectionpoint.fullAddress', fieldTitle: t('Full Address'), fieldType: 'TEXT' },
    { fieldName: 'collector.name', fieldTitle: t('Collector'), fieldType: 'TEXT' },
    {
      fieldName: 'collectorsPlatform.name',
      fieldTitle: t("Collector's Platform"),
      fieldType: 'TEXT',
    },
    { fieldName: 'requestDate', fieldTitle: t('Request Date'), fieldType: 'DATETIME' },
    { fieldName: 'orderedBy', fieldTitle: t('Order Requested By'), fieldType: 'TEXT' },
    { fieldName: 'ordEnteredBy', fieldTitle: t('Order Entered By'), fieldType: 'TEXT' },
    { fieldName: 'orderNotes', fieldTitle: t('Order Notes'), fieldType: 'TEXT' },
    {
      fieldName: 'tirecollectionsQty[numberFromFind1].expectedPieces',
      fieldFind: 'record.tirecollectionsQty.findIndex((qty) => qty.tiretypeId === 15)',
      fieldTitle: t('Requested Tires A'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tirecollectionsQty[numberFromFind2].expectedPieces',
      fieldFind: 'record.tirecollectionsQty.findIndex((qty) => qty.tiretypeId === 17)',
      fieldTitle: t('Requested Tires B'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tirecollectionsQty[numberFromFind3].expectedPieces',
      fieldFind: 'record.tirecollectionsQty.findIndex((qty) => qty.tiretypeId === 18)',
      fieldTitle: t('Requested Tires C'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tirecollectionsQty[numberFromFind4].expectedPieces',
      fieldFind: 'record.tirecollectionsQty.findIndex((qty) => qty.tiretypeId === 20)',
      fieldTitle: t('Requested Tires D'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tirecollectionsQty[numberFromFind5].expectedPieces',
      fieldFind: 'record.tirecollectionsQty.findIndex((qty) => qty.tiretypeId === 22)',
      fieldTitle: t('Requested Tires E'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tirecollectionsQty[numberFromFind6].expectedPieces',
      fieldFind: 'record.tirecollectionsQty.findIndex((qty) => qty.tiretypeId === 26)',
      fieldTitle: t('Requested Tires F'),
      fieldType: 'TEXT',
    },
    { fieldName: 'collectionDate', fieldTitle: t('Collection Date'), fieldType: 'DATETIME' },
    { fieldName: 'colDocNum', fieldTitle: t('Collection Document Number'), fieldType: 'TEXT' },
    { fieldName: 'vehPlateNo', fieldTitle: t('Vehicle Plate Number'), fieldType: 'TEXT' },
    { fieldName: 'colEnteredBy', fieldTitle: t('Collection Entered By'), fieldType: 'TEXT' },
    { fieldName: 'collectionNotes', fieldTitle: t('Collection Notes'), fieldType: 'TEXT' },
    {
      fieldName: 'tirecollectionsQty[numberFromFind1].pieces',
      fieldFind: 'record.tirecollectionsQty.findIndex((qty) => qty.tiretypeId === 15)',
      fieldTitle: t('Collected Tires A'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tirecollectionsQty[numberFromFind2].pieces',
      fieldFind: 'record.tirecollectionsQty.findIndex((qty) => qty.tiretypeId === 17)',
      fieldTitle: t('Collected Tires B'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tirecollectionsQty[numberFromFind3].pieces',
      fieldFind: 'record.tirecollectionsQty.findIndex((qty) => qty.tiretypeId === 18)',
      fieldTitle: t('Collected Tires C'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tirecollectionsQty[numberFromFind4].pieces',
      fieldFind: 'record.tirecollectionsQty.findIndex((qty) => qty.tiretypeId === 20)',
      fieldTitle: t('Collected Tires D'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tirecollectionsQty[numberFromFind5].pieces',
      fieldFind: 'record.tirecollectionsQty.findIndex((qty) => qty.tiretypeId === 22)',
      fieldTitle: t('Collected Tires E'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tirecollectionsQty[numberFromFind6].pieces',
      fieldFind: 'record.tirecollectionsQty.findIndex((qty) => qty.tiretypeId === 26)',
      fieldTitle: t('Collected Tires F'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tirecollectionsQty[numberFromFind1].weight',
      fieldFind: 'record.tirecollectionsQty.findIndex((qty) => qty.tiretypeId === 15)',
      fieldTitle: t('Collected Tires A Weight'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tirecollectionsQty[numberFromFind2].weight',
      fieldFind: 'record.tirecollectionsQty.findIndex((qty) => qty.tiretypeId === 17)',
      fieldTitle: t('Collected Tires B Weight'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tirecollectionsQty[numberFromFind3].weight',
      fieldFind: 'record.tirecollectionsQty.findIndex((qty) => qty.tiretypeId === 18)',
      fieldTitle: t('Collected Tires C Weight'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tirecollectionsQty[numberFromFind4].weight',
      fieldFind: 'record.tirecollectionsQty.findIndex((qty) => qty.tiretypeId === 20)',
      fieldTitle: t('Collected Tires D Weight'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tirecollectionsQty[numberFromFind5].weight',
      fieldFind: 'record.tirecollectionsQty.findIndex((qty) => qty.tiretypeId === 22)',
      fieldTitle: t('Collected Tires E Weight'),
      fieldType: 'TEXT',
    },
    {
      fieldName: 'tirecollectionsQty[numberFromFind6].weight',
      fieldFind: 'record.tirecollectionsQty.findIndex((qty) => qty.tiretypeId === 26)',
      fieldTitle: t('Collected Tires F Weight'),
      fieldType: 'TEXT',
    },

    { fieldName: 'isActive', fieldTitle: t('Active Record'), fieldType: 'TEXT' },
    { fieldName: 'firstDate', fieldTitle: t('First Date'), fieldType: 'DATETIME' },
    { fieldName: 'lastDate', fieldTitle: t('Last Date'), fieldType: 'DATETIME' },
    { fieldName: 'firstUser', fieldTitle: t('First User'), fieldType: 'TEXT' },
    { fieldName: 'lastUser', fieldTitle: t('Last User'), fieldType: 'TEXT' },
  ];
};

export async function handleExport(client, queryState: any, t): Promise<void> {
  function useGetQuery() {
    const [get, { loading }] = useLazyQuery(gqlGetQuery, { onCompleted: transformData });
    const [result, setResult] = useState<any>([get, { loading, data: undefined }]);
    function transformData(data) {
      const recs = data.getTirecollections.data.map((values) => {
        return {
          ...values,
          isActive: t(values.isActive ? 'TRUE' : 'FALSE'),
        };
      });

      const trsData = {
        getTirecollections: { data: recs, recordCount: data?.getTirecollections?.recordCount },
      };
      setResult([get, { loading, data: trsData }]);
    }

    return result;
    //return [null, { loading: null, data: null }];
  }
  const filterVariables = {
    where: buildWhereFromFilterValues(queryState?.filterValues),
  };
  await exportXlsModal({
    apolloClient: client,
    exportFileName: 'export Tirecollections',
    getLazyQuery: useGetQuery,
    columnsDef: exportColumns(t),
    filterVariables: filterVariables,
  });
}
