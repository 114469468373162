import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery, useApolloClient } from '@apollo/client';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import {
  FormCard,
  YupNumeric,
  YupDate,
  YupString,
  YupBoolean,
  promiseModalDialog,
  UserTooltip,
  objParseInt,
  objParseFloat,
  ButtonNewRecord,
  YupStringReq,
  YupNumericReq,
  FlexRow,
} from 'motion-components';
import * as Yup from 'yup';
import * as Lo from 'lodash';
import { toastSuccess } from 'utils/toasts';
import { useHandleError, usePersistentLog } from 'services';
import {
  gqlGetByIdQuery,
  gqlAdd,
  gqlUpdate,
  gqlDelete,
  FormInput,
  gqlLinkCollectionpoint,
} from '../graphql';
import { isNumeric } from 'utils/helpers';

import { FormFields } from './form-fields';
import { rootStore, useRootStore } from 'store';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Tab, Tabs } from 'react-bootstrap';
import { FormFieldsAll } from './form-fields-all';
import { FaStarOfLife } from 'react-icons/fa';
import { getRouteByName } from 'app/routes';
import promiseModalSelectCp from 'components/modal-select-cp';
import { CollectionpointCollectorsTable } from './collectionpoint-collectors-table';
import { collectionpointLogin } from 'pages/login/graphql';
import { CollectionpointTirecollectionsTable } from './collectionpoint-tirecollections-table';

export const CollectionpointFormPage = () => {
  const { t } = useTranslation();
  const { handleGraphqlError } = useHandleError('CollectionpointsFormPage');
  const { appStore } = useRootStore();
  const history = useHistory();
  const location = useLocation<any>();
  const client = useApolloClient();
  const { logInfo } = usePersistentLog('LoginCpPage');
  const { id } = useParams<{ id: string }>();
  const idNum = isNumeric(id) ? parseInt(id) : -1;
  const [isNew, setIsNew] = useState(idNum < 0);

  const formShape = {
    id: YupNumeric,
    name: YupStringReq,
    vatin: YupStringReq,
    isBlackListed: YupBoolean,
    collectorId: YupNumeric,
    categoryId: YupNumeric,
    typeId: YupNumeric,
    address: YupStringReq,
    city: YupString,
    countyId: YupNumericReq,
    phone: YupString,
    mobile: YupString,
    fax: YupString,
    email: YupString,
    email2: YupString,
    website: YupString,
    contactperson: YupString,
    notes: YupString,
    workDaysFrom: YupDate,
    workDaysTill: YupDate,
    saturdayDaysFrom: YupDate,
    saturdayDaysTill: YupDate,
    sundayDaysFrom: YupDate,
    sundayDaysTill: YupDate,
    isActive: YupBoolean,
    firstDate: YupDate,
    lastDate: YupDate,
    firstUser: YupString,
    lastUser: YupString,
  };

  // --------------------------------------------------------------------------
  const handleCollectionpointLogin = useCallback(
    async (id) => {
      const res = await collectionpointLogin(client, id).catch((err) => {
        handleGraphqlError('userLogin-collectionpointLogin', err, t('Login Error'));
        return null;
      });
      if (res == null) return;

      console.log('####### Login', res);

      rootStore.appStore.login(res.userName, res);
      // Persist Version to Database -----
      const msg = `web-app version: ${(global as any).appVersion}`;
      console.log('############ AppVersion', msg);
      logInfo('AppVersion', msg);

      // --------------------------- -----
      setIsNew(false);
      history.push('/');
    },
    [client, handleGraphqlError, history, logInfo, t],
  );

  const validationSchema = Yup.object().shape(formShape);
  const pickProperties = (values) => Lo.pick(values, Lo.keys(formShape));
  type FormValuesType = Yup.InferType<typeof validationSchema>;

  const newRecord = useMemo<FormValuesType>(
    () =>
      ({
        isActive: true,
      } as FormValuesType),
    [],
  );

  const [addRecord] = useMutation(gqlAdd);
  const [updateRecord] = useMutation(gqlUpdate);
  const [deleteRecord] = useMutation(gqlDelete);
  const [linkCollectionpoint] = useMutation(gqlLinkCollectionpoint);
  const [get, { data: rawData, error, refetch }] = useLazyQuery(gqlGetByIdQuery, {
    fetchPolicy: 'network-only',
  });

  const data = isNew ? newRecord : rawData ? rawData[Object.keys(rawData)[0]] : undefined;

  /* fetch data */
  useEffect(() => {
    if (!isNew) get({ variables: { id: idNum } });
  }, [get, id, idNum, isNew]);

  /**
   * * ΑΠΟΘΗΚΕΥΣΗ - UPDATE/CREATE
   */
  const handleSubmit = async (values: any, actions) => {
    actions.setSubmitting(false);
    let mutValues = Lo.pick(values, Lo.keys(new FormInput()));
    mutValues = objParseInt(['id', 'collectorId', 'categoryId', 'typeId', 'countyId'], mutValues);
    mutValues = objParseFloat([], mutValues);
    // Αν είναι νέα εγγραφή τοτε γράφω όλα τα πεδία
    if (isNew) {
      console.log('[Collectionpoints] New:', mutValues);
      addRecord({
        variables: {
          data: {
            ...mutValues,
            passthrough: false,
            needsConfirmation: !appStore.isLogIn ? true : undefined,
          },
        },
      })
        .then(async (d) => {
          const isNew = d.data[Object.keys(d.data)[0]].isNew;
          if (!isNew) {
            const res = await promiseModalSelectCp({
              title: `${t('The data you inputted has been found in existing Collection Points')}`,
              text: `${t('Choose the Collection Point you are looking for')}`,
              data: d.data[Object.keys(d.data)[0]].data,
              newData: mutValues,
            });
            if (res === -1) {
              await addRecord({
                variables: {
                  data: {
                    ...mutValues,
                    passthrough: true,
                    needsConfirmation: !appStore.isLogIn ? true : undefined,
                  },
                },
              })
                .then(async (d) => {
                  const id = d.data[Object.keys(d.data)[0]]?.data[0].id;
                  await linkCollectionpoint({ variables: { collectionPointId: id } });
                  toastSuccess(`${t('Record Created Successfully')} [${id}]`);
                  history.push({
                    pathname: generatePath(getRouteByName('Collectionpoint'), { id: id }),
                    state: {
                      pathname: location.pathname,
                      ...location.state,
                    },
                  });
                })
                .catch((error) => {
                  handleGraphqlError('mutation-add', error);
                });
            } else if (res > 0) {
              await linkCollectionpoint({ variables: { collectionPointId: res } });
              history.push({
                pathname: generatePath(getRouteByName('Collectionpoint'), { id: res }),
                state: {
                  pathname: location.pathname,
                  ...location.state,
                },
              });
            }
          } else {
            const id = d.data[Object.keys(d.data)[0]]?.data[0].id;
            console.log('id= ', id);
            toastSuccess(`${t('Record Created Successfully')} [${id}]`);
            if (!appStore.isLogIn) {
              handleCollectionpointLogin(id);
            } else {
              await linkCollectionpoint({ variables: { collectionPointId: id } });
              history.push({
                pathname: generatePath(getRouteByName('Collectionpoint'), { id: id }),
                state: {
                  pathname: location.pathname,
                  ...location.state,
                },
              });
            }
          }
        })
        .catch((error) => {
          handleGraphqlError('mutation-add', error);
        });
    } else {
      // Αν είναι update εγγραφής τοτε γράφω μονο τα αλλαγμένα πεδία
      console.log('[Collectionpoints] Update:', mutValues);
      updateRecord({ variables: { data: { ...mutValues, id: idNum } } })
        .then((d) => {
          toastSuccess(
            `${t('Record Updated Successfully')} [${d.data[Object.keys(d.data)[0]].id}]`,
          );
          refetch();
        })
        .catch((error) => {
          handleGraphqlError('mutation-update', error);
        });
    }
  };

  /**
   * *  DELETE
   */
  const handleDelete = useCallback(async () => {
    const res = await promiseModalDialog({
      title: `${t('Delete Record')}`,
      text: `${t('The record will be permanently deleted. Do you want to continue?')}`,
    });
    if (res) {
      deleteRecord({ variables: { id: idNum } })
        .then(() => {
          toastSuccess(t('The record has been successfully deleted'));
          history.goBack();
        })
        .catch((error) => {
          handleGraphqlError('mutation-delete', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRecord, idNum]);

  const handleNewTireCollection = () => {
    history.push({
      pathname: generatePath(getRouteByName('Tirecollection'), { id: -1 }),
      state: {
        pathname: location.pathname,
        collectionPointId: idNum,
        ...location.state,
      },
    });
  };

  const handleConfirm = async () => {
    const res = await promiseModalDialog({
      title: `${t('Confirm Collection Point')}`,
      text: `${t("Are you sure the Collection Point's data is correct?")}`,
    });
    if (res) {
      updateRecord({ variables: { data: { needsConfirmation: false, id: idNum } } })
        .then((d) => {
          toastSuccess(
            `${t('Collection Point Confirmed!')} [${d.data[Object.keys(d.data)[0]].id}]`,
          );
          refetch();
        })
        .catch((error) => {
          handleGraphqlError('mutation-update', error);
        });
    }
  };

  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <FormCard
        title={
          <>
            {isNew ? `${t('New Collection Point')}` : `${t('Collection Point')}: ${data?.name}`}
            {!isNew && data?.isActive && (
              <div style={{ position: 'absolute', right: '10px', top: '5px' }}>
                <ButtonNewRecord
                  label={t(
                    appStore?.isCollectionPoint
                      ? t('New Tire Collection Request')
                      : t('New Tire Collection'),
                  )}
                  onClick={handleNewTireCollection}
                />
              </div>
            )}
          </>
        }
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        onReturn={null}
        permitDelete={false}
        initialValues={pickProperties(data)}
        validationSchema={validationSchema}
        enableReinitialize
        idx={idNum}
        isNew={isNew}
      >
        {!isNew && !!data?.needsConfirmation && !appStore.isCollectionPoint && (
          <Alert
            variant="warning"
            className="py-3 mb-3 d-flex flex-row justify-content-center align-items-baseline"
            style={{ color: '#595959' }}
          >
            <h6 className="m-0">
              <FaStarOfLife size="1rem" className="pb-1 mr-4" />
              {t(
                'This Collection Point needs confirmation. Please correct if necessary and then press here',
              )}
              :
            </h6>
            <span className="mx-2"></span>
            <Button onClick={handleConfirm} variant="secondary">
              {t('Confirm')}
            </Button>
          </Alert>
        )}
        <FormFields isNew={isNew} updateRecord={updateRecord} refetch={refetch} data={data} />
        <div className="float-right">{data && <UserTooltip data={data} auditId={data.id} />}</div>
        {data && data.id > 0 ? (
          <div>
            <Tabs defaultActiveKey="form">
              <Tab eventKey="form" title={t('Information')}>
                <FormFieldsAll
                  isNew={isNew}
                  updateRecord={updateRecord}
                  refetch={refetch}
                  data={data}
                />
              </Tab>
              {!appStore.isCollector && (
                <Tab eventKey="collectors" title={t('Collectors')}>
                  <CollectionpointCollectorsTable
                    data={data.collectorsCollectionpoints}
                    refetch={refetch}
                  />
                </Tab>
              )}
              <Tab eventKey="collections" title={t('Tire Collections')}>
                <CollectionpointTirecollectionsTable collectionpointId={idNum} refetch={refetch} />
              </Tab>
            </Tabs>
          </div>
        ) : (
          <Tabs defaultActiveKey="form">
            <Tab eventKey="form" title={t('Information')}>
              <FormFieldsAll
                isNew={isNew}
                updateRecord={updateRecord}
                refetch={refetch}
                data={data}
              />
            </Tab>
          </Tabs>
        )}
      </FormCard>
    );
  }
};
