import React, { useMemo, useCallback } from 'react';
import {
  TablePaginatedUC,
  TableSubSimple,
  CellLeft,
  CellCenter,
  CellYesNo,
  CellYesNoNull,
  CellDateTime,
  CellInt,
  CellFloatCustom,
  CellIntCustom,
  EyeColumn,
} from 'motion-components';
import { useLocation } from 'react-router-dom';
import { generatePath, useHistory } from 'react-router';

import LoadingOverlay from 'react-loading-overlay';
import { useRootStore } from 'store';
import { getRouteByName } from 'app/routes';
import { useTranslation } from 'react-i18next';
import { CellFloatNegativeRed, CellIntNegativeRed } from 'components/cell-helpers';

/* ----------- Grid ------------------ */

export const DataGrid = ({
  data,
  dataCount,
  initPageIndex,
  onPageChange,
  loading,
  handleExport,
}) => {
  const { t } = useTranslation();

  const location = useLocation<any>();
  const history = useHistory();
  const { appStore } = useRootStore();

  const rowClicked = useCallback(
    (row) => {
      !!row.valoriserId &&
        history.push({
          pathname: generatePath(getRouteByName('ValorisersStockTransactions'), {
            valoriserId: row.valoriserId,
          }),
          state: {
            pathname: location.pathname,
            ...location.state,
          },
        });
    },
    [history, location.pathname, location.state],
  );

  const columns = useMemo(
    () => [
      // { Header: "id", accessor: "id", Cell: CellCenter, width: "100", isVisible: false, },
      EyeColumn(rowClicked),
      {
        Header: t('Valoriser'),
        accessor: 'valoriserName',
        Cell: CellLeft,
        width: '200',
        isVisible: false,
      },
      {
        Header: 'A',
        accessor: 'piecesA',
        Cell: CellIntNegativeRed,
        width: '50',
        isVisible: false,
      },
      {
        Header: 'B',
        accessor: 'piecesB',
        Cell: CellIntNegativeRed,
        width: '50',
        isVisible: false,
      },
      {
        Header: 'C',
        accessor: 'piecesC',
        Cell: CellIntNegativeRed,
        width: '50',
        isVisible: false,
      },
      {
        Header: 'D',
        accessor: 'piecesD',
        Cell: CellIntNegativeRed,
        width: '50',
        isVisible: false,
      },
      {
        Header: 'E',
        accessor: 'piecesE',
        Cell: CellIntNegativeRed,
        width: '50',
        isVisible: false,
      },
      {
        Header: 'F',
        accessor: 'piecesF',
        Cell: CellIntNegativeRed,
        width: '50',
        isVisible: false,
      },
      {
        Header: t('Weight'),
        accessor: 'weightTotal',
        Cell: CellFloatNegativeRed,
        width: '50',
        isVisible: false,
      },
    ],
    [t],
  );

  return (
    <LoadingOverlay active={loading} spinner text={t('Please wait...')}>
      {data ? (
        <TablePaginatedUC
          id="valorisersStocks"
          appStore={appStore}
          key="valorisersStocks"
          title={'Λίστα'}
          columns={columns}
          data={data}
          dataCount={dataCount}
          hidePagination={true}
          // initPageIndex={initPage}
          // onSortByChange={handleSortByChange}
          onExport={handleExport}
          onPageChange={onPageChange}
          initPageIndex={initPageIndex}
          resizable={true}
        />
      ) : (
        <div style={{ height: '200px' }}></div>
      )}
    </LoadingOverlay>
  );
};
