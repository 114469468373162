import React, { useMemo, useCallback, useState, useEffect } from 'react';

import { generatePath, useHistory } from 'react-router';
import { getRouteByName } from 'app/routes';
import LoadingOverlay from 'react-loading-overlay';
import { useRootStore } from 'store';
import { CellDateTime, TablePaginatedUC } from 'motion-components';
import { faFile, faEye } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import { getAuditTableDisplayName, getAuditTableFieldDisplayName } from '../audit-trail-dictionary';
import { auditTypeColor, useFieldValue2StringExtra } from '../common';
import { AppStore } from 'store/app-store';
import { useTranslation } from 'react-i18next';

/* ----------- Grid ------------------ */

export const DataGrid = ({
  data,
  dataCount,
  initPageIndex,
  onPageChange,
  loading,
  doAddFormValues,
  handleExport,
}) => {
  const { t } = useTranslation();
  //const auditTrailDb = useAuditTrailDb();
  const auditTrailDb = null;
  const { appStore } = useRootStore();

  const location = useLocation<any>();
  const history = useHistory();
  const rowClicked = useCallback(
    (row) => {
      history.push({
        pathname: generatePath(getRouteByName('AuditTrail'), { id: row.id }),
        state: {
          pathname: location.pathname,
          ...location.state,
        },
      });
    },
    [history, location.pathname, location.state],
  );

  const columns = useMemo(
    () => [
      {
        /* ΚΟΛΩΝΑ ΜΕ RedEye και Click handler */
        id: 'select',
        Header: () => <FontAwesomeIcon icon={faFile} />,
        Cell: ({ row }) => (
          <div
            className="text-center"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              rowClicked(row.original);
            }}
          >
            <FontAwesomeIcon icon={faEye} />
          </div>
        ),
        width: '15',
      },
      // { Header: `id`, accessor: 'id' },
      {
        Header: t('Date of Change'),
        accessor: 'auditDate',
        Cell: CellDateTime,
        width: '100',
      },
      { Header: t('User Name'), accessor: 'user', width: '100' },
      {
        Header: t('Action'),
        accessor: 'auditType',
        Cell: ({ row }) => {
          const auditType = row.original.auditType;
          const color = auditTypeColor(auditType);
          return <div style={{ color: color }}>{auditType}</div>;
        },
        width: '100',
      },
      {
        Header: t('Element'),
        accessor: 'tableName',
        Cell: ({ row }) => {
          const tableName = row.original.tableName;
          const displayName = getAuditTableDisplayName(tableName);
          return (
            <div>
              <div>{displayName}</div>
              <div style={{ paddingTop: '4px', color: 'gray', fontFamily: 'monospace' }}>
                {tableName}
              </div>
            </div>
          );
        },
        width: '200',
      },
      {
        Header: t('Id of Element'),
        accessor: 'tableRowId',
        width: '50',
      },
      {
        Header: t('Field Values'),
        accessor: 'previousValues1',
        Cell: ({ row }) => {
          const previousValues = JSON.parse(row.original.previousValues);
          const currentValues = JSON.parse(row.original.currentValues);
          return (
            <AuditRecordValues
              tableName={row.original.tableName}
              auditType={row.original.auditType}
              previousValues={previousValues}
              currentValues={currentValues}
              auditTrailDb={auditTrailDb}
            ></AuditRecordValues>
          );
        },
        width: '400',
      },
      // {
      //   Header: `Αρχικές Τιμές`,
      //   accessor: 'previousValues',
      //   width: '100',
      // },
      // {
      //   Header: `Νέες Τιμές`,
      //   accessor: 'currentValues',
      //   width: '100',
      // },
    ],
    [rowClicked, t],
  );

  return (
    <LoadingOverlay active={loading} spinner text={t('Please wait...')}>
      {data && data.length >= 0 ? (
        <TablePaginatedUC
          title={'Λίστα'}
          columns={columns}
          data={data}
          dataCount={dataCount}
          onExport={handleExport}
          onPageChange={onPageChange}
          initPageIndex={initPageIndex}
          id={'grd'}
          appStore={appStore}
        />
      ) : (
        <div style={{ height: '200px' }}></div>
      )}
    </LoadingOverlay>
  );
};

const AuditRecordValues = ({
  tableName,
  auditType,
  previousValues,
  currentValues,
  auditTrailDb,
}) => {
  if (currentValues === null) {
    return <div> null</div>;
  } else if (auditType === 'update') {
    const ret = (
      <div style={{ maxHeight: '120px', overflowY: 'scroll' }}>
        {Object.keys(currentValues).map((key, index) => {
          return (
            <FieldRow4Update
              key={index}
              tableName={tableName}
              fieldName={key}
              index={index}
              previousValues={previousValues}
              currentValues={currentValues}
            />
          );
        })}
      </div>
    );
    return ret;
  } else {
    // delete or create
    let values = {};
    if (auditType === 'create') values = currentValues;
    else values = previousValues;
    if (values === null) {
      return <div> null</div>;
    } else {
      // return (
      //   <div style={{ maxHeight: '120px', overflowY: 'scroll' }}>
      //     {Object.keys(values).map((key, index) => {
      //       const displayKey = getAuditTableFieldDisplayName(tableName, key);
      //       const displayValue = fieldValue2String(tableName, key, values[key]);
      //       return (
      //         <div key={index}>
      //           <span style={{ fontFamily: 'monospace', color: 'gray' }}>{displayKey}</span>: {displayValue}
      //         </div>
      //       );
      return (
        <div style={{ maxHeight: '120px', overflowY: 'scroll' }}>
          {Object.keys(values).map((key, index) => {
            return (
              <FieldRow4CreateOrDelete
                key={index}
                tableName={tableName}
                fieldName={key}
                index={index}
                currentValues={auditType === 'create' ? currentValues : previousValues}
              />
            );
          })}
        </div>
      );
    }
  }
};

const FieldRow4Update = ({ tableName, fieldName, index, previousValues, currentValues }) => {
  const { fieldValue2StringExtra } = useFieldValue2StringExtra();
  const displayKey = getAuditTableFieldDisplayName(tableName, fieldName);

  const [prvValue, setPrvValue] = useState<string>();

  const [curValue, setCurValue] = useState<string>();
  useEffect(() => {
    async function get() {
      const lcurValue = await fieldValue2StringExtra(
        tableName,
        fieldName,
        currentValues[fieldName],
      );
      setCurValue(lcurValue);
      const lprvValue = await fieldValue2StringExtra(
        tableName,
        fieldName,
        previousValues[fieldName],
      );
      setPrvValue(lprvValue);
    }
    get();
  }, [currentValues, fieldName, fieldValue2StringExtra, previousValues, tableName]);

  if (prvValue === curValue) {
    return (
      <div key={index}>
        <span style={{ fontFamily: 'monospace', color: 'gray' }}>{displayKey}</span>: {prvValue}
      </div>
    );
  } else {
    return (
      <div key={index}>
        <strong>
          <span style={{ fontFamily: 'monospace', color: 'gray' }}>{displayKey}</span>
        </strong>
        : {prvValue} {'->'} <strong>{curValue}</strong>
      </div>
    );
  }
};

const FieldRow4CreateOrDelete = ({ tableName, fieldName, index, currentValues }) => {
  const { fieldValue2StringExtra } = useFieldValue2StringExtra();
  const displayKey = getAuditTableFieldDisplayName(tableName, fieldName);

  const [curValue, setCurValue] = useState<string>();
  useEffect(() => {
    async function get() {
      const lcurValue = await fieldValue2StringExtra(
        tableName,
        fieldName,
        currentValues[fieldName],
      );
      setCurValue(lcurValue);
    }
    get();
  }, [currentValues, fieldName, fieldValue2StringExtra, tableName]);

  return (
    <div key={index}>
      <span style={{ fontFamily: 'monospace', color: 'gray' }}>{displayKey}</span>: {curValue}
    </div>
  );
};
