import React from 'react';
import { Modal, Button, ModalProps, Form, Col, Row } from 'react-bootstrap';
import { createModal } from 'react-modal-promise';
import { Formik, Form as FForm, Field, useFormikContext } from 'formik';
import { toastWarning } from 'utils/toasts';
import { useTranslation } from 'react-i18next';
import {
  CustomSelectComponent,
  FieldDatepicker,
  FieldInput,
  FieldTextArea,
  FlexRow,
  FormCol,
} from 'motion-components';
import './becomeUnavailable.css';

type Props = {
  open: boolean;
  close: (result: any) => void;
  title: string;
  /** body of dialog */
  text: string | React.ReactNode;
  /** accept text */
  acceptText?: string;
  /** deny text */
  denyText?: string;
  type?: string;
  valorisationMethodId?: number;
} & ModalProps;

const ModalInput = ({
  open,
  close,
  title,
  text,
  acceptText = 'OK',
  denyText = 'CANCEL',
  type = 'text',
  valorisationMethodId,
}: Props) => {
  const { t } = useTranslation();
  const handleOk = (values) => {
    if (values.reasonId === null || (values.reasonId === '4' && values.reason === '')) {
      toastWarning(`${t('You need to provide a reason')}`);
    } else if (values.unavailableTill === null || values.unavailableFrom === null) {
      toastWarning(`${t('You need to specify the dates of unavailability')}`);
    } else {
      const input = {
        reason:
          values.reasonId === '4'
            ? values.reason
            : options.find((rec) => rec.value === values.reasonId).label,
        unavailableFrom: values.unavailableFrom,
        unavailableTill: values.unavailableTill,
      };
      close(input);
    }
  };

  const options = [
    { value: '1', label: t('Plant Maintenance') },
    { value: '4', label: t('Other Reason') },
  ];

  const ModalForm = () => {
    const { values } = useFormikContext<any>();
    return (
      <Form as={FForm}>
        <br />
        <Modal.Body>
          <FieldDatepicker
            as={FormCol}
            label={t('Unavailable from')}
            name="unavailableFrom"
            dateFormat="d/M/yyyy HH:mm"
          />
          <FieldDatepicker
            as={FormCol}
            label={t('Unavailable till')}
            name="unavailableTill"
            dateFormat="d/M/yyyy HH:mm"
          />
          <Field
            as={Col}
            title={t('Reason')}
            name={'reasonId'}
            component={CustomSelectComponent}
            options={options}
          />
          {values.reasonId === '4' && (
            <FieldInput as={Col} label={t('Other Reason')} name="reason"></FieldInput>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">
            {t(acceptText)}
          </Button>
          <Button variant="danger" onClick={() => close(false)}>
            {t(denyText)}
          </Button>
        </Modal.Footer>
      </Form>
    );
  };

  return (
    <>
      <Modal show={open} centered backdrop="static">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            reasonId: null,
            reason: '',
            unavailableFrom: new Date(),
            unavailableTill: null,
          }}
          onSubmit={(values) => {
            handleOk(values);
          }}
        >
          <ModalForm></ModalForm>
        </Formik>
      </Modal>
    </>
  );
};

const promiseModalBecomeUnavailable = createModal(ModalInput);

export default promiseModalBecomeUnavailable;
