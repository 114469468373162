import React from 'react';
import { FieldSelectDB } from 'motion-components';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { FsProps } from 'motion-components';
import { t } from 'i18next';

const gqlGetValues = gql`
  query getValues($where: String, $offset: Int, $limit: Int, $order: String) {
    getCollectorsDistances(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        id: valoriserId
        isActive
        valoriser {
          name
          isActive
          isUnavailable
        }
        distance
      }
      recordCount
    }
  }
`;

function useGetValues() {
  return useLazyQuery(gqlGetValues, {
    fetchPolicy: 'network-only',
    variables: { order: 'valoriser.name DESC' },
  });
}
//
// Επιλέγει τους Valoriser που εξυπηρετεί η Platform
//
export const FieldSelectPlatformValoriser = ({
  label = t('Valoriser'),
  name = 'valoriserId',
  defaultValues = {},
  platformId,
  ...rest
}: FsProps & { platformId?: number }) => {
  const where = platformId
    ? JSON.stringify({
        platformId: platformId,
        distance: { $not: null },
        '$valoriser.isActive$': true,
      })
    : JSON.stringify({ platformId: 0, '$valoriser.isActive$': true });
  return (
    <FieldSelectDB
      {...rest}
      label={label}
      name={name}
      // @ts-ignore
      sbLabel={defaultValues?.valoriser?.name ?? undefined}
      hook={useGetValues}
      where={where}
      disableInactives={true}
      formatLabel={(rec) =>
        rec.valoriser.isUnavailable === true ? (
          <span style={{ color: 'red' }}>{`${rec.valoriser.name} (${rec.distance} km)`}</span>
        ) : (
          `${rec.valoriser.name} (${rec.distance} km)`
        )
      }
    />
  );
};
