import React from 'react';
import { FieldSelectDB } from 'motion-components';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { FsProps } from 'motion-components';

const gqlGet = gql`
  query getYesNo($where: String, $offset: Int, $limit: Int, $order: String) {
    getYesNo(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        id
        description: name
      }
      recordCount
    }
  }
`;

function useGet() {
  return useLazyQuery(gqlGet, { fetchPolicy: 'no-cache' });
}

const FieldSelectYesNo = ({
  label = 'ΝΑΙ/ΟΧΙ',
  name = 'yesNoId',
  defaultValues = {},
  ...rest
}: FsProps) => {
  return (
    <FieldSelectDB
      {...rest}
      label={label}
      name={name}
      sbLabel={(defaultValues as any)?.userRole?.name ?? undefined}
      hook={useGet}
    />
  );
};

export { FieldSelectYesNo };
