import { ApolloClient, useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback, useEffect, useState } from 'react';

export function useDbHelpFunc() {
  const client = useApolloClient();

  const isUserNameUnique = useCallback(
    async (userName: string, userId: number) => {
      const where = {
        userName: userName,
        id: { $ne: userId },
      };

      const ret = await client.query({
        query: gql`
          query getUsers($where: String) {
            getUsers(where: $where) {
              recordCount
            }
          }
        `,
        variables: {
          where: JSON.stringify(where),
        },
        fetchPolicy: 'network-only',
      });
      return (ret?.data?.getUsers?.recordCount ?? 0) === 0;
    },
    [client],
  );

  const encodeString = useCallback(
    async (key: string) => {
      const ret = await client.query({
        query: gql`
          query encodeKey($key: String) {
            encodeKey(key: $key)
          }
        `,
        variables: {
          key: key,
        },
        fetchPolicy: 'network-only',
      });
      return ret?.data?.encodeKey ?? '';
    },
    [client],
  );

  return { isUserNameUnique, encodeString };
}

export function getFileUrl(client: ApolloClient<object>, id: number): Promise<string> {
  return new Promise((resolve, reject) => {
    // console.log('id=', id);
    client
      .query<any, { id: number }>({
        query: gql`
          query getFilesrepositoryFileUrlById($id: Int) {
            getFilesrepositoryFileUrlById(id: $id)
          }
        `,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        if (res.data.getFilesrepositoryFileUrlById !== null) {
          let fileUrl = res.data.getFilesrepositoryFileUrlById;
          fileUrl = fileUrl.substr(0, fileUrl.indexOf('?')); // remove AWSAccessKeyId etc
          //console.log('fileUrl= ', fileUrl);
          resolve(fileUrl);
        } else {
          reject(res.errors[0].message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function useGetFileUrl(fileId: number): string {
  const client = useApolloClient();

  // Signature Url
  const [url, setUrl] = useState('');
  useEffect(() => {
    if (fileId) {
      getFileUrl(client, fileId).then((result) => {
        setUrl(result);
      });
    }
  }, [client, fileId]);

  return url;
}
