import gql from 'graphql-tag';

export const gqlGetQuery = gql`
  query getCollectorStockTransactions($limit: Int, $offset: Int, $order: String, $where: String) {
    getCollectorStockTransactions(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        transactionType
        transactionId
        referenceDate
        transactionInfo
        collectorId
        collectorName
        platformId
        platformName
        piecesA
        piecesB
        piecesC
        piecesD
        piecesE
        piecesF
        weightTotal
      }
      recordCount
    }
  }
`;

export const gqlGetPlatformQuery = gql`
  query getCollectorsPlatformById($id: Int) {
    getCollectorsPlatformById(id: $id) {
      id
      name
      collector {
        id
        name
      }
    }
  }
`;
