import { ADMINISTRATOR_ROLE_ID, USERS_ADMIN_ROLE_ID } from './../shared/constants';
import { observable, action, configure, computed } from 'mobx';

import { decodeJwtToken } from 'shared/jwt';
import { AppStore } from './app-store';

configure({ enforceActions: 'always' }); // strict mode

/*
  User Authorizations i.e. permissions
*/

// *    --- Store Class ---
//
export class AuthStore {
  appStore;
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  @computed get userId(): number {
    const jwt = decodeJwtToken(this.appStore.userLogin.jwt);
    return jwt.userId;
  }

  @computed get userRolesIds(): Array<any> {
    const jwt = decodeJwtToken(this.appStore.userLogin.jwt);
    return jwt.userRolesIds;
  }

  getUserPermissions = (roleId) => {
    const permissions = this.userRolesIds.filter((values) => values.userroleId === roleId);
    if (!permissions || permissions.length === 0) return null;
    else return permissions[0];
  };

  // Διαχειριστής Εφαρμογής
  @computed get isSysAdmin(): boolean {
    const userPerm = this.getUserPermissions(ADMINISTRATOR_ROLE_ID);
    return userPerm !== null;
  }

  // Διαχειριστής Χρηστών και Ρόλων
  @computed get isUsersAdmin(): boolean {
    const userPerm = this.getUserPermissions(USERS_ADMIN_ROLE_ID);
    return userPerm !== null;
  }

  @observable initialized = false;

  @action private init() {}
}
