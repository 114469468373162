import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { generatePath, useHistory, useLocation } from 'react-router';
import { gqlUserMailTempPassword } from './graphql';
import { getRouteByName } from 'app/routes';
import Card from 'react-bootstrap/Card';
import { useHandleError } from 'services';
import { Button } from 'react-bootstrap';
import { toastSuccess } from 'utils/toasts';
import { useTranslation } from 'react-i18next';
import { triggermailservice } from 'services/triggermailservice';

export const ForgotPwPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { handleGraphqlError } = useHandleError('newbatteriescollection');
  const [sendEmail] = useMutation(gqlUserMailTempPassword);
  console.log(location.pathname);
  const [email, setEmail] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    sendEmail({ variables: { email: email } })
      .then((d) => {
        console.log(d.data);
        toastSuccess(() => (
          <div>
            {t('Check your E Mail')}
            {/* Ελέγξτε το ηλεκτρονικό ταχυδρομείο σας. */}
            <br />
            {t('A new mail with a temporary password has been sent to you')}
            {/* Σας έχει αποστελεί mail με τον κωδικό πρόσβασης. */}
          </div>
        ));
        if (d.data.userMailTempPassword) {
          // Send Mails From Repo (triggermailservice)
          triggermailservice();
          history.push({
            pathname: generatePath(getRouteByName('HomePage')),
          });
        }
      })
      .catch((error) => {
        handleGraphqlError('mutation-add', error);
      });
  };

  return (
    <Card bg="light" className="rounded-0">
      <Card.Header>
        <Card.Title className="m-0">
          {/* Επαναφορά Κωδικού Πρόσβασης */}
          {t('Recover Password')}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="text-hdr-normal font-weight-bold mb-4 ">
          {/* Γράψτε το email που έχετε δηλώσει στον λογαριασμό σας <br /> και θα σας σταλεί νέος
          κωδικός πρόσβασης */}
          {t('Enter the E-Mail of your account and a new password will be sent to you')}
        </div>
        <label>{'Email: '}</label>
        <input
          className="form-control import"
          onChange={handleChange}
          type="email"
          value={email}
        />{' '}
        <br />
        <Button variant="primary" onClick={handleSubmit}>
          {/* Αποστολή */}
          {t('Send')}
        </Button>
      </Card.Body>
    </Card>
  );
};
