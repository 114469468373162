import React, { useMemo, useCallback } from 'react';
import {
  TablePaginatedUC,
  CellLeft,
  CellCenter,
  CellYesNo,
  CellYesNoNull,
  CellDateTime,
  CellInt,
  CellFloatCustom,
  CellIntCustom,
  EyeColumn,
  CellDate,
} from 'motion-components';
import { useLocation } from 'react-router-dom';
import { generatePath, useHistory } from 'react-router';

import LoadingOverlay from 'react-loading-overlay';
import { useRootStore } from 'store';
import { getRouteByName } from 'app/routes';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

/* ----------- Grid ------------------ */

export const DataGrid = ({
  data,
  dataCount,
  initPageIndex,
  onPageChange,
  loading,
  handleExport,
}) => {
  const location = useLocation<any>();
  const history = useHistory();
  const { appStore } = useRootStore();
  const { t } = useTranslation();
  const rowClicked = useCallback(
    (row) => {
      history.push({
        pathname: generatePath(getRouteByName('CollectorsInventoryCount'), { id: row.id }),
        state: {
          pathname: location.pathname,
          ...location.state,
        },
      });
    },
    [history, location.pathname, location.state],
  );

  const columns = useMemo(
    () => [
      // { Header: "id", accessor: "id", Cell: CellCenter, width: "100", isVisible: false, },
      EyeColumn(rowClicked),
      {
        Header: t('ID'),
        accessor: 'id',
        Cell: CellCenter,
        width: '50',
        isVisible: false,
      },
      {
        Header: t('Collector'),
        accessor: 'collector.name',
        Cell: CellCenter,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Platform'),
        accessor: 'collectorsPlatform.name',
        Cell: CellCenter,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('A'),
        accessor: 'piecesA',
        Cell: ({ row }) => {
          return (
            <div className="text-center">
              {row.original.collectorsStocktakingLns[
                _.findIndex(row.original.collectorsStocktakingLns, ['tireType.name', 'A'])
              ].pieces ?? '-'}
            </div>
          );
        },
        width: '50',
        isVisible: false,
      },
      {
        Header: t('B'),
        accessor: 'piecesB',
        Cell: ({ row }) => {
          return (
            <div className="text-center">
              {row.original.collectorsStocktakingLns[
                _.findIndex(row.original.collectorsStocktakingLns, ['tireType.name', 'B'])
              ].pieces ?? '-'}
            </div>
          );
        },
        width: '50',
        isVisible: false,
      },
      {
        Header: t('C'),
        accessor: 'piecesC',
        Cell: ({ row }) => {
          return (
            <div className="text-center">
              {row.original.collectorsStocktakingLns[
                _.findIndex(row.original.collectorsStocktakingLns, ['tireType.name', 'C'])
              ].pieces ?? '-'}
            </div>
          );
        },
        width: '50',
        isVisible: false,
      },
      {
        Header: t('D'),
        accessor: 'piecesD',
        Cell: ({ row }) => {
          return (
            <div className="text-center">
              {row.original.collectorsStocktakingLns[
                _.findIndex(row.original.collectorsStocktakingLns, ['tireType.name', 'D'])
              ].pieces ?? '-'}
            </div>
          );
        },
        width: '50',
        isVisible: false,
      },
      {
        Header: t('E'),
        accessor: 'piecesE',
        Cell: ({ row }) => {
          return (
            <div className="text-center">
              {row.original.collectorsStocktakingLns[
                _.findIndex(row.original.collectorsStocktakingLns, ['tireType.name', 'E'])
              ].pieces ?? '-'}
            </div>
          );
        },
        width: '50',
        isVisible: false,
      },
      {
        Header: t('F'),
        accessor: 'piecesF',
        Cell: ({ row }) => {
          return (
            <div className="text-center">
              {row.original.collectorsStocktakingLns[
                _.findIndex(row.original.collectorsStocktakingLns, ['tireType.name', 'F'])
              ].pieces ?? '-'}
            </div>
          );
        },
        width: '50',
        isVisible: false,
      },
      {
        Header: t('Stock Date'),
        accessor: 'stockDate',
        Cell: CellDate,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Approved'),
        accessor: 'approved',
        Cell: CellYesNoNull,
        width: '100',
        isVisible: false,
      },
    ],
    [t],
  );

  return (
    <LoadingOverlay active={loading} spinner text={t('Please wait...')}>
      {data ? (
        <TablePaginatedUC
          id="collectorsStocktakingHdr"
          appStore={appStore}
          key="collectorsStocktakingHdr"
          title={'Λίστα'}
          columns={columns}
          data={data}
          dataCount={dataCount}
          // initPageIndex={initPage}
          // onSortByChange={handleSortByChange}
          onExport={handleExport}
          onPageChange={onPageChange}
          initPageIndex={initPageIndex}
          resizable={true}
        />
      ) : (
        <div style={{ height: '200px' }}></div>
      )}
    </LoadingOverlay>
  );
};
