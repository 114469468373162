import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FieldDatepicker, FieldInput, FormCol, FormRow, FieldCheckBox } from 'motion-components';
import { useFormikContext } from 'formik';
import { useHandleError } from 'services';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';
import { FieldSelectCollector, FieldSelectPlatform } from 'components';
import { TiresSection } from './tires-section';

export const FormFields = ({ isNew, refetch, data }) => {
  const { t } = useTranslation();
  //
  const frmkCntx = useFormikContext();
  const { values } = frmkCntx;
  const { appStore } = useRootStore();
  const { handleGraphqlError } = useHandleError('Collectors_stocktaking_hdrFormFields');

  function calcUserCanEdit() {
    const canEdit = true;
    const canEditReasoning = '';
    return { canEdit, canEditReasoning };
  }
  // const canEdit =
  //   !(values as any)?.isFinalized && (authStore.userCanDraft(userRoleId) || authStore.userCanFinalize(userRoleId));
  const { canEdit, canEditReasoning } = calcUserCanEdit();

  return (
    <>
      {/* Header */}
      <Row>
        {/* 1st Column */}
        <Col>
          <FieldDatepicker
            disabled={true}
            as={Col}
            label={t('Stock Date')}
            name="stockDate"
            dateFormat="d/M/yyyy"
          />
          <FieldSelectCollector label={t('Collector')} as={Col} disabled={true} />
          <FieldSelectPlatform label={t('Platform')} as={Col} disabled={true} />
        </Col>

        {/* 2nd Column : Tires*/}
        <Col>
          <div className="mt-4">
            <TiresSection isNew={false} data={data} />
          </div>
        </Col>
        {/* 3rd Column : Action Buttons */}

        {appStore.isEcoanvelope && (
          <Col>
            <FieldInput
              as={Col}
              name="requestedByUser.userName"
              disabled={true}
              placeholder={data?.requestedByUser?.userName}
              label={t('Requested By')}
            />
            <FieldDatepicker
              disabled={true}
              as={Col}
              label={t('Request Date')}
              name="requestDate"
              showTimeSelect
              dateFormat="d/M/yyyy HH:mm"
              timeFormat="HH:mm"
              timeIntervals={15}
            />
          </Col>
        )}

        {/* 4th Column : Blank */}
        <Col>
          <FieldDatepicker
            disabled={true}
            as={Col}
            label={t('Approved or Rejected Date')}
            name="approvedDate"
            showTimeSelect
            dateFormat="d/M/yyyy HH:mm"
            timeFormat="HH:mm"
            timeIntervals={15}
          />

          <FieldInput
            disabled={true}
            as={Col}
            label={t('Person who approved/rejected the request')}
            name="approvedByUser.userName"
            type="text"
            placeholder={data?.approvedByUser?.userName}
            required={false}
          />

          <FieldInput
            disabled={true}
            as={Col}
            label={t('Approval Notes')}
            name="approvedNotes"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />
        </Col>
      </Row>
    </>
  );
};
