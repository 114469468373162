import React, { useMemo, useCallback } from 'react';
import { useRootStore } from 'store';
import {
  BootstrapSwitchButton,
  CellCheckbox,
  TableSubSimple,
  toastSuccess,
} from 'motion-components';
import { gql, useMutation } from '@apollo/client';
import { useHandleError } from 'services';
import { useTranslation } from 'react-i18next';

export const UserrolesTable = ({
  data,
  dataCount = 0,
  initPageIndex = 1,
  onPageChange = () => {},
  handleExport = () => {},
  refetch,
}) => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const { handleGraphqlError } = useHandleError('UserrolesTable ');

  const gqlUpdateFormValues = gql`
    mutation update($data: UsersUserroleInput) {
      updateUsersUserrole(data: $data) {
        id
      }
    }
  `;
  const [updateRecord] = useMutation(gqlUpdateFormValues);
  //
  const doUpdateRecord = useCallback(
    (data, row) => {
      updateRecord({ variables: { data } })
        .then((d) => {
          //const id = d.data.key[Object.keys(d.data)[0]].id;
          const id = d.data.updateUsersUserrole.id;
          toastSuccess(`${t('Record Updated Successfully')} [${id}]`);
          if (refetch) refetch();
        })
        .catch((error) => {
          handleGraphqlError('mutation-update', error);
        });
    },
    [handleGraphqlError, refetch, updateRecord],
  );

  //
  const isActiveChanged = useCallback(
    (checked, row) => {
      const data: any = { id: row.id, isActive: checked };
      doUpdateRecord(data, row);
    },
    [doUpdateRecord],
  );

  const columns = useMemo(
    () => [
      {
        Header: t('User Role'),
        accessor: 'userRole.name',
        Cell: ({ row }) => <div className="text-left">{t(row.original.userRole.name)}</div>,
      },

      {
        Header: t('Active'),
        accessor: 'isActive',
        Cell: CellCheckbox(
          isActiveChanged,
          !appStore.isAdmin && !appStore.isEcoanvelope && !appStore.isUserAdmin,
        ),
      },
    ],
    [appStore.isAdmin, isActiveChanged, t],
  );
  return (
    <div style={{ marginTop: '1.5rem' }}>
      <h4>{t('User Roles')}</h4>
      <TableSubSimple title={t('Users')} columns={columns} data={data} />
    </div>
  );
};
/*
export function CustomCellCheckbox(
  checkboxChanged,
  disabled = false,
  onStyle = undefined,
  offStyle = undefined,
) {
  const { t } = useTranslation()
  return (data) => {
    if (data?.row?.original?.userRole?.isSystem) return <></>
    else
      return (
        <div className="text-center">
          <BootstrapSwitchButton
            checked={data?.cell?.value || false}
            onlabel={t('Yes')}
            offlabel={t('No')}
            width="80"
            onstyle={onStyle ? onStyle : disabled ? 'dark' : 'primary'}
            offstyle={offStyle ? offStyle : disabled ? 'light' : 'warning'}
            disabled={disabled}
            onChange={(checked: boolean) => {
              checkboxChanged(checked, data?.row?.original)
            }}
          />
        </div>
      )
  }
}
*/
