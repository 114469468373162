import gql from "graphql-tag";

export const gqlGetQuery = gql`
    query getCollectorsStocktakingHdr($limit: Int, $offset: Int, $order: String, $where: String) {
        getCollectorsStocktakingHdr(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        id
        collectorId
        collector {
          id
          name
        }
        platformId
        collectorsPlatform {
          id
          name
        }
        collectorsStocktakingLns {
          id
          tiretypeId
          pieces
        }
        stockDate
        requestDate
        requestedById
        requestedByUser {
          id
          userName
        }
        approvedDate
        approvedById
        approvedNotes
        approved
        isActive
        firstDate
        lastDate
        firstUser
        lastUser
      }
      recordCount
    }
  }
`;