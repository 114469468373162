import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  FieldDatepicker,
  FieldInput,
  FormCol,
  FormRow,
  FieldCheckBox,
  FieldTextArea,
} from 'motion-components';
import { useFormikContext } from 'formik';
import { useHandleError } from 'services';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';
import { FieldSelectCollector } from 'components/fs-collector';
import { FieldContainer, FieldSelectCounty } from 'components';
import { FileRepoField } from 'components/filerepo-field';
import { FileRepoImage } from 'components/filerepo-image';

export const FormFields = ({ isNew, updateRecord, refetch, data }) => {
  const { t } = useTranslation();
  //
  const frmkCntx = useFormikContext();
  const { values } = frmkCntx;
  const { authStore, appStore } = useRootStore();

  const { handleGraphqlError } = useHandleError('Collectors_platformsFormFields');

  function calcUserCanEdit() {
    const canEdit = appStore.isEcoanvelope;
    const canEditReasoning = '';
    return { canEdit, canEditReasoning };
  }
  // const canEdit =
  //   !(values as any)?.isFinalized && (authStore.userCanDraft(userRoleId) || authStore.userCanFinalize(userRoleId));
  const { canEdit, canEditReasoning } = calcUserCanEdit();

  return (
    <>
      <Row>
        <Col>
          <div className="d-flex flex-row">
            <FormCol>
              <FieldInput
                disabled={!canEdit}
                label={t('Platform')}
                name="name"
                type="text"
                placeholder={t('- specify a value - ')}
                required={true}
              />
              <FieldSelectCollector
                label={t('Collector')}
                disabled={!canEdit || !appStore.isEcoanvelope}
              />
            </FormCol>

            <FormCol>
              <FieldInput
                disabled={!canEdit}
                label={t('Address')}
                name="address"
                type="text"
                placeholder={t('- specify a value - ')}
                required={true}
              />
              <FieldInput
                disabled={!canEdit}
                label={t('City')}
                name="city"
                type="text"
                placeholder={t('- specify a value - ')}
                required={true}
              />
              <FieldSelectCounty required={true} label={t('County')} disabled={!canEdit} />
            </FormCol>

            <FormCol>
              <FieldCheckBox disabled={!canEdit} as={FormCol} label={t('Active')} name="isActive" />
              <FieldCheckBox
                disabled={!canEdit}
                as={FormCol}
                label={t('Main Platform')}
                name="isMain"
              />
            </FormCol>
          </div>
        </Col>
      </Row>
    </>
  );
};
