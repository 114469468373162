import { useQuery } from '@apollo/client';
import ModalFlex from 'components/ModalFlex';
import { saveAs } from 'file-saver';
import { useWhyDidYouUpdate } from 'hooks';
import _ from 'lodash';
import React from 'react';
import { createModal } from 'react-modal-promise';
import { PDFDocument } from 'pdf-lib';
import { gqlGetByIdQuery } from 'pages/collectionpoints/graphql';
//* ----- Report ----------------------------------------------------------------------------------------

const addText = (record, pdfDoc) => {
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  //Collection Point Name
  !!record.name &&
    firstPage.drawText(record.name, {
      x: 364,
      y: 581,
      size: 8,
    });
  //Collection Point Address
  !!record.address &&
    firstPage.drawText(record.address, {
      x: 433,
      y: 552,
      size: 8,
    });

  //Collection Point City and County
  !!record.city &&
    !!record.countyId &&
    firstPage.drawText(record.city + ', ' + record.county.name, {
      x: 385,
      y: 535,
      size: 8,
    });
};

//* ----- Modal ----------------------------------------------------------------------------------------
//
type Props = {
  colPointId: number;
  isOpen: boolean; // for modal
  onResolve: () => void; // for modal
};

const PrintReport = ({ isOpen, onResolve, colPointId }: Props) => {
  const { data, loading } = useQuery(gqlGetByIdQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { id: colPointId },
    onCompleted: async (data) => {
      const record = data.getCollectionpointById;
      const url = process.env.REACT_APP_S3BUCKET + '/documents/LoadingUnloadingCP.pdf';
      const existingPdfBytes = await fetch(url, { cache: 'no-cache' }).then((res) =>
        res.arrayBuffer(),
      );
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      addText(record, pdfDoc);
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes]);
      saveAs(blob, `Incarcare-Descarcare.pdf`);
      onResolve();
    },
  });

  useWhyDidYouUpdate('[PrintReport]', { data, loading });
  return <ModalFlex isOpen={isOpen} />;
};

export default createModal<Props, void>(PrintReport);
