import React, { useMemo, useCallback } from 'react';
import {
  TablePaginatedUC,
  CellLeft,
  CellCenter,
  CellYesNo,
  CellYesNoNull,
  CellDateTime,
  CellInt,
  CellFloatCustom,
  CellIntCustom,
  EyeColumn,
  CellCheckbox,
  CellActive,
  CellDate,
} from 'motion-components';
import { useLocation } from 'react-router-dom';
import { generatePath, useHistory } from 'react-router';

import LoadingOverlay from 'react-loading-overlay';
import { useRootStore } from 'store';
import { getRouteByName } from 'app/routes';
import { useTranslation } from 'react-i18next';
import { getTiredeliveryStatusBadge } from 'common/badges-helpfunc';

/* ----------- Grid ------------------ */

export const DataGrid = ({
  data,
  dataCount,
  initPageIndex,
  onPageChange,
  loading,
  handleExport,
}) => {
  const { t } = useTranslation();
  const location = useLocation<any>();
  const history = useHistory();
  const { appStore } = useRootStore();

  const rowClicked = useCallback(
    (row) => {
      history.push({
        pathname: generatePath(getRouteByName('Tiredelivery'), { id: row.id }),
        state: {
          pathname: location.pathname,
          ...location.state,
        },
      });
    },
    [history, location.pathname, location.state],
  );

  const columns = useMemo(
    () => [
      // { Header: "id", accessor: "id", Cell: CellCenter, width: "100", isVisible: false, },
      EyeColumn(rowClicked),
      {
        Header: t('ID'),
        accessor: 'id',
        Cell: CellCenter,
        width: '25',
        isVisible: false,
      },
      {
        Header: t('Delivery Request Number'),
        accessor: 'requestNo',
        Cell: CellLeft,
        width: '50',
        isVisible: false,
      },
      {
        Header: t('Planned Delivery Date'),
        accessor: 'plannedDeliveryDate',
        Cell: CellDate,
        width: '90',
        isVisible: false,
      },
      {
        Header: t('Status'),
        accessor: 'statusId',
        Cell: ({ row }) => {
          const rec = row.original;
          return (
            <div className="text-left">
              <div
                className={'badge pb-2 pt-1 mr-2 '}
                style={{
                  backgroundColor: getTiredeliveryStatusBadge(rec?.status.value),
                  color: 'white',
                }}
              >
                {t(rec?.status.name)}
              </div>
            </div>
          );
        },
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Collector'),
        accessor: 'collector.name',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t("Collector's Platform"),
        accessor: 'collectorsPlatform.name',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Valoriser'),
        accessor: 'valoriser.name',
        Cell: CellLeft,
        width: '150',
        isVisible: false,
      },
      {
        Header: t('Request Date'),
        accessor: 'requestDate',
        Cell: CellDateTime,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Valorisation Method'),
        accessor: 'valorisationMethod.name',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Distance in km'),
        accessor: 'distance',
        Cell: CellFloatCustom(10, 2, true),
        width: '100',
        isVisible: false,
      },
    ],
    [rowClicked, t],
  );

  return (
    <LoadingOverlay active={loading} spinner text={t('Please wait...')}>
      {data ? (
        <TablePaginatedUC
          id="tiredeliveriesRequests"
          appStore={appStore}
          key="tiredeliveriesRequests"
          title={'Λίστα'}
          columns={columns}
          data={data}
          dataCount={dataCount}
          // initPageIndex={initPage}
          // onSortByChange={handleSortByChange}
          onExport={handleExport}
          onPageChange={onPageChange}
          initPageIndex={initPageIndex}
          resizable={true}
        />
      ) : (
        <div style={{ height: '200px' }}></div>
      )}
    </LoadingOverlay>
  );
};
