import { useIdleTimer } from 'react-idle-timer';
import gql from 'graphql-tag';
export class FormInput {
  id: number;
  name: string;
  vatin: string;
  tradeRegister: string;
  partnerName: string;
  contractNo: string;
  contractExpirationDate: Date;
  collectorId: number;
  isSubcontractor: boolean;
  address: string;
  city: string;
  countyId: number;
  phone: string;
  mobile: string;
  fax: string;
  email: string;
  email2: string;
  website: string;
  contactperson: string;
  notes: string;
  environmentalPermit: string;
  signatureFileId: number;
  stampFileId: number;
  deliveriesYearlyWeightTarget: number;
  autoApproveDeliveries: boolean;
  blockDeliveries: boolean;
  crossplatSeries: boolean;
  isActive: boolean;
  isBlackListed: boolean;
  firstDate: Date;
  lastDate: Date;
  firstUser: string;
  lastUser: string;
}

export const gqlGetQuery = gql`
  query getCollectors($limit: Int, $offset: Int, $order: String, $where: String) {
    getCollectors(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        address
        city
        collector {
          id
          name
        }
        collectorId

        contactperson
        county {
          id
          name
        }
        countyId
        email
        email2
        fax
        firstDate
        firstUser
        id
        isActive
        isSubcontractor
        lastDate
        lastUser
        mobile
        name
        notes
        phone
        vatin
        tradeRegister
        partnerName
        contractNo
        contractExpirationDate
        website
        deliveriesYearlyWeightTarget
        autoApproveDeliveries
        isBlackListed
      }
      recordCount
    }
  }
`;

export const gqlGetByIdQuery = gql`
  query getCollectorById($id: Int) {
    getCollectorById(id: $id) {
      id
      isBlackListed
      crossplatSeries
      deliveriesYearlyWeightTarget
        autoApproveDeliveries
        blockDeliveries
      address
      city
      collectorId
      collector {
        id
        name
      }
      contactperson
      county {
        id
        name
      }
      countyId
      email
      email2
      fax
      environmentalPermit
      envPermitExpirationDate
      signatureFileId
      signatureFileRec {
        id
        fileName
        mimeMediaType
      }
      stampFileId
      stampFileRec {
        id
        fileName
        mimeMediaType
      }
      firstDate
      firstUser
      isActive
      isSubcontractor
      lastDate
      lastUser
      mobile
      name
      notes
      phone
      vatin
      tradeRegister
        partnerName
        contractNo
        contractExpirationDate
      website
      collectorsPlatforms {
        id
        address
        city
        collectorId
        contactperson
        county {
          id
          name
          regionId
        }
        countyId
        email
        email2
        fax
        firstDate
        firstUser
        id
        isActive
        lastDate
        lastUser
        mobile
        name
        notes
        phone
        website
      }

      collectorsCounties {
        collectorId
        countyId
        firstDate
        firstUser
        id
        isActive
        lastDate
        lastUser
        county {
          id
          name
        }
      }

    }
  }
`;

export const gqlAdd = gql`
  mutation addCollector($data: CollectorInput) {
    addCollector(data: $data) {
      id
    }
  }
`;

export const gqlUpdate = gql`
  mutation updateCollector($data: CollectorInput) {
    updateCollector(data: $data) {
      id
    }
  }
`;

export const gqlDelete = gql`
  mutation deleteCollector($id: Int) {
    deleteCollector(id: $id)
  }
`;

export const gqlGetDistancesQuery = gql`
  query getCollectorsDistances($limit: Int, $offset: Int, $order: String, $where: String) {
    getCollectorsDistances(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        distance
        firstDate
        firstUser
        id
        isActive
        lastDate
        lastUser
        platformId
        valoriserId
        valoriser {
          id
          name
        }
      }
      recordCount
    }
  }
`;

export const gqlUpdateCollectorsDistance = gql`
  mutation updateCollectorsDistance($data: CollectorsDistanceInput) {
    updateCollectorsDistance(data: $data) {
      id
    }
  }
`;

export const gqlGetCollectorCountiesQuery = gql`
  query getCollectorsCounties($limit: Int, $offset: Int, $order: String, $where: String) {
    getCollectorsCounties(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        collectorId
        countyId
        firstDate
        firstUser
        id
        isActive
        lastDate
        lastUser
        county {
          id
          name
        }
      }
      recordCount
    }
  }
`;

export const gqlAddCollectorsCounty = gql`
  mutation addCollectorsCounty($data: CollectorsCountyInput) {
    addCollectorsCounty(data: $data) {
      id
    }
  }
`;

export const gqlDeleteCollectorsCounty = gql`
  mutation deleteCollectorsCounty($id: Int) {
    deleteCollectorsCounty(id: $id)
  }
`;

export const gqlGetCollectorCollectionpointsQuery = gql`
  query getCollectorsCollectionpoints($limit: Int, $offset: Int, $order: String, $where: String) {
    getCollectorsCollectionpoints(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        collectionpointId
        collectorId
        hasContract
        collectionpoint {
          id
          name
          address
          city
          county {
          id
          name
          }
          countyId
          phone
          vatin
          categoryId
          collectionpointsCategory {
            id
            name
          }
        }
        id
        firstDate
      }
      recordCount
    }
  }
`;

export const gqlSetAutoApproveCollector = gql`
  mutation setAutoApproveCollector($collectorId: Int, $value: Boolean) {
    setAutoApproveCollector(collectorId: $collectorId, value: $value)
  }
`;