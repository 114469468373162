import gql from "graphql-tag";
export class EdocSeriesInput {
  id: number;
  docPrefix: string;
}

export class FormInput {
  id: number;
  name: string;
  collectorId: number;
  address: string;
  city: string;
  countyId: number;
  phone: string;
  mobile: string;
  fax: string;
  email: string;
  email2: string;
  website: string;
  contactperson: string;
  notes: string;
  environmentalPermit: string;
  envPermitExpirationDate: Date;
  signatureFileId: number;
  stampFileId: number;
  isMain: boolean;
  isActive: boolean;
  firstDate: Date;
  lastDate: Date;
  firstUser: string;
  lastUser: string;
  edocSeries: [EdocSeriesInput];
}

export const gqlGetQuery = gql`
  query getCollectorsPlatforms(
    $limit: Int
    $offset: Int
    $order: String
    $where: String
  ) {
    getCollectorsPlatforms(
      limit: $limit
      offset: $offset
      order: $order
      where: $where
    ) {
      data {
        address
        city
        collector {
          id
          name
        }
        collectorId
        contactperson
        county {
          id
          name
          regionId
        }
        countyId
        email
        email2
        fax
        firstDate
        firstUser
        id
        isActive
        lastDate
        lastUser
        mobile
        name
        notes
        phone
        website
        environmentalPermit
        envPermitExpirationDate
        isMain
      }
      recordCount
    }
  }
`;

export const gqlGetByIdQuery = gql`
  query getCollectorsPlatformById($id: Int) {
    getCollectorsPlatformById(id: $id) {
      address
      city
      collector {
        id
        name
      }
      collectorId
      contactperson
      county {
        id
        name
        regionId
      }
      countyId
      email
      email2
      fax
      firstDate
      firstUser
      id
      isActive
      lastDate
      lastUser
      mobile
      name
      notes
      phone
      website
      environmentalPermit
      envPermitExpirationDate
      signatureFileId
      signatureFileRec {
        id
        fileName
        mimeMediaType
      }
      stampFileId
      stampFileRec {
        id
        fileName
        mimeMediaType
      }
      isMain
      edocSeries {
        id
        name
        collectorId
        seriestypeId
        docPrefix
        isYearlyRenumbering
        edocSeriesNumbers {
          year
          lastNumber
          lastCode
          lastDate
        }
      }
    }
  }
`;

export const gqlAdd = gql`
  mutation addCollectorsPlatform($data: CollectorsPlatformInput) {
    addCollectorsPlatform(data: $data) {
      id
    }
  }
`;

export const gqlUpdate = gql`
  mutation updateCollectorsPlatform($data: CollectorsPlatformInput) {
    updateCollectorsPlatform(data: $data) {
      id
    }
  }
`;

export const gqlDelete = gql`
  mutation deleteCollectorsPlatform($id: Int) {
    deleteCollectorsPlatform(id: $id)
  }
`;
