import React from 'react';
import { usePersistentLog } from 'services';
import { toastFailure } from 'utils/toasts';
import { useTranslation } from 'react-i18next';

// Present error to user
// + Persist Error to DB

export function getMessageOfError(error: any): string {
  let msg = '';
  if (error.graphQLErrors && error.graphQLErrors[0]?.message) msg = error.graphQLErrors[0]?.message;
  else if (error.message) msg = error.message;
  else msg = error;
  return msg;
}

export function useHandleError(componentName: string) {
  const { t } = useTranslation();
  const { logError, logInfo } = usePersistentLog('user');

  function handleGraphqlError(location: string, error: any, userPrefix?: string): void {
    //console.log('PersistErrorLog', componentName, location, message);
    // let msg = '';
    // if (error.graphQLErrors && error.graphQLErrors[0]?.message) msg = error.graphQLErrors[0]?.message;
    // else if (error.message) msg = error.message;
    // else msg = error;
    // //const msg = error.graphQLErrors[0]?.message ?? error.message
    const msg = getMessageOfError(error);

    let prefix = t('An error occured at the server'); //'Παρουσιάστηκε κάποιο λάθος στον σέρβερ.';
    if (userPrefix) prefix = userPrefix;
    else if (location.includes('add') || location.includes('update'))
      prefix = t('An error occured when saving the file');
    //'Παρουσιάστηκε κάποιο λάθος κατα την αποθήκευση.';
    else if (location.includes('delete')) prefix = t('Failed to delete the record'); //'Η διαγραφή της Εγγραφής απέτυχε!';

    toastFailure(
      <div>
        {prefix}
        <br />
        <p dangerouslySetInnerHTML={{ __html: msg }}></p>
      </div>,
    );
    logError(`[handleGraphqlError] ${componentName}.${location} Message=`, msg); // Αποθηκεύεται και στον Server
    console.error(`[handleGraphqlError] ${componentName}.${location}`, 'Message=', msg);
  }
  return { handleGraphqlError };
}
