import React from 'react';
import { FlexRow, usePaginatedQuery } from 'motion-components';
import { Card } from 'react-bootstrap';
import { useRootStore } from 'store';
import { useHandleError } from 'services';
import { gqlGetPlatformQuery, gqlGetQuery } from '../graphql';
import { buildWhereFromFilterValues, Filters } from './filters';
import { DataGrid } from './grid';
import { handleExport } from './export-columns';
import { useApolloClient } from '@apollo/client';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFirstDayOfMonth, getLastDayOfMonth } from 'common/date-utils';
import { StockTransactionsAggregateSection } from './aggregate-section';
import { useQuery } from '@apollo/react-hooks';

/* ----------------------------------------------------------- */
/* ------------------------ Page ----------------------------- */
export const CollectorsStockTransactionsGridPage = () => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const { handleGraphqlError } = useHandleError('CollectorsStockTransactionsGridPage');
  const { platformId } = useParams<{ platformId: string }>();
  console.log('platformId', platformId);
  const { data: platformDataRaw } = useQuery(gqlGetPlatformQuery, {
    variables: { id: parseInt(platformId) },
    fetchPolicy: 'cache-and-network',
  });
  const platformData = platformDataRaw?.getCollectorsPlatformById;

  const location = useLocation<any>();
  const history = useHistory();

  /* ------------------- Query ------------------------------- */
  const query = usePaginatedQuery(gqlGetQuery, buildWhereFromFilterValues, 'name', appStore, true, {
    platformId: platformId,
    startDate: getFirstDayOfMonth(new Date()),
    endDate: getLastDayOfMonth(new Date()),
  });
  const {
    loading,
    data,
    dataCount,
    error,
    initPageIndex,
    filtersChanged,
    gridPageChanged,
    queryState,
  } = query;

  const client = useApolloClient();

  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <Card className="shadow">
        <Card.Header>
          <FlexRow>
            <Card.Title className="m-0">
              {t("Collector's Platform Stock Transactions")}
              {platformData && (
                <span className="ml-3">
                  {t('Collector')}:{' '}
                  <span className="text-primary">{platformData.collector.name}</span>,
                  {t('Platform')}: <span className="text-primary">{platformData.name}</span>
                </span>
              )}
            </Card.Title>
          </FlexRow>
        </Card.Header>
        <Card.Body className="nopadding">
          {/* Φίλτρα */}
          <Filters
            onChange={filtersChanged}
            initialValues={{ ...queryState?.filterValues, platformId: platformId } ?? {}}
          />
          {/* SUMS for Period - load after data */}
          {data && (
            <StockTransactionsAggregateSection
              platformId={platformId}
              startDate={queryState?.filterValues?.startDate}
              endDate={queryState?.filterValues?.endDate}
            />
          )}
          {/* Grid */}
          <h5>{t('Detailed Transactions')}</h5>
          <DataGrid
            loading={loading}
            data={data}
            dataCount={dataCount}
            initPageIndex={initPageIndex}
            onPageChange={gridPageChanged}
            handleExport={() => {
              handleExport(client, queryState, appStore);
            }}
          />
        </Card.Body>
      </Card>
    );
  }
};
