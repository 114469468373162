import { FieldFeedback, toastSuccess } from 'motion-components';
import { Form as FForm, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';
import { createModal } from 'react-modal-promise';
import { useRootStore } from 'store';
import { InferType, object, ref, string } from 'yup'; // for only what you need
//  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,

import gql from 'graphql-tag';
import * as ApolloReactHooks from '@apollo/client';

import { useTranslation } from 'react-i18next';

const UserLoginDocument = gql`
  query userLogin($userName: String, $userPassword: String) {
    userLogin(userName: $userName, userPassword: $userPassword) {
      jwt
      userMustChangePassword
      expirationDate
    }
  }
`;

function useUserLoginLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<any, any>) {
  return ApolloReactHooks.useLazyQuery<any, any>(UserLoginDocument, baseOptions);
}

const UserSetNewPasswordDocument = gql`
  mutation userSetNewPassword($userName: String!, $userNewPassword: String!) {
    userSetNewPassword(userName: $userName, userNewPassword: $userNewPassword)
  }
`;
function useUserSetNewPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<any, any>,
) {
  return ApolloReactHooks.useMutation<any, any>(UserSetNewPasswordDocument, baseOptions);
}

type Props = {
  open: boolean;
  close: () => void;
  userName: string;
};

const SetPasswordModal = ({ open, close, userName }: Props) => {
  /*-------------------- Body -----------------------------------------------*/
  const { appStore } = useRootStore();
  const { t } = useTranslation();

  const [setPassword] = useUserSetNewPasswordMutation();
  const [get, { data }] = useUserLoginLazyQuery();

  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  /**
   * Setup Yup Validation
   */
  const validationSchema = object().shape({
    password: string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
        t(
          'It must be at least 6 characters long and contain at least one Uppercase letter, one Lowercase letter, and one Number',
        ),
        //'Πρέπει να είναι τουλάχιστον 6 χαρακτήρες και να περιέχει τουλάχιστον ένα Κεφαλαίο γράμμα, ένα Μικρό, και έναν Αριθμό',
      )
      .required(t('The field cannot be empty')),
    confirmPassword: string()
      .oneOf([ref('password'), null], t('The value differs')) //'Η επιβεβαίωση δεν είναι σωστή')
      .required(t('The field cannot be empty')),
  });
  type FormDataType = InferType<typeof validationSchema>;

  // * 1. Effect οταν πάρω data
  useEffect(() => {
    if (!!data) {
      const userLogin = data?.userLogin;
      appStore.login(appStore.user, userLogin);
      toastSuccess(t('Password succesfully changed'));
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  /*-------------------- Handlers -------------------------------------------*/
  const handleSubmitForm = (values: FormDataType, action) => {
    console.log(values);
    setPassword({ variables: { userName: userName, userNewPassword: values.password } }).then(
      () => {
        if (appStore.user === userName) {
          get({ variables: { userName: appStore.user, userPassword: values.password } });
        } else {
          toastSuccess(t('Password succesfully changed'));
          close();
        }
      },
    );
  };

  /*-------------------- Render ---------------------------------------------*/
  return (
    <Modal show={open} centered backdrop="static">
      <Modal.Header>
        <Modal.Title>{t('Change User Password')}</Modal.Title>
      </Modal.Header>
      <Formik
        onSubmit={handleSubmitForm}
        initialValues={{ password: '', confirmPassword: '' }}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {(formikBag) => (
          <Form as={FForm}>
            <Modal.Body>
              <Form.Group>
                <Form.Label className="mb-0">{t('New Password')}</Form.Label>
                <InputGroup>
                  <Form.Control
                    id="password"
                    type={passwordShow ? 'text' : 'password'}
                    {...formikBag.getFieldProps('password')}
                    isInvalid={!!formikBag.touched.password && !!formikBag.errors.password}
                  />
                  <InputGroup.Append>
                    <Button onClick={() => setPasswordShow((value) => !value)}>
                      <FaEye />
                    </Button>
                  </InputGroup.Append>
                  <FieldFeedback type="invalid" name={'password'} />
                </InputGroup>
              </Form.Group>

              <Form.Group>
                <Form.Label className="mb-0">{t('Confirm Password')}</Form.Label>
                <InputGroup>
                  <Form.Control
                    id="confirmPassword"
                    type={confirmPasswordShow ? 'text' : 'password'}
                    {...formikBag.getFieldProps('confirmPassword')}
                    isInvalid={
                      !!formikBag.touched.confirmPassword && !!formikBag.errors.confirmPassword
                    }
                  />
                  <InputGroup.Append>
                    <Button onClick={() => setConfirmPasswordShow((value) => !value)}>
                      <FaEye />
                    </Button>
                  </InputGroup.Append>
                  <FieldFeedback type="invalid" name={'confirmPassword'} />
                </InputGroup>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                {t('OK')}
              </Button>
              {!appStore.userLogin.userMustChangePassword && (
                <Button variant="danger" onClick={() => close()}>
                  {t('CANCEL')}
                </Button>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const setPasswordModal = createModal(SetPasswordModal);

export default setPasswordModal;
