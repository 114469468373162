import { faFile } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CellCenter, TableInMem } from 'motion-components';
import React from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { createModal } from 'react-modal-promise';

type Props = {
  readonly open: boolean;
  readonly close: (result: boolean | number) => void;
  readonly title: string;
  /** body of dialog */
  readonly text: string; // | React.ReactNode;
  readonly data: any;
  readonly newData: any;
} & ModalProps;

const ModalSelectCp = ({ open, close, title, text, data, newData }: Props) => {
  const { t } = useTranslation();
  const columns = [
    {
      /* ΚΟΛΩΝΑ ΜΕ RedEye και Click handler */
      id: 'select',
      Header: () => <FontAwesomeIcon icon={faFile} />,
      Cell: ({ row }) => (
        <div
          className="text-center"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            close(row.original.id);
          }}
        >
          <Button style={{ width: '70px' }} variant="primary" type="button">
            {t(' Select ')}
          </Button>
        </div>
      ),
      Footer: () => {
        return (
          <div
            className="text-center"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              close(-1);
            }}
          >
            <Button style={{ width: '70px' }} variant="success" type="button">
              {t(' Select New ')}
            </Button>
          </div>
        );
      },
    },
    // { Header: 'Id', accessor: 'id' },
    {
      Header: t('Collection Point Name'),
      accessor: 'name',
      Cell: ({ row }) => {
        const extraClassName = row.original?.name === newData?.name ? ' font-weight-bold' : '';
        return <div className={'text-center' + extraClassName}>{row.original?.name}</div>;
      },
      Footer: () => {
        return <div className="text-center font-weight-bold">{newData?.name}</div>;
      },
    },
    {
      Header: t('VAT Number'),
      accessor: 'vatin',
      Cell: ({ row }) => {
        const extraClassVatin = row.original?.vatin === newData?.vatin ? ' font-weight-bold' : '';
        return <div className={'text-center' + extraClassVatin}>{row.original?.vatin}</div>;
      },
      Footer: () => {
        return <div className="text-center font-weight-bold">{newData?.vatin}</div>;
      },
    },
    {
      Header: t('Phone Number'),
      accessor: 'phone',
      Cell: ({ row }) => {
        const extraClassPhone = row.original?.phone === newData?.phone ? ' font-weight-bold' : '';
        const extraClassMobile =
          row.original?.mobile === newData?.mobile ? ' font-weight-bold' : '';
        return (
          <>
            <div className={'text-center' + extraClassPhone}>{row.original?.phone}</div>
            <div className={'text-center' + extraClassMobile}>{row.original?.mobile}</div>
          </>
        );
      },
      Footer: () => {
        return (
          <>
            <div className="text-center font-weight-bold">{newData?.phone}</div>
            <div className="text-center font-weight-bold">{newData?.mobile}</div>
          </>
        );
      },
    },
    {
      Header: t('Address'),
      accessor: 'address',
      Cell: ({ row }) => {
        const extraClassCity = row.original?.city === newData?.city ? ' font-weight-bold' : '';
        const extraClassAddress =
          row.original?.address === newData?.address ? ' font-weight-bold' : '';
        return (
          <>
            <div className={'text-center' + extraClassCity}>{row.original?.city}</div>
            <div className={'text-center' + extraClassAddress}>{row.original?.address}</div>
          </>
        );
      },
      Footer: () => {
        return (
          <>
            <div className="text-center font-weight-bold">{newData?.city}</div>
            <div className="text-center font-weight-bold">{newData?.address}</div>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Modal show={open} size="lg" centered backdrop="static">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableInMem
            title={text}
            propPageSize={10}
            onCreate={undefined}
            columns={columns}
            data={data}
            hasSearch={false}
            hasFooter={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="p-3"
            style={{ minWidth: '5rem' }}
            variant="danger"
            onClick={() => close(false)}
          >
            &nbsp;{t('Cancel')}&nbsp;
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const promiseModalSelectCp = createModal(ModalSelectCp);

export default promiseModalSelectCp;
