import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { toastSuccess } from 'utils/toasts';
import { FaBan } from 'react-icons/fa';
import { promiseModalDialog } from 'motion-components';

const gqlCancelCollectionOrder = gql`
  mutation cancelCollectionOrder($tirecollectionId: Int) {
    cancelCollectionOrder(tirecollectionId: $tirecollectionId)
  }
`;

const UseCancelCollectionOrder = () => {
  const [updateRecord] = useMutation(gqlCancelCollectionOrder);
  const cancelCollectionOrder = async (data) => {
    const ret = await updateRecord({
      variables: data,
    });
    console.log('$$$', ret);
    return ret;
  };
  return { cancelCollectionOrder };
};

export const CancelCollectionOrderButton = ({ data, onClick, disabled = false }) => {
  const { t } = useTranslation();
  const { cancelCollectionOrder } = UseCancelCollectionOrder();

  const handleSubmit = async () => {
    const res = await promiseModalDialog({
      title: `${t('Cancel Tire Collection')}`,
      text: `${t('Are you sure you want to cancel this Tire Collection?')}`,
    });
    if (res) {
      await cancelCollectionOrder({ tirecollectionId: data.id }).then(() => {
        toastSuccess(
          `${t('Successfullly cancelled Tire Collection with ID')} ${data.collectionpointId}`,
        );
        onClick();
      });
    }
  };

  return (
    <>
      <Button disabled={disabled} onClick={handleSubmit} className="ml-3 mr-4" variant="danger">
        <FaBan className="mb-1 mr-3" />
        <span>{t('Cancel Collection')}</span>
      </Button>
    </>
  );
};
