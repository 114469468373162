import React, { useMemo, useCallback } from 'react';
import { useRootStore } from 'store';
import {
  BootstrapSwitchButton,
  CellCheckbox,
  TableSubSimple,
  toastSuccess,
} from 'motion-components';
import { gql, useMutation } from '@apollo/client';
import { useHandleError } from 'services';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export const ValMethodsTable = ({
  data,
  dataCount = 0,
  initPageIndex = 1,
  onPageChange = () => {},
  handleExport = () => {},
  refetch,
}) => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const { handleGraphqlError } = useHandleError('ValMethodsTable ');

  const gqlUpdateFormValues = gql`
    mutation update($data: ValorisersValorisationmethodInput) {
      updateValorisersValorisationmethod(data: $data) {
        id
      }
    }
  `;
  const [updateRecord] = useMutation(gqlUpdateFormValues);
  //
  const doUpdateRecord = useCallback(
    (data, row) => {
      updateRecord({ variables: { data } })
        .then((d) => {
          //const id = d.data.key[Object.keys(d.data)[0]].id;
          const id = d.data.updateValorisersValorisationmethod.id;
          toastSuccess(`${t('Record Updated Successfully')} [${id}]`);
          if (refetch) refetch();
        })
        .catch((error) => {
          handleGraphqlError('mutation-update', error);
        });
    },
    [handleGraphqlError, refetch, updateRecord],
  );

  //
  const isActiveChanged = useCallback(
    (checked, row) => {
      const data: any = { id: row.id, isActive: checked };
      doUpdateRecord(data, row);
    },
    [doUpdateRecord],
  );

  const columns = useMemo(
    () => [
      { Header: t('Valorisation Method'), accessor: 'valorisationMethod.name' },

      {
        Header: t('Active'),
        accessor: 'isActive',
        Cell: CellCheckbox(isActiveChanged, !appStore.isEcoanvelope),
      },
    ],
    [appStore.isEcoanvelope, isActiveChanged],
  );
  return (
    <div style={{ marginTop: '1.5rem' }}>
      <h5 className="form-group-title mb-0 ">{t('Valorisation Methods')}</h5>
      <TableSubSimple
        title={t('Users')}
        columns={columns}
        data={_.sortBy(data, ['valorisationMethodId'])}
      />
    </div>
  );
};
