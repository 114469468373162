import _ from 'lodash';
import {
  CellCenter,
  CellRight,
  FieldIntegerInput,
  FieldNumericInputNoLabel,
  TableSubSimple,
} from 'motion-components';
import React, { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTireTypesQuery } from './UseTireTypesQuery';
import './tires-section.scss';
import { useFormikContext } from 'formik';

export const TiresSection = ({ isNew, data }) => {
  const { t } = useTranslation();
  const { values, setFieldValue }: any = useFormikContext();
  const { tireTypesData: dataTT } = useTireTypesQuery();

  const modifiedDataTireTypes = (dataQty, dataTireTypes) => {
    const modifiedArray = [];
    dataTireTypes.map((dataTT) => {
      const index = _.findIndex(dataQty, ['tiretypeId', dataTT.id]);
      if (index !== -1) {
        modifiedArray.push(dataQty[index]);
        //setFieldValue(`tiredeliveriesQty[${index}].tiretypeId`, dataTT.id);
      } else {
        modifiedArray.push({ pieces: null, tireType: dataTT, tiretypeId: dataTT.id });
      }
    });

    return modifiedArray;
  };

  const columns = useMemo(
    () => [
      // { Header: 'Id', accessor: 'id' },
      {
        Header: t('Tires type'),
        accessor: 'name',
        Cell: ({ row }) => (
          <div className={`text-center ${isNew ? 'mt-2' : null}`}>
            {row.original?.name ?? row.original.tireType?.name ?? ''}
          </div>
        ),
      },
      {
        Header: t('Pieces'),
        accessor: 'pieces',
        Cell: !isNew
          ? CellCenter
          : ({ row }) => {
              /* const index = data.tiredeliveriesQty.findIndex(
                (tdq) => tdq.tiretypeId === row.original.tiretypeId,
              );
              const rowIndex = index >= 0 ? index : row.index + 6; */
              return (
                <FieldIntegerInput
                  onChange={() =>
                    setFieldValue(
                      `tiredeliveriesQty[${row.index}].tiretypeId`,
                      row.original.tiretypeId,
                    )
                  }
                  hasLabel={false}
                  name={`tiredeliveriesQty[${row.index}].pieces`}
                  required={false}
                />
              );
            },
      },
    ],
    [t, data],
  );

  return (
    <>
      {data && dataTT && (
        <div>
          <TableSubSimple
            id="tires-inheader"
            title={t('')}
            columns={columns}
            data={modifiedDataTireTypes(data.tiredeliveriesQty, dataTT)}
          />
        </div>
      )}
    </>
  );
};
