import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FieldDatepicker, FieldInput } from 'motion-components';
import { useTranslation } from 'react-i18next';

export const TabWeighing = ({ readonly }) => {
  const { t } = useTranslation();
  function calcUserCanEdit() {
    const canEdit = !readonly;
    const canEditReasoning = '';
    return { canEdit, canEditReasoning };
  }
  // const canEdit =
  //   !(values as any)?.isFinalized && (authStore.userCanDraft(userRoleId) || authStore.userCanFinalize(userRoleId));
  const { canEdit, canEditReasoning } = calcUserCanEdit();
  return (
    <>
      <h5 className="form-group-title-bg">{t('Weighing at Valorisation Point')}</h5>
      <Row>
        <Col>
          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Valoriser Weight Notes')}
            name="weightNotes"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Employee from Valoriser who entered the weighing')}
            name="valoriserWeightsBy"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />
        </Col>
        <Col>
          <FieldDatepicker
            disabled={!canEdit}
            as={Col}
            label={t('Delivered Date')}
            name="valoriserDeliveredDate"
            //showTimeSelect
            dateFormat="d/M/yyyy"
            timeFormat="HH:mm"
            timeIntervals={15}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Weighing Note Number')}
            name="valoriserWeighingNoteNo"
            type="text"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Gross')}
            name="valoriserGrossWeight"
            type="string"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Tare')}
            name="valoriserTareWeight"
            type="string"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Deduction')}
            name="valoriserDeductWeight"
            type="string"
            placeholder={t('- specify a value - ')}
            required={false}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Net')}
            name="valoriserNetWeight"
            type="string"
            placeholder={t('- specify a value - ')}
            required={false}
          />
        </Col>
      </Row>
    </>
  );
};
