import React, { useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { useLazyQuery } from '@apollo/react-hooks';

import { toastFailure } from 'utils/toasts';

import { Formik, Form as FForm } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { useHandleError } from 'services';
import { ButtonCancel, FieldInput, FormCol, TableInMem, toastWarning } from 'motion-components';
import { gqlAddTirecollectionRequest, gqlGetCollectionpoints } from 'pages/tirecollections/graphql';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

// Επιλογή Σημείου με τηλ ή αφμ
// περνάς μία συνάρτηση που σου επιστρέφει το id του σημείoυ
export const SelectCollectionpointForm = ({ count, setCount, onValueSet }) => {
  const [values, setValues] = useState();
  const { t } = useTranslation();
  const { handleGraphqlError } = useHandleError('selectcollectionpoint');
  function onGetCollectionpointsCompleted(data) {
    const records = data.getCollectionpoints.data;
    setCount(data.getCollectionpoints.recordCount);
    console.log('fetch completed', records);
    if (records.length === 0) {
      toastFailure(t('No Collection Point was found!'));
    } else if (records.length === 1) {
      getCanAdd({ variables: { collectionPointId: records[0].id } });
    }
  }
  const [get, { data: rawData, error }] = useLazyQuery(gqlGetCollectionpoints, {
    onCompleted: (data) => onGetCollectionpointsCompleted(data),
    fetchPolicy: 'network-only',
  });
  const dataCP = rawData ? rawData.getCollectionpoints.data : undefined;

  function onCanAdd(data) {
    const canAdd = data.addTirescollectionRequest.canAdd;
    const cpId = data.addTirescollectionRequest.collectionPointId;
    if (canAdd === true) {
      onValueSet(cpId);
    } else
      toastWarning(t("There's already a collection request submitted for this Collection Point"));
  }
  const [getCanAdd] = useMutation(gqlAddTirecollectionRequest, {
    onCompleted: (data) => onCanAdd(data),
    fetchPolicy: 'network-only',
  });
  //const count = rawData ? rawData.getCollectionpoints.recordCount : undefined;

  const createWhere = (phoneNumber, vatNumber, addressLine, name) => {
    let where = {};
    const likeObj = (obj) => {
      return { $like: '%' + obj + '%' };
    };
    if (vatNumber) where = { vatin: likeObj(vatNumber) };
    if (phoneNumber)
      where = {
        ...where,
        $or: [{ phone: likeObj(phoneNumber) }, { mobile: likeObj(phoneNumber) }],
      };
    if (name) where = { ...where, name: likeObj(name) };
    if (addressLine) where = { ...where, address: likeObj(addressLine) };

    where = { ...where, isActive: true };
    return JSON.stringify(where);
  };

  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <>
        {count > 1 ? (
          <CollectionPointsSection values={values} handleOk={getCanAdd} data={dataCP} />
        ) : (
          <RequestTelephoneOrVATNoForm
            setValues={setValues}
            onValuesSet={(values) => {
              get({
                variables: {
                  where: createWhere(
                    values.phoneNumber,
                    values.vatNumber,
                    values.addressLine,
                    values.name,
                  ),
                },
              });
            }}
          />
        )}
      </>
    );
  }
};

// Φόρμα για καταχώρηση Τηλ., ΑΦΜ ή Διεύθυνσης
const RequestTelephoneOrVATNoForm = ({ setValues, onValuesSet }) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <>
      <div className="text-hdr-normal font-weight-bold mb-4 ml-3">
        {t('Search by VAT Number, Phone Number, Collection Point Name or Address and press OK')}
      </div>
      <Formik
        initialValues={{ phoneNumber: '', vatNumber: '', addressLine: '', name: '' }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (
            (!values.phoneNumber || values.phoneNumber.match(/^\s*$/)) &&
            (!values.vatNumber || values.vatNumber.match(/^\s*$/)) &&
            (!values.addressLine || values.addressLine.match(/^\s*$/)) &&
            (!values.name || values.name.match(/^\s*$/))
          ) {
            setSubmitting(false);
            toastFailure(t('Please insert data in at least 1 of the inputs'));
            return;
          }
          setTimeout(() => {
            setSubmitting(false);
            setValues(values);
            onValuesSet(values); // return values
          }, 100);
        }}
      >
        <Form as={FForm}>
          <FieldInput as={FormCol} label={t('Collection Point Name')} name="name" />
          <FieldInput as={FormCol} label={t('VAT Number')} name="vatNumber" />
          <FieldInput as={FormCol} label={t('Phone Number')} name="phoneNumber" />
          <FieldInput as={FormCol} label={t('Address')} name="addressLine" />
          <Row className="mt-4 ml-3">
            <Button type="submit" variant="primary" style={{ width: '160px' }}>
              OK
            </Button>
            <ButtonCancel
              className="ml-4"
              onClick={() => {
                history.goBack();
              }}
            />
          </Row>
        </Form>
      </Formik>
    </>
  );
};

// Φόρμα για επιλογή σημείου απο λίστα
const CollectionPointsSection = ({ values, data, handleOk }) => {
  const { t } = useTranslation();
  const columns = [
    {
      /* ΚΟΛΩΝΑ ΜΕ RedEye και Click handler */
      id: 'select',
      Header: () => <FontAwesomeIcon icon={faFile} />,
      Cell: ({ row }) => (
        <div
          className="text-center"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleOk({ variables: { collectionPointId: row.original.id } });
          }}
        >
          <Button style={{ width: '70px' }} variant="primary" type="button">
            {' Select '}
          </Button>
        </div>
      ),
    },
    // { Header: 'Id', accessor: 'id' },
    { Header: t('Collection Point Name'), accessor: 'name' },
    {
      Header: t('VAT Number'),
      accessor: 'vatin',
    },
    {
      Header: t('Phone Number'),
      accessor: 'phone',
      Cell: ({ row }) => (
        <>
          <div className="text-center">{row.original.phone}</div>
          <div className="text-center">{row.original.mobile}</div>
        </>
      ),
    },
    {
      Header: t('Address'),
      accessor: 'address',
      Cell: ({ row }) => (
        <>
          <div className="text-center">{row.original.city}</div>
          <div className="text-center">{row.original.address}</div>
        </>
      ),
    },
    /* {
        accessor: 'postalCode',
        Header: 'ΤΚ',
        Cell: ({ row }) => {
          const rec = row.original;
          return (
            <a href="#" onClick={() => console.log('Hi')}>
              {rec.postalCode}
            </a>
          );
        },
      }, */
  ];

  return (
    <>
      {/* TableGrid */}

      <TableInMem
        title={t('Choose the collection point you are looking for')}
        //onRefresh={() => refetch()}
        //onExport={handleExport}
        propPageSize={10}
        onCreate={undefined}
        columns={columns}
        data={data}
        hasSearch={false}
        hasFooter={false}
      />

      {/* <LoadingOverlay active={loading} spinner text="Please wait..."></LoadingOverlay> */}
    </>
  );
};
