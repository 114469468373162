import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  ButtonCancel,
  promiseModalDialog,
  promiseModalInput,
  toastFailure,
} from 'motion-components';
import { toastSuccess } from 'utils/toasts';

const gqlCancelTireDelivery = gql`
  mutation cancelTireDelivery($tireDeliveryId: Int!) {
    cancelTireDelivery(tireDeliveryId: $tireDeliveryId)
  }
`;

const UseCancelTireDelivery = () => {
  const [cancelRecord] = useMutation(gqlCancelTireDelivery);
  const cancelTireDelivery = async (tireDeliveryId) => {
    const ret = await cancelRecord({
      variables: { tireDeliveryId: tireDeliveryId },
    });
    console.log('$$$', ret);
    return ret;
  };
  return { cancelTireDelivery };
};

export const CancelTireDeliveryButton = ({ deliveryId, onClick, disabled = false }) => {
  const { t } = useTranslation();
  const { cancelTireDelivery } = UseCancelTireDelivery();

  const handleSubmit = async () => {
    const res = await promiseModalDialog({
      title: `${t('Cancel Tire Delivery')}`,
      text: `${t('Are you sure you want to cancel this Tire Delivery?')}`,
      denyText: t('BACK'),
    });
    if (res) {
      await cancelTireDelivery(deliveryId)
        .then(() => {
          toastSuccess(t('Successfully Cancelled Delivery'));
          onClick();
        })
        .catch(() => {
          toastFailure(t('Failed to Cancel Delivery'));
        });
    }
  };
  return (
    <>
      <ButtonCancel onClick={handleSubmit} text={t('Cancel Delivery')} className="ml-3 mr-4" />
    </>
  );
};
