import React, { useMemo } from 'react';
import { Modal, Button, ModalProps, Form, Row, Col } from 'react-bootstrap';
import { createModal } from 'react-modal-promise';
import { Formik, Form as FForm } from 'formik';
import { toastWarning } from 'utils/toasts';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import { useRootStore } from 'store';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  CellDateTime,
  FieldInput,
  FormCol,
  TablePaginatedUC,
  usePaginatedQuery,
  ButtonSearch,
  FormRow,
  FormCol6,
  FormCol2,
} from 'motion-components';
import { FaSearch } from 'react-icons/fa';

const gqlGetFiles = gql`
  query getFilesrepository($where: String, $offset: Int, $limit: Int, $order: String) {
    getFilesrepository(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        id
        fileName
        firstUser
        firstDate
        s3Key
      }
      recordCount
    }
  }
`;

/* ----------------------- Διαχείριση Φίλτρων ------------------------------ */
export function buildWhereFromFilterValues(values): string {
  // Φτιάξε το where για το graphql
  let where = {};
  if (values) {
    //if (values.firstUser) where = { ...where, firstUser: values.firstUser };

    if (values.searchText) {
      const searchText = '%' + values.searchText + '%';
      const likeObj = { $like: searchText };
      where = {
        ...where,
        $or: [{ fileName: likeObj }],
      };
    }
  }
  return JSON.stringify(where);
}

type Props = {
  open: boolean;
  close: (result: any) => void;
  title: string;
  /** body of dialog */
  text: string | React.ReactNode;
  /** accept text */
  acceptText?: string;
  /** deny text */
  denyText?: string;
  type?: string;
} & ModalProps;

/*  ---------------------------------------------------
    Component
    --------------------------------------------------- */
const ModalInput = ({
  open,
  close,
  title,
  text,
  acceptText = 'SELECT FILE',
  denyText = 'CANCEL',
  type = 'text',
}: Props) => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const userName = appStore.user;

  /* ------------------- Query ------------------------------- */
  const query = usePaginatedQuery(
    gqlGetFiles,
    buildWhereFromFilterValues,
    'firstDate DESC',
    appStore,
    true,
    { firstUser: userName },
    false, // do not store in location state
  );
  const {
    loading,
    data,
    dataCount,
    error,
    initPageIndex,
    filtersChanged,
    gridPageChanged,
    queryState,
  } = query;

  const fileSelected = (values) => {
    const input = {
      fileId: values.id,
      fileName: values.fileName,
      s3Key: values.s3Key,
    };
    close(input);
  };

  return (
    <>
      <Modal show={open} centered backdrop="static">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{ userName: userName, searchText: null }}
          onSubmit={(values) => {
            // filtersChanged(values);
          }}
        >
          {(props) => (
            <Form as={FForm}>
              <Modal.Body>
                <Row>
                  <Col>
                    <FieldInput
                      as={FormCol}
                      name="searchText"
                      label={t('Search a file name')}
                      type="search"
                      className="pl-0"
                      suffixLabel={
                        <FaSearch
                          onClick={() => {
                            filtersChanged(props.values);
                          }}
                        />
                      }
                    />
                  </Col>
                  {/* <Col className="col-md-auto mb-3" style={{ alignSelf: 'end' }}>
                  <ButtonSearch onClick={doFilterData} />
                </Col> */}
                </Row>
                <br />
                {data ? (
                  <TablePaginatedUC
                    id="userFile"
                    appStore={appStore}
                    key="userFile"
                    title={'Λίστα'}
                    columns={[
                      {
                        Header: t('File name'),
                        accessor: 'fileName',
                      },
                      {
                        Header: t('Date'),
                        accessor: 'firstDate',
                        Cell: CellDateTime as any,
                      },
                      {
                        Header: t('User'),
                        accessor: 'firstUser',
                      },
                      {
                        Header: t('Actions'),
                        accessor: 'id',
                        Cell: ({ row }) => {
                          const rec = row.original;
                          return (
                            <div className="text-center">
                              <Button
                                variant="primary"
                                onClick={() => {
                                  props.setValues({ userName: userName, searchText: '' }); // reset form
                                  fileSelected(rec);
                                }}
                              >
                                {t(acceptText)}
                              </Button>
                            </div>
                          );
                        },
                      },
                    ]}
                    data={data}
                    dataCount={dataCount}
                    // initPageIndex={initPage}
                    // onSortByChange={handleSortByChange}
                    // onExport={handleExport}
                    onPageChange={gridPageChanged}
                    initPageIndex={initPageIndex}
                    resizable={true}
                  />
                ) : (
                  <div style={{ height: '200px' }}></div>
                )}
              </Modal.Body>
              <Modal.Footer>
                {/* <Button variant="primary" type="submit">
                {t(acceptText)}
              </Button> */}
                <Button variant="danger" onClick={() => close(false)}>
                  {t(denyText)}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

const promiseModalSelectUserFile = createModal(ModalInput);

export default promiseModalSelectUserFile;
