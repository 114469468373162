import React, { useMemo, useCallback } from 'react';
import { useRootStore } from 'store';
import {
  BootstrapSwitchButton,
  CellCheckbox,
  CellLeft,
  CellYesNoNull,
  TableSubSimple,
  toastSuccess,
} from 'motion-components';
import { gql, useMutation } from '@apollo/client';
import { useHandleError } from 'services';
import { useTranslation } from 'react-i18next';

export const CollectionpointCollectorsTable = ({
  data,
  dataCount = 0,
  initPageIndex = 1,
  onPageChange = () => {},
  handleExport = () => {},
  refetch,
}) => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const { handleGraphqlError } = useHandleError('CollectionpointCollectorsTable ');

  const columns = useMemo(
    () => [
      {
        Header: t('Collector'),
        accessor: 'collector.name',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Address'),
        accessor: 'collector.address',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('City'),
        accessor: 'collector.city',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('County'),
        accessor: 'collector.county.name',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        id: 'select',
        Header: t('Phone'),
        accessor: 'collector.phone',
        Cell: ({ row }) => (
          <>
            <div className="text-center">{row.original.collector.phone}</div>
            <div className="text-center">{row.original.collector.mobile}</div>
          </>
        ),
        width: '100',
        isVisible: false,
      },
      {
        Header: t('E-mail'),
        accessor: 'collector.email',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Contact Person'),
        accessor: 'collector.contactperson',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Active'),
        accessor: 'collector.isActive',
        Cell: CellYesNoNull,
        width: '100',
        isVisible: false,
      },
    ],
    [authStore.isUsersAdmin, t],
  );
  return (
    <div style={{ marginTop: '1.5rem' }}>
      <h5 className="form-group-title mb-0 ">{t('Collectors')}</h5>
      <TableSubSimple title={t('Collectors')} columns={columns} data={data} />
    </div>
  );
};
