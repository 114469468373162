import { useIdleTimer } from 'react-idle-timer';
import gql from 'graphql-tag';

export const gqlGetQuery = gql`
  query getResolvedTiredeliveries($limit: Int, $offset: Int, $order: String, $where: String) {
    getResolvedTiredeliveries(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        approvalNotes
        approvedBy
        approvedDate
        collectorId
        collector {
          id
          name
        }
        collectorNotes
        collectorsPlatform {
          id
          name
        }
        deductWeight
        deliveredDate
        plannedDeliveryDate
        collectorWeightsBy
        deliveryNoteNo
        distance
        driver
        licenseTransport
        representative
        ecoanvelopeNotes
        firstDate
        firstUser
        grossWeight
        id
        isActive
        lastDate
        lastUser
        collectorDeliveredDate
        collectorDeductWeight
        collectorDeliveryNoteNo
        collectorGrossWeight
        collectorNetWeight
        collectorTareWeight
        valoriserDeductWeight
        valoriserDeliveredDate
        valoriserDeliveryNoteNo
        valoriserGrossWeight
        valoriserNetWeight
        valoriserTareWeight
        loadUnloadNoteNo
        netWeight
        platformId
        requestDate
        requestNo
        requestedBy
        status {
          id
          name
          value
        }
        statusId
        tareWeight
        valRestrictedUntilDate
        valorisationDate
        valorisationEnteredBy
        valorisationMethodId
        valorisationNotes
        valoriser {
          id
          name
        }
        valorisationMethod {
          id
          name
        }
        valoriserId
        vehPlateNo
        weighingNoteNo
        weightNotes
        valoriserWeightsBy
        tiredeliveriesQty {
          id
          pieces

          tireType {
            id
            name
          }
          tiretypeId
          weight
        }
      }
      recordCount
    }
  }
`;
