import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  FieldDatepicker,
  FieldInput,
  FormCol,
  FormRow,
  FieldCheckBox,
  FieldTextArea,
  FormCol2,
  FormCol6,
} from 'motion-components';
import { useFormikContext } from 'formik';
import { useHandleError } from 'services';
import { useRootStore } from 'store';
import { useTranslation } from 'react-i18next';

export const FormFieldsAll = ({ isNew, updateRecord, refetch, data }) => {
  const { t } = useTranslation();
  //
  const frmkCntx = useFormikContext();
  const { values } = frmkCntx;
  const { appStore } = useRootStore();

  const { handleGraphqlError } = useHandleError('PiBaseFormFields');

  function calcUserCanEdit() {
    const canEdit = appStore.isLogIn
      ? (appStore.isCollector && appStore.hasCPElevatedPerms) ||
        appStore.isEcoanvelope ||
        isNew ||
        appStore.isCollectionPoint
      : isNew;
    const canEditReasoning = '';
    return { canEdit, canEditReasoning };
  }
  // const canEdit =
  //   !(values as any)?.isFinalized && (authStore.userCanDraft(userRoleId) || authStore.userCanFinalize(userRoleId));
  const { canEdit, canEditReasoning } = calcUserCanEdit();

  return (
    <>
      <Row>
        <Col>
          <h5 className="form-group-title">{t('Contact Information')}</h5>
          <div className="d-flex flex-row">
            <FormCol>
              <FieldInput
                disabled={!canEdit}
                label={t('Contact Person')}
                name="contactperson"
                type="text"
                placeholder={t('- specify a value - ')}
                required={false}
              />

              <FieldInput
                disabled={!canEdit}
                label={t('Phone')}
                name="phone"
                type="text"
                placeholder={t('- specify a value - ')}
                required={false}
              />

              <FieldInput
                disabled={!canEdit}
                label={t('Mobile')}
                name="mobile"
                type="text"
                placeholder={t('- specify a value - ')}
                required={false}
              />
            </FormCol>

            <FormCol>
              <FieldInput
                disabled={!canEdit}
                label={t('Fax')}
                name="fax"
                type="text"
                placeholder={t('- specify a value - ')}
                required={false}
              />
              <FieldInput
                disabled={!canEdit}
                label={t('E-mail')}
                name="email"
                type="text"
                placeholder={t('- specify a value - ')}
                required={false}
              />

              <FieldInput
                disabled={!canEdit}
                label={t('Web Site Address')}
                name="website"
                type="text"
                placeholder={t('- specify a value - ')}
                required={false}
              />
            </FormCol>
          </div>
          <h5 className="form-group-title">{t('Operating Hours')}</h5>

          <FormRow>
            <FieldDatepicker
              disabled={!canEdit}
              as={FormCol}
              label={t('Monday to Friday from')}
              name="workDaysFrom"
              showTimeSelect
              showTimeSelectOnly
              dateFormat="h:mm aa"
              timeIntervals={15}
            />

            <FieldDatepicker
              disabled={!canEdit}
              as={FormCol}
              label={t('Till')}
              name="workDaysTill"
              showTimeSelect
              showTimeSelectOnly
              dateFormat="h:mm aa"
              timeIntervals={15}
            />
          </FormRow>

          <FormRow>
            <FieldDatepicker
              disabled={!canEdit}
              as={FormCol}
              label={t('Saturday from')}
              name="saturdayDaysFrom"
              showTimeSelect
              showTimeSelectOnly
              dateFormat="h:mm aa"
              timeIntervals={15}
            />

            <FieldDatepicker
              disabled={!canEdit}
              as={FormCol}
              label={t('Till')}
              name="saturdayDaysTill"
              showTimeSelect
              showTimeSelectOnly
              dateFormat="h:mm aa"
              timeIntervals={15}
            />
          </FormRow>

          <FormRow>
            <FieldDatepicker
              disabled={!canEdit}
              as={FormCol}
              label={t('Sunday from')}
              name="sundayDaysFrom"
              showTimeSelect
              showTimeSelectOnly
              dateFormat="h:mm aa"
              timeIntervals={15}
            />

            <FieldDatepicker
              disabled={!canEdit}
              as={FormCol}
              label={t('Till')}
              name="sundayDaysTill"
              showTimeSelect
              showTimeSelectOnly
              dateFormat="h:mm aa"
              timeIntervals={15}
            />
          </FormRow>

          <h5 className="form-group-title">{t('Additional Information')}</h5>
          <div className="d-flex flex-row">
            <FieldTextArea
              disabled={!canEdit}
              as={FormCol6}
              label={t('Notes')}
              name="notes"
              type="text"
              rows={4}
              placeholder={t('')}
              required={false}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
