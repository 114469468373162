import gql from 'graphql-tag';

export const gqlGetQuery = gql`
  query get($where: String, $offset: Int, $limit: Int, $order: String) {
    getAuditTrail(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        id
        auditDate
        auditType
        tableName
        tableRowId
        previousValues
        currentValues
        user
      }
      recordCount
    }
  }
`;

export const gqlGetByIdQuery = gql`
  query get($id: Int) {
    getAuditTrailById(id: $id) {
      id
      auditDate
      auditType
      tableName
      tableRowId
      previousValues
      currentValues
      user
    }
  }
`;
