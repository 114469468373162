import gql from 'graphql-tag';

export class FormInput {
  id: number;
  logDate: Date;
  logType: string;
  originatedFrom: string;
  grouping: string;
  message: string;
  user: string;
  clientIp: string;
}

export const gqlGetQuery = gql`
  query getApplog($limit: Int, $offset: Int, $order: String, $where: String) {
    getApplog(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        clientIp
        grouping
        id
        logDate
        logType
        message
        originatedFrom
        user
      }
      recordCount
    }
  }
`;

export const gqlGetByIdQuery = gql`
  query getApplogById($id: Int) {
    getApplogById(id: $id) {
      clientIp
      grouping
      id
      logDate
      logType
      message
      originatedFrom
      user
    }
  }
`;

export const gqlAdd = gql`
  mutation addApplog($data: ApplogInput) {
    addApplog(data: $data) {
      clientIp
      grouping
      id
      logDate
      logType
      message
      originatedFrom
      user
    }
  }
`;

export const gqlUpdate = gql`
  mutation updateApplog($data: ApplogInput) {
    updateApplog(data: $data) {
      clientIp
      grouping
      id
      logDate
      logType
      message
      originatedFrom
      user
    }
  }
`;

export const gqlDelete = gql`
  mutation deleteApplog($id: Int) {
    deleteApplog(id: $id)
  }
`;
