import gql from 'graphql-tag';

export class FormInput {
  id: number;
  value: number;
  lcid: number;
  name: string;
  isActive: boolean;
  firstDate: Date;
  lastDate: Date;
  firstUser: string;
  lastUser: string;
}

export const gqlGetQuery = gql`
  query getTirecollectionsStatuses($limit: Int, $offset: Int, $order: String, $where: String) {
    getTirecollectionsStatuses(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        firstDate
        firstUser
        id
        isActive
        lastDate
        lastUser
        lcid
        name
        value
      }
      recordCount
    }
  }
`;

export const gqlGetByIdQuery = gql`
  query getTirecollectionsStatusById($id: Int) {
    getTirecollectionsStatusById(id: $id) {
      firstDate
      firstUser
      id
      isActive
      lastDate
      lastUser
      lcid
      name
      value
    }
  }
`;

export const gqlAdd = gql`
  mutation addTirecollectionsStatus($data: TirecollectionsStatusInput) {
    addTirecollectionsStatus(data: $data) {
      id
    }
  }
`;

export const gqlUpdate = gql`
  mutation updateTirecollectionsStatus($data: TirecollectionsStatusInput) {
    updateTirecollectionsStatus(data: $data) {
      id
    }
  }
`;

export const gqlDelete = gql`
  mutation deleteTirecollectionsStatus($id: Int) {
    deleteTirecollectionsStatus(id: $id)
  }
`;
