/*****************************************************************************
 *  ΚΑΝΟΝΙΚΑ ΔΕΝ ΧΡΕΙΑΖΟΝΤΑΙ ΑΛΛΑΓΕΣ ΣΕ ΑΥΤΟ ΤΟ ΑΡΧΕΙΟ
 *  δημιουργεί το RootStore και το περνάει στο Context και κάνει export
 *  όλα τα helpers που χρειάζονται στη χρήση.
 *****************************************************************************/

import React, { createContext, FC, useContext } from 'react';

import { RootStore } from './root-store';

/** Create the instance of the store */
export const rootStore = new RootStore();
export const RootStoreContext = createContext(rootStore);

type Props = {};
export const RootStoreProvider: FC<Props> = (props) => {
  return <RootStoreContext.Provider value={rootStore}>{props.children}</RootStoreContext.Provider>;
};

/** Export Consumer as NameConsumer */
export const RootStoreConsumer = RootStoreContext.Consumer; // for use with class components
/** Export useContext Hook as useName */
export const useRootStore = () => useContext(RootStoreContext); // for use with Hooks
/**
 *  Export HOC as withName,
 *  and injected props as NameInjectedProps
 *  and props as name
 */
export const withRootStore = (Component) => (props) =>
  (
    <RootStoreContext.Consumer>
      {(context) => <Component rootStore={context} {...props} />}
    </RootStoreContext.Consumer>
  );
export type RootStoreInjectedProps = { rootStore: typeof rootStore };
