import React from 'react';
import { FormCol, FormRow, FieldInput, FieldCheckBox } from 'motion-components';
import { useRootStore } from 'store';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

const UserRoleForm = ({ isNew, data }) => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const { values } = useFormikContext<any>();

  const canEdit = authStore.isUsersAdmin;

  return (
    <>
      <FormRow>
        <FieldInput
          name="name"
          disabled={values?.isSystem || !canEdit}
          as={FormCol}
          label={t('User Role')}
          placeholder={`- ${t('User Role Name')} - `}
        />
        <FieldCheckBox
          disabled={values?.isSystem || !canEdit}
          as={FormCol}
          label={t('Active')}
          name="isActive"
        />
        <FieldCheckBox disabled as={FormCol} label={t('Fixed Record')} name="isSystem" />
      </FormRow>
    </>
  );
};

export default UserRoleForm;
