import React, { useEffect, useState } from 'react';
import { Field, useField } from 'formik';
import { FsProps, CustomSelectComponent } from 'motion-components';
import { useTranslation } from 'react-i18next';

export const FieldSelectAuditTableName = ({ label, name = 'tableName', ...rest }: FsProps) => {
  //
  const { t } = useTranslation();
  label = label ? label : t('Element Name');

  const options = [
    { value: 'users', label: t('Users') },
    { value: 'user_roles', label: t('User Roles') },
  ];

  const [extraOptions, setExtraOptions] = useState([]);
  const [value] = useField(name);

  useEffect(() => {
    if (value && options.filter((v) => v.value === value.value).length === 0) {
      const v = { value: value?.value, label: value?.value };
      setExtraOptions([...options, v]);
    } else setExtraOptions([...options]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Field
      name={name}
      title={label}
      component={CustomSelectComponent}
      // options={[...options, ...extraOptions]}
      options={extraOptions}
      useCreatableSelect={true}
    />
  );
};
