import React from 'react';
import { Col } from 'react-bootstrap';
import { FieldDatepicker, FieldInput, FieldTextArea } from 'motion-components';
import { useTranslation } from 'react-i18next';
import { FieldSelectValMethod } from 'components';

export const TabValorisation = ({ readonly }) => {
  const { t } = useTranslation();
  function calcUserCanEdit() {
    const canEdit = !readonly;
    const canEditReasoning = '';
    return { canEdit, canEditReasoning };
  }
  // const canEdit =
  //   !(values as any)?.isFinalized && (authStore.userCanDraft(userRoleId) || authStore.userCanFinalize(userRoleId));
  const { canEdit, canEditReasoning } = calcUserCanEdit();
  return (
    <>
      <h5 className="form-group-title-bg">{t('Valorisation Process')}</h5>
      <FieldSelectValMethod disabled={!canEdit} as={Col} />
      <FieldDatepicker
        disabled={!canEdit}
        as={Col}
        label={t('Valorification Restricted Until Date')}
        name="valRestrictedUntilDate"
        showTimeSelect
        dateFormat="d/M/yyyy HH:mm"
        timeFormat="HH:mm"
        timeIntervals={15}
      />
      <FieldDatepicker
        disabled={!canEdit}
        as={Col}
        label={t('Valorisation Date')}
        name="valorisationDate"
        dateFormat="d/M/yyyy"
      />
      <FieldTextArea
        disabled={!canEdit}
        as={Col}
        label={t('Valorisation Notes')}
        name="valorisationNotes"
        rows={3}
      />

      <FieldInput
        disabled={!canEdit}
        as={Col}
        label={t('Valorisation Entered By')}
        name="valorisationEnteredBy"
        type="text"
        placeholder={t('- specify a value - ')}
        required={false}
      />
    </>
  );
};
