import { useLazyQuery } from '@apollo/client';
import { exportXlsModal } from 'motion-components';
import { gqlGetQuery } from '../graphql';
import { buildWhereFromFilterValues } from './filters';

export const exportColumns = [
  // { fieldName: "id", fieldTitle: "Id", fieldType: "TEXT" },
  { fieldName: 'id', fieldTitle: 'id', fieldType: 'TEXT' },
  { fieldName: 'statusId', fieldTitle: 'Status', fieldType: 'TEXT' },
  { fieldName: 'requestNo', fieldTitle: 'Delivery Request Number', fieldType: 'TEXT' },
  { fieldName: 'collectorId', fieldTitle: 'Collector', fieldType: 'TEXT' },
  { fieldName: 'platformId', fieldTitle: "Collector's Platform", fieldType: 'TEXT' },
  { fieldName: 'valoriserId', fieldTitle: 'Valoriser', fieldType: 'TEXT' },
  {
    fieldName: 'requestDate',
    fieldTitle: 'Planned Delivery - Request Approval',
    fieldType: 'DATETIME',
  },
  { fieldName: 'requestedBy', fieldTitle: 'Employee who entered the request', fieldType: 'TEXT' },
  { fieldName: 'approvedDate', fieldTitle: 'Approved Date', fieldType: 'DATETIME' },
  {
    fieldName: 'approvedBy',
    fieldTitle: 'Person who approved (judged) the delivery request',
    fieldType: 'TEXT',
  },
  { fieldName: 'approvalNotes', fieldTitle: 'Approval Notes', fieldType: 'TEXT' },
  { fieldName: 'deliveryNoteNo', fieldTitle: 'Delivery Note Number', fieldType: 'TEXT' },
  { fieldName: 'loadUnloadNoteNo', fieldTitle: 'Loading Unloading Number', fieldType: 'TEXT' },
  { fieldName: 'deliveredDate', fieldTitle: 'Delivered Date', fieldType: 'DATETIME' },
  { fieldName: 'vehPlateNo', fieldTitle: 'Vehicle Plate', fieldType: 'TEXT' },
  { fieldName: 'driver', fieldTitle: 'Driver', fieldType: 'TEXT' },
  { fieldName: 'weighingNoteNo', fieldTitle: 'Weighing Note Number', fieldType: 'TEXT' },
  { fieldName: 'grossWeight', fieldTitle: 'Gross', fieldType: 'TEXT' },
  { fieldName: 'tareWeight', fieldTitle: 'Tare', fieldType: 'TEXT' },
  { fieldName: 'deductWeight', fieldTitle: 'Container', fieldType: 'TEXT' },
  { fieldName: 'netWeight', fieldTitle: 'Net', fieldType: 'TEXT' },
  { fieldName: 'collectorNotes', fieldTitle: 'Collector Notes', fieldType: 'TEXT' },
  {
    fieldName: 'collectorWeightsBy',
    fieldTitle: 'Employee from Collector who declared the delivery',
    fieldType: 'TEXT',
  },
  { fieldName: 'weightNotes', fieldTitle: 'Valoriser Notes', fieldType: 'TEXT' },
  {
    fieldName: 'valoriserWeightsBy',
    fieldTitle: 'Employee from Valoriser who declared the delivery',
    fieldType: 'TEXT',
  },
  {
    fieldName: 'valRestrictedUntilDate',
    fieldTitle: 'Valorification Restricted Until Date',
    fieldType: 'DATETIME',
  },
  { fieldName: 'valorisationDate', fieldTitle: 'Valorisation Date', fieldType: 'DATETIME' },
  { fieldName: 'valorisationNotes', fieldTitle: 'Valorisation Notes', fieldType: 'TEXT' },
  {
    fieldName: 'valorisationEnteredBy',
    fieldTitle: 'Employee from Valoriser who declared the delivery',
    fieldType: 'TEXT',
  },
  { fieldName: 'valorisationMethodId', fieldTitle: "Collector's Platform", fieldType: 'TEXT' },
  { fieldName: 'distance', fieldTitle: 'Distance in km', fieldType: 'TEXT' },
  { fieldName: 'ecoanvelopeNotes', fieldTitle: 'Legacy Notes', fieldType: 'TEXT' },
  { fieldName: 'legacyCPDeliveryNoteNo', fieldTitle: 'CP Weighing Note Number', fieldType: 'TEXT' },
  { fieldName: 'legacyCPGrossWeight', fieldTitle: 'CP Gross', fieldType: 'TEXT' },
  { fieldName: 'legacyCPTareWeight', fieldTitle: 'CP Tare', fieldType: 'TEXT' },
  { fieldName: 'legacyCPDeductWeight', fieldTitle: 'CP Container', fieldType: 'TEXT' },
  { fieldName: 'legacyCPNetWeight', fieldTitle: 'CP Net', fieldType: 'TEXT' },
  { fieldName: 'legacyRPDeliveryNoteNo', fieldTitle: 'RP Weighing Note Number', fieldType: 'TEXT' },
  { fieldName: 'legacyRPGrossWeight', fieldTitle: 'RP Gross', fieldType: 'TEXT' },
  { fieldName: 'legacyRPTareWeight', fieldTitle: 'RP Tare', fieldType: 'TEXT' },
  { fieldName: 'legacyRPDeductWeight', fieldTitle: 'RP Container', fieldType: 'TEXT' },
  { fieldName: 'legacyRPNetWeight', fieldTitle: 'RP Net', fieldType: 'TEXT' },
  { fieldName: 'isActive', fieldTitle: 'Is Active Record', fieldType: 'TEXT' },
  { fieldName: 'firstDate', fieldTitle: 'First Date', fieldType: 'DATETIME' },
  { fieldName: 'lastDate', fieldTitle: 'Last Date', fieldType: 'DATETIME' },
  { fieldName: 'firstUser', fieldTitle: 'First Usr', fieldType: 'TEXT' },
  { fieldName: 'lastUser', fieldTitle: 'Last User', fieldType: 'TEXT' },
];

// export function useGetQuery(): any {
//   const [get, { loading }] = useLazyQuery(gqlGetQuery, { onCompleted: transformData });

//   const [result, setResult] = useState<any>([get, { loading, data: undefined }]);

//   function transformData(data) {
//     const recs = data.getFormsDefinitions.data.map(values => {
//       return {
//         ...values,
//         solvingPeriod: periodQtyUnitToString(values.solvingPeriodQty, values.solvingPeriodUnit),
//         restoringPeriod: periodQtyUnitToString(values.restoringPeriodQty, values.restoringPeriodUnit),
//         pausingPeriod: periodQtyUnitToString(values.pausingPeriodQty, values.pausingPeriodUnit),
//         repeatingPeriod: periodQtyUnitToString(values.repeatingPeriodQty, values.repeatingPeriodUnit),
//       };
//     });

//     const trsData = { getFormsDefinitions: { data: recs, recordCount: data?.getFormsDefinitions?.recordCount } };
//     setResult([get, { loading, data: trsData }]);
//   }

//   return result;
// }

export async function handleExport(client, queryState: any): Promise<void> {
  function useGetQuery() {
    return useLazyQuery(gqlGetQuery);
    //return [null, { loading: null, data: null }];
  }
  const filterVariables = {
    where: buildWhereFromFilterValues(queryState?.filterValues),
  };
  await exportXlsModal({
    apolloClient: client,
    exportFileName: 'export Tiredeliveries',
    getLazyQuery: useGetQuery,
    columnsDef: exportColumns,
    filterVariables: filterVariables,
  });
}
