import { ApolloClient, useMutation } from '@apollo/client';
import { useDbBase } from './db-base-service';
import gql from 'graphql-tag';
import Lo from 'lodash';

/*
    hook function
*/
export const useDb = () => {
  //
  // const { appStore } = useRootStore();
  // const client = useApolloClient();
  const dbBase = useDbBase();

  async function getValorisers(where: any): Promise<any> {
    const gqlGetValorisers = gql`
      query getValorisers($where: String, $offset: Int, $limit: Int, $order: String) {
        getValorisers(where: $where, offset: $offset, limit: $limit, order: $order) {
          data {
            id
          }
        }
      }
    `;
    const res = await dbBase.execQuery(gqlGetValorisers, { where: where }).catch((err) => {
      throw new Error(err);
    });

    if (res.data.getValorisers === null) {
      if ((res as any).errors) {
        throw new Error((res as any).errors[0].message);
      }
    }
    return res.data.getValorisers.data;
  }

  async function canAddTireColRequest(collectionPointId: any): Promise<any> {
    const gqlAddTirecollectionRequest = gql`
      mutation addTirescollectionRequest($collectionPointId: Int) {
        addTirescollectionRequest(collectionPointId: $collectionPointId) {
          collectionPointId
          canAdd
        }
      }
    `;
    const res = await dbBase
      .execMutation(gqlAddTirecollectionRequest, { collectionPointId: collectionPointId })
      .catch((err) => {
        throw new Error(err);
      });

    if (res.data.addTirescollectionRequest === null) {
      if ((res as any).errors) {
        throw new Error((res as any).errors[0].message);
      }
    }
    return res.data.addTirescollectionRequest;
  }

  async function getFilesrepositoryData(fileId: number): Promise<any> {
    const gqlGetFilesrepositoryById = gql`
      query getFilesrepositoryById($id: Int) {
        getFilesrepositoryById(id: $id) {
          id
          fileName
          mimeMediaType
        }
      }
    `;
    const res = await dbBase.execQuery(gqlGetFilesrepositoryById, { id: fileId }).catch((err) => {
      throw new Error(err);
    });
    if (res.data.getFilesrepositoryById === null) {
      if ((res as any).errors) {
        throw new Error((res as any).errors[0].message);
      }
    }
    return res.data.getFilesrepositoryById.data;
  }
  // export -------------------------------------------------------
  return {
    getFilesrepositoryData,
    getValorisers,
    canAddTireColRequest,
  };
};
