import React, { useEffect, useCallback, useMemo, useState } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery, useMutation, useQuery, useApolloClient } from '@apollo/client';

const gqlGetQuerySimple = gql`
  query getTireTypes($limit: Int, $offset: Int, $order: String, $where: String) {
    getTireTypes(limit: $limit, offset: $offset, order: $order, where: $where) {
      data {
        id
        name
      }
      recordCount
    }
  }
`;

export const useTireTypesQuery = () => {
  const [getTireTypes, { data: rawDataTT, loading: loadingTT }] = useLazyQuery(gqlGetQuerySimple, {
    fetchPolicy: 'network-only',
    variables: {
      order: 'sortOrder ASC',
      where: JSON.stringify({
        isActive: true,
      }),
    },
  });
  const tireTypesData = rawDataTT ? rawDataTT.getTireTypes.data : undefined;

  /* fetch data */
  useEffect(() => {
    getTireTypes();
  }, [getTireTypes]);

  return { tireTypesData };
};
