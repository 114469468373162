import React, { useCallback, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { collectionpointLogin, userLogin } from './graphql';
import { rootStore } from 'store';
import LoginForm from './login-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useHandleError, usePersistentLog } from 'services';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

const LoginPage = () => {
  /*-------------------- Body -----------------------------------------------*/
  const { t } = useTranslation();
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const history = useHistory();
  const location = useLocation();
  //const { from } = location.state || { from: { pathname: '/' } };
  const state: any = location.state || { from: { pathname: '/' } };
  const from = state.from;
  const { handleGraphqlError } = useHandleError('LoginPage');
  const { logError, logInfo } = usePersistentLog('LoginPage');

  console.log('[LoginPage] invoked from =', from);
  /*! -------------------- Handlers -------------------------------------------*/
  /**
   * * 1. ΠΑΤΗΘΗΚΕ ΤΟ SUBMIT -> ΕΚΤΕΛΩ ΤΟ QUERY (Callback)
   */
  // const handleSubmit = useCallback(
  //   (values, actions) => {
  //     //console.log('Submit', values, actions);
  //     actions.setSubmitting(false);
  //     setLoading(true);
  //     userLogin(client, values.name, values.password)
  //       .then((res) => {
  //         console.log('####### Login', res);
  //         //Διάβασε το User Type Name

  //         rootStore.appStore.login(values.name, res);
  //         // Persist Version to Database -----
  //         const msg = `web-app version: ${(global as any).appVersion}`;
  //         console.log('############ AppVersion', msg);
  //         logInfo('AppVersion', msg);

  //         // --------------------------- -----
  //         setLoading(false);
  //         history.replace(from);
  //       })
  //       .catch((err) => {
  //         handleGraphqlError('userLogin-get', err, t('Login Error'));
  //         setError(err);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   },
  //   [client, from, handleGraphqlError, history, logInfo, t],
  // );
  const handleSubmit = useCallback(
    async (values, actions) => {
      //console.log('Submit', values, actions);
      actions.setSubmitting(false);
      setLoading(true);
      const res = await userLogin(client, values.name, values.password).catch((err) => {
        handleGraphqlError('userLogin-get', err, t('Login Error'));
        setError(err);
        setLoading(false);
        return null;
      });
      if (res == null) return;

      console.log('####### Login', res);
      //Διάβασε το User Type Name

      rootStore.appStore.login(values.name, res);
      // Persist Version to Database -----
      const msg = `web-app version: ${(global as any).appVersion}`;
      console.log('############ AppVersion', msg);
      logInfo('AppVersion', msg);

      // --------------------------- -----
      setLoading(false);
      history.replace(from);
    },
    [client, from, handleGraphqlError, history, logInfo, t],
  );

  /*-------------------- Render ---------------------------------------------*/

  return (
    <div className="d-flex align-items-center justify-content-center" style={{ height: '85vh' }}>
      <div>
        <LoginForm onSubmit={handleSubmit} message={error} loading={loading} />
        <Button
          block
          variant="warning"
          size="sm"
          style={{ fontSize: '0.8rem', color: '#595959' }}
          onClick={() => {
            history.push('logincp');
          }}
        >
          {t('If you are a Collection Point without an account, press here')}
        </Button>
      </div>
    </div>
  );
};

export default LoginPage;
LoginPage.whyDidYouRender = true;
