import React from 'react';
import { Button, Modal, ModalProps, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { createModal } from 'react-modal-promise';
import { Form as FForm, Formik } from 'formik';
import { FieldInput, FieldDatepicker, FormCol } from 'motion-components';
import { UserTypeValues } from 'shared/constants';

type Props = {
  open: boolean;
  close: (result: any) => void;
  title: string;
  /** body of dialog */
  text: string | React.ReactNode;
  /** deny text */
  denyText?: string;
  type?: string;
  data?: any;
} & ModalProps;

const ModalInput = ({ open, close, title, denyText = 'CANCEL', data }: Props) => {
  const { t } = useTranslation();

  const handleOk = (chosenUserTypeId) => {
    const input = {
      chosenUserTypeId: chosenUserTypeId,
    };
    close(input);
  };
  return (
    <>
      <Modal show={open} size="lg" centered backdrop="static">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Formik initialValues={{ ...data }} onSubmit={(values) => {}}>
          <Form as={FForm}>
            <Modal.Body>
              <br />
              <Row>
                <Col>
                  <h5 className="form-group-title mb-2">{t('Collector')}</h5>
                  <FieldDatepicker
                    disabled={true}
                    as={Col}
                    label={t('Delivered Date')}
                    name="collectorDeliveredDate"
                    showTimeSelect
                    dateFormat="d/M/yyyy HH:mm"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                  />

                  <FieldInput
                    disabled={true}
                    as={Col}
                    label={t('Weighing Note Number')}
                    name="collectorWeighingNoteNo"
                    type="text"
                    placeholder={t('- specify a value - ')}
                    required={false}
                  />

                  <FieldInput
                    disabled={true}
                    as={Col}
                    label={t('Gross')}
                    name="collectorGrossWeight"
                    type="number"
                    placeholder={t('- specify a value - ')}
                    required={false}
                    suffixLabel={t('kg')}
                  />

                  <FieldInput
                    disabled={true}
                    as={Col}
                    label={t('Tare')}
                    name="collectorTareWeight"
                    type="number"
                    placeholder={t('- specify a value - ')}
                    required={false}
                    suffixLabel={t('kg')}
                  />

                  <FieldInput
                    disabled={true}
                    as={Col}
                    label={t('Deduction')}
                    name="collectorDeductWeight"
                    type="number"
                    placeholder={t('- specify a value - ')}
                    required={false}
                    suffixLabel={t('kg')}
                  />

                  <FieldInput
                    disabled={true}
                    as={Col}
                    label={t('Net')}
                    name="collectorNetWeight"
                    type="number"
                    placeholder={t('- specify a value - ')}
                    required={false}
                    suffixLabel={t('kg')}
                  />
                  <Button
                    as={FormCol}
                    variant="primary"
                    onClick={() => handleOk(UserTypeValues.Collector)}
                  >
                    {t('Choose Collector')}
                  </Button>
                </Col>

                <Col>
                  <h5 className="form-group-title-alt mb-2">{t('Valoriser')}</h5>
                  <FieldDatepicker
                    disabled={true}
                    as={Col}
                    label={t('Delivered Date')}
                    name="valoriserDeliveredDate"
                    showTimeSelect
                    dateFormat="d/M/yyyy HH:mm"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                  />

                  <FieldInput
                    disabled={true}
                    as={Col}
                    label={t('Weighing Note Number')}
                    name="valoriserWeighingNoteNo"
                    type="text"
                    placeholder={t('- specify a value - ')}
                    required={false}
                  />

                  <FieldInput
                    disabled={true}
                    as={Col}
                    label={t('Gross')}
                    name="valoriserGrossWeight"
                    type="number"
                    placeholder={t('- specify a value - ')}
                    required={false}
                    suffixLabel={t('kg')}
                  />

                  <FieldInput
                    disabled={true}
                    as={Col}
                    label={t('Tare')}
                    name="valoriserTareWeight"
                    type="number"
                    placeholder={t('- specify a value - ')}
                    required={false}
                    suffixLabel={t('kg')}
                  />

                  <FieldInput
                    disabled={true}
                    as={Col}
                    label={t('Deduction')}
                    name="valoriserDeductWeight"
                    type="number"
                    placeholder={t('- specify a value - ')}
                    required={false}
                    suffixLabel={t('kg')}
                  />

                  <FieldInput
                    disabled={true}
                    as={Col}
                    label={t('Net')}
                    name="valoriserNetWeight"
                    type="number"
                    placeholder={t('- specify a value - ')}
                    required={false}
                    suffixLabel={t('kg')}
                  />
                  <Button
                    as={FormCol}
                    variant="info"
                    onClick={() => handleOk(UserTypeValues.Valoriser)}
                  >
                    {t('Choose Valoriser')}
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              {/*  <Button variant="danger" onClick={() => close(false)}>
            {denyText}
          </Button> */}
              <Button variant="danger" onClick={() => close(false)}>
                {t(denyText)}
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

const promiseModalResolveTireDelivery = createModal(ModalInput);

export default promiseModalResolveTireDelivery;
