import React from 'react';
import { generatePath, useHistory } from 'react-router';
import { getRouteByName } from 'app/routes';
import { useTranslation } from 'react-i18next';
import { useRootStore } from 'store';
import { Dashboard4Collectionpoint } from './dashboard4collectionpoint';
import { Dashboard4Collector } from './dashboard4collector';
import { Dashboard4Ecoanvelope } from './dashboard4ecoanvelope';
import { Dashboard4Valoriser } from './dashboard4valoriser';
import { TirecollectionStatus } from 'shared/constants';

export enum IdxEnum {
  todayNewCollectionOrders,
  todayExecutedCollectionOrders,
  pendingCollectionOrders,
  todayPlannedDeliveries,
  todayExecutedDeliveries,
  unconfirmedDeliveries,
  unconfirmedDeliveriesCol,
  unconfirmedDeliveriesVal,
  unreconciledDeliveries,
  todayResolvedDeliveries,
  Index5,
}

export const Dashboard = () => {
  const history = useHistory<any>();
  const { appStore, authStore } = useRootStore();
  const { t } = useTranslation();

  const cardClicked = (value: IdxEnum) => {
    let loc: any;
    // ------ Collections ---------
    if (value === IdxEnum.todayNewCollectionOrders)
      loc = {
        pathname: generatePath(getRouteByName('Tirecollections')),
        state: {
          filterValues: { reqStartDate: new Date(), reqEndDate: new Date(), isActive: true },
        },
      };
    else if (value === IdxEnum.todayExecutedCollectionOrders)
      loc = {
        pathname: generatePath(getRouteByName('Tirecollections')),
        state: {
          filterValues: {
            statusId: TirecollectionStatus.COMPLETED,
            isActive: true,
          },
        },
      };
    else if (value === IdxEnum.pendingCollectionOrders)
      loc = {
        pathname: generatePath(getRouteByName('Tirecollections')),
        state: { filterValues: { statusId: TirecollectionStatus.PENDING, isActive: true } },
      };
    // ------ Deliveries ---------
    else if (value === IdxEnum.todayPlannedDeliveries)
      loc = {
        pathname: generatePath(getRouteByName('Tiredeliveries')),
        state: {
          filterValues: { startDatePlan: new Date(), endDatePlan: new Date(), isActive: true },
        },
      };
    else if (value === IdxEnum.todayExecutedDeliveries)
      loc = {
        pathname: generatePath(getRouteByName('Tiredeliveries')),
        state: { filterValues: { startDate: new Date(), endDate: new Date(), isActive: true } },
      };
    else if (value === IdxEnum.unconfirmedDeliveries)
      loc = {
        pathname: generatePath(getRouteByName('Tiredeliveries-unconfirmed')),
      };
    else if (value === IdxEnum.unconfirmedDeliveriesCol)
      loc = {
        pathname: generatePath(getRouteByName('Tiredeliveries-col')),
      };
    else if (value === IdxEnum.unconfirmedDeliveriesVal)
      loc = {
        pathname: generatePath(getRouteByName('Tiredeliveries-val')),
      };
    else if (value === IdxEnum.unreconciledDeliveries)
      loc = {
        pathname: generatePath(getRouteByName('Tiredeliveries-unreconciled')),
      };
    else if (value === IdxEnum.todayResolvedDeliveries)
      loc = {
        pathname: generatePath(getRouteByName('Tiredeliveries-resolved')),
        state: { filterValues: { startDateRes: new Date(), endDateRes: new Date() } },
      };
    // ---------------------
    else loc = { pathname: generatePath(getRouteByName('Users')), state: { filterValues: {} } };
    history.push(loc);
  };

  if (appStore.isEcoanvelope) return <Dashboard4Ecoanvelope cardClicked={cardClicked} />;
  else if (appStore.isCollector) return <Dashboard4Collector cardClicked={cardClicked} />;
  else if (appStore.isValoriser) return <Dashboard4Valoriser cardClicked={cardClicked} />;
  else if (appStore.isCollectionPoint)
    return <Dashboard4Collectionpoint cardClicked={cardClicked} />;
  else return <></>;
};
