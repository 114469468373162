import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FieldDatepicker, FieldInput, UserTooltip } from 'motion-components';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  FieldSelectCollector,
  FieldSelectPlatform,
  FieldSelectValMethod,
  FieldSelectValoriser,
} from 'components';
import { TiresSection } from './tires-section';
import { FieldSelectValMethodValoriser } from 'components/fs-valorisation-method-valoriser';
import { useRootStore } from 'store';
import { FieldSelectTiredeliveriesStatuses } from 'components/fs-tiredelivery-status';
import { TireDeliveriesStatus } from 'shared/constants';
import { FieldSelectTiredeliveryType } from 'components/fs-tiredelivery-type';

export const FormFieldsHeader = ({ data }) => {
  const { t } = useTranslation();
  //
  const { appStore } = useRootStore();
  const frmkCntx = useFormikContext();

  const [canEdit, canEditReasoning] = [false, ''];

  return (
    <>
      {/* Header */}
      <Row>
        {/* 1st Column */}
        <Col>
          <FieldInput
            disabled={appStore.isValoriser}
            as={Col}
            label={t('Delivery Request Number')}
            name="requestNo"
            type="text"
            required={false}
          />
          <FieldDatepicker
            as={Col}
            label={t('Planned Delivery Date')}
            name="plannedDeliveryDate"
            showTimeSelect
            dateFormat="d/M/yyyy"
            timeFormat="HH:mm"
            timeIntervals={15}
            disabled={!(appStore.isEcoanvelope && data.statusId === TireDeliveriesStatus.Approved)}
          />
          <FieldInput
            disabled={true}
            as={Col}
            label={t('Collector')}
            name="collector.name"
            type="text"
            required={false}
          />
          {/* <FieldSelectCollector as={Col} disabled={!canEdit} /> */}
          <FieldInput
            disabled={true}
            as={Col}
            label={t('Platform')}
            name="collectorsPlatform.name"
            type="text"
            required={false}
          />
          {/* <FieldSelectPlatform as={Col} disabled={!canEdit} /> */}
          <FieldInput
            disabled={true}
            as={Col}
            label={t('Valoriser')}
            name="valoriser.name"
            type="text"
            required={false}
          />
          <FieldSelectTiredeliveryType
            label={t('Unloading Type')}
            disabled={appStore.isValoriser}
            as={Col}
          />
          {/* <FieldSelectValoriser as={Col} disabled={!canEdit} /> */}
          {appStore.isEcoanvelope && <FieldSelectTiredeliveriesStatuses as={Col} name="statusId" />}
        </Col>

        {/* 2nd Column : Tires*/}
        <Col>
          <div className="mt-4">
            <TiresSection
              isNew={appStore.isEcoanvelope && data.statusId === TireDeliveriesStatus.Approved}
              data={data}
            />
          </div>
        </Col>
        {/* 3rd Column : Weighing*/}
        <Col>
          <FieldDatepicker
            disabled={!canEdit}
            as={Col}
            label={t('Delivered Date')}
            name="deliveredDate"
            //showTimeSelect
            dateFormat="d/M/yyyy"
            timeFormat="HH:mm"
            timeIntervals={15}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Weighing Note Number')}
            name="weighingNoteNo"
            type="text"
            required={false}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Gross')}
            name="grossWeight"
            type="number"
            required={false}
            suffixLabel={t('kg')}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Tare')}
            name="tareWeight"
            type="number"
            required={false}
            suffixLabel={t('kg')}
          />

          <FieldInput
            disabled={!canEdit}
            as={Col}
            label={t('Net')}
            name="netWeight"
            type="number"
            required={false}
            suffixLabel={t('kg')}
          />
        </Col>
        {/* 4th Column : Valorisation */}
        <Col>
          <FieldDatepicker
            disabled={!canEdit}
            as={Col}
            label={t('Valorisation Date')}
            name="valorisationDate"
            dateFormat="d/M/yyyy"
          />
          <FieldDatepicker
            style={{ color: 'red' }}
            disabled={!canEdit}
            as={Col}
            label={t('Valorification Restricted Until Date')}
            name="valRestrictedUntilDate"
            dateFormat="d/M/yyyy"
          />
          <FieldSelectValMethodValoriser
            valoriserId={data?.valoriserId}
            as={Col}
            disabled={!canEdit}
          />
          <div className="float-right mt-4 mr-3">
            {data && <UserTooltip data={data} auditTable={'Tiredeliveries'} auditId={data.id} />}
          </div>
        </Col>
      </Row>
    </>
  );
};
