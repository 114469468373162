import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { generatePath, useHistory } from 'react-router';
import { Card } from 'react-bootstrap';
import './dashboard.scss';
import { getRouteByName } from 'app/routes';
import { useTranslation } from 'react-i18next';
import { TirecollectionStatus } from 'shared/constants';
import { IdxEnum } from './dashboard';

const doc = gql`
  query getEcoanvelopeDashboardIndexes {
    getEcoanvelopeDashboardIndexes {
      todayNewCollectionOrders
      todayExecutedCollectionOrders
      pendingCollectionOrders
      todayPlannedDeliveries
      todayExecutedDeliveries
      unconfirmedDeliveries
      unreconciledDeliveries
      todayResolvedDeliveries
    }
  }
`;

export const Dashboard4Ecoanvelope = ({ cardClicked }) => {
  const history = useHistory<any>();
  const { t } = useTranslation();
  const { data: rawData } = useQuery(doc, { fetchPolicy: 'network-only' });
  const {
    todayNewCollectionOrders,
    todayExecutedCollectionOrders,
    pendingCollectionOrders,
    todayPlannedDeliveries,
    todayExecutedDeliveries,
    unconfirmedDeliveries,
    unreconciledDeliveries,
    todayResolvedDeliveries,
  } = rawData?.getEcoanvelopeDashboardIndexes ?? {};

  if (!rawData) return <></>;
  else
    return (
      <div className="mt-5">
        {/* Row 1 Collections */}
        <h3
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          {t('Tire Collections')}
        </h3>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          <Card
            text="white"
            className="dash-card dash-card-blue dash-anim-1"
            onClick={() => cardClicked(IdxEnum.todayNewCollectionOrders)}
          >
            <blockquote className="blockquote mb-0 card-body">
              <h1 className="outline-text">{todayNewCollectionOrders}</h1>
              <footer>{t("Today's Tire Collection Orders")}</footer>
            </blockquote>
          </Card>
          <Card
            text="white"
            className="dash-card dash-card-green dash-anim-2"
            onClick={() => cardClicked(IdxEnum.todayExecutedCollectionOrders)}
          >
            <blockquote className="blockquote mb-0 card-body">
              <h1 className="outline-text">{todayExecutedCollectionOrders}</h1>
              <footer>{t("Today's Executed Tire Collections")}</footer>
            </blockquote>
          </Card>
          <Card
            text="white"
            className="dash-card dash-card-red dash-anim-3"
            onClick={() => cardClicked(IdxEnum.pendingCollectionOrders)}
          >
            <blockquote className="blockquote mb-0 card-body">
              <h1 className="outline-text">{pendingCollectionOrders}</h1>
              <footer>{t('Pending Tire Collections in total')}</footer>
            </blockquote>
          </Card>
        </div>
        {/* Row 2 Deliveries */}
        <h3
          className="dash-anim-2"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          {t('Tire Deliveries')}
        </h3>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          <Card
            text="white"
            className="dash-card dash-card-blue dash-anim-1"
            onClick={() => cardClicked(IdxEnum.todayPlannedDeliveries)}
          >
            <blockquote className="blockquote mb-0 card-body">
              <h1 className="outline-text">{todayPlannedDeliveries}</h1>
              <footer>{t("Today's Planned Deliveries")}</footer>
            </blockquote>
          </Card>

          <Card
            text="white"
            className="dash-card dash-card-green dash-anim-2"
            onClick={() => cardClicked(IdxEnum.todayExecutedDeliveries)}
          >
            <blockquote className="blockquote mb-0 card-body">
              <h1 className="outline-text">{todayExecutedDeliveries}</h1>
              <footer>{t("Today's Executed Deliveries")}</footer>
            </blockquote>
          </Card>

          <Card
            text="white"
            className="dash-card dash-card-red dash-anim-3"
            onClick={() => cardClicked(IdxEnum.unconfirmedDeliveries)}
          >
            <blockquote className="blockquote mb-0 card-body">
              <h1 className="outline-text">{unconfirmedDeliveries}</h1>
              <footer>{t('Unconfirmed Tire Deliveries')}</footer>
            </blockquote>
          </Card>

          <Card
            text="white"
            className="dash-card dash-card-red dash-anim-3"
            onClick={() => cardClicked(IdxEnum.unreconciledDeliveries)}
          >
            <blockquote className="blockquote mb-0 card-body">
              <h1 className="outline-text">{unreconciledDeliveries}</h1>
              <footer>{t('Unreconciled Tire Deliveries')}</footer>
            </blockquote>
          </Card>

          <Card
            text="white"
            className="dash-card dash-card-red dash-anim-3"
            onClick={() => cardClicked(IdxEnum.todayResolvedDeliveries)}
          >
            <blockquote className="blockquote mb-0 card-body">
              <h1 className="outline-text">{todayResolvedDeliveries}</h1>
              <footer>{t("Today's Resolved Tire Deliveries")}</footer>
            </blockquote>
          </Card>
        </div>
      </div>
    );
};
