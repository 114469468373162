import React, { useCallback } from 'react';
import { ButtonNewRecord, FlexRow, toastWarning, usePaginatedQueryV8 } from 'motion-components';
import { Card } from 'react-bootstrap';
import { useRootStore } from 'store';
import { useHandleError } from 'services';
import { gqlGetQuery } from '../graphql';
import { buildWhereFromFilterValues, Filters } from './filters';
import { DataGrid } from './grid';
import { handleExport } from './export-columns';
import { useApolloClient } from '@apollo/client';
import { getRouteByName } from 'app/routes';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/* ----------------------------------------------------------- */
/* ------------------------ Page ----------------------------- */
type Props = {
  collectionPointId?: number;
};

export const TirecollectionsGridPage = ({ collectionPointId }: Props) => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const { handleGraphqlError } = useHandleError('TirecollectionsGridPage');

  const location = useLocation<any>();
  const history = useHistory();
  /* ------------------- Query ------------------------------- */
  const initOrderBy = 'id DESC';
  const query = usePaginatedQueryV8(
    gqlGetQuery,
    buildWhereFromFilterValues,
    location.state?.order ?? initOrderBy,
    appStore,
    true,
    !!collectionPointId
      ? { isActive: true, collectionpointId: collectionPointId }
      : { isActive: true },
  );
  const {
    loading,
    data,
    dataCount,
    error,
    initPageIndex,
    filtersChanged,
    gridPageChanged,
    queryState,
    queryParams,
    setQueryParams,
  } = query;

  const client = useApolloClient();

  const canCreateNew = appStore.isEcoanvelope || appStore.isCollector || appStore.isCollectionPoint;
  const handleCreateNew = useCallback(() => {
    if (appStore.isBlacklisted) {
      toastWarning(t('You are blacklisted. Please contact Eco Anvelope'));
    } else {
      const path: any = appStore.isCollectionPoint
        ? generatePath(getRouteByName('Tirecollection'), { id: -1 })
        : generatePath(getRouteByName('NewTirecollection'));
      history.push({
        pathname: path,
        state: {
          pathname: location.pathname,
          collectionPointId: appStore.isCollectionPoint ? appStore.collectionpointId : undefined,
          ...location.state,
        },
      });
    }
  }, [history, location.pathname, location.state]);

  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <Card className="shadow">
        <Card.Header>
          <FlexRow>
            <Card.Title className="m-0">{t('Tire Collections')}</Card.Title>
            {canCreateNew && (
              <ButtonNewRecord
                label={t(
                  appStore?.isCollectionPoint
                    ? t('New Tire Collection Request')
                    : t('New Tire Collection'),
                )}
                onClick={handleCreateNew}
              />
            )}
          </FlexRow>
        </Card.Header>
        <Card.Body className="nopadding">
          {/* Φίλτρα */}
          <Filters onChange={filtersChanged} initialValues={queryState?.filterValues ?? {}} />
          {/* Grid */}
          <DataGrid
            loading={loading}
            data={data}
            dataCount={dataCount}
            initPageIndex={initPageIndex}
            onPageChange={gridPageChanged}
            handleExport={() => {
              handleExport(client, queryState, t);
            }}
            initOrderBy={queryParams?.order ?? initOrderBy}
            onOrderChange={(orderBy) => {
              const newQueryParams = { ...queryParams, order: orderBy };
              setQueryParams(newQueryParams);
              history.replace({
                pathname: location.pathname,
                state: {
                  ...location.state,
                  order: orderBy,
                },
              });
            }}
          />
        </Card.Body>
      </Card>
    );
  }
};
