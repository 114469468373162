import { useQuery } from '@apollo/client';
import ModalFlex from 'components/ModalFlex';
import { saveAs } from 'file-saver';
import { useWhyDidYouUpdate } from 'hooks';
import _ from 'lodash';
import React from 'react';
import { createModal } from 'react-modal-promise';
import { PDFDocument } from 'pdf-lib';
import { gqlGetByIdQuery } from 'pages/collectors/graphql';
//* ----- Report ----------------------------------------------------------------------------------------

const addText = (record, pdfDoc) => {
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  //Collector Name
  !!record.partnerName
    ? firstPage.drawText(record.partnerName, {
        x: 364,
        y: 414,
        size: 8,
      })
    : firstPage.drawText(record.name, {
        x: 364,
        y: 414,
        size: 8,
      });

  //Collector Contract No
  !!record.contractNo &&
    firstPage.drawText(record.contractNo.substring(0, record.contractNo.indexOf(' ')), {
      x: 438,
      y: 370,
      size: 8,
    });

  //Collector Additional Contract Info
  !!record.contractNo &&
    firstPage.drawText(record.contractNo.substring(record.contractNo.indexOf(' ')), {
      x: 386,
      y: 360,
      size: 8,
    });

  /*  //Collector Environmental Permit
  if (!!record.environmentalPermit) {
    if (record.environmentalPermit.includes(',')) {
      firstPage.drawText(
        record.environmentalPermit.substring(0, record.environmentalPermit.indexOf(',')),
        {
          x: 365,
          y: 245,
          size: 8,
        },
      );
      firstPage.drawText(
        record.environmentalPermit.substring(record.environmentalPermit.indexOf(',') + 1),
        {
          x: 364,
          y: 228,
          size: 8,
        },
      );
    } else {
      firstPage.drawText(record.environmentalPermit, {
        x: 365,
        y: 245,
        size: 8,
      });
    }
  }

  //Collector Environmental Permit Expiration Date
  !!record.envPermitExpirationDate &&
    firstPage.drawText(record.envPermitExpirationDate, {
      x: 394,
      y: 173,
      size: 8,
    }); */
};

//* ----- Modal ----------------------------------------------------------------------------------------
//
type Props = {
  collectorId: number;
  isOpen: boolean; // for modal
  onResolve: () => void; // for modal
};

const PrintReport = ({ isOpen, onResolve, collectorId }: Props) => {
  const { data, loading } = useQuery(gqlGetByIdQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { id: collectorId },
    onCompleted: async (data) => {
      const record = data.getCollectorById;
      const url = process.env.REACT_APP_S3BUCKET + '/documents/LoadingUnloadingCP.pdf';
      const existingPdfBytes = await fetch(url, { cache: 'no-cache' }).then((res) =>
        res.arrayBuffer(),
      );
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      addText(record, pdfDoc);
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes]);
      saveAs(blob, `Incarcare-Descarcare.pdf`);
      onResolve();
    },
  });

  useWhyDidYouUpdate('[PrintReport]', { data, loading });
  return <ModalFlex isOpen={isOpen} />;
};

export default createModal<Props, void>(PrintReport);
