import React, { useMemo, useCallback } from 'react';
import { useRootStore } from 'store';
import {
  BootstrapSwitchButton,
  ButtonNewRecord,
  CellCheckbox,
  CellLeft,
  CellYesNoNull,
  TableSubSimple,
  toastSuccess,
} from 'motion-components';
import { gql, useMutation } from '@apollo/client';
import { useHandleError } from 'services';
import { useTranslation } from 'react-i18next';
import promiseModalSelectCounty from 'components/modal-select-county';
import { gqlAddCollectorsCounty, gqlDeleteCollectorsCounty } from '../graphql';
import { Button } from 'react-bootstrap';

export const ColCountiesTable = ({
  data,
  collectorId,
  dataCount = 0,
  initPageIndex = 1,
  onPageChange = () => {},
  handleExport = () => {},
  refetch,
}) => {
  const { t } = useTranslation();
  const { appStore, authStore } = useRootStore();
  const { handleGraphqlError } = useHandleError('ColCountiesTable ');
  const [addCollectorsCounty] = useMutation(gqlAddCollectorsCounty);
  const [deleteCollectorsCounty] = useMutation(gqlDeleteCollectorsCounty);

  const handleCreateNew = async () => {
    const res = await promiseModalSelectCounty({
      title: `${t('Add County')}`,
      text: 'Choose County',
      collectorId: collectorId,
    });

    if (res) {
      addCollectorsCounty({
        variables: { data: { countyId: res.countyId, collectorId: collectorId } },
      })
        .then(() => {
          toastSuccess(`${t('Record successfully added')}`);
          refetch();
        })
        .catch((error) => {
          handleGraphqlError('mutation-add', error);
        });
    }
  };

  const handleWithdrawCounty = useCallback(
    (row) => {
      if (
        !window.confirm(
          `${t('Are you sure you want to remove the county?')} (${row.original.county.name})`,
        )
      )
        return;

      deleteCollectorsCounty({
        variables: { id: row.original.id },
      })
        .then(() => {
          refetch();
          toastSuccess(`${t('County successfully removed')}`);
        })
        .catch((err) => {
          handleGraphqlError('mutation-deleteCollectorsCounty', err);
        });
    },
    [deleteCollectorsCounty, handleGraphqlError, refetch, t],
  );

  const columns = useMemo(
    () => [
      {
        Header: t('County'),
        accessor: 'county.name',
        Cell: CellLeft,
        width: '100',
        isVisible: false,
      },
      {
        Header: t('Active'),
        accessor: 'isActive',
        Cell: CellYesNoNull,
        width: '100',
        isVisible: false,
      },
      ...(appStore.isEcoanvelope || appStore.isCollector
        ? [
            {
              id: 'withdrawCity',
              Header: `${t('Action')}`,
              accessor: 'action',
              width: '30',
              Cell: ({ row }) => (
                <div className="text-center">
                  <Button
                    variant="link"
                    style={{ color: 'Red' }}
                    onClick={() => {
                      handleWithdrawCounty(row);
                    }}
                  >
                    {t('Remove County')}
                  </Button>
                </div>
              ),
            },
          ]
        : []),
    ],
    [authStore.isUsersAdmin],
  );
  return (
    <div>
      <h5 className="form-group-title mb-0 d-flex flex-row">
        {t('Collector Counties Connected')}

        <div style={{ marginLeft: 'auto', marginRight: '20px' }}>
          {(appStore.isCollector || appStore.isEcoanvelope) && (
            <ButtonNewRecord onClick={handleCreateNew} size="sm" />
          )}
        </div>
      </h5>
      <TableSubSimple title={t('Collector Counties')} columns={columns} data={data} />
    </div>
  );
};
