import { useQuery } from '@apollo/client';
import ModalFlex from 'components/ModalFlex';
import { saveAs } from 'file-saver';
import { useWhyDidYouUpdate } from 'hooks';
import _ from 'lodash';
import React from 'react';
import { createModal } from 'react-modal-promise';
import { PDFDocument } from 'pdf-lib';
import { gqlGetByIdQuery } from 'pages/tirecollections/graphql';
import { format, parseISO } from 'date-fns';
//* ----- Report ----------------------------------------------------------------------------------------

const addText = (record, pdfDoc) => {
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  //Collection Point Name
  !!record.collectionpoint?.name &&
    firstPage.drawText(record.collectionpoint?.name, {
      x: 364,
      y: 581,
      size: 8,
    });
  //Collection Point Address
  !!record.collectionpoint?.address &&
    firstPage.drawText(record.collectionpoint?.address, {
      x: 433,
      y: 552,
      size: 8,
    });

  //Collection Point City and County
  !!record.collectionpoint.city &&
    !!record.collectionpoint.countyId &&
    firstPage.drawText(record.collectionpoint.city + ', ' + record.collectionpoint.county?.name, {
      x: 385,
      y: 535,
      size: 8,
    });

  //Collector Name
  !!record.collector.partnerName
    ? firstPage.drawText(record.collector.partnerName, {
        x: 364,
        y: 414,
        size: 8,
      })
    : firstPage.drawText(record.collector?.name, {
        x: 364,
        y: 414,
        size: 8,
      });

  //Collector Contract No
  !!record.collector.contractNo &&
    firstPage.drawText(
      record.collector.contractNo.substring(0, record.collector.contractNo.indexOf(' ')),
      {
        x: 438,
        y: 370,
        size: 8,
      },
    );

  //Collector Additional Contract Info
  !!record.collector.contractNo &&
    firstPage.drawText(
      record.collector.contractNo.substring(record.collector.contractNo.indexOf(' ')),
      {
        x: 386,
        y: 360,
        size: 8,
      },
    );

  //Collector Platform
  !!record.collectorsPlatform?.name &&
    firstPage.drawText(record.collectorsPlatform?.name, {
      x: 373,
      y: 337,
      size: 8,
    });

  //Collector Address
  !!record.collectorsPlatform?.address &&
    firstPage.drawText(record.collectorsPlatform.address, {
      x: 430,
      y: 308,
      size: 8,
    });

  //Collector City and County
  !!record.collectorsPlatform?.city &&
    !!record.collectorsPlatform?.countyId &&
    firstPage.drawText(
      record.collectorsPlatform?.city + ', ' + record.collectorsPlatform.county?.name,
      {
        x: 382,
        y: 298,
        size: 8,
      },
    );

  //Collector Environmental Permit
  if (!!record.collectorsPlatform?.environmentalPermit) {
    if (record.collectorsPlatform?.environmentalPermit.includes(',')) {
      firstPage.drawText(
        record.environmentalPermit.substring(
          0,
          record.collectorsPlatform?.environmentalPermit.indexOf(','),
        ),
        {
          x: 365,
          y: 245,
          size: 8,
        },
      );
      firstPage.drawText(
        record.collectorsPlatform?.environmentalPermit.substring(
          record.collectorsPlatform?.environmentalPermit.indexOf(',') + 1,
        ),
        {
          x: 364,
          y: 228,
          size: 8,
        },
      );
    } else {
      firstPage.drawText(record.collectorsPlatform?.environmentalPermit, {
        x: 365,
        y: 245,
        size: 8,
      });
    }
  }

  //Collector Environmental Permit Expiration Date
  !!record.collectorsPlatform?.envPermitExpirationDate &&
    firstPage.drawText(
      format(parseISO(record.collectorsPlatform?.envPermitExpirationDate), 'dd/MM/yyyy'),
      {
        x: 394,
        y: 163,
        size: 8,
      },
    );
};

//* ----- Modal ----------------------------------------------------------------------------------------
//
type Props = {
  tireCollectionId: number;
  isOpen: boolean; // for modal
  onResolve: () => void; // for modal
};

const PrintReport = ({ isOpen, onResolve, tireCollectionId }: Props) => {
  const { data, loading } = useQuery(gqlGetByIdQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { id: tireCollectionId },
    onCompleted: async (data) => {
      const record = data.getTirecollectionById;
      const url = process.env.REACT_APP_S3BUCKET + '/documents/LoadingUnloadingCP.pdf';
      const existingPdfBytes = await fetch(url, { cache: 'no-cache' }).then((res) =>
        res.arrayBuffer(),
      );
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      addText(record, pdfDoc);
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes]);
      saveAs(blob, `${record.orderNo}.pdf`);
      onResolve();
    },
  });

  useWhyDidYouUpdate('[PrintReport]', { data, loading });
  return <ModalFlex isOpen={isOpen} />;
};

export default createModal<Props, void>(PrintReport);
