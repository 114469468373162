import React from 'react';
import { FieldSelectDB } from 'motion-components';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { FsProps } from 'motion-components';

const gqlGetValues = gql`
  query getValues($where: String, $offset: Int, $limit: Int, $order: String) {
    getCollectionpoints(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        id
        description: name
      }
      recordCount
    }
  }
`;

function useGetValues() {
  return useLazyQuery(gqlGetValues, { fetchPolicy: 'cache-first' });
}

export const FieldSelectCollectionpoint = ({
  label = 'Collectionpoint',
  name = 'collectionpointId',
  defaultValues = {},
  ...rest
}: FsProps) => {
  return (
    <FieldSelectDB
      {...rest}
      label={label}
      name={name}
      // @ts-ignore
      sbLabel={defaultValues?.collectionpoint?.name ?? undefined}
      hook={useGetValues}
    />
  );
};
