import React from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { createModal } from 'react-modal-promise';

type Props = {
  open: boolean;
  close: (result: boolean) => void;
  title: string;
  /** body of dialog */
  text: string | React.ReactNode;
  /** accept text */
  acceptText?: string;
} & ModalProps;

const ModalAlert = ({ open, close, title, text, acceptText = 'OK' }: Props) => {
  return (
    <>
      <Modal show={open} centered backdrop="static">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{text}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => close(true)}>
            {acceptText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const promiseModalAlert = createModal(ModalAlert);
export default promiseModalAlert;
